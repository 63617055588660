:root {
  --primaryColor: #118936;
  --primaryColor-hover: #18c04d;
  --primaryColor-error: #db2828;
  --primaryColor-blue: #4054b2;
  --primaryColor-grey: #777;
  --statusColorr-blue: #4298a9;
  --statusColor-lightBlue: #e0faff;
  --statusColor-brown: #b38c3e;
  --statusColor-cream: #fcf0d1;
}

@font-face {
  font-family: "SFProText";
  src: local("SFProText"),
    url(../fonts/SF-Pro-Text-Regular.otf) format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "SFProText";
  src: local("SFProText"),
    url(../fonts/SF-Pro-Text-Medium.otf) format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "SFProText";
  src: local("SFProText"), url(../fonts/SF-Pro-Text-Bold.otf) format("opentype");
  font-weight: bold;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  /* font-family: "Roboto", sans-serif !important; */
  /* font-family: -apple-system, BlinkMacSystemFont, sans-serif !important; */
  background-color: #f8f8f8;
  font-family: SFProText, -apple-system, BlinkMacSystemFont, sans-serif !important;
}

.error-red {
  color: var(--primaryColor-error) !important;
}

.primaryColor-green {
  color: var(--primaryColor) !important;
}

.primaryColor-blue {
  color: var(--primaryColor-blue) !important;
}

.clickable {
  cursor: pointer;
}

.click-disable {
  cursor: not-allowed !important;
}

#root {
  background-color: #f8f8f8;
}

.headerSpaceRouter {
  /* margin-top: 4.38rem; */
  padding-top: 4.38rem;
  background: #fff;
}

.headerSpaceRouter-the-drop {
  padding-top: 8.94rem !important;
}

iframe {
  pointer-events: none;
}
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}
/* 
320px — 480px: Mobile devices.
481px — 768px: iPads, Tablets.
769px — 1024px: Small screens, laptops.
1025px — 1200px: Desktops, large screens.
1201px and more — Extra large screens, TV. 
*/

@media screen and (max-width: 1000px) {
  .headerSpaceRouter {
    /* margin-top: 6.4rem; */
    margin-top: 2rem;
  }

  .headerSpaceRouter-the-drop {
    padding-top: 15rem !important;
  }
}
/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
/* @media screen and (device-aspect-ratio: 2/3) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }
} */

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
/* @media screen and (device-aspect-ratio: 40/71) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }
} */

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
/* @media screen and (device-aspect-ratio: 375/667) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="url"] {
    font-size: 16px;
  }
} */

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
/* @media screen and (device-aspect-ratio: 9/16) {
  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="url"] {
    font-size: 16px;
  }
} */
