  .PlaceBid {
    padding: 2.5rem;
    display: flex;
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    background-color: white;
  }

  .ToolTipPlaceBid {
    display: flex;
    align-items: center;
  }
  
  