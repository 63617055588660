.skilllisting-panel-page  {
  display: flex;
  flex-direction: column;
  width: 45rem;
  /* width: 100%; */
}

.skilllisting-panel-page  .skilllisting-title-counts {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1.25rem;
}

.skilllisting-title-counts .title {
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.skilllisting-title-counts .count {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #7e7e7e;
}

.skilllisting-panel {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* justify-content: center; */
}

.skilllisting-panel .card-inner-box {
  width: 21.88rem;
  height: max-content;
  /* margin: 0.63rem 0.63rem 105.63rem 1.88rem; */
  /* padding: 0 1.25rem 2.19rem 0; */
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
}

.skilllisting-panel .create-skilllisting-tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 21.88rem;
  min-height: 10.44rem;
  /* margin: 0.63rem 0.63rem 105.63rem 1.88rem; */
  /* padding: 0 1.25rem 2.19rem 0; */
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
  cursor: pointer;
}

.skilllisting-panel .create-skilllisting-tab span {
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
}

@media screen and (max-width: 720px) {
  .ProfileTooltipsSection {
    display: none;
  }
}
