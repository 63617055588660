.skilllisting-add-button {
  margin-top: 0.25rem;
  /* margin-left: 0.625rem; */
  height: 0.75rem;
  width: 0.75rem;
  color:#fff;
}

.add-skill-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* .TooltipDashboard {
  padding-left: .625rem;
} */


@media screen and (max-width: 768px) {
.skilllisting-add-button {
  color:#118936;
  margin-top: 0.17rem;
}
}

 