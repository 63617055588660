.NoViewCardContainer {
  display: flex;
  gap: 2.5rem;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 6.375rem 0rem 8.25rem;
}

.NoViewCardContainer .NoViewCardText {
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 768px) {
  .NoViewCardContainer {
    gap: 1.875rem;
    padding: 3.125rem 0rem 4.25rem;
  }

  .NoViewCardContainer .NoViewCardText {
    font-size: 0.875rem;
  }
}
/********** For Mobile View  Media Query End **********/
