.carousel-Image {
  background-image: url("../../../assets/img/Carousells.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed;
  background-position: center; */
}

.carousel-Image-2 {
  background-image: url("../../../assets/img/Carouselltwo.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed;
  background-position: center; */
}

@media screen and (max-width: 480px) {
  .carousel-Image {
    /* background-size: 100% 100%; */
    background-image: url("../../../assets/img/carouselMobile1.png");
  }

  .carousel-Image-2 {
    /* background-size: 100% 100%; */
    background-image: url("../../../assets/img/carouselMobile2.png");
  }

  .carousel-inbox-main1 {
    height: 20.5rem;
  }

  .carousel-section {
    height: 20.5rem;
  }

  .carousel-inbox-main-first {
    padding: 13.688rem 1.25rem 0px !important;
  }
}

.carousel-inbox-main1 {
  border-radius: 0.25rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  /* textAlign: 'center'; */
  text-align: "flex-start";
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.carousel-section {
  color: #fff;
  display: flex;
  flex-direction: column;
  /* textAlign: 'center'; */
  text-align: "flex-start";
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.dashboard-carousel .ant-carousel .slick-dots li button {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  text-align: "flex-start";
}

.dashboard-carousel .ant-carousel .slick-dots li.slick-active {
  width: 0rem !important;
}

.dashboard-carousel .ant-carousel .slick-dots li {
  width: 0rem !important;
  height: 0rem !important;
  margin-right: 1.125rem !important;
  margin-left: 0rem !important;
}

.dashboard-carousel .ant-carousel .slick-dots {
  margin-top: 1.15% !important;
  justify-content: flex-start !important;
  margin-right: 0rem !important;
  margin-left: 2.89% !important;
}

.carousel-inbox-main-first {
  padding: 4.688rem 1.25rem 0px;
  font-size: 1.75rem;
  font-weight: 900;

  line-height: normal;

  text-align: left;
  color: #fff;
}

.carousel-inbox-main-second {
  padding: 0.625rem 1.25rem 3.688rem;
  font-size: 0.875rem;
  font-weight: 500;

  text-align: left;
  color: #fff;
}
