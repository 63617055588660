.ToolTipsAlert-Main {
    width: 22.0625rem;
    padding: .625rem .5625rem .625rem .625rem;
    border-radius: .3125rem;
    border: solid .0625rem #d4d4d4;
    background-color: #f2f2f2;
    margin-top: .4375rem;
    display: flex;
}

.ToolTipsAlert-Text {
    font-size: .6875rem;
    font-weight: normal;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    padding-left: .625rem;
}

.width {
    width: 100% !important;
}