.header {
  width: 100vw;
  /* box-shadow: 1px 1px 1px 1px lightgray; */
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  height: 4.38rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  background-color: #0f1e2b;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

/* .extra-drop-not-verified {
  top: 4.56rem !important;
} */

.ant-dropdown .ant-dropdown-placement-bottom > .ant-dropdown-arrow {
  top: 3.6rem;
  right: 26.8rem;
  position: fixed;
  left: unset;
}

/* .header .ant-select-open {
  width: 5rem;
} */

.wizard-head-dashboard .logout-buttons-header {
  display: flex;
  max-width: 21rem;
  width: 100%;
  justify-content: space-between;
}

.headerMenuRight {
  flex: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.headerBtn {
  display: grid;
  place-items: center;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: normal;
  min-height: 2.5rem;
  padding: 0rem 0.875rem;
  cursor: pointer;
  margin-right: 0.125rem;
}

.headerBtn > div {
  color: #fff;
}

.headerBtn:nth-child(3) {
  margin-right: 2.25rem;
}
.headerBtn:nth-child(4) {
  margin-right: 0.75rem;
}

.headerBtn:nth-child(4) .ant-badge-count {
  color: #000000;
}

.headerBtnIcon .ant-badge-count {
  box-shadow: 0 0 0 0.1875rem #0f1e2b;
}

.headerBtn:last-child {
  margin-right: 1.125rem;
}

.headerBtnIcon {
  max-width: 2.5rem;
  justify-content: center;
}

.header .headerBtnIcon img {
  display: block;
}

.headerBtn > a {
  color: #ffffff;
}

.headerBtn:hover {
  border-radius: 0.25rem;
  background-color: #09141e;
}

.black-header {
  background-color: #0f1e2b;
}

.header .logo {
  /* height: 3rem; */
  width: 7.38rem;
  cursor: pointer;
}

.wizard-head {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 1rem;
  line-height: 1rem;
}

.wizard-head .wizard-head-text {
  max-width: 80%;
}
.wizard-head .wizard-head-text h5 {
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0;
}
.wizard-head .wizard-head-text span {
  font-size: 0.6rem;
}

.wizard-head-dashboard {
  display: flex;
  flex: 1;
  justify-content: inherit;
  /* margin-left: 1rem; */
  height: inherit;
  padding-right: unset;
}

.header .ant-popover {
  position: fixed !important;
}

.wizard-head-dashboard .search-gigs .search-gigs .icons {
  /* max-width: 16rem; */
  width: 20vw;
  height: 2.3rem;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  padding-left: 40px;
  /* padding-top: 1rem; */
  /* padding-bottom: 1rem; */
  /* border: 1px solid gray; */
  margin-top: 1rem;
  outline: none;
  background-color: transparent;
  border-bottom: 1px solid #9e9e9e;
  border-top: 1px solid #9e9e9e;
  border-left: 1px solid #9e9e9e;
  /* border-right: 1px solid #9e9e9e; */
  border-bottom-right-radius: unset;
  border-top-right-radius: unset;
  border-right: none;
  color: white;
}

.wizard-head-dashboard .search-gigs .search-gigs .icons:hover {
  border-bottom: 1px solid #ededed;
  border-top: 1px solid #ededed;
  border-left: 1px solid #ededed;
  border-right: none;
}
.wizard-head-dashboard .search-gigs .ant-select-dropdown {
  min-width: 30rem;
}

.search-gigs button {
  text-transform: none;
  color: white;
  width: 8vw;
  height: 2.275rem;
  /* font-family: SFProText; */
  font-size: 14px;
  /* border: none; */
  background-color: green;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  padding-top: 1px;
  padding-bottom: 0px;
  /* border-bottom: 1px solid gray;
  border-top: 1px solid gray;
  border-right: 1px solid gray; */
  border: 1px solid green;
  cursor: pointer;
}

.search-gigs button:hover {
  background-color: darkgreen;
}

.wizard-head-dashboard .theAlign {
  display: flex;
  align-items: center;
  margin-left: 1.68rem;
}

.header .ant-dropdown-trigger {
  cursor: pointer;
}

.wizard-head-dashboard .profile {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  width: max-content;
}

.profile > span {
  padding: 0 0.3rem 0 0.7rem;
}

.clickSkillListing {
  height: 39px;
  gap: 10px;
  padding: 6px 3rem;
  border-radius: 3px;
  background-color: white;
  font-size: 16px;
  width: max-content;
  color: #118936;
  cursor: pointer;
  border: 1px solid #118936;
}

.Mob_Btn {
  display: grid;
}

/* .ant-drawer-header {
  background-color: rgba(17, 137, 54, 0.2);
} */
.DrowerHeader {
  display: flex;
  /* background-color: rgba(17, 137, 54, 0.2); */
  /* width: 10px; */
}
.Guestuserfont {
  /* width: 77px;
  height: 15px; */
  margin: 4px 41px 10px 20px;
  /* font-family: SFProText; */
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}
.welcomefont {
  /* width: 118px; */
  height: 14px;
  margin: 10px 0 15px 20px;
  font-size: 12px;
  font-weight: 400;
}

.signIN {
  width: 52px;
  height: 19px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* text-align: left; */
  color: #5e5e5e;
  /* font-family: SFProText; */
}

.General_btn {
  height: 19px;
  /* font-family: SFProText; */
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* text-align: center; */
  color: #3e3e3e;
  width: 60px;
  cursor: pointer;
}

.homeBtn {
  margin-top: 15px;
  width: 45px;
  /* font-family: SFProText; */
  font-size: 16px;
  font-weight: normal;
  height: 19px;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e;
  font-stretch: normal;
  cursor: pointer;
}

.Line-109 {
  width: 213px;
  height: 1px;
  margin-top: 17px;
  background-color: #e4e4e4;
}

.DrowerHeader img {
  width: 60px;
  height: 60px;
}

.wizard-head-dashboard .bars {
  display: none;
}

.btn_sign_In {
  margin-top: 1.36rem;
  cursor: pointer;
  min-width: fit-content;
  height: 19px;
  /* font-family: SFProText; */
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #fff;
}

.btn_home {
  cursor: pointer;
  /* font-family: SFProText; */
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
}

.wizard-head-dashboard img {
  display: none;
}

.mobile_button {
  display: none;
  cursor: pointer;
}

.clickSkillListinglogin {
  width: 10rem;
  height: 2.5rem;
  grid-gap: 6px;
  gap: 10px;
  padding: 0.63rem 0 0.69rem;
  border-radius: 0.31rem;
  background-color: var(--primaryColor);
  margin-top: 1.05rem;

  /*font*/
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.title-drawer-mobileView .circle-wrap-p {
  width: 3.75rem;
  height: 3.75rem;
  background-color: var(--primaryColor);
  border-radius: 50%;
}

.Avat {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: var(--primaryColor);
  font-size: 1rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  /* padding-top: 7px; */
}
/* 
320px — 480px: Mobile devices.
481px — 768px: iPads, Tablets.
769px — 1024px: Small screens, laptops.
1025px — 1200px: Desktops, large screens.
1201px and more — Extra large screens, TV. 
*/

.header-dropdown {
  width: 14.375rem;
  max-height: 89%;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  position: fixed;
}

.header-dropdown .ant-menu {
  /* padding: 0.56rem 0.1rem 0.56rem 1.25rem; */
  height: 100%;
}

.burgerMenuDrawerMobileView {
  z-index: 10000;
}

.burgerMenuDrawerMobileView .ant-drawer-body {
  padding: 1.25rem !important;
}

.profileAvatarDesktop > .ProfileAvatar .ant-badge-dot,
.profileAvatarDesktopOffline > .ProfileAvatar .ant-badge-dot {
  margin-top: 2.125rem !important;
  right: 0.4375rem !important;
  width: 0.625rem;
  height: 0.625rem;
  box-shadow: none;
}

.profileAvatarDesktop > .ProfileAvatar .ant-badge-dot {
  background: #5abb7a !important;
}
.profileAvatarDesktopOffline > .ProfileAvatar .ant-badge-dot {
  background: #9e9e9e !important;
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .wizard-head-dashboard .emailDash {
    margin: 0 1.2rem;
    width: max-content;
  }

  /* .searchInputDashboard {
    width: 45vw;
   
  } */
  .wizard-head-dashboard .search-gigs input {
    width: 75%;
  }
}

@media screen and (max-width: 1000px) {
  .header {
    height: 3.13rem;
  }

  /* .extra-drop-not-verified {
    top: 8.56rem !important;
  } */

  .black-header {
    /* background-color: black; */
    background-color: #0f1e2b;
    width: 100%;
  }

  .header .logo {
    width: unset;
    padding-left: unset;
    padding-right: unset;
    height: 1.5625rem;
    /* padding-left: 4rem; */
  }

  .header .logo-guest {
    width: unset;
    padding-left: unset;
    padding-right: unset;
    height: 2.4rem;
  }

  .wizard-head-dashboard .logout-buttons-header {
    display: flex;
    max-width: unset;
    width: unset;
    justify-content: space-between;
    margin-left: auto;
  }

  .wizard-head-dashboard .mobileDefaultButtons {
    display: flex;
    align-items: center;
    gap: 1.125rem;
    /* margin-top: 9px; */
    margin-left: auto;
  }

  /* Badge Red */
  .wizard-head-dashboard .bellIcon .ant-badge-dot {
    top: 3px;
    right: 3px;
    box-shadow: 0 0 0 1.5px #000;
  }

  .wizard-head-dashboard .bellIcon .ant-badge-count {
    color: #000;
    box-shadow: 0 0 0 0.0938rem #0f1e2b;
  }

  .bellIconDesktop {
    margin-right: -54px;
  }

  .wizard-head-dashboard .messageIconHeader .ant-badge-dot {
    top: 3px;
    right: 2px;
    box-shadow: 0 0 0 1.5px #000;
  }

  .wizard-head-dashboard .messageIconHeader .ant-badge-count {
    box-shadow: 0 0 0 0.0938rem #0f1e2b;
  }

  .headerMenuRight {
    display: none !important;
  }

  .wizard-head-dashboard {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    margin-left: unset;
    margin-right: unset;
    height: inherit;
    align-items: center;
  }

  .wizard-head-dashboard-guest {
    display: flex;
    flex: 1;
    justify-content: center;
    margin-left: unset;
    margin-right: unset;
    height: inherit;
    align-items: center;
  }

  .wizard-head-dashboard img {
    /* margin-left: 7rem;
    margin-right: auto; */
    display: block;
    margin-top: 4px;
  }

  .btn_sign_In {
    display: none;
    cursor: pointer;
  }

  .btn_home {
    display: none;
    cursor: pointer;
  }

  .mobile_button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 34px;
    /* font-family: SFProText; */
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }

  .Avatar {
    display: none;
  }

  .wizard-head-dashboard .clickSkillListing {
    display: flex;
    height: unset;
    padding: unset;
    border-radius: unset;
    background-color: unset;
    font-size: 16px;
    width: max-content;
    cursor: pointer;
    border: unset;

    /* font */
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  .wizard-head-dashboard .search-gigs input {
    width: 75%;
    height: 2.5rem;
    /* display: none; */
  }

  .search-gigs {
    width: 100%;
    padding: 0px 0px;
    display: block;
    /* width: 75vw; */
    /* margin-left: 0.5rem; */
  }

  .wizard-head-dashboard .bars {
    display: flex;
    align-items: center;
    font-size: 1.5625rem;
    margin-right: 1.25rem;
  }

  .wizard-head-dashboard .bars > img {
    margin-top: 0rem;
  }

  .wizard-head-dashboard .search-gigs input {
    margin-top: 0.9rem;
  }

  .header {
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 1000;
  }

  .RemoveSearchBarMobile {
    display: none !important;
  }

  /* .extra-drop-not-verified-search {
    top: 11.7rem !important;
  } */

  .search-header-mobile-view {
    height: 3.125rem;
    width: 100%;
    background-color: rgb(255, 255, 255);
    position: fixed;
    top: 3.25rem;
    z-index: 1000;
    display: flex;
    padding: 9px 15px;
    justify-content: center;
    /* align-items: center; */
    border-bottom: 1px solid #e4e4e4;
  }

  .search-header-mobile-view button {
    display: none;
  }

  .search-header-mobile-view input {
    border: none;
    border-radius: 4px;
    box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 1.88rem;
    color: auto;
    font-size: 16px;
  }

  .search-header-mobile-view input:focus {
    outline: none;
  }

  .icons {
    padding: 0.9rem 0 0.75rem 2.25rem;
    background: url("https://img.icons8.com/ios-filled/40/7e7e7e/search.png")
      no-repeat left;
    background-size: 1.25rem;
    background-position: 0.438rem;
  }

  .findJobsSearchBar {
    color: #7e7e7e;
  }
  .search-header-mobile-view input[type="text"]::placeholder {
    /* text-align: left; */
    color: #7e7e7e !important;
    margin-left: 1rem;
    font-size: 1rem !important;
  }

  .searchHeaderMobileViewLoggedIn {
    top: 0rem;
    padding: 0rem;
  }

  .searchHeaderMobileViewLoggedIn input,
  .searchHeaderMobileViewLoggedIn
    .ant-select:not(.ant-select-disabled):hover
    .ant-select-selector {
    height: 3.125rem;
  }

  .searchHeaderMobileViewLoggedIn .search-gigs .ant-select {
    width: 100vw;
  }

  .searchHeaderMobileViewLoggedIn
    .search-gigs
    .ant-select-selection-search-input
    .closeIcon {
    top: 0.9375rem;
    right: 0.625rem;
  }

  .search-header-mobile-view
    .search-gigs
    .ant-select-selection-search-input
    .evaArrowBackOutline {
    display: none;
  }

  .searchHeaderMobileViewLoggedIn
    .search-gigs
    .ant-select-selection-search-input
    .evaArrowBackOutline {
    display: block;
    position: absolute;
    min-width: 1.25rem;
    min-height: 1.25rem;
    top: 0.9375rem;
    left: 0.625rem;
    transition: all 1s ease-in-out;
  }

  .searchHeaderMobileViewLoggedIn .dropdown-menuu {
    width: 100%;
    max-width: unset;
    height: 90vh;
    border: none;
    top: auto;
  }

  .searchHeaderMobileViewLoggedIn .icons {
    padding: 0.9375rem 2.8125rem 0.9375rem 2.3125rem;
    background: none;
    font-weight: 600;
  }

  .searchHeaderMobileViewLoggedIn .mobileLoggedInSuggetion {
    padding: 0.625rem 0.9375rem 0rem;
  }

  .searchHeaderMobileViewLoggedIn .mobileLoggedInSuggetionOption:first-child {
    margin-top: 0.625rem;
  }

  .searchHeaderMobileViewLoggedIn .mobileLoggedInSuggetionOption {
    margin-bottom: 0.75rem;
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
    cursor: pointer;
  }
  .searchHeaderMobileViewLoggedIn .mobileLoggedInSuggetionOption:last-child {
    margin-bottom: 0rem;
  }

  .searchHeaderMobileViewLoggedIn .dropdown-menuu-insidebox {
    justify-content: space-between;
  }

  .burgerMenuDrawerMobileView .ant-drawer-body {
    padding: 3.2px 1.25rem !important;
  }

  .burgerMenuDrawerMobileView .ant-drawer-header {
    padding: 1.25rem 1.25rem 0.813rem;
    /* height: 6.25rem; */
    background-color: rgba(17, 137, 54, 0.2);
  }

  .burgerMenuDrawerMobileView .ant-drawer-title {
    height: 100%;
  }

  .burgerMenuDrawerMobileView .title-drawer-mobileView {
    display: flex;
    gap: 1.2rem;
    height: 100%;
  }

  .title-drawer-mobileView .username-header {
    display: flex;
    align-items: center;
    /*font*/
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }

  .username-header .star-title-BM {
    padding: 0 6px 0 10px;
  }

  .username-header .rating-title-BM {
    font-size: 0.88rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
  }

  .burgerMenuDrawerMobileView .Avat {
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
    background: var(--primaryColor);
    padding-top: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    /* font */
    font-size: 1.25rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  .title-drawer-mobileView .ant-badge-dot {
    z-index: auto;
    width: 0.63rem;
    min-width: 0.63rem;
    height: 0.63rem;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #fff;
    top: 3.2rem;
    right: 0.6rem;
  }

  .title-drawer-mobileView .ant-badge-status-success {
    background-color: var(--primaryColor);
  }

  .title-drawer-mobileView .title-data {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.625rem;
    /* justify-content: space-between; */
  }

  .title-drawer-mobileView .hired {
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
  }

  .title-drawer-mobileView .profession-change {
    display: flex;
    justify-content: space-between;
  }

  .profession-change .profession-title {
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
  }

  .profession-change .change-title {
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #4054b2;
  }

  .wizard-head-dashboard .theAlign {
    min-width: 3.4375rem;
    justify-content: flex-end;
  }

  .profileAvatarDesktop > .ProfileAvatar .ant-badge-dot,
  .profileAvatarDesktopOffline > .ProfileAvatar .ant-badge-dot {
    margin-top: 0rem !important;
    right: 0.625rem !important;
  }
}
