.Interview_Details_GigsCard {
  display: flex;
  background: #fff;
  gap: 0.9375rem;
  width: 24.375rem;
  padding: 1.25rem 0.9375rem;
  border: solid 0.0625rem #e4e4e4;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.25);
}
.Interview_Avatar {
  position: relative;
  cursor: pointer;
}

.Interview_Avatar .Img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}

.Interview_Avatar .Without_Logo {
  display: grid;
  place-items: center;
  background-color: #e4e4e4;
}

.Interview_Active_Bubble {
  position: absolute;
  right: 0rem;
  top: 1.75rem;
  width: 0.625rem;
  height: 0.625rem;
  border: solid 0.0625rem #fff;
  border-radius: 50%;
  background-color: #9e9e9e;
}

.Interview_Active {
  background-color: #008000;
}

.Interview_Content {
  width: 84.8%;
}

.Interview_Content div:first-child {
  cursor: pointer;
}

.Interview_Content p {
  margin: 0;
  margin-bottom: 0.625rem;
}

.Interview_Content p:first-child {
  margin-top: 0.125rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}
.Interview_Content p:nth-child(2) {
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #727272;
}

.Interview_Content p:nth-child(3) {
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
}

.Interview_Link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2rem;
  padding: 0.5rem 0.625rem 0.5rem 0.5625rem;
  border-radius: 0.125rem;
  border: solid 0.0625rem #eee;
  background-color: #fff;
}

.Interview_Link span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0.0625rem 0.5625rem 0.0625rem 0;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #888;
}

.Interview_Link img.bxbx-copy {
  width: 1rem;
  height: 1rem;
  object-fit: contain;
  cursor: pointer;
}

.Interview_Location {
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #727272;
  cursor: pointer;
}

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 768px) {
  .Interview_Details_GigsCard {
    gap: 15px;
    width: 100%;
    padding: 12px 13px 14px 15px;
    border-radius: 0px;
    background-color: #fff;
    box-shadow: none;
  }

  .Interview_Content {
    width: 83.3%;
  }

  .Interview_Content p {
    margin: 0;
  }

  .Interview_Content p:first-child {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .Interview_Content p:nth-child(2) {
    font-size: 12px;
    margin-bottom: 8px;
  }

  .Interview_Content p:nth-child(3) {
    font-size: 12px;
    margin-bottom: 8px;
  }

  .Interview_Link span {
    margin-right: 19px;
  }

  .Interview_Location {
    font-size: 12px;
    margin-bottom: 5px;
    color: #888;
  }
}
/********** For Mobile View  Media Query End **********/
