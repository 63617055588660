.root {
  width: auto;
  height: auto;
  background-color: #fff;
}
.time-sheet-container {
  margin: auto;
  width: 90%;
  background-color: #fff;
}
.bids-spin {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 200px;
}
.heading-timesheet {
  width: 124px;
  height: 29px;
  padding-top: 40px;
  padding-bottom: 30px;
  /* margin: 40px 136px 30px 80px; */
  /* font-family: SFProText; */
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 1%;
}
.heading-reports {
  width: 84px;
  height: 26px;
  /* margin: 30px 176px 20px 80px; */
  /* font-family: SFProText; */
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}
.date {
  height: 21px;
  flex-grow: 0;
  /* font-family: SFProText; */
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}
@media screen and (min-width: 320px) and (max-width: 450px) {
  .date {
    height: 21px;
    flex-grow: 0;
    /* font-family: SFProText; */
    font-size: 14px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
  }
}
/* .mobilePagination{
    background-color: aqua;
    
    
}
.paginationDestop:hover{
    
} */
.root .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 0;
  font-size: 18px;
}
.root .ant-notification-notice-btn {
  float: none;
  margin-left: 80%;
}
.root .ant-btn-sm {
  height: 100%;
  width: 100%;
  padding: 25px 7px;
  float: right;
  text-align: center;
}
.root .ant-btn-primary {
  border-color: aliceblue;
}
.notification-icon {
  height: 15%;
  width: 17%;
  margin-top: 4%;
  border-radius: 50%;
}
.hoursContainer {
  display: flex;
  justify-content: center;
}
.break-time{font-size: 16px;
text-align: left;
padding-left: 5px;
color:#7e7e7e;
margin-top: 38px;}

@media screen and (max-width: 768px) {
  .heading-reports {
    font-size: 18px;
    margin-top: 5%;
  }
  .heading-timesheet {
    font-size: 16px;
    padding-top: 20px;
  }
  .date{
    font-size: 14px;
  }

  .hoursContainer {
    justify-content: left;
  }
}