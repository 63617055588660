.JobDetails_left_text {
  padding-left: 1.25rem;
  color: #3e3e3e;
  font-weight: 600;
  font-size: 16px;
}

.JobDetails_selecter {
  padding-right: 1.25rem;
  color: #3e3e3e;
  position: relative;
}

.Selected_Option {
  border: solid 1px #c4c4c4;
  border-radius: 4px;
}
.JobDetails_selecter .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: green;
}
.Selected_Option_div {
  border-radius: 4px;
  background: white;
  min-height: 3rem;
}

/* .ant-select-selection--single {
    pointer-events: none;
} */
.Outerborder {
  border: solid 1px #c4c4c4;
  width: -webkit-fill-available;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 2px;
}

@media screen and (max-width: 480px) {
  .JobDetails_left_text {
    display: flex;
    padding: 1rem 0rem;
  }
  .Dashboard-Dropdown-Menu {
    display: flex;
    flex-direction: column;
  }

  .Outerborder select {
    padding-left: 0.3rem;
    border: 0px;
    outline: 0px;
  }
}
