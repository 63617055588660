.career-description {
  display: flex;
  justify-content: space-between;
}

.careerDescription .title {
  margin-bottom: 0.938rem !important;
}

.careerSummary .career-summary-subheading {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  margin-bottom: 1.875rem;
}

.careerSummary .clinical-assessment {
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  margin-top: 1.875rem;
  margin-bottom: 00.938rem;
}

.careerSummary .relias-CS {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  margin-bottom: 0.625rem;
}

.career-summary .startExam-CS {
  width: 6rem;
  height: 2rem;
  flex-grow: 0;
  /* margin: 0.63rem 11.19rem 0 0; */
  padding: 0.5625rem 0.9375rem 0.5625rem 0.9125rem;
  border-radius: 0.25rem;
  border: solid 0.06rem var(--primaryColor);

  /* font */
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primaryColor);
}

.textAreaCareerSummary .ant-input {
  width: 100%;
  background-color: #f2f2f2;
  border: none;
  resize: none;
}

.textAreaCareerSummary .ant-input:hover {
  border: none;
}

.textAreaCareerSummary .ant-input:focus,
.textAreaCareerSummary .ant-input-focused {
  box-shadow: none;
  border: none;
  outline: none;
}

.textAreaCareerSummary .ant-input::-webkit-scrollbar {
  width: 0.25rem;
}

.textAreaCareerSummary .ant-input::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 12px !important;
  cursor: pointer !important;
}

.careerSummary .user-description {
  overflow: auto;
  max-height: 12.94rem;
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  word-wrap: break-word;
}

.user-description::-webkit-scrollbar {
  width: 0.25rem;
}

.user-description::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 12px !important;
  cursor: pointer !important;
}

@media screen and (max-width: 768px) {
  .careerSummary .relias-CS {
    font-size: 1rem;
  }

  .careerSummary .startExam-CS {
    font-size: 0.88rem;
    text-align: center;
    padding: 0.4rem 0 0.56rem 0;
  }

  .career-description {
    width: 100%;
  }

  .textAreaCareerSummary .ant-input {
    height: 7.63rem;
  }
}
