.credentialing-user-documents {
  padding: 2.5rem;
  background-color: #fff;
  display: flex;
  /* justify-content: space-around; */
  gap: 1.25rem;
}

.space-around {
  justify-content: space-around;
}
