.effective-profile-info-main {
    padding: 80px 40px 140px 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 87px;
    background-color: white;
  }
  .effective-profile-info-second {
    display: flex;
    flex-direction: column;
  }
  .effective-profile-info-content {
    display: flex;
    flex-direction: row;
    gap: 3rem;
  }
  .info-heading {
    color: #1e1e1e;
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    display: flex;
    flex-direction: column;
    max-width: 795px;
  }
  .info-sub-heading {
    color: #7e7e7e;
    font-size: 18px;
    padding: 8px 0px 44px;
  }
  .effective-profile-info-content-div {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 230px;
  }
  .effective-profile-info-content-text {
    /* display: flex; */
    flex-direction: column;
  
    color: #1e1e1e;
    font-size: 16px;
    line-height: 24px;
  }
  .effective-profile-icon{
    min-height: 3.5rem;
  }
  
  .effective-profile-info-content-first {
    margin-bottom: 57px !important;
  }
  .effective-profile-info-lorem {
    display: flex;
    max-width: 767px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
  
    color: #000;
    font-size: 16px;
    line-height: 25px;
    padding-top: 34px;
  }
  .effective-profile-info-btn-senctin {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    padding-top: 48px;
  }
  .btn-senctin-continue {
    display: flex;
    max-width: 200px;
    height: 42px;
    padding: 12px 64px 12px 65px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  
    border-radius: 4px;
    background: #118936;
    color: #fff;
    cursor: pointer;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 112.5% */

  }
  .btn-senctin-back {
    color: #4054b2;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
  }
  .underlineWithGap{
    text-decoration: underline;
    text-decoration-color: transparent;
    text-decoration-thickness: 1px;
    text-decoration-line: underline;
    text-decoration-skip-ink: none;
    text-underline-position: under;
    background-image: linear-gradient(to bottom, #4054B2, #4054B2);
    background-repeat: repeat-x;
    background-size: 2px 5px;
    background-position-y: 18px;
  }
  @media screen and (max-width: 768px) {
    .effective-profile-info-first {
      display: none;
    }
    .effective-profile-info-lorem {
      display: none;
    }
    .effective-profile-info-main {
      display: flex;
      flex-direction: column;
      padding: 10px 20px 25px;
      margin-top: -35px;
    }
    .info-heading {
      display: flex;
      flex-direction: column;
      min-width: 295px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
    .effective-profile-info-content {
      min-width: 320px;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
    .effective-profile-info-content-div {
      min-width: 320px;
      display: flex;
      align-self: center;
      justify-content: center;
    }
    .info-sub-heading {
      font-size: 14px;
      padding-bottom: 23px;
    }
    .effective-profile-info-btn-senctin {
      flex-direction: column;
      gap: 19px;
      margin-top: 14px;
    }
    .effective-profile-info-img-mobile {
      min-width: 320px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 23px;
    }
    .effective-profile-info-lorem-mobile {
      color: #000;
      font-size: 16px;
      line-height: 25px;
      padding-bottom: 40px;
    }
    .effective-profile-info-content-text {
      font-size: 14px;
      line-height: 24px;
      /* display: flex; */
      flex-direction: column;
      justify-content: center;
    }
    .mobile-second-page {
      padding-bottom: 75px;
    }
     .underlineWithGap{
      background-position-y: 15px;
    }
  }
  