.education-history .title-education-history {
  display: flex;
  justify-content: space-between;
}

.education-history .title {
  margin-bottom: 1rem;
}

.education-history .add-your-education {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.education-history .educationHistory-data {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
}

.educationHistory-data .school {
  display: flex;
  justify-content: space-between;

  /* font */
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e;
}

.educationHistory-data .school .top {
  display: flex;
  gap: 10px;
}

.educationHistory-data .courseType-Name {
  width: 16rem;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.educationHistory-data .courseName {
  width: 6.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.educationHistory-data .schoolName {
  width: 16rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.educationHistory-data .sub-font-ED {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

/* Form CSS */
.education-history .selectEducationHistory .ant-select-selector {
  width: 100%;
  height: 2.44rem;
  /* margin: 0 0 0.63rem; */
  /* padding: 0.63rem 13.81rem 0.63rem 0.63rem; */
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 0;
  border: none;
}

.education-history .selectEducationHistory input {
  /* margin-top: 4px !important; */
  height: 100% !important;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.selectEducationHistory .ant-select-selection-placeholder {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  margin-top: 4px;
}

.selectEducationHistory .ant-select-selection-item {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  margin-top: 4px;
}

/***********************************************************************/
