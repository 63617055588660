.g-gql-justBackground {
  background-color: #fafafa;
}

.g-gql-dashBoardFull {
  /* height: 35rem; */
  height: auto;
  width: 100%;
  /* padding: 0rem 3.813rem 0rem 3.813rem; */
  display: flex;
  flex-direction: column;
  /* margin: auto 9.55%; */
  /* justify-content: space-evenly; */
  flex-direction: column;
  /* background-color: #fafafa; */
}

/* .filters-wrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  border: 0.063rem solid black;
} */
.parent {
  display: flex;
  font-size: 0;
  flex-wrap: wrap;
  margin: -0.625rem 0 0 -0.625rem;
}
.child {
  display: inline-block;
  background: blue;
  margin: 0.625rem 0 0 rem;
  flex-grow: 1;
  height: 6.25rem;
  width: 7.5rem;
}

.location-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.g-gql-receivedGigs {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
  list-style: none;
  width: 100%;
  flex: 0 0 33.333333%;
  font-size: 1.5em;
  /* color: var(--primaryColor); */
}

.g-gql-dashboardHeaders {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin: 2rem 4rem;
  font-size: 1em !important;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.g-gql-viewAllBtn {
  font-size: 1em;
  color: var(--primaryColor);
  padding-top: 0.313rem;
}

.g-gql-headerLine {
  background-color: #eeeeee;
  opacity: 30%;
}

.g-gql-countCheck {
  width: 100%;
  margin: 0.7rem 0 0 0rem;
  font-size: 1em;
  font-weight: normal;
  color: #777;
}

.g-gql-loader {
  display: flex !important;
  width: 100%;
  height: 40vh;
  justify-content: center !important;
  font-size: 3rem !important;
  /* margin: 1vh 0vh 0vh -4vh !important; */
  /* margin-right: 0 4vh !important; */
  color: #e73959 !important;
  align-items: center !important;
  /* background-color: #777; */
}

.g-gql-message {
  display: flex !important;
  width: 100%;
  /* height: 40vh; */
  justify-content: center !important;
  /* font-size: 3rem !important; */
  /* margin: 1vh 0vh 0vh -4vh !important; */
  /* margin-right: 0 4vh !important; */
  align-items: center !important;
  /* background-color: #777; */
}

@media screen and (max-width: 700px) {
  .g-gql-dashBoardFull {
    height: 100%;
    width: 100%;
    justify-content: center;
  }

  .g-gql-countCheck {
    width: 100%;
    margin: 0.7rem 0 0 0rem;
    font-size: 1em;
    font-weight: normal;
    color: #3e3e3e;
  }

  .g-gql-receivedGigs {
    width: auto;
    padding: 0px 0rem;
    border-radius: 2px;
  }

  .g-gql-listingTabsFull {
    margin-top: 0.8rem;
    width: 94vw;
    padding: 0 0.5rem;
    border-radius: 2px;
  }
  .g-gql-dashboardHeaders {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    margin: 2rem 1.5rem;
    /* width: 100%; */
  }
  .sbar-loader {
    display: flex;
    width: 100%;
    /* width: fit-content; */
    justify-content: center;
    align-items: center;
    margin: 1vh auto;
  }
}
