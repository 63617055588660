.interview-container {
  padding: 2.5rem 2.5rem 11.875rem;
  background-color: #fff;
}

.interview-heading h3 {
  width: auto;
  height: 1.1875rem;
  margin: 0 7.8125rem 0.3125rem 0;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.interview-heading p {
  width: auto;
  height: 1.0625rem;
  margin: 0.3125rem 0 0;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.interview-details-cantainer {
  display: flex;
  gap: 1.25rem 0.9375rem;
  flex-wrap: wrap;
  margin-top: 1.25rem;
}

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 768px) {
  .interview-container {
    padding: 0;
  }

  .interview-heading {
    padding: 0.9375rem 0.9375rem 0.5rem 0.9375rem;
    border-bottom: 0.0625rem solid #e4e4e4;
  }

  .interview-heading h3 {
    margin: 0;
    height: auto;
    padding-bottom: 0.625rem;
  }

  .interview-heading p {
    height: auto;
    font-size: 0.75rem;
    font-weight: 500;
  }

  .interview-details-cantainer {
    margin-top: 0rem;
    gap: 0rem;
    padding-bottom: 9rem;
    border-bottom: 0.0625rem solid #e4e4e4;
  }
}
/********** For Mobile View  Media Query End **********/
