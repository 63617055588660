.dashboard-Analytics-main-container {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.dashboard-main-examination-container {
  /* margin-top: 0.938rem; */
  display: flex;
  justify-content: space-between;
}
.dashboard-main-examination-panel {
  display: flex;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.dashboard-main-examination-listing{
  border-radius: 0.25rem;
  background-color: #118936;
}
.dashboard-main-analytics-container{
  display: flex;
   /* justify-content: space-between; */
  margin: 1.25rem 0rem -0.9375rem 0rem !important;
  gap: 0.375rem;
}
.dashboard-main-analytics-container-class-2{
  display: flex;
   /* justify-content: space-between; */
  margin: 2.5rem 0rem -0.9375rem 0rem !important;
  gap: 0.375rem;
}

.dashboard-main-examination-panel-content {
  display: flex;
  gap: 0.5rem;
  padding: 1.25rem;
}

/* .dashboard-main-examination-panel-content-inner {
  padding-right: 69px;
} */

.dashboard-main-examination-listing {
  display: flex;
  border-radius: 0.25rem;
  background-color: #118936;
  align-items: center;
  padding: 0.625rem 0.625rem 0.688rem 0.625rem;
}

.dashboard-main-examination-listing-txt {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}

.dashboard-main-examination-panel-txt {
  font-size: 0.875rem;
  font-weight: 600;
  text-align: left;
  color: #3e3e3e;
}

.dashboard-main-examination-panel-subTxt {
  font-size: 0.875rem;
  font-weight: normal;
  text-align: left;
  color: #3e3e3e;
}
.dashboard-analytics-components{
  border-radius: 0.25rem;
  box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  padding: 0.938rem 0rem 0.938rem 0rem;
  flex: 1;
  cursor: pointer;
}

.dashboard-analytics-components-name{
  font-size: 1.625rem;
  font-weight: normal;
  text-align: center;
  color: #1e1e1e;
}

.dashboard-analytics-components-value{
  font-size: 0.875rem;
  font-weight: normal;
  /* padding-top: 10px; */
  text-align: center;
  color: #1e1e1e;
  cursor: pointer;
}

.dashboard-analytics-components-value-2{
  font-size: 0.875rem;
  font-weight: normal;
  padding-top: 0.313rem;
  text-align: center;
  color: #1e1e1e;
  cursor: pointer;
}
.dashboard-Analytics-resumeCompletion {
  border-radius: 0.25rem;
  box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  padding: 0.688rem 0.375rem 0rem 0.5rem;
  cursor: pointer;
}

.analytics-resumeCompletion-value {
  text-align: center;
  /* margin-bottom: 4px; */
}
.analytics-resumeCompletion-value
  .ant-progress-circle
  .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #4054b2;
  font-size: 1em;
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}

.analytics-resumeCompletion-value
  .ant-progress-circle
  .ant-progress-inner {
  font-size: 0.625rem !important ;
  width: 2.25rem !important;
  height: 2.25rem !important;
}

.dashboard-Analytics-resumeCompletion-txt {
  font-size: 0.875rem;
  /* padding-top: 10px; */
  text-align: center;
  color: #1e1e1e;
  cursor: pointer;
}

.dashboard-Analytics-RMV-symbol {
  font-size: 1.625rem;
  font-weight: normal;
  text-align: center;
  color: #1e1e1e;
}

.dashboard-listing-plus {
  width: 1.125rem;
  height: 1.125rem;
}

.add-skill-button {
  display: flex;
  /* width: 13.063rem;
  height: 2.5rem; */
  padding:0.625rem;
  border-radius: 0.25rem;
  background-color: #118936;
  gap: 0.625rem;
  cursor: pointer;
  margin-left: 0rem;
  /* display: flex;
  width: 209px;
  height: 40px;
  padding: 10px 10px 11px;
  border-radius: 4px;
  background-color: #118936;
  gap: 10px;
  cursor: pointer; */
}

.dashboard-Analytics-main-container .Create-a-Skill-Listing {
  /* width: 161px; */
  height: 1.188rem;
  flex-grow: 0;
  margin: 0 0 0 1.313rem;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.Create-a-Skill-Listing3 {
  font-size: 600;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.dashboard-start-the-exam {
  border-radius: 0.25rem;
  border: solid 0.063rem #118936;
  font-size: 0.875rem;
  color: #118936;
  padding: 0.375rem 0.938rem 0.375rem 0.928rem;
  font-weight: 600;
}

.dashboard-exam-starter-button {
  padding-top: 1.375rem;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .add-skill-button-container {
    display: flex;
    justify-content: center;
  }
  .add-skill-button {
    width: 92vw;
    height: 2.25rem;
    flex-grow: 0;
    /* padding: 0.563rem 4.938rem 0.563rem 5.5rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 0.25rem;
    border: solid 0.063rem #118936;
    margin-left:0rem;

  }
  .Create-a-Skill-Listing3 {
    font-size: 0.875rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #118936;
  }
  .add-skill-button {
    gap: 5px;
  }
  .dashboard-analytics-components{
    width: 45.6vw;
    flex: none;
  }
  .dashboard-main-analytics-container{
    flex-wrap: wrap;
    justify-content: center;
    margin: 1.25rem 0rem 0rem 0rem !important;
  }
  .dashboard-main-analytics-container-class-2{
    flex-wrap: wrap;
    justify-content: center;
    margin: 1.25rem 0rem 0rem 0rem !important;
  }

  .dashboard-exam-starter-button {
    width: fit-content;
    padding-top: 0.625rem;
  }
  .dashboard-main-examination-panel-content {
    display: initial;
    width: 93vw;
  }
  .dashboard-analytics-components-value{
    font-size: 0.75rem;
  }
  .dashboard-analytics-components-value-2{
    font-size: 0.75rem;
  }

}
