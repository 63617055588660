.time-correction-main-div{
    /* width: 100%;
    height: 100%;
    background-color: #fff;
    padding-top: 30px; */

    display: flex;
    background-color: #f8f8f8;
    justify-content: flex-start;
    padding: 2.5rem 2.5rem 3.063rem;
}

.RequestForm-container{
    /* margin: auto;
    border: solid 1px #e4e4e4;
    border-radius: 4px;
    padding: 3%;
    padding-bottom: 50px; */

    /* padding: 35px; */
    /* margin-top: 8%; */

    width: 47.5rem;
    border-radius: 0.25rem;
    border: solid 0.06rem #e4e4e4;
    background-color: #fff;
    /* padding: 1.25rem 1.875rem; */
    /* margin: 2.5rem 2.5rem 3.063rem; */
}

.Form-Item{
    margin-top: 2%;
}
.important-star{
    color: #db2828;
    margin-left: 1%;
    font-size: 16px;
}
.submit-btn{
    /* background-color: #118936; */
    background-color: #118936;
    color:white;
    border-radius: 5px;
    float: right;
    /* width: 100%; */
    /* text-align: center; */

    width: 100%;
    text-align: center;
}

.submit-btn:hover{
    background-color: #118936;
    color:white;
    border-color : #118936;
}

.submit-btn:focus{
    background: #118936;
    color:white;
    border-color : #118936;
}

.Time-icon{
    border: solid 1px #d4d4d4;
    text-align: center;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    /* margin-top: 15%; */
    /* margin: auto; */
}
/*.ant-modal{*/
/*    background: transparent;*/
/*}*/
.close {
    color: #aaaaaa;
    float: right;
    font-size: 18px;
    font-weight: bold;
    top:0;
    padding-bottom: 0;
  }
.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
.modal-content {
    margin: auto;
    background-color: #118936;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
}
.tick{
    float : left;
}
.alert-msg{
    margin: auto;

}

.request-correction-h2 {
    color:#1e1e1e;
    font-size:24px;
    font-weight:600;
    padding-left:1.25rem;
    padding-top:1.25rem;
    margin-bottom:0;
}

.request-correction-h4{
    color:#1e1e1e;
    font-size:16px;
    font-weight:600;
}

#basic_Date, #basic_StartTime, #basic_EndTime{
    font-size: 16px;
    color: #7e7e7e;
    box-shadow: none;
}
.datepicker-icon{
    width: 16px;
    height: 17.5px;
    margin-top:12px;
}

.datetimepicker-icon{
    width: 16.7px;
    height: 16.7px;
    margin-top:12px;
    margin-right: 18px;
}

#basic_Reason{
    resize: none;
    font-size: 16px;
    color: #1e1e1e;
    border-radius: 4px;
    border: solid 1px #d4d4d4; 
    box-shadow: none;
}

#basic_Reason::placeholder {
    color: #7e7e7e; 
}

@media screen and (max-width: 768px) {
    .time-correction-main-div{
        padding: 0;
    }

    .request-correction-h2{
        font-size:18px;
        font-weight:500;
    }

    .request-correction-h4{
        font-size:14px;
        font-weight:600;
    }

    .important-star{
        font-size: 14px;
    }

    #basic_Date, #basic_StartTime, #basic_EndTime{
        font-size: 14px;
    }

    .datepicker-icon{
        width: 12.8px;
        height: 14px;
    }

    .datetimepicker-icon{
        width: 13.3px;
        height: 13.3px;
    }

    #basic_Reason{
        font-size: 14px;
    }
}

.time-correction-main-div .ant-form-item-explain {
    color: var(--primaryColor-error);
    margin-top: 0.4rem;
  }

.request-correction-datepicker .ant-picker-today-btn, .ant-picker-today-btn:active, .ant-picker-today-btn:hover{
    color: var(--primaryColor);
}

.request-correction-datepicker .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background : var(--primaryColor);
}

.request-correction-datepicker .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner{
    background : var(--primaryColor)
}

.request-correction-datepicker .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
    border: 1px solid var(--primaryColor)
}

.request-correction-datepicker .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner{
    background: rgba(17, 137, 54, 0.1);
}

.request-correction-datepicker .ant-picker-now-btn, .ant-picker-now-btn:active, .ant-picker-now-btn:hover {
    /* color: var(--primaryColor); */
    font-size: 12px;
    color : #4054b2;
}

.request-correction-datepicker .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover{
    background : rgba(17, 137, 54, 0.1);
}

.request-correction-datepicker .ant-picker-ok .ant-btn-primary, .ant-picker-ok .ant-btn-primary:hover{
    border-color: var(--primaryColor);
    background: var(--primaryColor);
}

.request-correction-datepicker .ant-btn-sm{
    margin-top: 7px !important;
    padding : 0px 6px !important;
    color: #fff !important;
    font-size: 12px !important;
}

.reason.ant-input-textarea-show-count::after{
    font-size: 14px !important;
    /* color: #7e7e7e; */
    position: relative;
    margin-right: 1.2rem;
    margin-top: -2rem !important;
} 
/* 
.request-correction-datepicker .ant-picker-large .ant-picker-input>input{
    color: #7e7e7e;
} */