.edit-bid-div-aj {
  /* width: 100%; */
  height: 2.63rem;
  flex-grow: 0;
  margin: 1.88rem 1.25rem 1.25rem;
  padding: 0.75rem 0 0.75rem;
  border-radius: 0.25rem;
  background-color: var(--primaryColor);

  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
