.profile-header-menu {
  padding: 9px 0px 6px;
}
.profile-header-profile-item {
  /* padding: 0.9375rem; */
  padding: 6px 15px 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  /* height: 2.4rem; */
  font-family: SFProText;
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e;
  cursor: pointer;
  align-items: center;
}
.profile-header-profile-divider {
  height: 0.0625rem;
  margin: 0.375rem 0.9375rem;
  background-color: #d4d4d4;
}
.profile-header-profile-item-home {
  padding-top: 0.875rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  /* height: 2.4rem; */
  font-family: SFProText;
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e;
  cursor: pointer;
  align-items: center;
}
.counts {
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-width: 23px; */
  width: fit-content;
  height: 16px;
  flex-grow: 0;
  border-radius: 0.625rem;
  padding: 0.125rem 0.27rem;
  flex-grow: 0;
  font-family: SFProText;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  /* text-align: left; */
  color: #fff;
  background-color: #118936;
}
.profile-header-profile-item-counts:hover {
  background-color: #eee;
}
.profile-header-profile-item:hover {
  background-color: #eee;
}

.manage-skill-listing {
  padding-bottom: 5px;
}

@media screen and (max-width: 786px) {
  .new-burgerMenuDrawerMobileView .ant-drawer-heade {
    border-bottom: none;
  }
  .profile-header-profile-item {
    padding: 9px 0px 6px;
    font-size: 0.875rem;
  }
  .profile-header-profile-divider {
    margin: 0.375rem 0rem;
  }
  .profile-header-profile-item:hover {
    background-color: unset;
  }
  .profile-header-menu {
    padding: 0px;
  }
}
