.resume {
  text-align: center;
}

.react-pdf__Page__canvas {
  display: inline !important;
}

.disable-back {
  color: #c4c4c4 !important;
}

.react-pdf__Page {
  height: 150px;
  text-align: center;
}

.preview-card .react-pdf__Page__textContent {
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 25%) !important;
}

.text-margin {
  margin-top: 1.25rem;
}

.continue-btn {
  background-color: #118936;
  color: white;
  border-radius: 0.25rem;
  border: solid 1px #d4d4d4;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.resume-card {
  margin-top: 2.5rem;
  margin-bottom: 3rem;
  border: solid 0.0625rem #d4d4d4;
  border-radius: 0.25rem !important;
  /* height: 35rem; */
}

.Resume_divider {
  background-color: #e4e4e4 !important;
}

.resume-card .ant-btn.ant-btn-block {
  min-height: 2.625rem;
  font-size: 1rem;
  font-weight: 600;
}

.resume-card > div {
  padding-top: 2rem;
  padding-left: 1.8rem;
  padding-right: 1.8rem;
}

.upload-button {
  color: #118936 !important;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #118936 !important;
  background-color: #fff !important;

  margin-bottom: 2.5rem;
}

.resume-card .ant-btn > span {
  display: inline-block;
  height: 18px;
}

.resume-page-layout {
  background-color: #fff;
}

.text-margin > span {
  color: #5e5e5e;
  font-size: 14px;
  font-weight: 500;
}

.resume-info {
  font-weight: 600 !important;
  margin-bottom: 1.8rem;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.bullets li {
  margin-bottom: 1rem;
  padding-left: 0.25rem;
  margin-left: 0.75rem;
  font-size: 0.875rem;
  font-weight: normal;
  color: #3e3e3e;
}

.resume-card-BackButton {
  cursor: pointer;
  color: #4054b2;
  font-size: 0.875rem;
  font-weight: 500;
}

.resume-page-layout .ant-form-item {
  margin-bottom: unset !important;
}

.resume-page-layout .ant-card-body {
  padding-bottom: 0.9375rem !important;
}

.preview-card {
  /* margin: 1rem 10rem; */
  height: 100%;
  max-height: 500px;
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
  text-align: center;
}

.resume-pdf-container .ant-card-bordered {
  border: none;
}

/* .preview-card > .ant-card-body{
  border-radius: 4px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
} */
/* //15rem */
.attachment {
  text-align: center;
  margin: 1.9rem 1rem 50px;
}

.notification-class {
  width: 100% !important;
  background-color: #f3bd1a;
  color: white;
}

#notification-container {
  background-color: #f3bd1a;
  color: white;
}

#notification-container > div > .ant-notification {
  right: 0;
  left: 0;
  top: 0;
  position: relative;
  width: 100% !important;
  /* text-align: center; */
  max-width: 100%;
  color: white;
}

.lorem-text {
  height: 1.38rem;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  margin-bottom: 1rem;
}

.preview-image {
  width: 150px;
  width: -webkit-fill-available;
}

.resume-pdf-container .ant-card-body {
  padding: 0rem;
  margin: 0rem;
}

#notification-container > div > .ant-notification-notice,
#notification-container
  > div
  > .ant-notification
  > div
  > .ant-notification-notice {
  right: 0;
  left: 0;
  top: 0 !important;
  width: 100% !important;
  background-color: white;
  height: 3.13rem;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  max-width: 100%;
  background-color: #f3bd1a;
  color: white;
  display: flex;
  justify-content: center;
}

.custom-class > .ant-message-notice-content {
  color: white;
}

#notification-container .ant-notification-notice-close {
  color: white;
  text-align: center;
}

.heading-resume {
  height: 1.5rem !important;
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e !important;
}

.resume-pdf-container {
  padding: 1rem;
  padding-top: 1.25rem;
  border-radius: 4px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
  margin: 2rem 0rem;
}

/* .react-pdf__Page> canvas{
      border: 1px solid red;
      max-width: 80%;
      min-height: 80%;
  } */
@media screen and (max-width: 1000px) {
  .resume-card {
    height: 100%;
    margin-bottom: 0rem;
    margin-top: 0rem;
  }

  .resume-page-layout .ant-divider-horizontal {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .resume-page-margin {
    margin-top: -4rem;
  }

  .resume-pdf-container .ant-card-body {
    padding: 0rem;
  }

  .text-margin {
    margin-top: 0.6rem;
  }

  #notification-container > div > .ant-notification-notice,
  #notification-container
    > div
    > .ant-notification
    > div
    > .ant-notification-notice {
    justify-content: start;
  }

  .preview-card {
    max-height: 46.875rem;
  }
}

/* .site-card-border-less-wrapper {
      padding: 30px;
      background: #ececec;
    } */
