.dropdown {
  width: 225px;
  position: relative;
  padding-right: 1.25rem;
}

.dropdown .dropdown-btn {
  padding-left: 15px;
  background: #fff;
  font-size: 14px;
  color: #3e3e3e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border: solid 1px #c4c4c4;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}

.dropdown .dropdown-content {
  position: absolute;
  top: 110%;
  padding: 10px;
  background: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: 500;
  color: #333;
  width: 95%;
  z-index: 1050;
  width: 200px;
}

.dropdown .dropdown-content .dropdown-item {
  padding: 5px;
  cursor: pointer;
  transition: all 0.2s;
}

.dropdown .dropdown-content .dropdown-item:hover {
  background: #fcfcfc;
  color: green;
}

.dropdown .dropdown-content .dropdown-item::-moz-selection {
  background: #fcfcfc;
  color: green;
}

.fas-fa-caret-down {
  padding-right: 10px;
}

@media only screen and (max-width: 480px) {
  .dropdown {
    width: -webkit-fill-available;
    position: relative;
    padding: 0;
  }

  .dropdown .dropdown-content {
    width: -webkit-fill-available;
  }
  .dropdown .dropdown-btn {
    /* margin-left: 1rem; */
    color: #1e1e1e;
    border: solid 1px #e4e4e4;
    gap: 1.25rem;
    justify-content: flex-start;
  }
  .fas-fa-caret-down {
    padding-top: 2px;
  }
}
