.chat-container{
    box-shadow: 0px -0px 30px -6px rgba(0,0,0,0.30);
    -webkit-box-shadow: 0px -0px 30px -6px rgba(0,0,0,0.30);
    -moz-box-shadow: 0px -0px 30px -6px rgba(0,0,0,0.30);
    margin-top: 14px;
    margin-bottom: 14px;
    padding: 0;
    border: 1px solid #e2e2e2;
    padding-bottom: 20px;
    padding-left: 4%;
    padding-right: 4%;
}

.chat-container-inner{
    display: flex;
    flex-direction: column;
    background-color: #e73959;
    padding-top: 20px;
}

.chat-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
}

.chat-header-title{
    margin-left: 10px;
}

.chat-header-context{
    margin-top: 20px;
}

.chat-input-title{
    margin-top: 25px;
    font-size: 12px;
}

.file-upload-container{
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.chat-button-cancel{
    background-color: #e0e1e2;
    border-radius: 5px 0px 0px 5px;
    color: #444444;
}

.chat-button-cancel:hover{
    color: black;
}

.chat-button-send{
    border-radius: 0px 5px 5px 0px;
    background-color: #e73959;
    border-width: 0px;
}

.chat-buttons-col{
    display: flex;
    align-items: flex-end;
}

.chat-button-or-separator{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    margin-bottom: -8px;
    margin-right: -10px;
    margin-left: -10px;
    z-index: 1;
    background-color: white;
    border-radius: 14px;
    color: #444444;
    font-weight: bold;
}

.chat-history-panel{
    margin-top: 20px;
}
