.bhsFltSortContainer .dropdownBox {
  flex: 1;
  position: relative;
}

.bhsFltSortContainer .dropdownBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4375rem 0.625rem 0.5rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
  cursor: pointer;
}

.bhsFltSortContainer .dropdownBtn > span {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.bhsFltSortContainer .dropdownOption {
  display: none;
  max-height: 50vh;
  overflow-y: auto;
  width: 100%;
  position: absolute;
  top: 3.125rem;
  left: 0rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
}

.bhsFltSortContainer .dropdownOption::-webkit-scrollbar {
  width: 0.4375rem;
}

.bhsFltSortContainer .dropdownOption::-webkit-scrollbar-thumb {
  background: #e4e4e4;
  border-radius: 0.75rem !important;
  cursor: pointer !important;
}

.bhsFltSortContainer .dropdownOption::-webkit-scrollbar-track {
  background: #fff;
}

.bhsFltSortContainer .dropdownOptionVisible {
  display: block;
  z-index: 1;
}

.bhsFltSortContainer .dropdownOption .dropdownItem {
  padding: 0.3125rem 0.625rem;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  cursor: pointer;
}

.bhsFltSortContainer .dropdownOption .dropdownItem:hover {
  background-color: #e4e4e4;
}
