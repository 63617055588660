.dashboard-inbox-section {
  margin:1.25rem 0rem 1.25rem 0rem;
  border-radius: 0.25rem;
  border: solid 0.063rem #e4e4e4;
}

.dashboard-inbox-chat-container {
  /* width: 21.25rem; */
  display: flex;
  justify-content: space-between;
  line-height: normal;
}

.dashboard-inbox-section-heading-class2{
  font-size: 1rem;
  font-weight: normal;
  color: #4054b2;
  padding-right: 0.938rem;
}

.dashboard-inbox-section-heading-class-2 {
  font-size: 1rem !important;
  font-weight: normal;
  color: #1e1e1e;
  padding-left: 0.938rem;
}

.dashboard-inbox-section-heading-class-2 {
  font-size: 1rem !important;
  font-weight: normal;
  color: #1e1e1e;
  padding-left: 0.938rem;
}

.dashboard-inbox-section-heading {
  padding-top: 0.938rem;
  padding-bottom: 0.938rem;
}

.dashboard-inbox-section-heading-class-3 {
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .dashboard-inbox-section-heading-class-2 {
    font-size: 0.875rem !important;
  }
  .dashboard-inbox-section-heading-class2{
    font-size: 0.875rem;
  }
}
