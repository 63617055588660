.stages-main {
  display: flex;
}

.stages-main .stages-receive--bids-active {
  width: 100%;
  height: 0.188rem;
  flex-grow: 0;
  margin: 0 0.7px;
  background-color: var(--primaryColor);
}

.stages-main .stages-receive-bids {
  width: 100%;
  height: 0.188rem;
  flex-grow: 0;
  margin: 0 0.7px;
  background-color: #e4e4e4;
}

.stages-main .stages-main-single-stagebar {
  position: relative;
  display: flex;
  flex: 1;
}

.stages-main .status-received-bids {
  display: flex;
  position: absolute;
  width: max-content;
  bottom: 0.313rem;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  padding-bottom: 5px;
  cursor: pointer;
  padding-left: 0.156rem;
}

.stages-main .bids-Align-Right-side {
  right: 0;
}

/* @media screen and (max-width: 768px) {
  .stages-main .stages-receive-bids {
    width: 1.69rem;
  }

  .stages-main .stages-receive--bids-active {
    width: 1.69rem;
  }
} */
