.chat-interview-accepted-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4%;
  background-color: #fff;
  height: 40px;
  padding: 3.5% 0%;
}

.chat-interview-accepted-btns .btns {
  background-color: #e7f3eb;
  color: #118936;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  width: 48%;
  border: none;
}

.copy-overlay{
  z-index: 500;
}

.interview-declined-card {
  width: 22rem;
}

.bid-decline-headline {
  padding: 4%;
}
.main-class {
  padding: 4%;
}
@media screen and (max-width: 375px) {
  .interview-declined-card {
    width: 20rem;
  }
}
/* ------------------------------------------------ACCEPTED--------------------------------------------------------- */

.cardAccepted {
  width: 100%;
  background-color: #e7f3eb;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  flex-direction: column;
  border-radius: 5px;
  align-self: center;
}

.cardTimingsAccepted {
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  padding: 4%;
  display: flex;
  flex-direction: row;
  margin-top: 2%;
  /* margin-top: 1%; */
}

.cardHeadingMainAccepted {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
  align-self: flex-start;
}

.cardHeading {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
  align-self: flex-start;
}

.cardTimeStamp {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #b4b4b4;
  align-self: flex-end;
}

.dateAndTimingsContainerAccepted {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 90%;
}

.dateText {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 2%;
}

.timeText {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
  margin-top: 2%;
}

.acceptedCardShareDetailsBtn {
  width: 80%;
  height: 39px;
  background-color: #118936;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-top: 3.2%;
  cursor: pointer;
}

.modal-footer-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.modal-footer-div button {
  width: 100%;
  background-color: #118936;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  height: 2.5rem;
}

.time-reason {
  font-size: 14px;
  font-weight: 400;
}

/* -----------------------------------------PROPOSE INTERVIEW --------------------------------------------- */

.card {
  width: 22rem;
  /* height: 300px; */
  /* background-color: #e7f3eb; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  border-radius: 5px;
}

.interview-proposed-buttons {
  background-color: #fff;
}

.interview-proposed-card-content {
  background-color: #e7f3eb;
  padding: 4%;
  width: -webkit-fill-available;
}

.cardTimings {
  width: 100%;
  height: 61px;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 2%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.cardHeading {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
  align-self: flex-start;
}

.cardTimeStamp {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #b4b4b4;
  align-self: flex-end;
}

.radioBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
}

.dateAndTimingsContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 85%;
}

.dateText {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 2%;
}

.timeText {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
  margin-top: 2%;
}

.cardbtnsContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.cardBtn1 {
  background-color: aqua;
  height: 40px;
  width: 48%;
  background-color: #e7f3eb;
  margin-top: 3%;
  border-radius: 5px;
  border: none;

  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #118936;

  cursor: pointer;
}

.cardBtn2 {
  background-color: green;
  width: 48%;
  background-color: #e7f3eb;
  margin-top: 3%;
  border-radius: 5px;
  border: none;

  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #118936;
  cursor: pointer;
}

@media screen and (max-width: 375px) {
  .card {
    width: 20rem;
  }
} 
/* ------------------------------------------------FEEDBACK--------------------------------------------------------- */

.feedbackBox {
  height: 135px;
  flex-grow: 0;
  margin: 0 0 10px;
  border-radius: 5px;
  background-color: #e7f3eb;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.feebackText {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
  align-self: flex-start;
}

.cardTimeStamp {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #b4b4b4;
  align-self: flex-end;
}

.similiContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedbackCardBtn {
  width: 100%;
  height: 39px;
  background-color: #118936;

  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: none;
  border-radius: 5px;

  margin-bottom: 10px;
  cursor: pointer;
}

/* ------------------------------------------------PROFILE SUBMITTED--------------------------------------------------------- */

.cardProfileSubmitted {
  width: 100%;
  height: 185px;
  background-color: #e7f3eb;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  border-radius: 5px;
  border: 2px solid red;
}

.cardTimingsProfileSubmitted {
  width: 100%;
  height: 61px;
  border-radius: 5px;
  background-color: #fff;

  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 2%;
  margin-bottom: 2%;
}

.cardHeadingMainAccepted {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
  align-self: flex-start;
}

.cardHeading {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
  align-self: flex-start;
}

.cardTimeStamp {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #b4b4b4;
  align-self: flex-end;
}

.dateAndTimingsContainerAccepted {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 90%;
}

.dateText {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 2%;
}

.timeText {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
  margin-top: 2%;
}

.profileSubmittedCardBtn {
  width: 100%;
  height: 39px;
  background-color: #118936;

  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: none;
  border-radius: 5px;

  margin-top: 10px;
  cursor: pointer;
}

/***************************************** Emojies *****************************************/

.Sad-emoji {
  font-size: 3rem;
  /* height: 25px; */
  color: #118936;
}

.happy-emoji {
  font-size: 2.5rem;
  margin-right: 1rem;
  color: #118936;
}

/************************Interviews********************************/
.decline-interview-btn {
  /* background-color: #e7f3eb; */
  background-color: #e7f3eb;
  width: 10rem;
  height: 32px;
  border: none;
  color: #118936;
  border-radius: 4px;
  margin-top: 2%;
}
.decline-interview-btn:hover {
  /* background-color: #e7f3eb; */
  background-color: #e7f3eb;
  width: 10rem;
  height: 32px;
  border: none;
  color: #118936;
  border-radius: 4px;
  margin-top: 2%;
}
.decline-interview-btn:focus {
  background-color: #e7f3eb;
  color: #118936;
}
.proposed-buttons-div {
  width: 100%;
  background-color: #fff;
  margin-top: 3%;
}
.proposed-buttons {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
}

.accet-and-decline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.interview-accept-btn {
  background-color: #e7f3eb;
  width: 10rem;
  height: 32px;
  border: none;
  color: #118936;
  border-radius: 4px;
}
.interview-accept-btn:hover {
  background-color: #e7f3eb;
  width: 10rem;
  height: 32px;
  border: none;
  color: #118936;
  border-radius: 4px;
  cursor: pointer;
}
.interview-accept-btn:focus {
  background-color: #e7f3eb;
  color: #118936;
}

.interview-reschedule-btn {
  background-color: #e7f3eb !important;
  width: 10rem;
  height: 32px;
  border: none;
  color: #118936;
  border-radius: 4px;
}
.interview-reschedule-btn:hover {
  background-color: #e7f3eb;
  color: white;
}
.reschedule-btn-container {
  width: 100%;
  background-color: #fff;
  margin-top: 2%;
}
.reschedule {
  width: -webkit-fill-available;
  background-color: #e7f3eb;
  text-align: center;
  justify-content: center;
  display: flex;
  color: #118936;
  margin-top: 2%;
  height: 35px !important;
  border-radius: 4px;
  align-items: center;
  height: 32px;
  padding: 10px 25px;
  border-color: #118936;
  border: none;
}
.reschedule:hover {
  background-color: #e7f3eb;
  border-color: #118936;
  color: #118936;
  height: 32px;
  border: none;
  margin-top: 2%;
}
.reschedule:focus {
  background-color: #e7f3eb;
  border-color: #e7f3eb;
  color: #118936;
  height: 32px;
  border: none;
  margin-top: 2%;
}

.interview-accept {
  cursor: pointer;
  margin-top: 3px;
  color: #118936;
}

.interview-accepted-card {
  background-color: #e7f3eb;
  padding: 4%;
  width: 22rem;
}

.interview-accept:focus {
  cursor: pointer;
  margin-top: 3px;
  color: #118936;
}
.accept-interview {
  cursor: pointer;
  padding: 0px 10px;
  color: #118936;
}

.decline {
  cursor: pointer;
}

.iconn {
  text-align: center;
}

.Clip_Board_Icon {
  margin-top: 15px;
  margin-bottom: 8px;
  object-fit: contain;
  text-align: center;
}

.Profile_Submitted {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #118936;
}

.interview-accepted-date {
  padding: 0.5rem 0.6rem;
  /* margin: 0.5rem 0.5rem; */
  width: 100%;
  height: 3.75rem;
  flex-grow: 0;
  margin-bottom: 0.935rem !important;
  /* padding: 0 13rem 0 0; */
  border-radius: 0.313rem;
  /* border: solid 0.06rem #d4d4d4; */
  background-color: #fff;
}

.interview-scheduled-on-container {
  background-color: #e7f3eb;
  width: 22rem;
  padding: 4%;
}

.congratulation {
  font-size: 1rem;
  font-weight: bold;
}

@media screen and (max-width: 375px) {
  .interview-accepted-card {
    width: 20rem;
  }
  .interview-scheduled-on-container {
    width: 20rem;
  }
  .decline-interview-btn {
    width: 9rem;
  }
  .interview-accept-btn {
    width: 9rem;
  }
} 