.EVContainer {
  padding: 1.25rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;

  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
  top: 0;
}

.EVContainer .EVInfo {
  display: flex;
  gap: 10px;
}

.EVContainer .EVInfo > p {
  margin: 0px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2e2e2e;
}

.EVContainer .EVInfo > img {
  width: 20px;
  height: 20px;
  flex-grow: 0;
  object-fit: contain;
}

.EVContainer .EVInfo > p > .EmailInfo {
  color: var(--primaryColor-blue);
}

.EVContainer .EVRButton {
  width: 10.31rem;
  height: 2.06rem;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  background-color: var(--primaryColor-blue);
}

.EVContainer .SentEV {
  color: #1e1e1e;
  background-color: #e4e4e4;
}

@media screen and (max-width: 768px) {
  .EVContainer {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .EVContainer .EVInfo > p {
    font-size: 12px;
    line-height: 1.556;
  }

  .EVContainer .EVInfo > p > .EmailInfo {
    color: var(--primaryColor-blue);
  }

  .EVContainer .EVRButton {
    /* padding: 8px 50px; */
    font-size: 12px;
  }
}
