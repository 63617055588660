.ViewCardContainer {
  display: flex;
  gap: 0.9375rem;
  padding: 0.9375rem;
  min-width: 17.8125rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
  cursor: pointer;
}
.ViewCardContainer .vcuserPicInfo {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: #118936;
}

.vcuserPicInfo .vcuserPic {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.vcuserPicInfo .vcuserNameChar {
  display: grid;
  place-items: center;
  height: 100%;
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.vcuserInfo {
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
}

.vcuserInfo .vcuserName {
  font-size: 0.75rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}
.vcuserInfo .vcuserLocation,
.vcuserInfo .vcuserCount {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 768px) {
  .ViewCardContainer {
    width: 100%;
  }

  .ViewCardContainer .vcuserPicInfo {
    width: 1.875rem;
    height: 1.875rem;
  }
}
/********** For Mobile View  Media Query End **********/
