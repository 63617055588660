.left-col-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 21.25rem;
  /* height: 25.56rem; */
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
  padding: 1.88rem 0.94rem 0.94rem;
}

/* profile edit model */
.profileModelRoleExperience .roleExperiencePageNew .roleExperienceNew {
  width: 47.5rem !important;
  margin: unset !important;
  border-radius: unset !important;
  box-shadow: none !important;
  padding: 1.25rem 1.875rem !important;
}

.profileModelRoleExperience .padding-container {
  padding: unset !important;
}

.profileModelRoleExperience .ant-input-wrapper {
  width: 21.25rem;
}

.profileModelRoleExperience
  .roleExperiencePageNew-location
  .ant-form-item-control-input-content
  > input {
  height: 2.813rem;
  width: 21.25rem;
}

/*

.profileModelRoleExperience .roleExperience-Mobile-Number {
  padding: unset;
}

*/

.profileModelRoleExperience
  .roleExperienceNew
  .ant-form-horizontal
  .ant-form-item-control {
  width: auto !important;
}

.profileModelRoleExperience .disableClassRoleDiscipline {
  border-color: #88c49b !important;
  background-color: #88c49b !important;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25) !important;
}

.profileModelRoleExperience
  .roleExperiencePageNew-location
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 21.25rem;
}

.profile-avatar {
  height: 9.375rem;
  min-width: 9.375rem;
  border-radius: 50%;
  background-color: var(--primaryColor);
  color: white;
  font-size: 2.5rem;
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.938rem;
}

.avatar-img2 {
  border-radius: 50%;
  position: absolute;
  width: 9.5rem;
  height: 9.5rem;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.avatar-img-main-section {
  border-radius: 50%;
  position: absolute;
  width: 9.375rem;
  height: 9.375rem;
  top: 54%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  object-fit: cover;
}

.avatar-img4 {
  border-radius: 50%;
  position: absolute;
  width: 3.75rem;
  height: 3.75rem;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.profile-talent-name {
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
  margin-bottom: 0.625rem;
}

.left-col-card .profileStatus {
  font-size: 1.13rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7e7e7e;
  margin-bottom: 0.938rem;
}

.profile-dtl-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(21.25rem - 1.8rem);
}

.profile-dtl-btns span {
  /* display: inline-block; */
  background-color: var(--primaryColor);
  justify-content: space-between;
  color: white;
  align-items: center;
}

.threeD-resume-icon {
  width: 1.25rem;
  height: 1.25rem;
}
.bt-3d-resume {
  display: flex;
  gap: 10px;
  width: 17.5rem;
  height: 2.25rem;
  flex-grow: 0;
  /* margin: 1.25rem 0.63rem 1.19rem 0; */
  padding: 0.5rem 4.8rem;
  border-radius: 0.25rem;
  background-color: var(--primaryColor);

  font-size: 0.88rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  cursor: pointer;
}

.bt-chat {
  width: 3.75rem;
  height: 2.25rem;
  /* margin: 1.25rem 0 1.19rem 0.63rem; */
  padding: 0.5rem 1.25rem;
  border-radius: 0.25rem;
  background-color: var(--primaryColor);
  cursor: pointer;
}

.divider-line {
  width: calc(21.25rem - 1.8rem);
  height: 1px;
  margin: 19px 0 15px;
  background-color: #e4e4e4;
}

.basic-profile-dtls {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.basic-item {
  display: flex;
  flex-direction: row;
  width: calc(21.25rem - 1.8rem);
  align-items: baseline;
}

.basic-item .profile-basic-parameters {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e;
}

.basic-item .profile-basic-parameters-value {
  display: flex;
  gap: 10px;
  align-items: center;

  font-size: 0.88rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #3e3e3e;
}

.basic-item-div {
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-left: 12px;
}

.basic-item .addLocation {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--primaryColor-blue);
  cursor: pointer;
}

.locationSearch-TP {
  width: 100%;
}

.left-col-card
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #d9d9d9;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
}

.left-col-card
  .ant-select:not(.ant-select-disabled):hover
  .ant-select-selector {
  border-color: #d9d9d9;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
}

.left-col-card .selectExperience {
  width: 100%;
}

.marginLR_2px {
  margin: 0 0.125rem;
}

.uploadButton {
  gap: 0.75rem;
  margin-top: 0.625rem;
}

.uploadButton span {
  background-color: #fff;
  color: var(--primaryColor);
}

.uploadButton > span.bt-3d-resume {
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0rem;
  width: unset;
  border-radius: 0.25rem;
  border: solid 0.0625rem var(--primaryColor);
}

.uploadButton > span.bt-3d-resume label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.uploadButton .bt-chat {
  padding: 0rem;
  font-size: 1.25rem;
  text-align: center;
  border-radius: 0.25rem;
  background-color: #fff;
  color: var(--primaryColor);
  border: solid 0.0625rem var(--primaryColor);
}

.downloadButton a {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: var(--primaryColor);
}

@media screen and (max-width: 768px) {
  .profile-avatar {
    font-size: 1.88rem;
  }

  .left-col-card .profileStatus {
    font-size: 0.88rem;
    margin-bottom: 1.875rem;
  }

  .left-col-card {
    border: none;
  }

  .avatar-img2 {
    border-radius: 50%;
    position: absolute;
    width: 6.25rem;
    height: 6.25rem;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .avatar-img-main-section {
    border-radius: 50%;
    position: absolute;
    width: 6.25rem;
    height: 6.25rem;
    top: 62%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .avatar-img5 {
    border-radius: 50%;
    position: absolute;
    width: 3.75rem;
    height: 3.75rem;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .profile-avatar:hover div {
    background-color: var(--primaryColor);
  }

  .profile-avatar:hover {
    background-color: var(--primaryColor);
  }

  .profile-talent-name {
    display: flex;
    flex-direction: column;
  }
}
