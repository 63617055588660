.avatar-container {
  height: 100%;
  width: 100%;
  /* border: 1px solid gray; */
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-badge {
  height: 0.6rem;
  width: 0.6rem;
  background-color: #f5212d;
  position: absolute;
  border-radius: 50%;
  top: -2px;
  right: -2px;
}
.textt {
  left: 50%;
  display: block;
  text-align: center;
  background: #118936;
  transition: 0s all;
}

.resume-image-avatar {
  /* font-size: 1.5rem; */
  color: #fff;
}
/* .resume-image-avatar:hover div{
background-image: url(../assets/icons/photocamera.svg);
}

.resume-image-avatar:hover {
  background-image: url(../assets/icons/photocamera.svg);
} */
.resume-image-avatar {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.01s all;
}

.avatar-img {
  border-radius: 50%;
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  top: 52%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  object-fit: cover;
}
