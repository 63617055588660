.workHistory .titleWorkHistory {
  display: flex;
  justify-content: space-between;
}

.titleWorkHistory .title {
  margin-bottom: 1rem;
}

.workHistory .add-your-work {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.workHistory .twoItemInARow {
  margin-bottom: 0 !important;
}

.workHistory .twoItemInARow .ant-form-item-control-input-content {
  display: flex;
  justify-content: space-between;
}

.workHistory .twoItemInARow .ant-form-item {
  margin-bottom: 10px;
}

.workHistory .selectWorkHistory .ant-select-selector {
  width: 8.75rem;
  height: 2.44rem;
  flex-grow: 0;
  /* margin: 0 0.63rem 0.63rem 0; */
  padding: 0.6rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 0;
  /* border: none; */
}

.workHistory .inputWorkHistory {
  width: 8.75rem;
  height: 2.44rem;
  flex-grow: 0;
  /* margin: 0 0 0.63rem; */
  padding: 0.6rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  text-overflow: unset;

  /* font */
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  border-radius: 0;
  /* border: none; */
}

.workHistory .ant-input:focus,
.workHistory .ant-input-focused {
  outline: none;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  border-color: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
}

.workHistory .inputWorkHistory::placeholder {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  width: 100%;
}

.workHistory .selectWorkHistory input {
  height: 100% !important;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.workHistory .selectWorkHistory .ant-select-selection-placeholder {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  text-overflow: unset;
}

.workHistory .datepickerWorkHistory {
  width: 8.75rem;
  height: 2.44rem;
  flex-grow: 0;
  /* margin: 0.63rem 0.63rem 0.63rem 0; */
  padding: 0 0.625rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 0;
  /* border: none; */
}

.workHistory .ant-picker-input > input {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.workHistory .ant-picker-input > input::placeholder {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  /* color: #3e3e3e; */
}

.datepickerWorkHistory .ant-picker-input {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
}

.workHistory .disable .ant-picker-input {
  opacity: 30%;
}

.datepickerWorkHistory .ant-picker-suffix {
  margin-left: 0;
}

.datepickerWorkHistory input::placeholder {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.workHistory .checkbox-WorkHistory {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.checkbox-WorkHistory span {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primaryColor-blue);
}

.workHistory .textArea-workHistory {
  resize: none;
  height: 6.25rem !important;
  flex-grow: 0;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 0;
  /* border: none; */
  /* width: 18.13rem; */
  /* margin: 0.81rem 0 0.63rem; */
  /* padding: 0.63rem 9.75rem 4.44rem 0.63rem; */
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.workHistory .textArea-workHistory::placeholder {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.workHistory .itemWorkHistory {
  margin-bottom: unset;
}

.itemWorkHistory .itemWorkHistory-combo {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.itemWorkHistory-combo .ant-form-item {
  margin-bottom: unset;
}

.itemWorkHistory-combo .ant-form-item-control-input-content {
  flex-direction: row !important;
  /* align-items: center; */
  gap: 10px;
}

.workHistory .itemWorkHistory .ant-form-item-control-input-content {
  display: flex;
  flex-direction: column;
}

.workHistory .skillSelectWorkHistory .ant-select-selector {
  width: 8.13rem;
  height: 2.44rem;
  flex-grow: 0;
  /* margin: 0.63rem 0.63rem 0.63rem 0; */
  padding: 0.2rem 0.63rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 0;
  /* border: none; */
}

.itemWorkHistory-combo
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #fff !important;
}

.skillSelectWorkHistory .ant-select-selection-placeholder {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.itemWorkHistory .inputPercentWorkHistory {
  width: 6.25rem;
  height: 2.44rem;
  flex-grow: 0;
  /* margin: 0.63rem; */
  /* padding: 0.63rem 3.19rem 0.63rem 0.63rem; */
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 0;
  /* border: none; */

  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  /* color: #3e3e3e; */
}

.itemWorkHistory .inputPercentWorkHistory::placeholder {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.itemWorkHistory .editDeleteWorkHistory {
  display: flex;
  gap: 10px;
}

.itemWorkHistory .inputWorkHistory::placeholder {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.workHistory .roleWorkHistory {
  margin-bottom: 10px;
}

.rightDropdownWorkHistory {
  width: 18rem !important;
  /* position: relative;
  left: calc(5rem + 10%) !important; */
}

.leftDropdownWorkHistory {
  width: 18rem !important;
}

.workHistory .noteTotal {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #6e6e6e;
  margin-bottom: 10px;
}

.workHistory .twoItemInARow .ant-select-selection-item,
.twoItemInARow
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 19px !important;

  /* font */
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  /* color: #3e3e3e; */
}

.workHistory .skillSelectWorkHistory {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  /* color: #3e3e3e; */
}

/* Work History Data CSS*/
.workHistory .workHistory-Data {
  margin-bottom: 19px;
}

.workHistory-Data .companyTitleEditDelete {
  display: flex;
  justify-content: space-between;
}

.workHistory-Data .company-WH {
  display: flex;
  flex-direction: column;
  gap: 8px;

  /* font */
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  margin-bottom: 0.938rem;
}

.companyTitleEditDelete .titleWH {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.workHistory-Data .skills-panel-WH {
  margin-bottom: 0.813rem;
}

.workHistory-Data .skillTitle-WH {
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;

  margin-bottom: 0.75rem;
}

.workHistory-Data .skillsPercent {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
}

.skillsPercent .skill-WH {
  width: max-content;
  padding: 0.31rem 0.63rem;
  border-radius: 1.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
}

.responsibilities-WH .res-title-WH {
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;

  margin-bottom: 0.625rem;
}

.responsibilities-WH .res-para-WH {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
}

.workHistory .skill-add-btn {
  align-self: center;
}

/*///////////////////////////////////*/

@media screen and (max-width: 768px) {
  .workHistory-Data .skillTitle-WH {
    font-weight: 600;
  }

  .responsibilities-WH .res-title-WH {
    font-weight: 600;
  }

  .skilllisting-title-counts .count {
    font-size: 0.75rem !important;
  }

  .workHistory .titleWorkHistory {
    width: 100%;
  }

  .workHistory .selectWorkHistory .ant-select-selector {
    width: 9.38rem;
  }

  .workHistory .inputWorkHistory {
    width: 9.38rem;
  }

  .workHistory .datepickerWorkHistory {
    width: 9.38rem;
  }

  .itemWorkHistory .inputPercentWorkHistory {
    width: 7.5rem;
  }

  .workHistory .skillSelectWorkHistory .ant-select-selector {
    width: 9rem;
  }
  .editDelete{
    display: flex;
  }
  .workHistory-Data .companyTitleEditDelete {
    display: flex;
     gap: .3125rem;
  }
}
