.conversation-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80.7px;
  padding: 9px 3% 0px 10px;
  flex-grow: 0;
  /* border: solid 1px #e4e4e4; */
  border-bottom: solid 1px #e4e4e4;
  border-top: solid 1px #e4e4e4;
  background-color: #fff;
  cursor: pointer;
  /* justify-content: space-between; */
}

.conversation-card-star {
  height: 22px;
  flex-grow: 0;
  margin: 11px 3% 11px 0;
  /* border: #e4e4e4 solid 1px; */
}

.conversation-card-null {
  width: 2.7rem;
  height: 2.6rem;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  object-fit: contain;
  border-radius: 100%;
  margin-right: 3%;
  background-color: #118936;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
}

.conversation-card-image {
  width: 2.7rem;
  height: 2.6rem;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  object-fit: contain;
  border-radius: 10%;
  margin-right: 3%;
  /* background-color: #118936; */
  text-align: center;
  margin-top: 2%;
}

.image-container {
  border-radius: 50%;
  width: 43px;
  height: 43px;
  padding: 10px;
  background-color: #118936;
  object-fit: contain;
  text-align: center;
  font: 32px Arial, sans-serif;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.image-text {
  color: white;
  font-size: 22px;
  padding-bottom: 20px;
}

.conversation-card-image-avatar {
  width: 10px;
  height: 10px;
  flex-grow: 0;
  margin: 18px 0 0 -20%;
}

.conversation-card-contentContainer {
  height: 40px;
  flex-grow: 0;
  color: #727272;
  width: 65%;
  /* margin-right: 5%; */
}

.conversation-card-contentContainer-title {
  flex-grow: 0;
  font-size: 14px;
  /* font-weight: 400; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  width: 15vw;
  max-width: calc(100% - 90px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* border: #1e1e1e solid 1px; */
}

.conversation-card-contentContainer-message {
  max-height: 17px;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  margin: 7px 0 0 0px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  overflow: hidden;
  color: #727272;
  /* width: 19vw; */
  max-width: 100%;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.conversation-card-contentContainer-message p {
  margin-top: 0;
  margin-bottom: 0 !important;
  line-height: 2;
  width: -webkit-fill-available;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.conversation-card-extraContent {
  height: 42px;
  position: absolute;
  right: 0;
  margin-right: 2%;
}

.conversation-card-extraContent-time {
  flex-grow: 0;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #9e9e9e;
}

.conversation-card-extraContent-count {
  width: 25px;
  height: 21px;
  text-align: center;
  border-radius: 5px;
  background-color: #118936;
  color: #fff;
  position: absolute;
  right: 0;
  margin-top: 2%;
}
.conversation-card-bid-msg-content {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 11.875rem;

  margin: 0.4375rem 0 0 0rem;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

@media only screen and (max-width: 992px) and (min-width: 769px) {
  .conversation-card-contentContainer-title {
    /* width: 14vw; */
    max-width: calc(100% - 5px);
  }
}

@media screen and (max-width:768px){
  .conversation-card-contentContainer-title{
    width: 17rem;
  }
}