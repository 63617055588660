.bidDeclined-abj {
  /* width: 25.00rem; */
  height: 3.69rem;
  flex-grow: 0;
  margin: 1.88rem 0 0;
  padding: 0.63rem 0;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #e4e4e4;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.bidDeclined-abj .declined {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* text-align: left; */
  color: #7e7e7e;
}

.bidDeclined-abj .overallEx {
  font-size: 0.88rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* text-align: right; */
  color: #7e7e7e;
}
