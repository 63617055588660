.ManageAssigned-main {
  padding: 2.5rem 2.5rem 0rem 2.5rem;
}
.manage_tagline {
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e3e3e;
}
.manage_UserStatus {
  list-style: none;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7e7e7e;
  display: flex;
  gap: 2.625rem;
  margin: 1.25rem 0.688rem 0.25rem 0rem;
}

.manage_UserStatus > li {
  padding: 0.375rem 0.813rem 0.438rem 0rem;
}
.scroll-dividerr {
  /* position: relative !important; */
  width: 65.1%;
  /* margin: 0.563rem 0 0 -1.063rem !important; */
  /* height:0.063rem !important; */
  background-color: #e4e4e4 !important;
}
.search_manage {
  display: flex;
  gap: 0.938rem;
}
.search_manage .input-select-dropdown {
  width: 24.5%;
  margin: 1.313rem 0rem 0.938rem 0rem;
}

.search_manage .input-select input {
  width: 100%;
  background-color: #fff;
  font-size: 0.875rem;
  color: #7e7e7e;
  height: 1.063rem;
}

.search_manage .ant-picker-range {
  margin-top: 1.25rem;
  height: 2.188rem;
  border-radius: 0.25rem;
}

.manage_tagline2 {
  margin: 0rem 0rem 0.938rem 0rem;
  font-size: 1rem;
  color: #3e3e3e;
}

.infobox-main {
  width: 75rem;
  height: 8.688rem;
  flex-grow: 0;
  margin-bottom: 26.938rem;
  border-radius: 0.25rem;
  border: solid 0.063rem #e4e4e4;
}

 
.infobox-inside {
  display: flex;
  padding: 1.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #7e7e7e;
  border-bottom: solid 0.063rem #e4e4e4;
}
.infobox-params {
  display: flex;
  gap: 32.125rem;
}
.infobox-params2 {
    display: flex;
     gap: 10.25rem;
  }



 .search_manage .rating-review-internalbox{
    display: flex;
    gap: 25.5rem; 
    flex-wrap: wrap;
}

.titleJob{
    font-size: 1rem;
    color: #4054b2;
}

.ratingParameters{
    font-size: 0.75rem;
  color: #7e7e7e;
}

.bid-stages{
    padding: 0.063rem 0.313rem;
  border-radius: 0.125rem;
  background-color: #e3f1e7;
  font-size: 0.75rem;
  font-weight: 500;
  color: green;
}

.search_manage .rating-review-internals{
    display: flex;
    flex-direction: column;
    gap: 9.438rem;
    flex-wrap: wrap;
}

.rating-review-internals2{
    display:flex;
    gap: 9.438rem;
   margin-top:0.25rem ;
}

.rating-review-internals4{
    display:flex;
    gap: 13.25rem;
}

.dashboard-rating-review-Container2{
     display: flex;
     gap:0.625rem;
     padding: 0.938rem 0rem 0.938rem 0.938rem;
     border-radius: 0.25rem;   
 }

 .rating-review-internalboxx{
    display: flex;
    grid-gap: 3.25rem;
    gap: 25.5rem;
 }
 
 