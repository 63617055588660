.Dashboard-Profile-Card {
  /* margin-top: 0.938rem; */
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  background-color: #fff;
  border: solid 0.063rem #e4e4e4;
  /* margin-top: 1rem; */
}

/* .Dashboard-Profile-Card-main-container-heyyy {
    display: flex;
    justify-content: space-between;
  }
  
  .yutil {
    display: flex;
    border: solid 1px #118936;
  border-radius: 20px;
  }
  
  .antil {
    display: flex;
    justify-content: center;
  }
   */

.Dashboard-Profile-Card-main-container-Ellipse-frami {
  width: 0.25rem;
  height: 0.25rem;
  background-color: #118936;
  border-radius: 50%;
}
/* .Rectangle-17209 .ant-progress-success-bg,
  .ant-progress-bg {
    background-color: #52c41a;
  } */

/* .Rectangle-17209 .ant-progress-status-active .ant-progress-bg::before {
    -webkit-animation: unset important;
    animation: unset important;
  } */

/* dkd */

.Dashboard-Profile-Card-main-container-awt {
  display: flex;
  /* width: 65px; */
  /* margin: 10px 10px 0px 0px; */
  padding: 0rem 0.466rem 0rem 0.465rem;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 1.25rem;
  border: solid 0.063rem #118936;
}

.Dashboard-Profile-Card-main-container-onelane {
  padding: 0.25rem 0.125rem 0.25rem 0.375rem;
  font-size: 0.75rem;
  font-weight: normal;
  color: #118936;
}

.Dashboard-Profile-Card-main-container-ast {
  display: flex;
  justify-content: flex-end;
  padding: 0.625rem 0.625rem 0rem 0rem;
}

.Dashboard-Profile-Card-main-container-pjk {
  padding-top: 0.75rem;
  padding-left: 0.938rem;
  gap: 1.25rem;
  display: flex;
}

.Dashboard-Profile-Card-main-container-pkh {
  width: 3.75rem;
  height: 3.75rem;
  background-color: #118936;
  border-radius: 9.375rem;
}

.Dashboard-Profile-Card-main-container-sjdj {
  font-size: 1.125rem;
  font-weight: 600;
  color: #3e3e3e;
  /* cursor: pointer; */
}

.Dashboard-Profile-Card-main-container-jeks {
  font-size: 1rem;
  font-weight: normal;
  color: #3e3e3e;
}
.Dashboard-Profile-Card-main-container-yako {
  /* display: flex;
  justify-content: center;
  padding-right: 45px; */
  padding-bottom: 1.75rem;
}
.Dashboard-Profile-Card-main-container-jeko {
  font-size: 0.875rem;
  font-weight: 500;
  margin-left: 5.938rem;
  color: #118936;
  border-bottom: 0.063rem solid #118936;
  cursor: pointer;
}
/* <div className="rhsj">
     <div className="sjdj"></div>
     <div className="jeks"></div>
     </div>
  
     <div className="sjhwd"></div>
     <div className="qwjgdj"></div> */

.Dashboard-Profile-Card-main-container-qwjgdj {
  /* width: 21px;
  height: 17px;
  margin-right: 59px; */
  font-size: 0.875rem;
  font-weight: normal;
  padding-top: 0.188rem;
  color: #3e3e3e;
  cursor: pointer;
}

.Dashboard-Profile-Card-main-container-continor {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 0.938rem 1rem 0.938rem;
  height: 55px;
}

.Dashboard-Profile-Card-main-container-continor2 {
  display: flex;
  justify-content: space-between;
  padding: 0px 0.938rem 1.25rem 0.938rem;
  height: 39px;
}

.Dashboard-Profile-Card-main-container-continor
  .ant-progress-status-active
  .ant-progress-bg::before {
  position: relative !important ;
}

.Dashboard-Profile-Card-main-container-continor .ant-progress-bg {
  background-color: #118936;
}

.Dashboard-Profile-Card-main-container-continor2
  .ant-progress-status-active
  .ant-progress-bg::before {
  position: relative !important ;
}

.Dashboard-Profile-Card-main-container-continor2 .ant-progress-outer {
  max-width: 6.625rem !important;
}

.Dashboard-Profile-Card .ant-progress-show-info .ant-progress-outer {
  margin-right: none !important;
  padding-right: calc(0rem + 0.5rem) !important;
}

.Dashboard-Profile-Card-main-container-continor2 .ant-progress-inner {
  background-color: #118936;
}

.dashboard-profile-percentage {
  padding-left: 18px;
  color: #3e3e3e;
}

.Dashboard-Profile-Card-main-container-itemn2 {
  max-width: 5.5rem;
  flex: 1;
  flex-wrap: wrap;
}

.Dashboard-Profile-Card-main-container-itemn1 {
  flex-wrap: wrap;
  flex: 1;
  color: #3e3e3e;
  font-weight: 600;
}

.Dashboard-Profile-Card-main-container-itemn4 {
  flex-wrap: wrap;
  color: #3e3e3e;
  font-weight: 600;
}

.Dashboard-profile-card-main-container-picture {
  height: 3.75rem;
  width: 3.75rem;
  border-radius: 50%;
}
.Dashboard-Profile-Card-main-container-tunior {
  background-color: white;
  cursor: pointer;
  border: solid 0.063rem #e4e4e4;
  font-size: 1rem;
}

@media only screen and (max-width: 768px) {
  .Dashboard-Profile-Card-main-container-pjk {
    display: flex;
    order: 2;
  }
  .Dashboard-Profile-Card-main-container-tunior {
    order: 1;
    border-radius: 0.25rem;
  }
  .Dashboard-Profile-Card-main-container-rtyu {
    margin-top: 1rem;
    border-radius: 0.25rem !important;
    align-items: center;
  }
  .Dashboard-Profile-Card-main-container-sjdj {
    font-size: 1rem;
    font-weight: 500;
  }
  .Dashboard-Profile-Card-main-container-itemn1 {
    font-size: 0.875rem;
  }

  .Dashboard-Profile-Card-main-container-itemn4 {
    font-size: 0.875rem;
  }
  /* .Dashboard-Profile-Card-main-container-itemn3 {
    font-size: 14px;
    font-weight: 600;
  } */
  .Dashboard-Profile-Card-main-container-rhys {
    font-size: 14px;
    font-weight: 600;
  }
  .Dashboard-Profile-Card-main-container-kjys {
    font-size: 0.875rem !important;
    font-weight: 500;
  }
  .dashboard-profile-percentage {
    font-size: 14px;
    align-self: center;
  }
  .Dashboard-Profile-Card-main-container-yako {
    padding-bottom: 0.875rem;
  }
}

.Dashboard-Profile-Card-main-container-rtyu {
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  padding: 1.188rem 0.938rem 1.188rem;
  background-color: #fff;
  border: solid 0.063rem #e4e4e4;
  color: #3e3e3e;
  cursor: pointer;
  font-size: 1rem !important;
  border-radius: 0rem 0rem 0.25rem 0.25rem;
  line-height: normal;
}

.Dashboard-Profile-Card-main-container-talent {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

.Dashboard-Profile-Card-main-container-234 {
  display: flex;
  flex-direction: column;
}
.Dashboard-profile-card-main-container-profile {
  cursor: pointer;
  position: relative;
  padding: 0.75rem 1.375rem;
  font-size: 1.5rem;
  color: #fff;
}

/* .Dashboard-Profile-Card-main-container-rhsj  .ant-rate {
  width:18px !important;
  height:18px !important;
  margin-top:-5px !important;
}
   */
.dashboard-profileStar-142 {
  width: 1.125rem !important;
  height: 1.125rem !important;
  padding: 0rem 1.438rem 0.375rem 0.938rem;
}

.dashboard-profileStar-142 .ant-rate-star {
  color: #ffba08;
}

.Dashboard-Profile-Card-main-container-kjys {
  font-size: 1rem;
  font-weight: 600;
  color: #3e3e3e;
}

.dashboard-profileStar-142 .anticon svg {
  width: 1.063rem !important;
  height: 1.063rem !important;
  margin-bottom: 0.563rem;
  cursor: pointer;
}

.Dashboard-Profile-Card-main-container-itemn3 {
  font-size: 0.875rem;
  font-weight: 600;
  color: #118936;
  height: 1.188rem;
}
.notapllicable {
  color: #3e3e3e !important;
}
