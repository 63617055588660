/* PDF viewer */
.pdfViewerCredentialing {
  width: 100%;
  height: max-content;
  /* min-height: 100%; */
  padding: 0.38rem 0.5rem 0.56rem 0.63rem;
  background-color: rgba(0, 0, 0, 0.6);
  /* width: 33.75rem; */
  /* margin: 2.5rem 2.5rem 3.06rem 1.25rem; */
  /* position: fixed; */
}

.pdfViewerCredentialing .react-pdf__Page__textContent {
  display: none;
}

.pdfViewerCredentialing .react-pdf__Page__annotations {
  display: none;
}

/* .pdfViewerCredentialing .preview-card {
  max-height: unset;
} */

.pdfViewerCredentialing .docNameSizeClose {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.docNameSizeClose .nameSize {
  display: flex;
  flex-direction: column;
}

.docNameSizeClose .name {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.docNameSizeClose .size {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9e9e9e;

  margin-bottom: 4.1px;
}

.pdfViewerCredentialingContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.pdfViewerCredentialing .theDocWrap {
  /* height: 100%; */
  height: 34.13rem;
  /* width: 32.5rem; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
}

.theDocWrap .react-pdf__Page {
  height: 100%;
}

.pdfViewerCredentialing .theDocWrap img {
  max-width: 100%;
  max-height: 34.13rem;
  border-radius: 0.31rem;
}

.theDocWrap .react-pdf__Page__canvas {
  max-height: 34.13rem;
  max-width: 100%;
}

@media screen and (max-width: 1000px) {
  .pdfCredDocModal {
    max-width: 100% !important;
    max-height: 100vh !important;
    margin: unset !important;
  }

  .pdfViewerCredentialing {
    height: 100vh;
    padding: 7px 10px;
  }

  .pdfViewerCredentialing .theDocWrap {
    height: 95%;
  }

  .pdfViewerCredentialing .theDocWrap img {
    max-width: 100%;
    max-height: 100%;
  }

  .docNameSizeClose .size {
    margin-bottom: 7px;
  }
}
