.ucn-container .ant-modal-content {
  border-radius: 0.25rem;
  box-shadow: none;
}

.ucn-container .ant-modal-header {
  padding: 0.9375rem;
  border: 0.0625rem solid #e4e4e4;
  font-weight: 600;
}

.ucn-container .ant-modal-close {
  top: -0.25rem;
  right: -0.375rem;
}

.ucn-container .ant-modal-title {
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.ucn-container .ucn-box {
  padding: 1.25rem 0.9375rem 1.5625rem;
}

.ucn-container .ucn-body {
  width: 100%;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
}

.ucn-container .ucn-body span {
  border: none;
  padding: 0.6875rem 0.9375rem;
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  border-right: solid 0.0625rem #e4e4e4;
}

.ucn-container .ucn-body input {
  outline: none;
  border: none;
  padding: 0.6875rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  height: 2.3125rem;
}

.ucn-container .ucn-footer button {
  margin-top: 1.5625rem;
  margin-left: 1.25rem;
  width: 8.4375rem;
  height: 2.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4e4e4e;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.25);
  border: none;
  background-color: #fff;
  cursor: pointer;
}

.ucn-container .ucn-footer button:last-child {
  background-color: var(--primaryColor);
  color: #fff;
}

.ucn-container .ucn-footer button:last-child.contactUpdateDisabled {
  background-color: #88c49b;
  cursor: not-allowed;
  text-decoration: none;
}

.ucn-container .redBorderError {
  border-radius: 0.25rem;
  border: solid 0.0625rem #db2828;
  background-color: #fff;
}

.ucn-container .redBorderError span {
  border-color: #db2828;
}

.isTenNumber {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #db2828;
}
