.successPageWrap {
  /* background-color: #f8f8f8; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.successPageWrap .successPage {
  width: 34.37rem;
  /* height: 450px; */
  margin: 6rem 22.8rem 3rem;
  padding: 1.87rem;
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
}

.successPageWrap .allSetSuccess {
  /* width: 69px;
  height: 21px;
  margin: 0 111px 20px 0;
  font-family: SFProText; */
  font-size: 1.125rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.successPageWrap .subHeader {
  /* width: 427px;
    /* height: 48px; */
  margin: 1.25rem 0rem 1.87rem 0;
  /* font-family: SFProText; */
  font-size: 0.87rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e;
}

.successPageWrap .successFinish {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.successPageWrap .finishButtton {
  width: 30.6rem;
  height: 2.6rem;
  margin: 1.87rem 0 1.87rem 0;
  padding: 0.625rem 0 0.87rem 0;
  border-radius: 4px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  background-color: #118936;
  color: white;
  text-align: center;
  cursor: pointer;
}

.successPageWrap .editProfile {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primaryColor-blue);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .successPageWrap .successPage {
    width: 100%;
    margin: unset;
    padding: 1.87rem 0;
    border: unset;
    border-radius: unset;
  }

  .successPageWrap .successHeader {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .successPageWrap .allSetSuccess {
    text-align: center;
  }

  .successPageWrap .subHeader {
    margin: 0.5rem 0 1.25rem 0;
    font-size: 0.75rem;
    text-align: center;
    width: 20.6rem;
  }

  .successPageWrap .finishButtton {
    width: 20.6rem;
    /* width: 20.6rem; */
    margin: 2.5rem 0 1rem 0;
    font-size: 1rem;
    padding: 0.59rem 0 0.91rem 0;
  }
}
