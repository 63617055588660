.onBilling-card-info-div {
    background-color: #fff;
    border-radius: 4px;
    padding: 4%;
}

.onBilling-card-info-div .info-style div {
    color: #7e7e7e;
}

.onBilling-card-info-div .info-style {
    justify-content: space-between;
    margin-bottom: 1.5%;
}

.justifyBetween {
    justify-content: space-between;
}