.bhsContainer {
  padding: 1.25rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
}

/* For BHS Filter & Sort */
.bhsFltSortContainer {
  gap: 2.78%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* For BHS Card */
.bhsCardContainer {
  margin-top: 0.9375rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}
