.bidAcceptedBtn{
    background-color: #118936;
    height: 36px;
    text-align: center;
    display: flex;
    align-items: center;   
    border: none;
    color: #fff;
    padding: 0% 3%;
    border-radius: 3px;
    justify-content: center;
    width: 12rem;
}

.acceptContainer {
background-color: white;
padding: 0.8rem;
margin: 0.1rem
}
.reason-container{
    background-color: #fff;
    padding: 4%;
    margin-top: 2%;
    border-radius: 4px;
}
.congratulation-text {
    font-size: 1rem;
    font-weight: bold;
}