.Tips-Getting-Started-Container {
    /* padding: 2.1875rem .9375rem .9375rem; */
    padding: 0em 0.9375rem 0.9375rem;
    height: 20rem;
    border-radius: .25rem;
    box-shadow: 0 0 .25rem 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
    margin-top: 1.25rem;
    margin-left: 4.125rem;
}

.Tips-Getting-Started-Heading {
    font-size: .875rem;
    font-weight: 500;
    color: #1e1e1e;
    padding-bottom: .5625rem;
}

.Tips-Getting-Started-Sub-Heading-container {
    text-align: justify;
    text-justify: inter-word;
}

.Tips-Getting-Started-Sub-Heading {
    font-size: .75rem;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1e1e1e;
    padding-bottom: .5rem;
    text-align: left;
    max-width: 13.4375rem;
    word-wrap: break-word;
}

.Tips-Getting-Started-Important {
    font-size: .75rem;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7e7e7e;
    padding-bottom: .625rem;
}

.Tips-Getting-Started-points {
    display: list-item;
    font-size: .75rem;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1e1e1e;
    margin-left: .9375rem;
    padding-bottom: .5rem;
    text-align: left;
    max-width: 13.25rem;
    word-wrap: break-word;
}

.Tips-Getting-Started-points-container {
    /* padding-left: .25rem; */
    text-align: justify;
    text-justify: inter-word;
}

.Tips-Getting-Started-Cricle {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #4054b2;
    position: relative;
    /* top: 6.875rem;
    right: 9.375rem; */
    top: -1.125rem;
    right: -5.625rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Tips-Getting-Started-Cricle>img {
    max-height: 1.25rem;
}

.Tips-Getting-Started-Cricle-arrow-left {
    position: relative;
    margin: 0;
    box-sizing: border-box;
    box-shadow: 0px .1875rem .1875rem 0 rgba(0, 0, 0, 0.4);
}

.Tips-Getting-Started-Cricle-arrow-left::after {
    content: "";
    position: absolute;
    top: 1rem;
    left: -.875rem;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: .5rem solid black;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(45deg);
    box-shadow: -.1875rem .1875rem .1875rem 0 rgb(0 0 0 / 9%);
}

.skillListingPagePadding {
    padding: 2.5rem 6.375rem 3.063rem;
}

@media screen and (max-width: 768px) {
    .Tips-Getting-Started-Container {
        display: none;
    }

    .skillListingPagePadding {
        padding: 0 .9375rem;
    }
}