.g-sgigs-receiveBidCard {
  display: flex;
  /* flex: 0 0 33.333333%; */
  flex-direction: row;
  justify-content: space-evenly;
}

.g-sgigs-thecards {
  /* width: 28.7vw;
  height: 14.5rem;
  border: 1px solid #eee;
  margin: 20px 20px 20px 0;
  padding: 20px 0 0;
  border-radius: 3px;
  box-shadow: 1px 1px 1px 1px #eee;
  background-color: #fff; */
  /* width: 390px; */
  width: 20.562rem;
  /* height: 284px; */
  /* margin: 20px 15px 20px 40px; */
  margin: 0.825rem 0rem 0rem 0rem;
  /* padding: 0 0 0.756rem; */
  /* border-radius: 0.126rem; */
  /* border: solid 0.063rem #e4e4e4; */
  background-color: #fff;
  position: relative;
}

.g-sgigs-thecards .g-sgigs-upAbove {
  padding: 7.5rem 0.625rem 0rem;
}

.g-sgigs-theStatus {
  padding: 0.313rem 0.625rem;
  background-color: var(--statusColor-cream);
  width: fit-content;
  border-radius: 0.188rem;
  color: var(--statusColor-brown);
  font-size: 0.8rem;
  margin-right: 0.313rem;
}

.g-sgigs-image {
  /* width: 389px; */
  /* width: 442px; */
  width: 100%;
  height: 7.5rem;
  /* margin: 0 0 15px; */
  /* margin: 0rem 0rem 0rem -0.8rem; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
}

.g-sgigs-theTitleJob {
  width: 100%;
  height: 1.188rem;
  margin: 0.925rem 8.75rem 0rem 0rem;
  font-size: 1rem;
  font-weight: 500;
}
.g-sgigs-theTitleJob {
  color: #1e1e1e;
}
.job-details-color-black-white {
  color: #6e6e6e !important;
}
.g-sgigs-theFacilityJob {
  /* font-size: 0.8rem;
  font-weight: 0;
  color: grey; */
  width: 18.75rem;
  /* height: 1.063rem; */
  margin: 0.4375rem 13.625rem 0rem 0rem;
  font-size: 0.875rem;
  color: #4e4e4e;
  /* margin: 0.5rem 0; */
}

.g-sgigs-information {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.625rem 0rem 0rem 0rem;
  height: 1.1rem;
}
.Num_rating_color {
  color: #6e6e6e;
}
.Job_Details_Status {
  justify-content: flex-end !important;
  width: -webkit-fill-available !important;
}
.g-sgigs-starJob {
  width: 1rem;
  height: 1rem;
  flex-grow: 0;
  margin-top: -0.2rem;
  /* margin: 0.75rem 0.5rem 0 0; */
  object-fit: contain;
  border-radius: 0.031rem;
  color: #ffba08;
}

.s-sgigs-ratingJob {
  width: 1.313rem;
  /* height: 1.063rem; */
  flex-grow: 0;
  /* margin: 0.75rem 0rem 0rem 0rem; */
  margin-right: 1rem;
  font-size: 0.875rem;
  padding-left: 0.5rem;
  color: #ffba08;
}

.sgigs-thecard .ant-rate-star-second {
  color: #9e9e9e;
}

.g-sgigs-reviewJob {
  display: flex;
  width: 20rem;
  font-size: 0.875rem;
  margin: -0.188rem 0rem 0rem 0rem;
  color: #7e7e7e;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.5rem;
}

.g-sgigs-reviewJob-IT {
  display: flex;
  width: 20rem;
  font-size: 0.875rem;
  margin: -1.188rem 0rem 0rem 0rem;
  color: #7e7e7e;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.5rem;
}

.GigCard-Budget-span-IT{
  font-size: 0.875rem;
  color: #1e1e1e;
  font-weight: normal;
}

.g-sgigs-Budgets {
  display: flex;
  /* justify-content: baseline; */
  width: 6.5rem;
  height: 1.5rem;
  flex-grow: 0;
  margin: 0 0 0rem;
  /* font-family: SFProText; */
  font-size: 1.25rem;
  font-weight: 500;
  color: #118936;
}

.g-sgigs-gigcard-interestedBtn {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin: -0.4rem 0 0rem;
  height: 1.5rem;
  /* width: 17.5rem; */
}
.g-sgigs-Dashbord {
  /* width: 4.25rem; */
  height: 0.875rem;
  flex-grow: 0;
  margin: 0rem 2.25rem 0 0rem !important ;
  font-size: 0.75rem;
  font-weight: 500;
  color: #7e7e7e;
}
.g-sgigs-gigcard-interestedBtn .g-sgigs-div2 {
  width: 6rem;
  background-color: var(--primaryColor);
  font-size: 0.9rem;
  color: white;
  border-radius: 0.188rem;
  padding-top: 0.125rem;
}
.g-sgigs-div2 {
  cursor: pointer;
}

.g-sgigs-receiveTime {
  font-size: 0.65rem;
  font-style: italic;
  color: #777;
  padding: 0.5rem 1rem;
  width: max-content;
}
.g-Duration-color {
  background-color: #118936;
}

.g-Duration-color-black-white {
  background-color: #e4e4e4;
  color: #6e6e6e;
}
.JobDetails-textColor {
  color: #e4e4e4 !important;
}
.Project_earning_font {
  font-size: 0.75rem;
}
.Doller_font {
  font-size: 0.875rem;
  font-weight: 600;
}
.g-Duration {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-top: 1.25rem;
}
.g-Duration-Cancelled-Terminated {
  justify-content: space-between !important;
}
.gig-card-status-Active {
  background-color: #f0f4fd;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  color: #2e437c;
  font-size: 0.625rem;
  display: flex;
  align-items: center;
}
.gig-card-status-Completed {
  background-color: rgba(17, 137, 54, 0.3);
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  color: #118936;
  font-size: 0.625rem;
  display: flex;
  align-items: center;
}
.gig-card-status-Cancelled {
  background-color: #e4e4e4;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  color: #6e6e6e;
  font-size: 0.625rem;
  display: flex;
  align-items: center;
}
.gig-card-status-Terminated {
  background-color: #e4e4e4;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  color: #6e6e6e;
  font-size: 0.625rem;
  display: flex;
  align-items: center;
}
.Duration_p {
  padding-left: 1rem;
  color: #e4e4e4;
}
.JobDetail-padding-left {
  padding-left: 0.625rem;
}
.JobDetail-itemCenter {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}
.JobDetail-padding-Right {
  padding-right: 1rem;
}
.Duration {
  padding-right: 1rem;
  color: #e4e4e4;
}
.Job_Details_GigsCard {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  gap: 1rem;
}
.Skill-Listing-Title {
  padding-left: 0.625rem;
  font-size: 1rem;
  color: #1e1e1e;
  font-weight: 500;
}

.Skill-Listing-Title-Dashboard{
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  width: 17.5rem;
}
.GigArrow {
  padding-top: 0.25rem;
}
.GigCard-section-padding {
  padding-right: 0.7rem;
}
.Duration_Title-Cancelled {
  padding: 0.85rem;
  font-size: 0.75rem;
}
.Duration_Title {
  float: right;
  font-size: 0.75rem;
}
.Job-Detail-See-Detailed-Ratings {
  float: right;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4054b2;
  padding: 0.625rem;
  cursor: pointer;
}
.Rating-inner-div1 {
  display: flex;
  justify-content: space-between;
  padding: 0.625rem;
  font-size: 0.75rem;
}
.Rating-inner-div2 {
  display: flex;
  justify-content: space-between;
  padding: 0.625rem;
  font-size: 0.75rem;
}
.Rating_section {
  padding-top: 0.438rem;
}
.Job_Details_black_white {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.JobDetailChartIcon {
  cursor: pointer;
}

.GigCard-status-section{
  height: 0.063rem;
  background-color: #eee;
  margin: 1rem -0.75rem;
}

.GigCard-status-section-IT{
  background-color: #eee;
  margin: 1rem -0.75rem;
}

.GigCard-Budget-span{
  font-size: 1.125rem;
  margin: -0.125rem 0px 0px -0.188rem;
  padding-left: 0.125rem;
  font-weight: 600;
}

.Gigcard-Dummy-text{
  font-size: 3rem;
}

@media screen and (max-width: 700px) {
  .black-header {
    background-color: black;
    width: 100%;
  }
  .gig-card-status-Active {
    border-radius: 0.125rem;
  }
  .Skill-Listing-Title {
    font-size: 0.875rem;
  }
  .g-Duration {
    box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  }
  /* .g-sgigs-thecards {
    width: -webkit-fill-available;
  } */
}
.span-icon {
  font-size: 0.95rem;
  /* padding-top: .06rem; */
}
.span-item {
  font-size: 0.9rem;
  width: 24rem;
  margin-right: 0.7rem;
}
.Job_Details_cantainer {
  border: solid 0.063rem #e4e4e4;
  background: white;
  padding-top: 0.825rem;
}
.Job-Details-Modal-contain {
  display: flex;
  flex-direction: column;
  font-size: larger;
  padding-left: 1rem;
  justify-content: space-around;
}
.Job-Details-Modal-selecter {
  padding-bottom: 0.625rem;
}

/* .Job-Details-Heading{

}
.Job-Details-Mobile-View{
  
}
.Job-Details-selecter{

} */

@media screen and (max-width: 480px) {
  .Job_Details_cantainer {
    margin-bottom: 1.5rem;
    width: -webkit-fill-available;
    border-radius: 2px;
  }
  .Skill-Listing-Title {
    font-size: 0.875rem;
    font-weight: 600;
  }
  .Job_Details_GigsCard {
    flex-wrap: wrap;
  }
  .Job_Details_GigsCard {
    justify-content: center;
  }
  .g-sgigs-thecards {
    width: -webkit-fill-available;
  }
  .g-sgigs-theTitleJob {
    font-weight: 600;
  }
}
