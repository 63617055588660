.authorizationModal .ant-modal-content {
  width: 24.37rem !important;
}
.authorizationModal .ant-modal {
  width: 24.37rem !important;
}

.authorization-sign-in {
  width: 24.37rem !important;
  margin: auto;
  /* height: 27.8rem; */
  flex-grow: 0;
  padding: 1.375rem 1.87rem 1rem;
  border-radius: 4px;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .authorization-sign-in {
    width: 100% !important;
    position: relative;
    top: -10px;
  }
}
