.bid-detail-container {
  height: auto;
  width: 100%;
  padding: 2.5vw 6.25vw;
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  gap: 10%;
}

.bid-detail-container .header-container {
  display: flex;
  flex-direction: column;
  min-width: 50%;
}
.CoverLetterHTML_seemore {
  height: max-content;
  max-height: 20rem;
  overflow: hidden;
  font-weight: normal;
  word-break: break-word;
}
.CoverLetterHTML_seemore ul{
    margin-left: 1rem;
}
.CoverLetterHTML_seemore ol{
    margin-left: 1rem;
}
.CoverLetterHTML_seemore dl{
    margin-left: 1rem;
}
.CoverLetterHTML_seemore li{
    margin-left: 1rem;
}


.CoverLetterHTML_seemore ul li{
  margin-left: 0;
}
.cover-letter-content .CoverLetterHTML_seemore ol li{
  margin-left: 0;
}

.CoverLetterHTML_less {
  height: 20rem;
  font-weight: normal;
  word-break: break-word;
}
.CoverLetterHTML_less ul{
    margin-left: 2rem;
}
.CoverLetterHTML_less ol{
    margin-left: 2rem;
}
.CoverLetterHTML_less dl{
    margin-left: 2rem;
}
.CoverLetterHTML_less li{
    margin-left: 2rem;
}

.CoverLetterHTML_less ul li{
    margin-left: 0rem;
}

.CoverLetterHTML_less ol li{
    margin-left: 0rem;
}

ol, ul, dl {
  margin-left: 2rem;
}
.seeMoreButton {
  color: #4054b2;
  cursor: pointer;
  width: max-content;
  position: absolute;
  right: 8%;

  font-weight: 500;
}

.bid-detail-container .header-container .job-title {
  margin: 0.625rem 0 0.625rem 0;
  /* height: 1.313rem; */
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #4054b2;
  cursor: pointer;
}

.bid-detail-container .bid-details-header {
  display: flex;
  /* justify-content: space-between; */
  /* height: 100%; */
  width: 100%;
  cursor: default;
}

.bid-detail-container .bid-details-header > div {
  line-height: normal;
}

.offerError-managebid {
  padding-top: .625rem;
  color: #db2828;
  font-size: .75rem;
  font-weight: normal;
}

.bid-detail-container .g-details-arrow .arrow {
  height: 1rem;
  width: 1rem;
  margin: 0.15rem 0.475rem 0 0.358rem;
  /* line-height: normal; */
  align-self: center;
  color: black;
}

.bid-detail-container .bid-header-text {
  height: 1.188rem;
  flex-grow: 0;
  /* font-family: SFProText; */
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  /* line-height: normal; */
  letter-spacing: normal;
  text-align: left;
  color: #4054b2;
  cursor: pointer;
}

.bid-detail-container .bid-header-text-grey {
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #7e7e7e;
  max-width: 70%;
}

.bid-detail-container .g-details-facility-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: default;
}

.bid-detail-container .g-details-facility-left {
  display: flex;
  align-items: center;
  height: 1.063rem;
}

.bid-detail-container .g-details-facility-container .g-details-facility-name {
  display: flex;
  margin: 0 1.563rem 0 0;
  align-self: center;
}

.bid-detail-container
  .g-details-facility-container
  .g-details-facility-name
  > div {
  margin: -1px 0.25rem 0 0;
  height: 1.063rem;
  /* font-family: SFProText; */
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.bid-detail-container .g-details-facility-container .right {
  display: flex;
  height: 0.875rem;
  align-items: center;
  padding-top: 0.375rem;
}

.bid-detail-container .bid-detail-inner-container {
  margin: 19rem 4.125rem 0.625rem 1.188rem;
}

.bid-detail-container .bid-details {
  /* width: 7.438rem; */
  width: max-content;
  height: 3.313rem;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 0.313rem;
  gap: 0.313rem;
  padding: 0.313rem 0.625rem;
  border-radius: 0.25rem;
  background-color: var(--primaryColor);
}

.bid-detail-container .location-details {
  /* width: 11.563rem; */
  width: max-content;
  height: 1.938rem;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 0.625rem;
  gap: 0.625rem;
  /* margin: 0.625rem 0 0 1.188rem; */
  margin: 0.625rem 0 0 0rem;
  padding: 0.313rem 0.625rem;
  border-radius: 0.25rem;
  background-color: rgba(30, 30, 30, 0.9);
}

.bid-detail-container .content-container-bid {
  margin: 1.25rem 0 0 0rem;
}

.bid-detail-container .content-container-bid-disabled {
  margin: 1.25rem 0 0 0rem;
}

.bid-detail-container .skillListing-title-bid {
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;

  margin-bottom: 0.938rem;
}

.bid-detail-container .ractangle-left {
  flex: 1.558 1;
  height: 26.125rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
  cursor: default;
  border-radius: 4px 4px 0 0;
}

.content-container-bid-disabled .city-image-bd {
  filter: grayscale(100%);
}

.content-container-bid-disabled .bid-details {
  background-color: #7e7e7e;
}

.content-container-bid-disabled .location-details {
  background-color: #7e7e7e;
}

.content-container-bid-disabled
  .g-details-facility-container
  .g-details-facility-name
  > div {
  color: #7e7e7e;
}

.content-container-bid-disabled .g-details-facility-container .right .ant-rate {
  color: #7e7e7e;
}

.content-container-bid-disabled .rating-text-color {
  color: #7e7e7e !important;
}

.bid-about-job-container-disabled .details-about-job-left {
  color: #7e7e7e !important;
}

.bid-about-job-container-disabled .details-about-job-right-green {
  color: #7e7e7e !important;
}

.bid-about-job-container-disabled .bid-about-job .details-about-job-right {
  color: #7e7e7e !important;
}

.bid-about-job-container-disabled .bid-cover-letter {
  color: #7e7e7e !important;
}

.bid-about-job-container-disabled .bid-cover-message {
  color: #7e7e7e !important;
}

.bid-detail-container .rating-text-color {
  color: #ffba08;
  font-size: 0.75rem;
  padding-top: 0.25rem;
}

.Review-Rating-bid-detail {
  padding-top: 0.25rem;
  font-size: 0.75rem;
  color: #7e7e7e;
}

.start-Rating-talent-stage .ant-rate {
  font-size: 0.75rem !important;
}

.bid-detail-container .city-image-bd {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  z-index: -1;
}

.bid-detail-container .bid-details-text {
  padding: 1.25rem;
  border-radius: 0 0 0.25rem 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
}

.bid-details-text .text-section {
  margin-bottom: 1.25rem;
}

.bid-detail-container .bid-text-title {
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 1rem;
}

.bid-details-text .sub-text-bid {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.bid-details-text .check-icon-text {
  font-size: 0.6rem;
}

.bid-details-text .subText-icon-bid {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.bid-details-text .bid-text-paragraph {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: left;
  color: #6e6e6e;
  margin-bottom: 1.25rem;
}

.bid-details-text .subText-icon-bonus-bid {
  display: flex;
  align-items: baseline;
  gap: 10px;
  margin-bottom: 10px;
}

.upload-sign-file {
  width: 100%;
  height: 4.31rem;
  /* margin: 1.25rem; */
  padding: 0.63rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;

  margin-bottom: 1.5rem;

  display: flex;
  gap: 10px;
  /* justify-content: center; */
  align-items: center;
}

.upload-sign-file .filesize-and-type {
  display: flex;
  flex-direction: column;
}

/* right side of the page */

.bid-detail-container .bid-about-job-container {
  min-width: 38%;
  display: flex;
  flex-direction: column;
}

.bid-detail-container .bid-about-job-container-disabled {
  min-width: 38%;
  display: flex;
  flex-direction: column;
}

.bid-about-job-container .bid-status-bar {
  width: 100%;
  height: 4.19rem;
  /* margin: 2.50rem 6.25rem 2.38rem 3.75rem; */
  padding: 1.25rem 0.94rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  margin-bottom: 1.8rem;
  padding-top: 2.5rem;
}

.bid-about-job-container .bid-about-job {
  width: 100%;
  /* height: 29.25rem; */
  flex-grow: 0;
  /* margin: 1.25rem 6.25rem 0.94rem 3.75rem; */
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
  margin-bottom: 0.938rem;
}

.bid-about-job-container .bid-about-job-margin {
  width: 100%;
  /* height: 29.25rem; */
  flex-grow: 0;
  /* margin: 1.25rem 6.25rem 0.94rem 3.75rem; */
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
  margin-bottom: 1.25rem !important;
}

.bid-about-job-container-disabled .bid-status-bar {
  width: 100%;
  height: 4.19rem;
  /* margin: 2.50rem 6.25rem 2.38rem 3.75rem; */
  padding: 1.25rem 0.94rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  margin-bottom: 1.8rem;
  padding-top: 2.5rem;
}

.bid-about-job-container-disabled .bid-about-job {
  width: 100%;
  /* height: 29.25rem; */
  flex-grow: 0;
  /* margin: 1.25rem 6.25rem 0.94rem 3.75rem; */
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
  margin-bottom: 0.938rem;
}

.bid-about-job .bid-about-job-section {
  margin: 1.25rem;
}

.bid-about-job .title-about-job {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  margin-bottom: 1.194rem;
}

.bid-about-job .para-about-job {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 0.938rem;
}

.bid-about-job .details-about-job {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.938rem;
}

.bid-about-job .details-about-job-left {
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e;
}

.bid-about-job .details-about-job-right-green {
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #118936;
}

.bid-about-job .details-about-job-right {
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #1e1e1e;
}

.bid-detail-container .bid-cover-letter {
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  margin-bottom: 9px;
}

.bid-detail-container .bid-cover-message {
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.bid-detail-container .bid-chat-with-recruiter-mobile {
  width: 100%;
  height: 5.13rem;
  flex-grow: 0;
  border: solid 0.06rem #e4e4e4;
}

.bid-chat-with-recruiter-mobile .chat-with-recruiter-button-green {
  display: flex;
  gap: 10px;
  justify-content: center;
  /* width: 22.50rem;
      height: 2.63rem; */
  flex-grow: 0;
  padding: 0.69rem 0;
  /* border-radius: 0.25rem; */
  text-align: center;

  height: 2.63rem;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: var(--primaryColor);

  /* font */
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.bid-about-job-container .bid-chat-with-recruiter {
  width: 100%;
  height: 5.13rem;
  flex-grow: 0;
  /* margin: 0.94rem 6.25rem 23.56rem 3.75rem; */
  padding: 1.25rem;
  border: solid 0.06rem #e4e4e4;
}

.bid-about-job-container-disabled .bid-chat-with-recruiter {
  width: 100%;
  height: 5.13rem;
  flex-grow: 0;
  /* margin: 0.94rem 6.25rem 23.56rem 3.75rem; */
  padding: 1.25rem;
  border: solid 0.06rem #e4e4e4;
}

.bid-chat-with-recruiter .chat-with-recruiter-button-white {
  display: flex;
  gap: 10px;
  justify-content: center;
  /* width: 22.50rem;
  height: 2.63rem; */
  flex-grow: 0;
  padding: 0.69rem 6.19rem 0.69rem 6.25rem;
  border-radius: 0.25rem;
  border: solid 0.06rem var(--primaryColor);
  text-align: center;

  /* font */
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--primaryColor);
}

.bid-chat-with-recruiter .chat-with-recruiter-button-green {
  display: flex;
  gap: 10px;
  justify-content: center;
  /* width: 22.50rem;
    height: 2.63rem; */
  flex-grow: 0;
  padding: 0.69rem 0;
  border-radius: 0.25rem;
  text-align: center;

  height: 2.63rem;
  border: solid 0.06rem var(--primaryColor);
  background-color: var(--primaryColor);

  /* font */
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.bid-detail-container .completeCredentialing-bid {
  /* width: 25rem; */
  height: 17.38rem;
  flex-grow: 0;
  /* margin: 0.94rem 6.25rem 0.94rem 3.75rem; */
  padding: 1.25rem 0;
  box-shadow: 0 0 0.25rem 0 #000;
  background-color: var(--primaryColor-blue);

  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1.25rem 0.625rem;
}

.completeCredentialing-bid .congratesName {
  font-size: 0.88rem;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  margin-bottom: 0.938rem;
}

.completeCredentialing-bid .finalStepOnboard {
  width: 16rem;
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin-bottom: 0.938rem;
}

.completeCredentialing-bid .completeButton {
  width: 13.94rem;
  height: 1.88rem;
  flex-grow: 0;
  /* margin: 1.44rem 4.75rem 0 4.75rem; */
  padding: 0.31rem 0;
  border-radius: 1.25rem;
  background-color: #fff;
  text-align: center;

  /* font */
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4054b2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.completeButton img {
  padding-right: 0.625rem;
}

.content-container-bid-disabled-rating .g-details-facility-name > div {
  color: #7e7e7e !important;
}

.content-container-bid-disabled-rating .rating-text-color {
  color: #7e7e7e !important;
}

.content-container-bid-disabled-rating .ant-rate-disabled {
  color: #7e7e7e !important;
}

.offerLetter-bid-details {
  height: 3.75rem;
  flex-grow: 0;
  /* margin: 1.88rem 0 0; */
  /* padding: 0.63rem 8.5rem; */
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #eeeeee;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.offerLetter-bid-details .viewOffer {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.placebid-talentStage {
  justify-content: center !important;
  padding: 0.63rem 0rem !important;
}

.placebid-offerletter {
  padding-top: 0.563rem;
}

.offerLetter-bid-details .offerletter {
  font-size: 0.88rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #7e7e7e;

  display: flex;
  gap: 8px;
}

.welcome-on-board {
  /* width: 25.00rem; */
  /* height: 7.63rem; */
  flex-grow: 0;
  margin: 0 0 0.94rem;
  padding: 1.25rem;
  /* box-shadow: 0 0 0.25rem 0 #000; */
  background-color: #e7f3eb;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.875rem !important;
}

.welcome-on-board .welcome-on-board-text {
  font-size: 1.25rem;
  font-weight: 600;
  color: #118936;
  padding: 0.938rem 0 1.25rem 0;
}

.welcome-on-board-Rejected-text {
  padding: 0.938rem 0 1.25rem 0;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: left;
  color: #c44d3d;
}

.bid-detail-container .bid-full-description {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primaryColor-blue);
  margin-top: 5px;
}

.drawer-bid-detail .ant-drawer-title {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.drawer-bid-detail .ant-drawer-body {
  padding: 20px 15px;
}

.Happy-Skillgigsing {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  text-align: center;
  margin-top: 30px;
}

.bid-detail-container .bid-cover-detailPage {
  margin: 0 1.25rem;
}

.bid-detail-container .g-star-rating {
  font-weight: 500;
  font-size: 0.75rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.bid-detail-container .add-favorite-bid {
  position: absolute;
  top: 10px;
  right: 10px;
}

.declinedBidsModal .declineThisBid {
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  padding: 1rem 1.25rem;
  border-bottom: 0.06rem solid #e4e4e4;
}

.declinedBidsModal .reasonForDeclined {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;

  margin-bottom: 1.25rem;
}

.declinedBidsModal .reasonbox {
  height: 2.31rem;
  /* margin: 0.31rem 1.25rem; */
  padding: 0.625rem 0 0.625rem 0.938rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #d4d4d4;
  background-color: #fff;

  /* font */
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.declinedBidsModal .reasonbox-selected {
  background-color: #e4ffec !important;
}

.declinedBidsModal .reasonbox:hover {
  background-color: #e4ffec;
  cursor: pointer;
}

.declinedBidsModal .reasonbox-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.declinedBidsModal .declinedThisdBidContent {
  padding: 0.625rem 0.875rem 1.25rem 0.938rem;
}

.Bid-cursor-pointer {
  cursor: pointer !important;
}

.declinedBidsModal .declinedThisdBidContent .submitReason {
  height: 2.31rem;
  /* margin: 1.25rem 1.25rem 0; */
  padding: 0.6rem 0;
  border-radius: 0.313rem;
  background-color: var(--primaryColor);
  text-align: center;
  margin-top: 1.25rem;

  /* font */
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  cursor: pointer;
}

.Workplace-Policy {
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.welcome-on-board-text-Congratual {
  font-size: 1.375rem;
  color: #3e3e3e;
  font-weight: 600;
}

.welcome-on-board-text-status {
  font-size: 1.25rem;
  color: #3e3e3e;
  font-weight: 500;
}

.welcome-on-board-Rejected-text-Congratual {
  font-size: 1.375rem;
  color: #3e3e3e;
  font-weight: 600;
}

.welcome-on-board-text-status-Duration {
  font-size: 1rem;
  color: #3e3e3e;
  padding: 0.75rem 0 1.25rem 0;
}

.seeMoreButton {
  color: #4054b2;
  cursor: pointer;
  width: max-content;
  position: absolute;
  right: 8%;

  font-weight: 500;
}

.welcome-on-board-Rejected-text-status-Duration {
  font-size: 1rem;
  color: #3e3e3e;
  padding: 0.75rem 0 1.25rem 0;
}

.welcome-on-board-Rejected-text-status {
  font-size: 1rem;
  color: #3e3e3e;
  font-weight: 600;
}

.welcome-on-board img {
  max-width: 4.375rem;
  margin-left: .375rem;
}

.welcome-on-board-Rejected {
  flex-grow: 0;
  margin: 0 0 0.94rem;
  padding: 1.25rem;
  /* box-shadow: 0 0 0.25rem 0 #000; */
  background-color: #fff2f2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.875rem !important;
}

.chatButton-Mobile {
  height: 1.875rem;
  border-radius: 1.25rem;
  background-color: #118936;
  padding: 0.313rem 1.563rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: #e7f3eb;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 11.875rem;
  margin-top: 0rem;
}

.chatButton-Mobile .ant-badge-dot {
  box-shadow: none;
  top: 0.25rem;
}

.TalentStage-mobile-span-text-value {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.TalentStage-mobile-span-text-value > span {
  font-weight: bold;
  font-size: 0.813rem;
  color: #1e1e1e;
}

.TalentStage-mobile-span-text-value > div {
  font-weight: normal;
  font-size: 0.75rem;
  color: #4e4e4e;
}

.TalentStages-RadioButton-interview .ant-radio {
  top: 0em;
}

.talent-stages-Offer-Accepted {
  display: flex;
  flex-direction: column-reverse;
}

.TalentStages-RadioButton-interview {
  /* width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 0.625rem; */
  margin-top: 1.25rem;
  /* margin-bottom: 1.875rem; */
  width: 100%;
  display: flex;
  justify-content: center;
}

.remove-check-Button-TalentStages .ant-radio {
  display: none;
}

.remove-check-Button-ExtraSpace {
  padding-left: 0.125rem !important;
}

.TalentStages-RadioButton-interview
  .TalentStages-RadioButton-interview-section {
  display: flex;
  width: 100%;
  height: 3.5rem;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0.625rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.15);
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
}

.TalentStages-RadioButton-interview .ant-space-vertical {
  width: 93%;
  gap: 0.625rem !important;
}

/* .TalentStages-RadioButton-interview .ant-radio-wrapper{
  min-width: 22.63rem;
} */

.TalentStages-RadioButton-interview .ant-radio-checked .ant-radio-inner {
  border-color: var(--primaryColor);
}

.TalentStages-RadioButton-interview .ant-radio-inner::after {
  background-color: var(--primaryColor);
}

.welcome-on-board-Rejected img {
  max-width: 4.375rem;
}

/* Mobile View */
@media screen and (max-width: 768px) {
  .bid-detail-container {
    display: grid;
    padding: 0;
    gap: 2.7rem;
    justify-content: unset;
  }

  .bid-detail-container .bid-details-header {
    width: unset !important;
    margin: 1.25rem 0.938rem 0;
  }

  .bid-detail-container .bid-detail-inner-container {
    margin: 4rem 4.125rem 0.625rem 1.188rem;
  }

  .bid-detail-container .ractangle-left {
    height: 10.63rem;
    border-radius: unset;
  }

  .bid-detail-container .ractangle-left-disabled {
    height: 10.63rem;
    border-radius: unset;
  }

  .bid-detail-container .g-details-facility-left {
    display: flex;
    align-items: flex-start;
    height: 1.063rem;
    flex-direction: column;
    gap: 10px;
  }

  .bid-detail-container .detail-title-mobile-view {
    padding: 0 15px;
  }

  .bid-detail-container .header-container .job-title {
    margin: 1.25rem 0 1.625rem 0;
  }

  .bid-about-job .title-about-job {
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #7e7e7e;
  }

  .bid-about-job-container .bid-about-job {
    margin: 0;
    width: unset !important;
    border: unset !important;
    margin-bottom: unset !important;
    /* border-top: solid 0.06rem #e4e4e4;
    border-bottom: none;
    border-left: none;
    border-right: none; */
  }

  .bid-about-job-container .bid-divider-detail {
    height: 0.06rem;
    margin: 0 0.94rem;
    background-color: #d4d4d4;
  }

  .bid-about-job-container-disabled .bid-cover-detailPage {
    margin: 0 1.25rem;
  }

  .bid-about-job-container-disabled .bid-about-job {
    margin: 0;
    width: unset !important;
    border: unset !important;
    /* border-top: solid 0.06rem #e4e4e4;
    border-bottom: none;
    border-left: none;
    border-right: none; */
  }

  .bid-about-job-container-disabled .bid-divider-detail {
    height: 0.06rem;
    margin: 0 0.94rem;
    background-color: #d4d4d4;
  }

  .bid-about-job-container-disabled .bid-cover-detailPage {
    margin: 0 1.25rem;
  }

  .bid-about-job .bid-about-job-section {
    margin: 1.25rem 1.25rem 0.938rem;
  }

  .bid-about-job .details-about-job {
    margin-bottom: 0.75rem;
  }

  .bid-detail-container .edit-retract-row {
    display: flex;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
  }

  .bid-detail-container .editButton-bid {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38%;
    height: 2.88rem;
    flex-grow: 0;
    /* margin: 0 8.75rem 0 0; */
    padding: 0.84rem 0 0.88rem 0;
    background-color: var(--primaryColor);
    border: none;

    font-size: 0.88rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  .bid-detail-container .editButton-bid-disabled {
    width: 38%;
    height: 2.88rem;
    flex-grow: 0;
    /* margin: 0 8.75rem 0 0; */
    padding: 0.84rem 0 0.88rem 0;
    background-color: #e4e4e4;

    border: none;

    font-size: 0.88rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #c4c4c4;
  }

  .bid-detail-container .retractedButton-bid {
    width: 38%;
    height: 2.88rem;
    padding: 0.84rem 0 0.88rem 0;

    border: none;

    font-size: 0.88rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #7e7e7e;

    background-color: white;
  }

  .bid-detail-container .retractedButton-bid-disabled {
    width: 38%;
    height: 2.88rem;
    padding: 0.84rem 0 0.88rem 0;

    border: none;

    font-size: 0.88rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #c4c4c4;

    background-color: white;
  }

  .bid-detail-container .chatbutton-bid {
    width: 24%;
    border: none;
    border-left: 1px solid lightgrey;
    background-color: white;
  }

  .bid-detail-container .chatbutton-bid-green {
    width: 24%;
    border: none;
    background-color: var(--primaryColor);
  }

  .bid-detail-container .ant-badge-dot {
    box-shadow: none !important;
    top: 0.25rem !important;
  }

  .bid-detail-container .screening-status-disable {
    margin-bottom: 0;
  }

  .bid-detail-container .chatWithRecruiterMobile {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    height: 2.88rem;
    box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
    background-color: var(--primaryColor);

    /* font */
    font-size: 0.88rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  .bid-detail-container .status-bid-success {
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--primaryColor);
    margin-bottom: 8px;
  }

  .bid-detail-container .status-bid-fail {
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #c44d3d;
    margin-bottom: 8px;
  }

  .bid-detail-container .title-bid-greet {
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    color: #3e3e3e;
  }

  .bid-detail-container .subtext-detail-info {
    margin-bottom: 8px;
  }

  .bid-detail-container .date-detail-info {
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e3e3e;
    margin-bottom: 10px;
  }

  .bid-detail-container .chat-detail-button-card {
    width: 11.88rem;
    height: 1.88rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 1.25rem;
    background-color: var(--primaryColor);

    font-size: 0.75rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #e7f3eb;
  }

  .bid-detail-container .status-bid-detail-info-success {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0.63rem;
    box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
    background-color: #e7f3eb;

    padding: 15px 0;
  }

  .bid-detail-container .status-bid-detail-info-fail {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0.63rem;
    box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
    background-color: #fff2f2;

    padding: 15px 0;
  }

  .bid-detail-container .gotAccepted-bid {
    height: 3.56rem;
    flex-grow: 0;
    margin: 1.56rem 0 0;
    padding: 1.3rem 0 1.3rem;
    box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
    background-color: #e4e4e4;
    text-align: center;

    /* font */
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7e7e7e;
  }

  .bid-detail-container .skillListing-title-bid {
    margin-left: 0.938rem;
    font-size: 0.875rem;
    font-weight: 600;
  }

  .Workplace-Policy {
    font-size: 0.875rem;
  }

  .welcome-on-board {
    margin: 0.625rem;
  }

  .welcome-on-board-Rejected {
    margin: 0.625rem;
  }

  .chatButton-Mobile {
    height: 1.875rem;
    border-radius: 1.25rem;
    background-color: #118936;
    padding: 0.313rem 1.563rem;
    font-size: 0.75rem;
    font-weight: 500;
    color: #e7f3eb;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 11.875rem;
    margin-top: 0rem !important;
  }

  .Chat-with-employer-icon {
    margin-left: .625rem;
  }

  .screening-status {
    margin: none;
  }

  .welcome-on-board .welcome-on-board-text {
    font-size: 1rem;
    font-weight: 600;
    color: #118936;
    padding: 0.5rem 0 0.5rem 0;
  }

  .welcome-on-board-text-Congratual {
    font-size: 1rem;
    font-weight: bold;
  }

  .welcome-on-board-text-status {
    font-size: 0.75rem;
    color: #3e3e3e;
  }

  .welcome-on-board-text-status-Duration {
    font-size: 0.75rem;
    color: #3e3e3e;
    padding: 0.5rem 0 0.625rem 0;
  }

  .welcome-on-board-Rejected-text-status-Duration {
    font-size: 0.75rem;
    color: #3e3e3e;
    padding: 0.5rem 0 0.625rem 0;
  }

  .welcome-on-board-Rejected-text-status {
    font-size: 0.75rem;
    color: #3e3e3e;
  }

  .welcome-on-board-Rejected {
    flex-grow: 0;
    padding: 1.25rem;
    background-color: #fff2f2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .welcome-on-board-Rejected-text-Congratual {
    font-size: 1rem;
    color: #3e3e3e;
    font-weight: bold;
  }

  .welcome-on-board-Rejected img {
    max-width: 3.125rem;
  }

  .welcome-on-board img {
    max-width: 3.125rem;
  }

  .welcome-on-board-Rejected-text {
    padding: 0.5rem 0 0.5rem 0;
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
    color: #c44d3d;
  }

  .TalentStages-RadioButton-interview {
    /* padding-bottom: 1.25rem; */
    margin-bottom: unset;
    /* margin-bottom: 0.75rem; */
  }

  .ErrorMessage {
    padding: 0.938rem .125rem 1.25rem 1.25rem !important;
  }

  .bid-about-job .details-about-job-left {
    color: #3e3e3e;
  }

  .TalentStages-RadioButton-interview .ant-space-vertical {
    width: 93%;
    gap: 0.188rem !important;
  }

  .completeCredentialing-bid .completeButton {
    font-size: 0.75rem;
  }

  .bid-detail-container .bid-header-text {
    font-weight: 500;
    text-align: initial !important;
  }

  .offerLetter-bid-details .viewOffer {
    margin-bottom: .375rem;
  }

}

