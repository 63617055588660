.message-container-outer {
  display: flex;
  flex-direction: row;
}
.Message-Detial {
  display: flex;
  height: 70%;
  width: -webkit-fill-available;
  min-width: auto;
  padding-top: 1.25rem;
  overflow-y: scroll;
  flex-direction: column;
}
.Message-Detial::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.Message-Detial::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.Message-Detial::-webkit-scrollbar-thumb {
  background: #e4e4e4;
}

/* Handle on hover */
.Message-Detial::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
.Message-Detial .Card-container {
  max-width: 50%;
  margin-bottom: 1rem;
  margin-right: 0.938rem;
  margin-left: 0.938rem;
}

.Message-Detial .chat-right {
  align-self: flex-end;
}

.user-sender {
  /* display: flex !important; */
  justify-content: flex-end !important;
}
.employer-sender {
  display: flex !important;
  justify-content: flex-start !important;
  /* margin-left: 1.25rem !important; */
}

.Message-Detial .Card-container-placed-bid {
  flex: 1;
  margin: 1.563rem 1.25rem 0.625rem 23vw;
}
/* INTERESTED NOT INTERESTED BUTTONS */
.Message-Detial .Card-container .buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: -2.375rem 0 0 0;
  gap: 0.625rem;
  /* padding: 0 10px 10px; */
}
.Message-Detial .Card-container .buttons .interested {
  display: flex;
  width: 291.3px;
  height: 38.7px;
  border-radius: 0.25rem;
  /* border: solid 1px #118936; */
  background-color: #e7f3eb;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #118936;
  font-weight: 500;
  cursor: pointer;
}
.Message-Detial .Card-container .buttons .interested-decline {
  display: flex;
  width: 291.3px;
  height: 38.7px;
  border-radius: 4px;
  /* border: solid 1px #118936; */
  background-color: #f2f2f2;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #9e9e9e;
  font-weight: 500;
  cursor: default;
}
.Message-Detial .Card-container .buttons .not-interested {
  display: flex;
  width: 291.3px;
  height: 38.7px;
  /* flex-grow: 0; */
  /* margin: 0 17.4px 0 0; */
  /* padding: 10px 106.3px 9.7px 106px; */
  border-radius: 4px;
  /* border: solid 1px #118936; */
  background-color: #e7f3eb;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #118936;
  font-weight: 500;
  cursor: default;
}
/*  */
.Message-Detial .Card-container .gig-detail-container {
  display: flex;
  width: 45vw;
  /* height: 884px; */
  /* box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25); */
  background-color: #fff;
}
.Message-Detial .Card-container .gig-detail-container .congratulation {
  font-weight: 600;
  color: #4e4e4e;
}
.Message-Detial .Card-container .employer-offer {
  padding: 0.938rem 0.938rem !important;
  background-color: #fff;
  border-radius: 0.313rem;
}
.Message-Detial .Card-container .employer-terminated {
  padding: 0.938rem 0.938rem !important;
  background-color: #fff2f2 !important;
  border-radius: 0.313rem;
}
.Message-Detial .Card-container .gig-detail-container .message-header .text1 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #1e1e1e;
  margin-left: 0.938rem;
  margin-top: 0.938rem;
  margin-bottom: 0.375rem;
}
.Message-Detial .Card-container .gig-detail-container .message-header .text1 {
  font-size: 0.938rem;
  font-weight: 600;
  color: #1e1e1e;
  margin-left: 0.938rem;
  margin-top: 0.938rem;
  margin-bottom: 0.375rem;
  cursor: default;
}
.Message-Detial
  .Card-container
  .gig-detail-container
  .message-header
  .text1-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Message-Detial .Card-container .gig-detail-container .ractangle-right .text1 {
  font-size: 1rem;
  font-weight: bold;
  color: #1e1e1e;
  margin-bottom: 0.75rem;
  cursor: default;
}
/* /// Favorite Star //// */
.Message-Detial .favorite-bid-background {
  display: none;
  width: 1.25rem;
  height: 1.25rem;
  flex-grow: 0;
  /* margin: 0 0 2.13rem 8.13rem; */
  margin-top: 0.938rem;
  margin-right: 0.938rem;
  border-radius: 1.38rem;
  box-shadow: unset;
  background-color: unset;
  display: flex;
  justify-content: center;
}
.Message-Detial .Card-container .gig-detail-container .message-header .text2 {
  font-size: 0.938rem;
  font-weight: 600;
  margin-left: 0.938rem;
  margin-bottom: 0.5rem;
  color: #1e1e1e;
  cursor: default;
}
.Message-Detial
  .Card-container
  .gig-detail-container
  .message-header
  .skill-text {
  font-size: 0.938rem;
  font-weight: 500;
  color: #1e1e1e;
  margin-left: 0.938rem;
  margin-bottom: 0.5rem;
  cursor: default;
}
.Message-Detial .content-container-mobile .divider-mobile {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  background-color: #dadcdb;
  margin: 0 0 1px 0;
}
.Message-Detial .Card-container .gig-detail-container {
  height: auto;
  width: 100%;
  /* padding: 2.5rem 6.25rem; */
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: #e7f3eb;
  border-radius: 0.313rem;
}
.Message-Detial .Card-container .content-container-mobile .ractangle-left {
  width: 100%;
  height: 23vw;
  flex-grow: 0;
  margin: 0rem 0rem 1.25rem 0rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.Message-Detial .Card-container .content-container-mobile .ractangle-right {
  width: 100%;
  flex-grow: 0;
  padding: 0.625rem 0.938rem;
  border-radius: 0.313rem;
  background-color: #e7f3eb;
}
.Message-Detial
  .Card-container
  .content-container-mobile
  .ractangle-right-employer-offer {
  background-color: #fff;
  margin-top: 0.625rem;
  margin-bottom: 0.938rem;
}

.Message-Detial .Card-container .open-document {
  margin-top: 0.938rem;
  margin-bottom: 0.5rem;
  font-size: 14px;
  line-height: 1.57;
  color: #4e4e4e;
}
.Message-Detial .offercard-document {
  display: flex;
  background-color: #fff;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}
.Message-Detial .offercard-document .document-details {
  height: 68px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Message-Detial .offercard-document .document-details .file-name {
  font-size: 0.875rem;
  font-weight: bold;
  color: #1e1e1e;
}
.Message-Detial .offercard-document .document-details .file-size {
  font-size: 0.875rem;
  color: #4e4e4e;
}
.Message-Detial
  .Card-container
  .content-container-mobile
  .g-details-facility-container
  .rating-review
  .ant-rate {
  background-color: #e7f3eb;
  height: 0.75rem;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.7rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: "tnum", "tnum", "tnum";
  display: inline-block;
  margin: 0px 0px 0 0px;
  padding: 0;
  color: #ffba08;
}

.Message-Detial
  .Card-container
  .content-container-mobile
  .g-details-facility-container
  .rating-review
  > div {
  height: 0.875rem;
  font-size: 0.75rem;
  flex-grow: 0;
  margin: 0 0px 0 0;
  padding: 0px;
  background-color: #e7f3eb;
}

/* ////////// STAR RATING /////////// */
.Message-Detial
  .Card-container
  .content-container-mobile
  .g-details-facility-container
  .rating-review {
  display: flex;
  flex-direction: row;
  margin: -8px 1.53rem 0.875rem 14px;
}

.Message-Detial
  .Card-container
  .content-container-mobile
  .ractangle-right
  .details
  > div {
  /* width: 56px; */
  font-size: 0.75rem;
  cursor: default;
  font-weight: 500;
  color: #3e3e3e;
}
.offer-details {
  font-size: 0.875rem !important;
  color: #1e1e1e;
  font-weight: 500;
}
.offer-message-text {
  font-size: 0.875rem !important;
  color: #7e7e7e !important;
}
.Message-Detial
  .Card-container
  .content-container-mobile
  .ractangle-right
  .offer-details
  > div {
  font-size: 0.875rem !important;
  font-weight: 500;
  color: #1e1e1e;
}
.Message-Detial
  .Card-container
  .content-container-mobile
  .ractangle-right
  .details
  .values {
  /* width: 56px; */
  font-size: 0.75rem;
  cursor: default;
  font-size: 14px;
  font-weight: 500;
  color: #7e7e7e;
}

.Message-Detial
  .content-container-mobile
  .ractangle-left
  .bid-details-mobile-container {
  margin: 11.25rem 0rem 0.625rem 0.938rem;
}
.Message-Detial .send-message-container {
  padding: 0 10px 10px;
  bottom: 0;
  position: absolute;
  width: -webkit-fill-available;
  background: #fff;
  z-index: 1;
}
.Message-Detial .send-message-container .ant-input {
  padding: 0rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  transition: all 0.3s, height 0s;
}

.Message-Detial .send-message-container .teaxArea-content {
  padding: 0.75rem 0.9375rem 0.6875rem 0.9375rem;
  height: 2.5rem;
  font-size: 0.875rem;
  width: 100%;
  border: none;
  box-shadow: none;
  line-height: 1.1625rem;
  overflow: hidden;
  resize: none;
  transition: all 0.3s, height 0s;
}

.attachment-box {
  margin: 0rem;
}
.attachment-container {
  width: 50%;
  margin: 0.75rem 0.9375rem 0.6875rem;
  position: relative;
}

.attachment-content {
  width: 100%;
  padding: 0.625rem 0.75rem 0.9375rem;
  border-radius: 0.3125rem;
  border: solid 0.0625rem #c4c4c4;
  display: flex;
  gap: 0.9375rem;
}

.attachment-content .fileIcon {
  max-width: 1.25rem;
  max-height: 1.625rem;
  fill: #cb0606;
}

.attachment-content-info {
  width: 90%;
}

.attachment-content-info > h3 {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0rem;
  padding-bottom: 0.125rem;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.attachment-content-info > p {
  margin: 0rem;
  font-size: 0.625rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9e9e9e;
}

.attachment-container .attachment-container-close-btn {
  position: absolute;
  top: -0.4375rem;
  right: -0.5rem;
}

a.message-send-btn {
  color: #118936;
  font-weight: 500;
}

.Message-Detial .send-message-container .message-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.25rem;
}
.Message-Detial .bid-title {
  font-size: 18px;
  color: #1e1e1e;
  font-weight: 500;
  line-height: 1.22;
}
.Message-Detial .Message-box-profile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -11px 0 0 0;
}

/* ////////Rate Employer Button */
.Message-Detial .rate-employer-button {
  margin-top: 0.625rem;
  margin-bottom: 1.25rem;
}
.Message-Detial .rate-employer-button .rate-employer {
  display: flex;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  width: -webkit-fill-available;
  background-color: var(--primaryColor);
  border-radius: 0.313rem;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  cursor: default;
}

/* //// RECEIVED BID MESSAGE /// */
/* //CONGRATULATION// */
.Message-Detial .chat-container1 {
  display: flex;
  justify-content: flex-end;
  padding-right: 0.938rem;
}
.Message-Detial .chat-container-receivedbid {
  display: flex;
  justify-content: flex-start;
  padding-right: 0.938rem;
}
.Message-Detial .chat-container-receivedbid .user-threat {
  width: 325px;
  height: 60px;
  flex-grow: 0;
  margin: 20px 3px 20px 20px;
  padding: 19.1px 9.1px 9.8px 17.5px;
  border-radius: 0.313rem;
  color: #4e4e4e;
  /* border: solid 1px #ddd; */
  background-color: #e7f3eb;
}
.Message-Detial .chat-container1 .user-threat {
  width: 325px;
  height: min-content;
  flex-grow: 0;
  margin: 20px 3px 20px 20px;
  padding: 5px 9.1px 9.8px 15px;
  border-radius: 0.313rem;
  color: #4e4e4e;
  /* border: solid 1px #ddd; */
  background-color: #e7f3eb;
}
.Message-Detial .chat-container1 .user-threat .congratulation {
  font-size: 14px;
  font-weight: bold;
  margin-top: 0.938rem;
}
.Message-Detial .chat-container1 .user-threat .accept {
  font-size: 12px;
  margin-bottom: 10px;
}
.Message-Detial .user-threat .proposed-interview-font {
  font-size: 0.875rem !important;
  margin-top: 0.938rem;
  margin-bottom: 0rem !important;
}
.Message-Detial .chat-container2 {
  margin-bottom: 60px;
  margin-bottom: 10px;
}
.Message-Detial .chat-container2 .user-threat {
  width: 325px;
  height: 60px;
  flex-grow: 0;
  margin: 20px 3px 20px 20px;
  padding: 19.1px 9.1px 9.8px 17.5px;
  border-radius: 0.313rem;
  border: solid 1px #ddd;
  background-color: #fff;
}

/* //PROFILE SUBMITTED// */

.Message-Detial .chat-profile-submit {
  display: flex;
  justify-content: flex-start;
  padding-right: 0rem;
}

.Message-Detial .chat-profile-submit.interview-declined-card {
  justify-content: flex-end;
}

.Message-Detial .chat-profile-submit.interview-declined-card .user-threat {
  margin-right: 0.9375rem;
}

.Message-Detial .chat-profile-proposed {
  justify-content: flex-end;
  margin-right: 1.25rem;
}
.Message-Detial .chat-profile-proposed .user-threat {
  /* height: 339px !important; */
}
.terminated-threat {
  width: -webkit-fill-available !important;
  margin: 0rem !important;
  padding: 0rem !important;
  border-radius: 0.313rem;
  color: #4e4e4e;
  background-color: #fff2f2 !important;
}
.Message-Detial .chat-profile-proposed .user-threat {
  width: 325px;
  /* height: 60px; */
  flex-grow: 0;
  margin: 1.25rem 3px 1.25rem 1.25rem;
  padding: 1.194rem 0.569rem 0.613rem 1.094rem;
  border-radius: 0.313rem;
  color: #4e4e4e;
  /* border: solid 1px #ddd; */
  /* background-color: ; */
}
.Message-Detial .chat-profile-submit .user-threat {
  width: 325px;
  /* height: 165px; */
  flex-grow: 0;
  margin: 1.25rem 3px 0.625rem 1.25rem;
  padding: 5px 0.569rem 0.613rem 0.938rem;
  border-radius: 0.313rem;
  color: #4e4e4e;
  /* border: solid 1px #ddd; */
  background-color: #e7f3eb;
}
.Message-Detial .ant-space-vertical {
  flex-direction: row;
  min-width: 100%;
}
.Message-Detial .ant-space-item {
  align-self: center;
  width: 100%;
}
.Message-Detial .chat-profile-submit .user-threat .datepicker {
  padding: 0.5rem 0.6rem;
  margin: 0.5rem 0 0;
  width: auto;
  height: 3.75rem;
  flex-grow: 0;
  margin-bottom: 0.935rem !important;
  /* padding: 0 13rem 0 0; */
  border-radius: 0.313rem;
  /* border: solid 0.06rem #d4d4d4; */
  background-color: #fff;
}
.Message-Detial .chat-profile-submit .user-threat .datepicker-radio-options {
  display: flex !important;
  flex-direction: row !important;
  width: 100%;
}
.Message-Detial .ant-radio-checked .ant-radio-inner {
  border-color: #118936 !important ;
}

.Message-Detial .ant-radio-checked .ant-radio-inner:after {
  background-color: #118936;
}

.Message-Detial .ant-radio:hover .ant-radio-inner {
  border-color: #118936;
}
.proposal-radio-button {
  display: flex;
  align-items: center;
}
.Message-Detial .chat-profile-submit .user-threat .date {
  font-size: 0.875rem;
  font-weight: bold;
  color: #1e1e1e;
  margin-bottom: 0.625rem;
  cursor: default;
}
.Message-Detial .chat-profile-submit .user-threat .time {
  font-size: 0.75rem;
  color: #4e4e4e;
  cursor: default;
}
.Message-Detial .chat-profile-submit .user-threat .congratulation {
  font-size: 0.875rem;
  font-weight: bold;
  margin-top: 0.938rem;
}
.Message-Detial .chat-profile-submit .user-threat .accept {
  font-size: 0.75rem;
  margin-bottom: 0.625rem;
  color: #4e4e4e;
}

/* ///// PLACED BID CARD/// */
.Message-Detial .chat-profile-submit .user-threat-placed-bid {
  /* height: 16.5rem; */
}
.Message-Detial .chat-profile-submit .user-threat .zoom-link-container {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 0.639rem;
  border-radius: 0.25rem;
}
.Message-Detial
  .chat-profile-submit
  .user-threat
  .zoom-link-container
  .zoom-link {
  font-size: 0.875rem;
  color: #888;
}
.Message-Detial
  .chat-profile-submit
  .user-threat
  .zoom-link-container
  .copy-icon {
  display: flex;
  align-items: center;
  /* margin-right: 1vw; */
}

.Message-Detial .chat-profile-submit .user-threat .add-to-calander {
  display: flex;
  font-size: 0.875rem;
  font-weight: 600;
  width: 15rem;
  height: 2.25rem;
  margin: 10px 27px 15px 28px;
  background-color: var(--primaryColor);
  border-radius: 0.313rem;
  color: #fff;
  justify-content: center;
  align-items: center;
  cursor: default;
}

/* /// PROPOSSED BUTTON /// */
.Message-Detial .proposed-buttons {
  width: 20.3rem;
  margin-left: 1.25rem;
  margin-bottom: 1.25rem;
}
.Message-Detial .proposed-buttons-rescheduled {
  display: flex;
  justify-content: flex-end;
  margin-right: 1.25rem;
  margin-bottom: 1.25rem;
}
.Message-Detial .proposed-buttons .accet-and-decline {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 0.625rem;
}
.Message-Detial .proposed-buttons .accet-and-decline .accept {
  display: flex;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  width: -webkit-fill-available;
  background-color: #e7f3eb;
  font-size: 1rem;
  font-weight: 500;
  color: var(--primaryColor);
  cursor: pointer;
}
.Message-Detial .proposed-buttons .accet-and-decline .decline {
  display: flex;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  width: -webkit-fill-available;
  background-color: #e7f3eb;
  font-size: 1rem;
  font-weight: 500;
  color: var(--primaryColor);
  cursor: pointer;
}
.Message-Detial .proposed-buttons .reschedule {
  display: flex;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  width: -webkit-fill-available;
  background-color: #e7f3eb;
  font-size: 1rem;
  font-weight: 500;
  color: var(--primaryColor);
  cursor: pointer;
}
.Message-Detial .proposed-buttons .reschedule.interested-decline,
.Message-Detial
  .proposed-buttons
  .accet-and-decline
  .decline.interested-decline,
.Message-Detial
  .proposed-buttons
  .accet-and-decline
  .accept.interested-decline {
  background-color: #f2f2f2;
  color: #9e9e9e;
  cursor: default;
}

.Message-Detial .interview-accept {
  display: flex;
  justify-content: flex-end;
  padding-right: 0rem;
}

.receipent-name {
  display: flex;
  flex-direction: column;
  margin-left: 1.25rem;
}
.receipent-name .name {
  color: #1e1e1e;
  font-weight: 500;
}
.receipent-name .last-seen {
  color: #7e7e7e;
  font-size: 0.75rem;
}
.conversation-icon svg {
  width: 18.1px;
  height: 18.3px;
  margin-left: 2px;
  margin-top: 4px;
  color: #7e7e7e;
}

/* .conversation-message{
  font-size: 18px;
  margin-top: 52.9px;
  font-weight: 600;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e3e3e;
} */
.Message-Detial
  .content-container-mobile
  .ractangle-left
  .bid-details
  > div:nth-child(2) {
  color: #e4e4e4;
}

.Message-Detial
  .content-container-mobile
  .g-details-facility-container
  .job-details {
  /* height: 1.313rem; */
  font-size: 1rem;
  font-weight: 500;
}
.Message-Detial
  .content-container-mobile
  .g-details-facility-container
  .g-details-facility-name
  > div {
  font-size: 1rem;
}
.Message-Detial .showmore-container {
  margin-top: 1.125rem;
  margin-bottom: 1.125rem;
  display: flex;
  padding: 0 1.25rem;
  justify-content: space-between;
}

.Message-Detial .showmore {
  /* margin-left: 1.25rem; */
  color: #4054b2;
}

.Message-Detial .message-time {
  color: #b7b7b7;
}

.Message-Detial .content-container-mobile {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0rem 0 0 0rem;
  /* border: solid 1px #e4e4e4; */
}

.Message-Detial
  .Card-container
  .content-container-mobile
  .ractangle-right
  .about-job {
  height: 1.063rem;
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  cursor: default;
}

.Message-Detial .time-stamp {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}
.Message-Detial .time-stamp .time-text {
  font-size: 0.75rem;
  color: #b4b4b4;
  cursor: default;
}
.Message-Detial .time-stamp .time-text-employer {
  font-size: 0.75rem;
  color: #b7b7b7;
  cursor: default;
}
.Message-Detial .tick-icon {
  display: flex;
  align-items: center;
  color: #007cf0;
}
/* ////Message//// */
.Message-Detial .content-container-mobile .ractangle-right .about-job-text {
  cursor: default;
  margin: 0.25rem 0 0.938rem 0;
  color: #3e3e3e;
}

/* /////BID DECLINE ///// */
.Bid-decline-model {
  padding: 1.25rem 1.25rem !important;
}
.bid-decline-headline {
  font-size: 14px;
  color: #3e3e3e;
  margin-bottom: 10px;
  min-width: 102%;
}
.bid-decline-headline-rechedule {
  padding: 0px 1rem 0px 0.2rem;
}
.retract-bid {
  font-size: 1rem;
}
.bid-rechedule-text {
  color: #5e5e5e;
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
}
.bid-reschedule-date-picker {
  width: 100%;
  margin-bottom: 0.635rem;
  border-radius: 0.25rem;
  border: 1px solid #e4e4e4;
}
.bid-decline-option {
  display: flex;
  height: 2.5rem;
  padding-left: 0.938rem;
  padding-right: 0.938rem;
  border-radius: 0.25rem;
  border: solid 1px #d4d4d4;
  background-color: #fff;
  align-items: center;
  margin-bottom: 0.625rem;
  color: #1e1e1e;
  cursor: pointer;
}
.bid-decline-option-upload {
  display: flex;
  justify-content: center;
  gap: 0.635rem;
  height: 2.5rem;
  padding-left: 0.938rem;
  padding-right: 0.938rem;
  border-radius: 0.313rem;
  border: solid 1px var(--primaryColor);
  background-color: #fff;
  align-items: center;
  /* margin-bottom: 1.625rem; */
  font-size: 0.875rem;
  color: var(--primaryColor);
}
.bid-file-upload-icon {
  color: var(--primaryColor);
  line-height: normal;
  height: 1.25rem;
  width: 1.25rem;
  font-weight: 600;
}
.bid-file-upload-icon svg {
  font-size: 1.125rem;
}
.bid-decline-option-selected {
  display: flex;
  height: 2.5rem;
  padding-left: 0.938rem;
  padding-right: 0.938rem;
  border-radius: 0.313rem;
  border: solid 1px #d4d4d4;
  background-color: #e4ffec;
  align-items: center;
  margin-bottom: 0.625rem;
  color: #1e1e1e;
}
.bid-decline-button {
  display: flex;
  height: 2.625rem;
  margin-top: 1.875rem;
  border-radius: 0.313rem;
  border: solid 1px var(--primaryColor);
  background-color: var(--primaryColor);
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}
.bid-decline-button_disable {
  display: flex;
  height: 2.625rem;
  margin-top: 1.875rem;
  border-radius: 0.313rem;
  border: solid 1px var(--primaryColor);
  background-color: var(--primaryColor);
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  cursor: no-drop;
}
.bid-rechedule-button {
  background-color: #fff;
  color: var(--primaryColor);
  cursor: pointer;
}
.Bid-decline-model .ant-picker-suffix {
  position: fixed;
  align-self: center;
  margin-left: 0.25rem;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none;
}
.Bid-decline-model .ant-picker-input > input:placeholder-shown {
  text-overflow: ellipsis;
  margin-left: 2rem;
}
.Bid-decline-model .ant-picker-input > input {
  margin-left: 2rem;
  color: #3e3e3e;
  font-size: 0.75rem;
  font-weight: 500;
}

/* //////BID RETRACT///// */
.retract-bid-card {
  background-color: #fff2f2 !important;
}
.bid-retract-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.635rem;
}
.bid-retract-container .bid-retract-no {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 155px;
  height: 42px;
  font-size: 1rem;
  font-weight: 600;
  color: #9e9e9e;
  cursor: pointer;
}
.bid-retract-container .bid-retract-yes {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 155px;
  height: 42px;
  border-radius: 0.313rem;
  background-color: var(--primaryColor);
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}
.Message-Detial .general-message {
  background-color: #fff !important;
  border: solid 1px #ddd;
}

.message-details-model .ant-modal-title {
  color: #3e3e3e !important;
  cursor: default;
}

.interested-decline-bg * {
  background-color: #f2f2f2 !important;
  color: #7e7e7e !important;
  border-radius: 5px;
}

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 768px) {
  .Message-Detial {
    overflow-y: unset;
  }

  .Message-Detial .Card-container {
    min-width: 300px;
    margin: 0px 15px 0px;
  }

  .Message-Detial
    .Card-container
    .gig-detail-container
    .content-container-mobile
    .ant-divider.ant-divider-horizontal.divider-mobile {
    width: unset;
    min-width: unset;
    margin: 0 10px 1px 10px;
  }

  .Message-Detial .proposed-buttons .accet-and-decline {
    margin-bottom: 0px;
  }

  .Message-Detial .Card-container .gig-detail-container {
    border-radius: 5px;
  }

  .Message-Detial .Card-container .gig-detail-container .message-header .text1 {
    margin: 15px 0px 0px;
    padding: 0px 10px 2px;
  }

  .Message-Detial .Card-container .gig-detail-container .message-header .text2,
  .Message-Detial
    .Card-container
    .gig-detail-container
    .message-header
    .skill-text,
  .Message-Detial
    .Card-container
    .content-container-mobile
    .g-details-facility-container
    .rating-review {
    margin-left: 10px;
  }

  .Message-Detial .Card-container .content-container-mobile .ractangle-right {
    padding: 12px 15px;
  }

  .Message-Detial .ant-modal-header {
    padding: 15px;
    border-bottom: 1px solid #e4e4e4;
  }

  .bid-decline-headline {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .Bid-decline-model {
    padding: 10px 15px 20px !important;
  }

  .Message-Detial .Card-container .buttons .interested {
    font-size: 14px;
  }

  .Message-Detial .time-stamp .time-text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #b4b4b4;
    cursor: default;
  }

  .Message-Detial .chat-container1 .user-threat,
  .Message-Detial .chat-container-receivedbid .user-threat {
    margin: 0px 15px 0px;
    padding: 5px 13px 10px;
    max-width: 300px;
    height: auto;
    text-align: center;
  }

  .Message-Detial .chat-profile-submit .user-threat,
  .Message-Detial .proposed-buttons {
    height: auto;
    max-width: 300px;
    margin: 0px 15px 0px;
    padding: 5px 13px 10px;
    text-align: center;
  }

  .Message-Detial .chat-profile-submit .user-threat .datepicker {
    text-align: left;
    min-width: 274px;
    height: 50px;
    padding: 8px 10px;
    margin: 10px 0px 0px;
    margin-bottom: 0px !important;
  }

  .Accepted_Icon > img,
  .Clip_Board_Icon > img {
    margin-top: 15px;
    margin-bottom: 8px;
    width: 60px;
    height: 60px;
    object-fit: contain;
  }

  .Accepted,
  .Profile_Submitted {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #118936;
  }

  .Terminated {
    color: #e16974;
  }

  .Accepted_Icon,
  .Accepted,
  .congratulation,
  .accept {
    text-align: center;
  }

  .Message-Detial .chat-container1 .user-threat .congratulation,
  .Message-Detial .chat-profile-submit .user-threat .congratulation,
  .Message-Detial .Card-container .employer-offer .congratulation,
  .Message-Detial .Card-container .employer-terminated .congratulation {
    font-size: 16px;
    font-weight: bold;
  }

  .Message-Detial .chat-container1 .user-threat .accept,
  .Message-Detial .chat-profile-submit .user-threat .accept,
  .Message-Detial .Card-container .employer-offer .accept,
  .Message-Detial .Card-container .employer-terminated .accept {
    margin: 2px 0px 5px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #3e3e3e;
  }

  .Message-Detial .chat-profile-submit .user-threat .proposed-interview-font {
    margin-top: 10px;
    margin-bottom: 9px !important;
    font-size: 16px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
  }

  .Message-Detial .chat-profile-submit .user-threat .datepicker-radio-options {
    margin-top: 3px;
    margin-bottom: 0px !important;
  }

  .Message-Detial
    .chat-profile-submit
    .user-threat
    .ant-space-vertical:last-child
    .datepicker-radio-options {
    margin-bottom: 0px !important;
  }

  .Message-Detial .chat-profile-submit .user-threat .date {
    margin-bottom: 0px;
    font-size: 13px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1;
    text-align: left;
    color: #1e1e1e;
  }

  .Bid-decline-model .bid-decline-button {
    margin-top: 10px !important;
  }

  .Bid-decline-model .declineThisOffer {
    margin-top: 1.25rem !important;
  }

  .Message-Detial .chat-profile-submit .user-threat .time {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #4e4e4e;
  }

  .Message-Detial .chat-profile-submit .user-threat .ant-radio-wrapper {
    margin-right: 10px;
  }

  .Message-Detial .proposed-buttons {
    padding: 0px;
  }

  .Message-Detial .proposed-buttons > *,
  .Message-Detial .proposed-buttons .accet-and-decline > * {
    border-radius: 5px;
    font-size: 14px !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }

  .Message-Detial .proposed-buttons .accet-and-decline {
    gap: 5px;
  }

  .Message-Detial .chat-profile-proposed {
    margin-right: 0px;
  }

  .Message-Detial .proposed-buttons-rescheduled {
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .Message-Detial .chat-profile-submit {
    margin-right: 0px !important;
  }

  .Message-Detial .chat-profile-submit .user-threat .zoom-link-container {
    margin-top: 7px;
    padding: 9px 10px;
  }

  .Message-Detial
    .chat-profile-submit
    .user-threat
    .zoom-link-container
    > .zoom-link {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .Message-Detial
    .chat-profile-submit
    .user-threat
    .zoom-link-container
    .copy-icon
    > svg {
    min-width: 20px;
    min-height: 20px;
  }

  .Message-Detial .chat-profile-submit .user-threat .add-to-calander {
    margin: 20px auto 5px;
    font-weight: bold;
  }

  .reshedule_interview_title {
    margin: 10px 0px 12px;
    text-align: center;
  }

  .reshedule_interview_content {
    margin: 0px 0px 15px;
    text-align: center;
  }

  .bid-reschedule-date-picker {
    padding: 7px 8px;
    margin-bottom: 10px;
  }

  .Bid-decline-model .ant-picker-suffix {
    margin-left: 0px;
  }

  .Bid-decline-model .ant-picker-input > input {
    margin-left: 32px;
    color: #7e7e7e;
    font-size: 12px;
    font-weight: normal;
  }

  .decline_interview_content {
    margin-bottom: 18px;
  }

  .decline_interview_button {
    margin: 20px 0px 4px;
    background-color: red;
  }
  .decline_interview_button_disable {
    cursor: alias;
  }

  .Message-Detial .Card-container .employer-offer,
  .Message-Detial .Card-container .employer-terminated {
    padding: 5px 13px !important;
  }

  .Message-Detial
    .Card-container
    .content-container-mobile
    .ractangle_right_mobile {
    padding: 12px 15px 16px;
    margin-bottom: 0px;
    border-radius: 4px;
    background-color: #fff;
  }

  .Message-Detial
    .Card-container
    .content-container-mobile
    .ractangle-right
    .offer-details
    > div {
    font-size: 12px !important;
  }

  .Message-Detial
    .Card-container
    .content-container-mobile
    .ractangle-right
    .details
    .values {
    font-size: 12px;
  }

  .offer-details {
    font-size: 12px !important;
    font-weight: 600;
  }

  .offer-details div:first-child {
    font-weight: 600 !important;
  }

  .Message-Detial
    .content-container-mobile
    .ractangle-right
    .offer_msg_txt_mobile {
    margin-bottom: 0px;
    font-size: 12px !important;
    color: #777 !important;
  }

  .Message-Detial .Card-container .open-document {
    margin-top: 15px;
    margin-right: -1px;
    margin-bottom: 7px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .message-details-model .ant-modal-title {
    font-weight: 600;
  }

  .Message-Detial .offercard-document {
    padding: 7px 10px 7px 3px;
    border-radius: 4px;
    margin-bottom: 5px;
    height: 50px;
  }

  .Message-Detial .offercard-document .document-details {
    height: auto;
    margin-left: 2px;
    text-align: left;
  }

  .Message-Detial .offercard-document .document-details .file-name {
    font-size: 13px;
  }

  .Message-Detial .offercard-document .document-details .file-size {
    font-size: 12px;
  }

  .Message-Detial .chat-profile-submit .user-threat .offer_accepted_datepicker {
    margin-top: 0px;
  }

  .Message-Detial .general-message {
    border-radius: 4px !important;
    border: solid 1px #d3d3d3;
  }

  .Message-Detial .general_message_mobile_receive {
    max-width: 230px !important;
  }

  .Message-Detial .general_message_mobile_send {
    max-width: 220px !important;
    background-color: #f2f2f2 !important;
    border: none !important;
  }

  .Message-Detial .general_message_mobile_send .accept {
    text-align: left;
    margin-bottom: 10px !important;
  }
  .Message-Detial .general_message_mobile_send .offercard-document {
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  }

  /* .interested-decline-bg * {
    background-color: #f2f2f2 !important;
    color: #7e7e7e !important;
    border-radius: 5px;
  } */

  .Message-Detial .Card-container .time-text,
  .Message-Detial .time-stamp .time-text-employer {
    color: #b4b4b4 !important;
  }

  .Message-Detial .Card-container .tick-icon > svg {
    fill: #007cf0 !important;
  }

  .Message-Detial .general-message > div:first-child {
    padding-top: 4px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
  }

  .see_more {
    color: #4054b2;
  }

  /* .employer-sender {
    margin-top: 10px;
  } */

  /* .Message-Detial .Card-container .onbilling_offer {
    margin-top: 10px;
  } */

  .Message-Detial
    .Card-container
    .content-container-mobile
    .ractangle-right
    .offer-details:last-child {
    margin-bottom: 0px;
  }

  .Message-Detial .rate-employer-button .rate-employer {
    font-size: 14px;
    font-weight: bold;
    height: 38px;
  }

  .Message-Detial
    .Card-container
    .gig-detail-container
    .message-header
    .skill-text {
    margin-bottom: 4px;
  }

  .Message-Detial .Card-container .gig-detail-container .message-header .text2 {
    margin-bottom: 2px;
  }

  .Message-Detial
    .Card-container
    .content-container-mobile
    .g-details-facility-container
    .rating-review {
    margin: 0px 10px 16px;
  }

  .content-container-mobile .ractangle-right .details > div:last-child {
    font-weight: normal !important;
  }

  .Message-Detial
    .Card-container
    .content-container-mobile
    .ractangle-right
    .about-job {
    font-weight: 600;
    margin-bottom: 6px;
  }

  .Message-Detial .favorite-bid-background > svg {
    width: 19px;
    height: 18px;
  }

  .bid_decline_offer {
    margin-bottom: 18px;
  }

  .Message-Detial .rate-employer-button {
    margin-bottom: 0px;
  }

  .Message-Detial .time-stamp {
    margin-top: 5px;
  }

  .Message-Detial .chat-container1 {
    padding-right: 0px;
  }

  .offercard-document-accept {
    margin-top: 10px;
  }

  .selected-bid-decline-option {
    background: #e4ffec;
  }

  .Message-Detial .Card-container .content-container-mobile .ractangle-right {
    margin-bottom: 0px;
  }

  .Message-Detial .chat-container1 .user-threat,
  .Message-Detial .chat-container-receivedbid .user-threat,
  .Message-Detial .chat-profile-submit .user-threat,
  .Message-Detial .proposed-buttons,
  .Message-Detial .rate-employer-button,
  .Message-Detial .proposed-buttons .reschedule,
  .Message-Detial .Card-container {
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .Message-Detial .proposed-buttons-rescheduled .reschedule {
    margin-top: 0px;
  }

  .mt15px {
    margin-top: 0.3125rem;
  }

  .send-message-container {
    /* position: fixed !important; */
    bottom: 0rem;
    left: 0rem;
  }

  .Message-Detial .send-message-container {
    width: 100%;
    padding: 0rem 0.9375rem 1.3125rem;
    border-top: 0.0625rem solid #e4e4e4;
  }

  .Message-Detial .send-message-container .ant-input {
    /* height: 3.3125rem; */
    padding: 0rem;
    padding: 1rem 0rem 1.25rem;
    box-shadow: none;
    outline: none;
    border: none;
  }
  .Message-Detial .send-message-container .teaxArea-content {
    height: 3.3125rem;
  }

  .Message-Detial .send-message-container .message-bottom {
    margin-top: 0rem;
  }

  .text-with-attachment.ant-input {
    padding-top: 0rem !important;
  }

  .attachment-container {
    width: 50%;
    margin-left: 0rem;
  }

  .attachment-content-info {
    width: 75%;
  }

  .attachment-content-info > h3 {
    width: 100%;
  }

  .conversation-icon,
  .conversation-icon svg {
    max-width: 1.25rem;
    max-height: 1.25rem;
    margin: 0;
  }

  a.message-send-btn {
    max-height: 1.25rem;
    line-height: 1.625rem;
  }
}
/********** For Mobile View  Media Query End **********/
