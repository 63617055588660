.g-details-header3 {
    max-width: 65.3%;
    margin: 0rem 23.75rem 0rem 18.75rem;
    background-color: #fff;
    padding: 0rem 1.25rem 1.25rem 1.25rem;
}

.message-back {
    display: flex;
    padding-top: 2.5rem;
}

.header-textss {
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4054b2;
}

.ratings-stars {
    color: "#c4c4c4"
}

.Ratings-Recommend-Row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 0.3rem;
    margin-bottom: 1rem;
    align-items: center;
}

.Ratings-rating-error {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #db2828;
    margin-bottom: 0.5rem;
}

.Ratings-Recommend-Text {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
}

.header-texts {
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7e7e7e;
}


.Provide_feedback {
    margin: 1.563rem 0rem 1.563rem 0rem;
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
}

.Profile-rating {
    display: flex;
    gap: 0.625rem;
    margin-bottom: -0.563rem;
}

.Rating-employer {
    font-size: 0.875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
}

.Rating_jobTitle {
    font-size: 0.75rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4054b2;
    margin-top: 0.625rem;
}

.rate_divider {
    height: 0.063rem;
    background-color: #e4e4e4;
}

.rating_tagg {
    width: 3.75rem;
    height: 1.188rem;
    margin: -0.438rem 0 0.313rem 0rem;
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
}

.g-details-arrow2 .arrow {
    height: 1rem;
    width: 1rem;
    margin: 0.15rem 0.475rem 0 0.358rem;
    /* line-height: normal; */
    align-self: center;
    color: solid 1.5px #3e3e3e;
}


.rating_five {
    font-size: 1.25rem;
    margin-bottom: -0.563rem;
    color: #aaa;
}

.text-ratings {
    font-size: 0.875rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
    margin: -0.563rem 0rem 0.938rem 0rem;
}

.text_rating_area {
    margin-bottom: 0.938rem;
}

.text-style-maindiv {
    margin-bottom: 0.313rem;
}

.text-style-1 {
    font-size: 0.875rem;
    color: #1e1e1e;

}

.text-style-2 {
    font-size: 0.875rem;
    color: #118936;

}

.g-details-header3 .ant-input-textarea-show-count::after {
    right: 25.625rem;
    top: 33.75rem;
    bottom: 0.25rem;
    position: absolute;

}

.Ratings-main-container {

    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(12, 172, 118);
    padding-top: 20px;
}

.Ratings-container {
    width: 37rem;
    padding: 2rem;
    margin-top: 3rem;
}

.Ratings-container-mobile {
    width: 90%;
    margin-top: 3rem;
}

.Ratings-heading {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.Ratings-picContainer {
    object-fit: contain;
    border-radius: 50%;
    background: var(--primaryColor);
    font-size: 1rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Ratings-recipent_image {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.Ratings-imageRow {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.Ratings-Job-CandidateName-Container {
    display: inline;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 0.5rem;
    padding-top: 6px;
}

.Ratings-Job-Title {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4054b2;
    margin-bottom: 5px;
}

.Ratings-Candidate-Name {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-left: "0px";
    color: #1e1e1e;
}

.Ratings-line-divider {
    height: 1px;
    background-color: #e4e4e4;
    margin-top: 2%;
}

.Ratings-Communication-Row,
.Ratings-WorkKnowledge-Row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 0.3rem;
    align-items: center;
}

.Ratings-Recommend-Row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 0.3rem;
    margin-bottom: 1rem;
    align-items: center;
}

.Ratings-Communication-Text,
.Ratings-WorkKnowledge-Text,
.Ratings-Recommend-Text {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
}

.Ratings-share-experience-text {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
    margin-top: 1rem;
}

.Ratings-count-drawer {

    margin-top: -2rem;
    margin-right: 1rem;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #7e7e7e;

}

.Ratings-count-finish-drawer {

    margin-top: -2rem;
    margin-right: 1rem;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: red;
}

.Ratings-appropriate-feedback {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: black;
    margin-top: 2rem;
}

.Ratings-example-text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #7e7e7e;
    margin-top: 2%;
}

.Ratings-example-text-hide {

    margin-top: 1rem;
    margin-bottom: 1.5rem;
    display: none;
}

.Ratings-Submit-Btn {

    color: #fff;
    width: 200px;
    height: 42px;
    background-color: #118936;
    display: flex;
    justify-content: center;
    align-items: center;

}

.Ratings-Submit-Btn-Container {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.Ratings-Submit-Btn:hover {

    border-color: #118936;
    color: #118936;
}

.Ratings-Submit-Text {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
}

.Ratings-normal-drawer {
    width: 100%;
    height: 120px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 0px;
    color: black;
    resize: none;
    outline: none
}

.Ratings-full-drawer {
    width: 100%;
    height: 120px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    border: #db2828 1px solid;
    outline: none;
    resize: none;
}

.Ratings-full-drawer:active {
    border: #db2828 1px solid;
}

.Ratings-full-drawer:focus {
    border: #db2828 1px solid;
}

#Ratings-current-word-count,
#Ratings-maximum-word-count {
    background-color: white;
}

.Ratings-rating-error {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #db2828;
    margin-bottom: 0.5rem;
}

.Ratings-feedback-error {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #db2828;
    margin-top: 1.3rem;
}

.Ratings-previousScreen {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4054b2;
    cursor: pointer;
}

.Ratings-Breadcrum {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: justify;
    color: #7e7e7e;
    margin-bottom: 1.5rem;
}

.greenTick-pic {
    height: 50px;
    width: 50px;
}

.Ratings-greenTickContainer {

    margin-top: -4.2rem;
    margin-bottom: 1rem;
}

.Ratings-thankyou-container {
    padding-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Ratings-thankyou-heading {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3e3e3e;
    margin-bottom: 0.6rem;
}

.Ratings-thankyou-text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #1e1e1e;
    width: 508px;
}

@media only screen and (max-width: 769px) {

    .Ratings-greenTickContainer {
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .Ratings-thankyou-text {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #1e1e1e;
        width: 90%;
    }

}

@media screen and (max-width: 768px) {

    .g-details-header3 {
        max-width: 100%;
        margin: 0rem 0rem 0rem 0rem;
        background-color: #fff;
        padding: 0rem 0.938rem 1.25rem 0.938rem;
    }

    .g-details-header3 .ant-input-textarea-show-count::after {
        right: 1.563rem;
        top: 27.5rem;
        bottom: 0.25rem;
        position: absolute;

    }

    .message-back {
        padding-top: 0.938rem;
    }

    .Profile-rating {
        margin-top: 0.938rem;
    }

    .rating_five {
        margin: 1.25rem 0px 0px 0.625rem;
    }

    .text-ratings {
        margin: 0.563rem 0rem 0.938rem 0rem
    }

    .employer_rating_view {
        font-size: 0.75rem;
        line-height: 1.42;
        color: #7e7e7e;
        margin: 0.625rem 0rem 0.563rem 0rem;
    }
}