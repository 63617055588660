.header-manage-bids-menu {
  padding: 0.938rem 1.25rem;
  width: 20rem !important;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25) !important;
  /* position: fixed; */
  /* right: 7rem;
  background-color: white; */
}

.header-dropdown-ManageBids {
  position: fixed;
}

/* .header-dropdown-ManageBids .ant-dropdown-arrow {
  right: 1rem;
  top: 0.56rem;
} */

.header-manage-bids-menu > div {
  height: 1.81rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  /* font */
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e;
}

.header-manage-bids-menu > div:hover {
  color: var(--primaryColor);
}

.header-manage-bids-menu .placed-bids-header {
  margin: 0.625rem 0;
}

.header-manage-bids-menu .header-recieved-bids-text {
  display: flex;
  justify-content: space-between;
}

.header-manage-bids-menu .bids-count-header {
  width: 1.88rem;
  height: 1.25rem;
  flex-grow: 0;
  /* margin: 0 0 0 9.00rem; */
  padding: 0.19rem 0.69rem;
  border-radius: 0.75rem;
  background-color: rgba(64, 84, 178, 0.2);

  /* font */
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4054b2;
  display: none;
}

@media screen and (max-width: 1000px) {
  .header-manage-bids-menu {
    padding: 0.4rem 0 0;
    box-shadow: none !important;
    width: unset !important;
  }
}
