.UserDocuments {
  width: 40rem;
  /* height: 37.25rem; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* gap: 0.63rem; */
  /* margin: 2.5rem 37.5rem 3.06rem 2.5rem; */
  padding: 1.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
}

.width100 {
  width: 100% !important;
}

.UserDocuments .credentialing-title {
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 1.25rem;
}

.UserDocuments .upload-documents  {
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 0.75rem;
}

.UserDocuments .header-info-text {
  /* width: 19rem; */
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  margin-bottom: 1.25rem;
}

.searchCredentials .docSearch .ant-select-selector {
  width: 36.88rem;
  height: 2.13rem;
  margin: 1.25rem 0 0.63rem 0.31rem;
  padding: 2px 10px;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.searchCredentials .ant-select-open {
  width: 36.88rem;
}

.UserDocuments .format-validation {
  font-size: 0.625rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  margin-bottom: 1.25rem;
}

.UserDocuments .format-validation span {
  color: var(--primaryColor-error);
}

.document-border{
  width: 100%;
  max-height: 44vh;
  overflow: auto;
  flex-grow: 0;
  margin: 0 0.13rem 0 0;
  padding: 0px 0.625rem 0px 0.625rem;
  border-radius: 0.31rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
}

.documentBordersingle {
  display: contents;
  width: 100%;
  max-height: 44vh;
  overflow: auto;
  flex-grow: 0;
  margin: 0 0.13rem 0 0;
  padding: 0 0.63rem;
  border-radius: 0.31rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
}

.documentBorder::-webkit-scrollbar {
  width: 0.35rem;
  cursor: pointer !important;
}

.documentBorder::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 12px !important;
  cursor: pointer !important;
}

.cred-documents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 11.2px 0;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
}
.cred-documents:last-child {
  border: none;
}
/* .credDocumentsSingle{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.7rem 0;
  align-items: center;
  border: none;
} */

.cred-documents .docs-name {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}
.cred-documents .clickable {
  height: 1.25rem;
}

.upload-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.25rem;
  /* width: 6rem; */
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: rgb(21, 100, 219);
  font-size: 0.7813rem;
  font-weight: 500;
  /* margin-right: 10px; */
  /* margin-top: 5px; */
}

.upload-actions .uploadDocs-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  bottom: 2px;
}

/* .docUnderline {
  height: 0.0625rem;
  width: 100%;
  background-color: #e4e4e4;
  position: relative;
}

.uploadIcon {
  position: relative;
  top: 3.5px;
  margin-right: 3px;
}

.loadingIcon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 2.5rem;
  margin: 30vh 0;
  color: #e73959;
}

.searchIconStepTwo {
  position: relative;
}

/* search select */
.input-select-dropdown {
  width: 99%;
  height: 2.13rem;
  /* margin: 1.25rem 0 0.63rem 0.31rem; */
  padding: 0 0.63rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  margin-bottom: 0.625rem;
  position: relative;
}

.input-select-dropdown .input-select {
  display: flex;
  align-items: center;
}

.selectDropdown {
  display: flex;
  flex-direction: column;
}

.input-select .search-cred {
  font-size: 1.25rem;
  margin-right: 0.625rem;
  position: relative;
  top: 3px;
}

.input-select input {
  width: 100%;
  height: 2.13rem;
  border: none;
  background-color: #fff;
  font-size: 1rem;
}

.input-select input::placeholder {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.input-select input:focus {
  border: none;
  outline: none;
}

.input-select .close-search {
  padding-top: 5px;
  margin-left: 0.3rem;
}

.input-select-dropdown .selectDropdown {
  max-height: 12.67rem;
  width: 100%;
  background-color: #fff;
  border: solid 0.06rem #e4e4e4;
  box-shadow: 0 0 0.4rem 0 rgba(0, 0, 0, 0.25);
  overflow: auto;
  overflow-x: hidden;
  position: absolute;
  z-index: 10;
  right: 0;
  top: 2.755rem;
}

.selectDropdown span {
  /* width: 36.88rem; */
  height: 1.81rem;
  /* margin: 0 0 0.38rem; */
  padding: 0 0.63rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.selectDropdown .docSearchName:hover {
  background-color: #e4e4e4;
}

.selectDropdown .docSearchName-active {
  background-color: #e4e4e4;
}

.selectDropdown .noDatafoundDocs {
  color: #e4e4e4;
}

.selectDropdown::-webkit-scrollbar {
  width: 7px;
}

.selectDropdown::-webkit-scrollbar-track {
  background: rgb(248, 248, 248);
}

.selectDropdown::-webkit-scrollbar-thumb {
  background: #e4e4e4;
  border-radius: 12px !important;
  cursor: pointer !important;
}

/* delete Modal */

.deleteCredDocModal .title-pop-up-delete {
  height: 3.125rem;
  padding: 1rem 0.938rem;

  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;

  border-bottom: 0.06rem solid #e4e4e4;
}

.deleteCredDocModal  .delete-popup-subheading {
  height: 9.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.625rem 0.938rem 1.25rem;
}

 .delete-popup-subheading .aysCDM {
  width: 17.25rem;
  height: 2.88rem;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

 .delete-popup-subheading .yesNoCDM {
  display: flex;
}

 .delete-popup-subheading .yesNoCDM .no {
  width: 9.69rem;
  height: 2.63rem;
  flex-grow: 0;
  margin: 0 0.63rem 0 0;
  padding: 0.75rem 0 0.69rem 0;
  border-radius: 0.25rem;
  background-color: #fff;

  /* font */
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9e9e9e;
  cursor: pointer;
}

 .delete-popup-subheading .yesNoCDM .yes {
  width: 9.69rem;
  height: 2.63rem;
  flex-grow: 0;
  /* margin: 0 0 0 0.63rem; */
  padding: 0.75rem 0 0.69rem 0;
  border-radius: 0.25rem;
  background-color: var(--primaryColor);

  /* font */
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;

  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .UserDocuments {
    border: none;
    padding: 1.25rem 0.938rem;
    margin-bottom: 1.25rem;
  }

  .credentialing-user-documents {
    padding: unset;
  }
  .UserDocuments .upload-documents  {
    margin-bottom: 0.75rem;
  }

  .input-select-dropdown {
    /* margin: 0.12rem 0 0 0; */
    margin-bottom: 0.625rem;
  }

  .document-border{
    margin: 0 0.13rem 0 0;
  }

  .cred-documents {
    height: 2.6rem;
  }

   .delete-popup-subheading .aysCDM {
    font-size: 0.88rem;
  }
}
