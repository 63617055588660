.g-container-search {
  display: flex;
  justify-content: center;
  width: auto;
  align-self: center;
  margin: auto;
  background-color: #777;
}
.g-container-search .searchbox-search {
  height: 2.2rem;
  width: 15rem;
  border-radius: 2rem;
  border: solid #777;
}
input[type="text"]::placeholder {
  /* text-align: left; */
  margin-left: 1rem;
  font-size: 16px;
}
