.AboutJobExtraSection-Cantainer {
    padding-top: .1875rem;
}

.AboutJobExtraSection-Cantainer .AboutJobExtraSection-title {
    display: flex;
    justify-content: space-between;
    padding-top: .75rem;
}

.AboutJobExtraSection-Cantainer .AboutJobExtraSection-SubTitle {
    font-size: .875rem;
    font-weight: normal;
    color: #3e3e3e;
}

.AboutJobExtraSection-Cantainer .AboutJobExtraSection-value {
    font-size: .875rem;
    font-weight: normal;
    color: #1e1e1e;
}

/* @media screen and (max-width: 700px) {
    .AboutJobExtraSection-Cantainer .AboutJobExtraSection-title {
   
    }
} */