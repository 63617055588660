.bidSuccessDiv {
  margin-top: 5%;
  display: flex;
  justify-content: center;
}
.bidSuccessDiv .checkIcon {
  height: 4rem;
  width: 4rem;
  margin: auto;
}

.bidcsuccess-box {
  width: 52rem;
  /* height: 35.5rem; */
  min-height: 35.5rem;
  /* margin: 2.5rem 14rem 3.06rem; */
  margin-bottom: 3.06rem;
  padding: 2.5rem 4.13rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;

  display: flex;
  gap: 1.25rem;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.bidsuccess-check {
  width: 60px;
  height: 60px;
  flex-grow: 0;
  padding: 5px;
  background-color: #e4ffec;
  border-radius: 50%;
}

.bidsuccess-icon {
  font-size: 3.13rem;
}

.bidsuccess-title {
  font-size: 1.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

.bidsuccess-detail {
  display: flex;
  gap: 1.25rem;
  flex-direction: column;
  width: 43.75rem;
  /* height: 22.44rem; */
  min-height: 22.44rem;
  flex-grow: 0;
  /* margin: 1.25rem 0 0; */
  padding: 1.25rem 2.5rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
}

.bidsuccess-section {
  display: flex;
  gap: 5.313rem;
}

.bidsuccess-item {
  display: flex;
  align-items: flex-start;
}

.bidsuccess-detail-left {
  flex-basis: 40%;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.bidsuccess-detail-right {
  flex-basis: 60%;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.bidsuccess-coverletter {
  display: flex;
  flex-direction: column;
  gap: 0.938rem;
  /* margin-top: 1.25rem; */
}

.bidsuccess-coverletter .cover-bs {
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.bidsuccess-coverletter .letter-bs {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  word-break: break-word;
}

@media only screen and (max-width: 750px) {
  .bidcsuccess-box {
    width: -webkit-fill-available;
    min-height: auto;
    gap: 1.25rem;
    padding: 1.25rem 0.935rem;
  }
  .bidsuccess-detail {
    width: 90vw;
    min-height: auto;
    padding: 1.25rem 0.935rem;
    gap: 0.625rem;
  }
  .bidsuccess-section {
    grid-gap: 1.875rem;
    gap: 1.875rem;
  }
  /* .title-text-style {
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 30vw;
  } */
  .bidsuccess-detail-left {
    font-size: 0.875rem;
    width: max-content;
  }
  .bidsuccess-detail-right {
    font-size: 0.875rem;
  }
  .bidsuccess-title {
    font-size: 1rem;
    color: #1e1e1e;
  }
  .bidsuccess-coverletter {
    gap: 0.375rem;
  }
  .bidsuccess-coverletter .cover-bs {
    font-size: 0.875rem;
  }
  .bidsuccess-coverletter .letter-bs {
    font-size: 0.875rem;
  }
}
