.viewListingConatainer {
  padding: 2.5rem;
}

.viewListingInfo .viewListingTitle {
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.viewListingInfo .viewListing90Days,
.viewListingInfo .viewListingCount {
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.viewListingInfo .viewListing90Days {
  margin: 0.625rem 0rem;
}

.viewListingInfo .viewListingCount {
  margin-top: 0.3125rem;
}

.viewListingConatainer .viewListingBox {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  margin-top: 1.25rem;
}

.viewListingConatainer .viewListingPagination {
  margin: 3.125rem 0rem 0rem 0rem;
  text-align: center;
}

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 768px) {
  .viewListingConatainer {
    padding: 0.9375rem;
  }

  .viewListingInfo .viewListing90Days {
    margin: 0.3125rem 0rem;
  }

  .viewListingInfo .viewListingCount {
    font-size: 0.75rem;
  }

  .viewListingConatainer .viewListingBox {
    gap: 0.625rem;
  }
}
/********** For Mobile View  Media Query End **********/
