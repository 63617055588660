.searchGigsMobile .ant-input-affix-wrapper > input.ant-input {
  /* text-align: left; */
  color: #7e7e7e;
  margin-left: 2rem;
  font-size: 16px;
  margin: 0 0 0 10px;
}

.searchGigsMobile .ant-input-prefix > span svg {
  /* text-align: left; */
  height: 15.6px;
  width: 15.6px;
  color: #7e7e7e;
}

.search-header-mobile-view .ant-input-affix-wrapper {
  width: 92vw;
}
