.roleExperiencePage {
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.roleExperiencePage .roleExperience {
  width: 56.25rem;
  /* height: 21.3rem; */
  padding: 1.875rem 1.25rem;
  background-color: white;
  /* flex-grow: 0; */
  /* margin: 2.5rem 20.4rem 3rem; */
  margin: 2.5rem 11.875rem 3.06rem;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.roleExperience .continue {
  width: 28.12rem;
  height: 2.625rem;
  font-size: 1rem;
  /* margin: 69px 206px 0 29px; */
  padding: 0.56rem 11.87rem 0.75rem 11.8rem;
  border-radius: 4px;
  color: white;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--primaryColor);
  cursor: pointer;
  margin-bottom: 0.62rem;
}

.roleExperience .roleLabel {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  margin-bottom: 0.7rem;
}

.roleExperience .inputDiscipline {
  width: 20.625rem;
  height: 2.5rem;
  /* margin: 20px 312px 30px 0; */
  /* padding: 10px 113px 10px 10px; */
  border-radius: 4px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.roleExperiencePage .ant-input-prefix {
  margin-right: 10px;
}

.roleExperiencePage .ant-radio-inner {
  width: 0.94rem;
  height: 0.94rem;
}

.roleExperiencePage .ant-radio-inner::after {
  width: 7px;
  height: 7px;
}

.roleExperience .ant-radio-checked .ant-radio-inner {
  border-color: var(--primaryColor);
}

.roleExperience .ant-radio-inner::after {
  background-color: var(--primaryColor);
}

.roleExperience .ant-radio-checked::after {
  border: 1px solid var(--primaryColor);
}

.roleExperience .radioGroupDiscipline {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 1rem 0;
}

.roleExperience .radioDiscipline {
  width: 13.56rem;
  height: 1.25rem;
  gap: 8px;
  margin: 0.7rem 0 0.6rem 0;
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.roleExperience .selectExperience {
  margin: 0 0 0.5rem 0;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.roleExperience .radioExperience {
  margin: 0.6rem 0 0.6rem 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.roleExperience .backButtonSkill {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4054b2;
  margin-bottom: 1.25rem;
  cursor: pointer;
}

.roleExperience .backButtonSkill2 {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4054b2;
  margin-bottom: 1.25rem;
  cursor: pointer;
}

.roleExperience .submitButtonRE {
  display: flex;
  justify-content: center;
  margin-top: 2.9rem;
  /* cursor: pointer; */
}

.roleExperience .disablebutton {
  width: 28.12rem;
  height: 2.625rem;
  /* margin: 69px 206px 0 29px; */
  padding: 0.56rem 11.87rem 0.75rem 11.8rem;
  border-radius: 4px;
  color: white;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  background-color: #88c49b;
  margin-bottom: 0.62rem;
}

@media screen and (max-width: 768px) {
  .roleExperience .backButtonSkill {
    width: 20.4rem;
  }

  .roleExperience .backButtonSkill2 {
    width: 20.4rem;
    margin-right:28px;
  }

  .roleExperiencePage .roleExperience {
    margin: unset;
    box-shadow: unset;
    align-items: center;
    padding: 1.25rem 1rem;
  }

  .roleExperience .inputDiscipline {
    width: 100%;
    min-width: 20.62rem;
  }

  .roleExperience .radioExperience {
    width: 100%;
    /* max-width: 25rem; */
    min-width: 20.62rem;
    height: 2.75rem;
    margin: 0;
    padding: 0.6rem 0.75rem 0.75rem 0.75rem;
    border-radius: 4px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #d4d4d4;
    background-color: #fff;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
  }

  .roleExperience .ant-form-item {
    margin-bottom: 1.34rem;
  }
}
