.skillChart {
  position: relative;
  min-height: 28vw;
}
.skillChart .skill-0 {
  width: 18.5vw;
  height: 18.5vw;
  border-radius: 50%;
  position: absolute;
  background-color: #5bd3e3;
  top: 13vw;
  left: 41vw;
  /* top: 50%;
    left: 51%; */
  transform: translate(-50%, -50%);
  box-shadow: -5px -5px 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
  padding: 0px 10px;
}
.skillChart .skill-0 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.skillChart .skill-1 {
  width: 15.62vw;
  height: 15.62vw;
  border-radius: 50%;
  position: absolute;
  background-color: #e1723d;
  top: 1vw;
  left: 48vw;
  /* top: 14%;
    left: 58%; */
  box-shadow: -5px -5px 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
}
.skillChart .skill-1 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.skillChart .skill-2 {
  width: 13.84vw;
  height: 13.84vw;
  border-radius: 50%;
  position: absolute;
  background-color: #f4cb5b;
  top: 1vw;
  left: 22vw;
  /* top: 12%;
    left: 29%; */
  box-shadow: -5px -5px 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
  padding: 0px 10px;
}
.skillChart .skill-2 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.skillChart .skill-3 {
  width: 13.0625vw;
  height: 13.0625vw;
  border-radius: 50%;
  position: absolute;
  background-color: #d44747;
  top: 8vw;
  left: 58vw;
  /* top: 34%;
    left: 70%; */
  box-shadow: -5px -5px 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
  padding: 0px 10px;
}
.skillChart .skill-3 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.skillChart .skill-4 {
  width: 12.28vw;
  height: 12.28vw;
  border-radius: 50%;
  position: absolute;
  background-color: #26ad85;
  top: 12vw;
  left: 48vw;
  /* top: 50%;
    left: 58%; */
  box-shadow: -5px -5px 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
  padding: 0px 14px;
}
.skillChart .skill-4 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.skillChart .skill-5 {
  width: 10.71vw;
  height: 10.71vw;
  border-radius: 50%;
  position: absolute;
  background-color: #d61195;
  top: 11vw;
  left: 23vw;
  /* top: 45%;
    left: 29%; */
  box-shadow: -5px -5px 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
}
.skillChart .skill-5 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.skillChart .skill-6 {
  width: 9.93vw;
  height: 9.93vw;
  border-radius: 50%;
  position: absolute;
  background-color: #8166ad;
  top: 8vw;
  left: 15vw;
  /* top: 34%;
    left: 21%; */
  box-shadow: -5px -5px 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
}
.skillChart .skill-6 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.skillChart .skill-7 {
  width: 9.15vw;
  height: 9.15vw;
  border-radius: 50%;
  position: absolute;
  background-color: #81d065;
  top: 17vw;
  left: 28vw;
  /* top: 63%;
    left: 35%; */
  box-shadow: -5px -5px 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
}
.skillChart .skill-7 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.skillChart .skill-8 {
  width: 8vw;
  height: 8vw;
  border-radius: 50%;
  position: absolute;
  background-color: #524280;
  top: 2vw;
  left: 61vw;
  /* top: 17%;
    left: 76%; */
  box-shadow: -5px -5px 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
}
.skillChart .skill-8 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.skillChart .skill-9 {
  width: 8vw;
  height: 8vw;
  border-radius: 50%;
  position: absolute;
  background-color: #138195;
  top: 1.5vw;
  left: 16vw;
  /* top: 17%;
    left: 21%; */
  box-shadow: -5px -5px 0 rgba(0, 0, 0, 0.1) inset;
  display: table;
  overflow: hidden;
  cursor: text;
}
.skillChart .skill-9 .name {
  color: #fff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: 500;
}
.name p {
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
@media only screen and (min-width: 767px) and (max-width: 991px) {
  .skillChart {
    position: relative;
    min-height: 32vw;
  }
  .skillChart .skill-0 {
    left: 37vw;
    top: 18vw;
    /* left: 50%;
    top: 50%; */
  }
  .skillChart .skill-1 {
    left: 43vw;
    top: 5vw;
    /* left: 57%;
    top: 24%; */
    padding: 0px;
  }
  .skillChart .skill-2 {
    left: 19vw;
    top: 3vw;
    /* left: 32%;
    top: 22%; */
    padding: 0px;
  }
  .skillChart .skill-3 {
    left: 18vw;
    top: 15vw;
    /* left: 56%;
    top: 50%; */
    padding: 0px;
  }
  .skillChart .skill-4 {
    left: 44vw;
    top: 17vw;
  }
  .skillChart .skill-5,
  .skillChart .skill-6,
  .skillChart .skill-7,
  .skillChart .skill-8,
  .skillChart .skill-9 {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .skillChart {
    min-height: 58vw;
  }
  .skillChart .skill-0 {
    width: 45vw;
    height: 45vw;
    border-radius: 50%;
    top: 50%;
    left: 50%;
  }
  .skillChart .skill-1 {
    width: 35vw;
    height: 35vw;
    border-radius: 50%;
    top: 10%;
    left: 1%;
  }
  .skillChart .skill-2 {
    width: 33vw;
    height: 33vw;
    border-radius: 50%;
    top: 13%;
    left: 62%;
  }
  .skillChart .skill-3,
  .skillChart .skill-4,
  .skillChart .skill-5,
  .skillChart .skill-6,
  .skillChart .skill-7,
  .skillChart .skill-8,
  .skillChart .skill-9 {
    display: none;
  }
}

.skillChart .skill-0 {
  z-index: 10;
}
.skillChart .skill-1 {
  z-index: 9;
}
.skillChart .skill-2 {
  z-index: 8;
}
.skillChart .skill-3 {
  z-index: 10;
}
.skillChart .skill-4 {
  z-index: 9;
}
.skillChart .skill-5 {
  z-index: 8;
}
.skillChart .skill-6 {
  z-index: 4;
}
.skillChart .skill-7 {
  z-index: 9;
}
.skillChart .skill-8 {
  z-index: 11;
}
.skillChart .skill-9 {
  z-index: 1;
}
