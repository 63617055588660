.placed-bid-dropdown{
    margin-right: 2%;
}

.placed-bid-dropdown-item{
    border: 1px solid dodgerblue;
    border-radius: 2px;
    color: dodgerblue;
}

.bids-spin{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.paginationBar{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
