.attachment-container-msg {
    width: 100%;
    margin: 0.75rem 0rem 0.5rem 0rem;
    position: relative;
    cursor: pointer;
}

.attachment-content-msg {
    width: 100%;
    padding: 0.625rem 0.75rem 0.9375rem;
    border-radius: 0.3125rem;
    border: solid 0.0625rem #c4c4c4;
    display: flex;
    gap: 0.9375rem;
}

.attachment-content-info-msg {
    width: 90%;
}

.attachment-content-info-msg>h3 {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0rem;
    padding-bottom: 0.125rem;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.attachment-content-info-msg>p {
    margin: 0rem;
    font-size: 0.625rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9e9e9e;
}