.message-item{
    display: flex;
    padding: 8px 5px;
    border-bottom: 1px solid #d5d7d8;
    background-color: #f5f7f7;
    justify-content: center;
    cursor: pointer;
}

.message-item-grid-item{
    display: flex;
    align-items: center;
}

.message-item-text-normal{
    color: #666666;
}

.message-item-title{
    margin-left: 8px;
}

.message-item-tag{
    margin-left: 5px;
    color: #000000;
}

.message-item-time-elapsed-container{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    padding-right: 5px;
}

.message-item-time-elapsed-text{
    color: #cccccc;
}
