.chat-ratingCard-innerDiv {
    margin-top: 3%;
    background-color: #fff;
    border-radius: 4px;
    padding: 12px 15px 5px 15px;
}

.chat-ratingCard-innerDiv .innerDiv-TopRating {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
}

.chat-ratingCard-innerDiv .innerDiv-Para {
    padding: 10px;
    text-align: center;
}

.chat-ratingCard-innerDiv .innerDiv-bottom-row {
    justify-content: space-between;
}