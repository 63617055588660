.empty-container {
  margin: auto;
  /* width: 90%; */
  margin-top: 40px;
  padding-bottom: 3.063rem;
  /* background-color: yellow; */
  text-align: center;
  padding-top: 20px;
}

.empty-container h3 {
  font-size: 26px;
  font-weight: 600;
  color: #1e1e1e;
}

.empty-container h3 strong {
  font-weight: 600;
}

.empty-container span {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #7e7e7e;
}

.empty {
  font-size: 320px;
  margin-top: 5%;
}

.empty-container img {
  width: 180px;
  height: 200px;
  margin-top: 50px;
}

@media screen and (max-width: 768px) {
  .empty-container h3 {
    font-size: 16px;
  }

  .empty-container span {
    font-size: 14px;
  }

  .empty-container img {
    width: 90px;
    height: 100px;
  }
}
