.InterviewsCard-model {
  max-width: 20.625rem;
  height: 10rem;
  display: flex;
  gap: 0.938rem;
  padding: 1.375rem 0.938rem 1.25rem 0.938rem;
  border: solid 0.063rem #e4e4e4;
  background-color: #fff;
  cursor: pointer;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.25);
}

.Interview_Avatar {
  position: relative;
}

.Interview_Avatar .Img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}

.Interview_Avatar .Without_Logo {
  display: grid;
  place-items: center;
  background-color: #e4e4e4;
}

.Interview_Active_Bubble {
  position: absolute;
  right: 0rem;
  top: 1.75rem;
  width: 0.625rem;
  height: 0.625rem;
  border: solid 0.0625rem #fff;
  border-radius: 50%;
  background-color: #9e9e9e;
}

.Interview_Active {
  background-color: #008000;
}

.InterviewsCard-model-employer {
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e1e1e;
}

.InterviewsCard-model-job-title {
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0.625rem 0rem 0.625rem 0rem;
  color: #727272;
}
.InterviewsCard-model-time {
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-bottom: 0.938rem;
  color: #4e4e4e;
}

.InterviewsCard-model-profile-dot {
  border-radius: 50%;
  width: 0.625rem;
  height: 0.625rem;
  border: solid 0.063rem #fff;
  background-color: #9e9e9e;
  left: 1.813rem;
  position: relative;
  bottom: 0.75rem;
}

.InterviewsCard-model-email-box {
  display: flex;
  grid-gap: 0.938rem;
  gap: 0.625rem;
  padding: 0.313rem;
  border-radius: 0.125rem;
  border: solid 0.063rem #eee;
  background-color: #fff;
}

.InterviewsCard-model-email {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #888;
}

.InterviewsCard-model-email-icon {
  cursor: pointer !important;
}

.Interview_Location {
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #727272;
}

@media screen and (max-width: 768px) {
  .InterviewsCard-model {
    max-width: none !important;
    height: 8.125rem;
    display: flex;
    gap: 0.938rem;
    padding: 1.594rem 0rem 1.531rem 0.625rem !important;
    border: 0rem !important;
    border-radius: 0rem !important;
    box-shadow: 0 0 0 0 !important ;
    /* border-bottom: solid 0.063rem #e4e4e4 !important; */
    border-top: solid 0.063rem #e4e4e4 !important;
    background-color: #fff;
    cursor: pointer;
  }

  .InterviewsCard-model-employer {
    font-size: 0.75rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1e1e1e;
  }

  .InterviewsCard-model-job-title {
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 0.313rem 0rem 0.5rem 0rem;
    color: #727272;
  }

  .InterviewsCard-model-time {
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding-bottom: 0.5rem;
    color: #4e4e4e;
  }

  .InterviewsCard-model-email {
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #888;
  }
}

