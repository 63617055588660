.GigExpiresIT-Cantainer .Skill-PieChart-Component {
    display: flex;
    justify-content: space-between;
    /* min-width: 25rem; */
    font-size: .875rem;
    color: #7e7e7e;
}

.GigExpiresIT-Cantainer .GigExpiresIT-Timer {
    display: flex;
}

.GigExpiresIT-Cantainer .GigExpiresIT-Timer>div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.GigExpiresIT-Cantainer .GigExpiresIT-Timer>div:nth-child(2) {
    padding-left: 1.875rem;
}

.GigExpiresIT-Cantainer .GigExpiresIT-Timer>div:nth-child(3) {
    padding-left: 1.875rem;
}

.GigExpiresIT-Cantainer .GigExpiresIT-Timer .GigExpiresIT-Timer-Unit {
    color: #6e6e6e;
    font-size: .875rem;
    font-weight: normal;
}

.GigExpiresIT-Cantainer .GigExpiresIT-Timer .GigExpiresIT-Timer-Left {
    font-size: .875rem;
    font-weight: 500;
    color: #1e1e1e;
}

@media screen and (max-width: 768px) {
    .GigExpiresIT-Cantainer .Skill-PieChart-Component {
        font-size: .75rem;
        min-width: 22rem;
    }
}