.no-interview-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 3.6875rem;
  margin-bottom: -1.25rem;
  gap: 1.25rem;
}

.illustration-icon {
  width: 15.625rem;
  height: 15.625rem;
  object-fit: contain;
}

.no-interviews {
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 768px) {
  .no-interview-container {
    margin: 0rem;
    margin-top: 5rem;
  }

  .illustration-icon {
    width: 9.375rem;
    height: 9.375rem;
    object-fit: contain;
  }

  .no-interviews {
    font-size: 0.75rem;
    line-height: 1.5;
    margin-bottom: 0.625rem;
  }
}
/********** For Mobile View  Media Query End **********/
