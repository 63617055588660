/* candidate-profile-container Start */
.candidate-profile-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 90px;
  background-color: #353353;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  position: relative;
}

/* All Polygon Icon Style Start */
.candidate-profile-container .zigzagLineIcon {
  position: absolute;
  top: 3%;
  left: 9%;
  width: 42.1px;
  height: 29.5px;
  object-fit: contain;
}

.candidate-profile-container .purpleTriangleIcon,
.red-triangle-icon-one,
.red-triangle-icon-two {
  position: absolute;
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.candidate-profile-container .purpleTriangleIcon {
  top: 6%;
  left: 30%;
}

.DResume-Tooltips {
  display: flex;
  align-items: center;
}

.candidate-profile-container .red-triangle-icon-one {
  top: 31%;
  left: 36%;
}

.candidate-profile-container .red-triangle-icon-two {
  top: 3%;
  left: 46%;
}

.candidate-profile-container .green-line-icon {
  position: absolute;
  width: 10.1px;
  height: 10.1px;
  bottom: 33%;
  left: 43%;
  object-fit: contain;
}

.candidate-profile-container .yellow-arc-icon-one,
.yellow-arc-icon-two {
  position: absolute;
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.candidate-profile-container .yellow-arc-icon-one {
  top: 25%;
  left: 45%;
}

.candidate-profile-container .yellow-arc-icon-two {
  top: 1%;
  left: 53%;
}
/* All Polygon Icon Style End */

/* Candidate_Info Start */
.candidate-info {
  flex-basis: calc(50% - 0.5px);
  padding: 35px 0px 20px;
}

.resume-candidate-avatar {
  position: relative;
  padding-top: 15px;
  margin-bottom: 27px;
  width: 150px;
}
.candidate-avtar-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #118936;
  display: flex;
  justify-content: center;
  align-items: center;
}

.candidate-avtar-img > .resume-image-avatar {
  font-size: 2.5rem !important;
  color: #fff;
  font-weight: 500;
}

.candidate-avatar-ratting {
  position: absolute;
  bottom: -7px;
  right: 0px;
  width: 62px;
  height: 60px;
  object-fit: contain;
}

.candidate-name-and-address > h3 {
  margin-bottom: 20px;
  font-size: 34px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.candidate-name-and-address > p {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.candidate-social {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.candidate-social svg {
  min-width: 30px;
  min-height: 30px;
  object-fit: contain;
  color: #fff;
}
/* Candidate_Info End */

/* candidate-divider-main-section Start */
.candidate-divider-main-section {
  padding: 35px 0px 85px;
}

.candidate-divider-main-section .Divider-line-resume-page-inner {
  display: none;
}
/* candidate-divider-main-section End */

/* candidate-resume-info Start */
.candidate-resume-info {
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: calc(50% - 0.5px);
  padding: 103px 0px 99px 40px;
}

.candidate-resume-box {
  display: flex;
  flex-wrap: wrap;
  padding-right: 14px;
}

.candidate-resume-box-content {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  min-height: 74px;
  padding-left: 17px;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #525076;
  cursor: pointer;
}

.candidate-resume-box-content:last-child {
  border: none;
}

.candidate-resume-box-content > h3 {
  margin-bottom: 0px;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: left;
  color: #fff;
}
.candidate-resume-box-content > p {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.resume-completion-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-left: 17px;
  gap: 32px;
}

.resume-completion-in-percentage {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.resume-completion-in-percentage > span {
  display: grid;
  place-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: solid 3px #4ec0b2;
}

/* .resume-completion-in-percentage > .span_border_white {
  border: solid 3px #fff;
} */
.resume-completion-in-percentage .ant-progress-circle .ant-progress-text {
  color: #ffffff;
  font-size: 16px;
}

.resume-completion-in-percentage p {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.resume-completion-in-percentage {
  margin-bottom: 8px;
}

.resume-completion-button {
  flex: 1;
  max-width: 240px;
}

.resume-completion-button > div {
  padding: 10px 0px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  background-color: #118936;
  cursor: pointer;
}
/* candidate-resume-info End */
/* candidate-profile-container End */

/* ----------------------------------------------------------------------------------------- */

/* Candidate_Profile_Info_Container */
.Candidate_Profile_Info_Container {
  width: 100%;
  padding: 40px 90px 49px;
}

/* Candidate_Profile_Info_Inside */
.Candidate_Profile_Info_Inside {
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 4px;
}

/* For Common Style Start */
.Career_Summary > h3,
._3DResume > h3,
.Skill_Index > h3,
.Career_History > h3,
.Work_Position_Info > h3,
.Responsibilities_Content > h3,
.Skills_Content > h3,
.Industry_Density > h3,
.Education_History > h3,
.Certificates_And_Awards > h3,
.No_Data > h3,
.Public_View_Credentialing_Container > h3 {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.Career_Summary,
._3DResume,
.Skill_Index,
.Career_History,
.Industry_Density,
.Education_History {
  padding-bottom: 19px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e4e4e4;
}
/* For Common Style End */

/* Career_Summary Start*/
.Career_Summary_Content .Edit_Button {
  width: 100%;
  margin-bottom: 20px;
  text-align: right;
}

.Career_Summary_Content .Edit_Button > a {
  display: inline-block;
  padding: 10px 61px 10px 62px;
  border-radius: 4px;
  color: #fff;
  background-color: #118936;
}

.Career_Summary_Content .Candidate_View {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.06);
  background-color: #fefefe;
}

.Candidate_View .Candidate_View_Info {
  margin-bottom: 20px;
}

.Candidate_View .Candidate_View_Info > h3 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.Candidate_View .Candidate_View_Info > p {
  font-size: 12px;
  margin-bottom: 0px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9e9e9e;
}

.Candidate_View_Box {
  display: flex;
  flex-wrap: wrap;
  gap: 31px;
}

.Candidate_View_Content_Link {
  flex: 1;
}

.Candidate_View_Content {
  min-width: 175.2px;
  max-width: 220px;
  flex: 1;
  text-align: center;
  padding: 14px 0px 13px 0px;
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  background-color: #fefefe;
  cursor: pointer;
}

.show-public-view {
  display: none;
}

.Candidate_View_Content > img {
  width: 24px;
  height: 24px;
  margin-bottom: 12px;
  object-fit: contain;
}

.Candidate_View_Content > p {
  font-size: 14px;
  margin-bottom: 0px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4e4e4e;
}

.Career_Summary > p {
  font-size: 14px;
  margin-bottom: 0px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
}
/* Career_Summary End */

/* _3DResume Start */
._3DResume > p {
  font-size: 14px;
  margin-bottom: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9e9e9e;
}

._3DResume > p > a {
  color: #4054b2;
}

._3DResume .Progress {
  border-radius: 10px;
  background-color: #e4e4e4;
}
._3DResume .Progress_Bar {
  height: 16px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 10px 0px 0px 10px;
  padding-left: 9.5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  background-color: #1e1e1e;
  transition: width 0.6s ease;
}
/* _3DResume End */

/* Skill_Index Start */
.Skill_Index .Skill_Index_Content {
  display: grid;
  /* place-items: center; */
}
/* Skill_Index End */

/* Career_History Start */
.Career_History > h3 {
  margin-bottom: 18px;
}

.Career_History_Progress_Container {
  margin-bottom: 21px;
}

.Career_History_Progress_Year {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 1px;
  width: 100%;
}

.Career_History_Progress_Year > span {
  display: inline-block;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  transition: width 0.6s ease;
}

.Career_History_Progress_Year > span:last-child {
  text-align: right;
}

.Career_History_Progress_Bar {
  max-height: 35px;
}

.Career_History_Progress_Bar > img {
  width: 100%;
  height: 100%;
}

.Experience_Container {
  padding: 15px 0px;
  border-radius: 5px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
  margin-bottom: 15px;
}

.Experience_Container:last-child {
  margin-bottom: 0px;
}

.Work_Position_Info {
  padding: 0px 25px;
  border-bottom: 1px solid #e4e4e4;
}

.Work_Position_Info > h3 {
  margin-bottom: 10px;
  line-height: 1.25;
}
.Work_Position_Info > p {
  margin-bottom: 9px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.Responsibilities_And_Skills_Content {
  padding: 0px 25px;
}

.Responsibilities_Content {
  padding: 15px 0px;
}

.Responsibilities_Content > h3,
.Responsibilities_Content > p {
  margin-bottom: 10px;
}

.Responsibilities_Content > p {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.Responsibilities_Content > p:last-child {
  margin-bottom: 0px;
}

.Skills_Content > h3 {
  margin-bottom: 13px;
}

.Skills_Content .Skill_Button {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.Skills_Content .Skill_Button > span {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  padding: 3px 10px;
  border-radius: 3px;
  background-color: #4054b2;
}
/* Career_History End */

/* Industry_Density Start */
.Industry_Density > h3 {
  margin-bottom: 10px;
}

.Industry_Density > p {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.Industry_Density > a {
  text-decoration: underline;
  color: #4054b2;
}

.Industry_Density_Percentage:first-of-type {
  margin-top: 20px;
}

.Industry_Density_Percentage {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 4px;
  background-color: var(--bgColor);
  border: 3px solid var(--borderColor);
}

.Industry_Density_Percentage:last-child {
  margin-bottom: 0px;
}

.Industry_Density_Percentage > span {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
/* Industry_Density End */

/* Education_History Start */
.Education_History_Carousel_Container {
  position: relative;
  padding: 40px 0px;
  border-radius: 4px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
}

.Education_History_Carousel {
  min-height: 270px;
  min-width: 100%;
}

.Education_History_Carousel_Container .ant-carousel .slick-dots li button {
  margin-top: 66px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  text-align: center;
  background-color: #4e4e4e;
}

.Education_History_Carousel_Container
  .ant-carousel
  .slick-dots
  li.slick-active {
  width: 0rem !important;
}

.Education_History_Carousel_Container .ant-carousel .slick-dots li {
  width: 0rem !important;
  height: 0rem !important;
  margin-right: 1.125rem !important;
  margin-left: 0rem !important;
}

.Education_History_Carousel_Container .ant-carousel .slick-dots {
  margin-top: 1.15% !important;
  justify-content: center !important;
  margin-right: 0rem !important;
  margin-left: 2.89% !important;
}

.Carousel_Left_Arrow,
.Carousel_Right_Arrow {
  position: absolute;
  top: 43%;
}

.Education_History_Carousel .ant-carousel .slick-next,
.Education_History_Carousel .ant-carousel .slick-prev {
  top: 60%;
  z-index: 10000;
}

.Carousel_Left_Arrow {
  left: 10%;
}

.Education_History_Carousel .ant-carousel .slick-prev {
  left: 11%;
}

.Carousel_Right_Arrow {
  right: 10%;
}

.Education_History_Carousel .ant-carousel .slick-next {
  right: 11%;
}

.Carousel_Left_Arrow > img,
.Carousel_Right_Arrow > img {
  min-width: 40px;
  min-height: 40px;
  object-fit: contain;
  background-color: transparent;
}

.Education_History_Content {
  display: flex !important;
  gap: 150px;
  justify-content: center;
  align-items: center;
}

.Education_History_Content .Education_History_Box {
  position: relative;
  min-width: 218px;
  min-height: 220px;
  object-fit: contain;
}

.Education_History_Box .Education_detail_Info {
  position: absolute;
  padding: 0px 30px;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.Education_detail_Info > p {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Education_detail_Info > p:last-child {
  margin-bottom: 0px;
  line-height: 1.25;
}

.Education_History_Info > p {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7e7e7e;
}
.Education_History_Info > h3 {
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4054b2;
}
/* Education_History End */

/* Certificates_And_Awards Start */
.Achievement_Box {
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 4px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
}
.Achievement_Box .Achievement {
  display: flex;
  align-items: center;
  gap: 15px;
}
.Achievement_Box .Achievement > img {
  min-width: 22px;
  min-height: 40px;
}

.Achievement_Box .Achievement > p {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}
/* Certificates_And_Awards End */

/* Credentialing_Container Start */
.Credentialing_Container .credentialing-user-documents {
  padding: 0px;
}

.Credentialing_Container
  .credentialing-user-documents
  .UserDocuments
  > .credentialing-title,
.Credentialing_Container
  .credentialing-user-documents
  .UserDocuments
  > .upload-documents {
  font-weight: 600;
}

.Credentialing_Container
  .credentialing-user-documents
  .UserDocuments
  > .upload-documents {
  margin-bottom: 0.75rem;
}

.Credentialing_Container
  .credentialing-user-documents
  .UserDocuments
  > .input-select-dropdown {
  margin: 0px;
  /* margin-top: 10px; */
  margin-bottom: 0.625rem;
}
.Credentialing_Container
  .credentialing-user-documents
  .UserDocuments
  > .document-border {
  /* margin-top: 10px; */
  border-radius: 4px;
}
.Credentialing_Container
  .credentialing-user-documents
  .UserDocuments
  > .documentBorder
  .uploadDocs-button
  > svg {
  fill: #4054b2;
}

.Public_View_Credentialing_Container > p {
  margin: 5px 0px 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}
/* Credentialing_Container End */

/* No_Data Start */
.No_Data {
  padding: 20px;
  border-radius: 4px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
}

.No_Data > h3 {
  margin-bottom: 0px;
  line-height: 1.25;
  font-weight: 500 !important;
}
/* No_Data End */

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 768px) {
  /* candidate-profile-container Start */
  .candidate-profile-container {
    flex-direction: column;
    padding: 0px;
    box-shadow: none;
  }

  /* All Polygon Icon Style Start */
  .candidate-profile-container .zigzagLineIcon {
    top: 55%;
  }

  .candidate-profile-container .purpleTriangleIcon {
    top: 10%;
    left: 15%;
  }

  .candidate-profile-container .red-triangle-icon-one {
    top: 58%;
    left: 88.5%;
  }

  .candidate-profile-container .red-triangle-icon-two {
    display: none;
  }

  .candidate-profile-container .green-line-icon {
    display: none;
  }

  .candidate-profile-container .yellow-arc-icon-one {
    top: 25%;
    left: 88%;
  }

  .candidate-profile-container .yellow-arc-icon-two {
    display: none;
  }
  /* All Polygon Icon Style End */

  /* Candidate_Info Start */
  .candidate-info {
    flex-basis: 100%;
    padding: 0px;
  }

  .resume-candidate-avatar {
    position: relative;
    padding-top: 30px;
    margin: auto;
    margin-bottom: 25px;
    width: 100px;
  }

  .candidate-avtar-img {
    width: 99.3px;
    height: 100px;
  }

  .candidate-avatar-ratting {
    width: 41.4px;
    height: 40px;
  }

  .candidate-name-and-address {
    display: flex;
    flex-direction: column-reverse;
  }

  .candidate-name-and-address > h3,
  .candidate-name-and-address > p {
    text-align: center;
  }

  .candidate-name-and-address > h3 {
    margin-bottom: 15px;
    font-size: 32px;
    order: 1;
  }

  .candidate-name-and-address > p {
    margin-bottom: 15px;
  }

  .candidate-social {
    justify-content: center;
    gap: 15px;
  }

  .candidate-social svg {
    min-width: 15.6px;
    min-height: 15.6px;
  }
  /* Candidate_Info End */

  /* candidate-divider-main-section Start */
  .candidate-divider-main-section {
    padding: 0px;
    flex-basis: 100%;
    /* padding: 10px 0px; */
    /* height: 1px; */
  }

  .candidate-divider-main-section .divider-line-resume-page {
    display: none;
  }

  .candidate-divider-main-section .Divider-line-resume-page-inner {
    display: block;
    height: 29px;
    border-bottom: 1px solid #535172;
  }
  /* candidate-divider-main-section End */

  /* candidate-resume-info Start */
  .candidate-resume-info {
    gap: 0px;
    padding: 0px;
    flex-direction: column-reverse;
  }

  .candidate-resume-box {
    max-height: 56px;
    background-color: #fff;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    padding-left: 1.25rem;
    padding-top: 0.625rem;
    padding-bottom: 0.5625rem;
    padding-right: 1.25rem;
  }

  .candidate-resume-box-content {
    padding-left: 0rem;
    min-height: auto;
    flex-direction: column-reverse;
    border: none;
  }

  .candidate-resume-box-content:first-child > h3 {
    padding-left: 1.875rem;
  }
  .candidate-resume-box-content:nth-child(2) {
    padding-left: 4.5rem;
  }
  .candidate-resume-box-content:nth-child(2) > h3 {
    padding-left: 1.5625rem;
  }

  .candidate-resume-box-content:last-child > h3 {
    margin-right: 0.9375rem;
    text-align: right;
  }
  .candidate-resume-box-content:last-child > p {
    text-align: right;
  }

  .candidate-resume-box-content > h3 {
    font-size: 14px;
    font-weight: 500;
    color: #1e1e1e;
  }
  .candidate-resume-box-content > p {
    font-size: 14px;
    color: #6e6e6e;
  }

  .resume-completion-container {
    justify-content: center;
    padding: 10px;
  }
  /* 
  .resume-completion-in-percentage > span {
    width: 40px;
    height: 40px;
  } */

  .resume-completion-in-percentage > span > p {
    font-size: 10px;
  }

  /* .resume-completion-in-percentage p {
    font-size: 12px;
  } */
  .resume-completion-in-percentage .ant-progress-circle .ant-progress-inner {
    width: 40px !important;
    height: 40px !important;
  }
  .resume-completion-in-percentage .ant-progress-circle .ant-progress-text {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  .resume-completion-in-percentage {
    /* display: none; */
    margin-bottom: 0px;
  }

  .resume-completion-button {
    display: none;
  }
  /* candidate-resume-info End */
  /* candidate-profile-container End */

  /* ----------------------------------------------------------------------------------------- */

  /* Candidate_Profile_Info_Container */
  .Candidate_Profile_Info_Container {
    padding: 0%;
  }

  /* Candidate_Profile_Info_Inside */
  .Candidate_Profile_Info_Inside {
    padding: 20px 15px 29px;
  }

  /* For Common Style Start */
  .Career_Summary > h3,
  ._3DResume > h3,
  .Skill_Index > h3,
  .Career_History > h3,
  .Work_Position_Info > h3,
  .Responsibilities_Content > h3,
  .Skills_Content > h3,
  .Industry_Density > h3,
  .Education_History > h3,
  .Certificates_And_Awards > h3,
  .No_Data > h3 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  /* For Common Style End */

  /* Career_Summary Start*/
  .Career_Summary_Content .Edit_Button {
    width: 100%;
    flex-grow: 0;
    margin-bottom: 1.5rem;
    border-radius: 0.25rem;
    background-color: var(--primaryColor);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  .Career_Summary_Content .Candidate_View {
    padding: 0px;
    margin-bottom: 20px;
    border-radius: none;
    box-shadow: none;
    background-color: #fff;
  }

  /* .Candidate_View .Candidate_View_Info {
    display: none;
  } */

  .Candidate_View_Box {
    gap: 10px;
  }

  .Candidate_View_Content_Link {
    max-width: 100%;
  }

  .Candidate_View_Content {
    min-width: 160px;
    padding: 0.9375rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
  }

  .manageCredentialIcon,
  .Public_View_In_Mobile {
    max-width: 100%;
    gap: 10px;
    justify-content: center;
    /* flex: 1; */
  }

  .Candidate_View_Content > img {
    width: 20px;
    height: 20px;
    margin-bottom: 0px;
  }

  .Candidate_View_Content > p {
    margin-bottom: 0px;
    font-size: 12px;
    color: #4e4e4e;
  }

  .Career_Summary > p {
    font-size: 12px;
    line-height: 1.42;
    color: #4e4e4e;
  }
  /* Career_Summary End */

  /* _3DResume Start */
  ._3DResume > h3 {
    margin-bottom: 20px;
  }

  ._3DResume > p {
    display: none;
  }

  ._3DResume .Progress {
    border-radius: 10px;
    background-color: #e4e4e4;
  }
  /* _3DResume End */

  /* Skill_Index Start */
  /* Skill_Index End */

  /* Career_History Start */
  .Career_History > h3 {
    margin-bottom: 15px;
  }

  .Career_History_Progress_Container {
    margin-bottom: 20px;
  }

  .Career_History_Progress_Year {
    margin-bottom: 5px;
  }

  .Career_History_Progress_Year > span {
    font-size: 8px;
  }

  .Career_History_Progress_Bar {
    min-height: 36px;
  }

  .Career_History_Progress_Bar > img {
    object-fit: cover;
  }

  .Experience_Container {
    border-radius: 4px;
    border: solid 1px #eee;
  }

  .Work_Position_Info {
    padding: 0px 12px;
    border-bottom: 1px solid #e4e4e4;
  }

  .Work_Position_Info > h3 {
    line-height: 1.43;
  }
  .Work_Position_Info > p {
    margin-bottom: 14px;
    font-size: 12px;
  }

  .Responsibilities_And_Skills_Content {
    padding: 0px 12px;
  }

  .Responsibilities_Content {
    padding-bottom: 20px;
  }

  .Responsibilities_Content > h3 {
    margin-bottom: 15px;
  }

  .Responsibilities_Content > p {
    font-size: 12px;
    line-height: 1.67;
  }

  .Skills_Content > h3 {
    margin-bottom: 15px;
  }

  .Skills_Content .Skill_Button {
    gap: 5px;
  }

  .Skills_Content .Skill_Button > span {
    font-size: 12px;
  }
  /* Career_History End */

  /* Industry_Density Start */
  .Industry_Density > h3 {
    margin-bottom: 10px;
  }

  .Industry_Density > p {
    font-size: 12px;
    line-height: 1.67;
  }

  .Industry_Density > a {
    display: none;
  }

  .Industry_Density_Percentage {
    margin-top: 0px;
    margin-bottom: 8px;
    padding: 12px 10px;
  }

  .Industry_Density_Percentage > span {
    font-size: 14px;
  }
  /* Industry_Density End */

  /* Education_History Start */
  .Education_History_Carousel_Container {
    padding: 30px 0px;
  }

  .Education_History_Carousel {
    min-height: 320px;
  }

  .Education_History_Carousel_Container .ant-carousel .slick-dots li button {
    margin-top: 40px;
  }

  .Education_History_Carousel .ant-carousel .slick-next,
  .Education_History_Carousel .ant-carousel .slick-prev {
    top: 50%;
  }

  .Carousel_Left_Arrow,
  .Education_History_Carousel .ant-carousel .slick-prev {
    left: 7%;
  }
  .Carousel_Right_Arrow,
  .Education_History_Carousel .ant-carousel .slick-next {
    right: 7%;
  }
  .Carousel_Left_Arrow > img,
  .Carousel_Right_Arrow > img {
    min-width: min-content;
    min-height: min-content;
    max-width: 21px;
    max-height: 21px;
  }

  .Education_History_Content {
    gap: 20px;
    flex-direction: column;
  }

  .Education_History_Content .Education_History_Box {
    min-width: 217px;
    min-height: 220px;
  }

  .Education_History_Info > p {
    font-size: 16px;
  }
  .Education_History_Info > h3 {
    font-size: 16px;
  }
  /* Education_History End */

  /* Certificates_And_Awards Start */
  .Achievement_Box {
    padding: 15px 12px;
    gap: 10px;
    border: solid 1px #eee;
  }
  .Achievement_Box .Achievement {
    gap: 12.8px;
  }
  .Achievement_Box .Achievement > img {
    min-width: 16.2px;
    min-height: 30px;
  }

  .Achievement_Box .Achievement > p {
    font-size: 12px;
    line-height: 1.67;
  }
  /* Certificates_And_Awards End */

  /* Credentialing_Container Start */
  .Credentialing_Container .credentialing-user-documents .UserDocuments {
    padding: 0px;
  }
  /* Credentialing_Container End */

  /* No_Data Start */
  .No_Data {
    padding: 15px 12px;
    border: solid 1px #eee;
  }

  .No_Data > h3 {
    margin-bottom: 0px;
    line-height: 1.43;
  }
  /* No_Data End */
  /*  Bubble Chart star */
  .Skill_Index .Skill_Index_Content {
    max-width: 46rem;
  }
}
/********** For Mobile View  Media Query End **********/
