.footer-container {
  background-color: #fff;
  padding: 1.4375rem 6.25rem 1.5rem;
  min-height: 20.06rem;
}

.footer-section {
  display: flex;
  justify-content: space-between;
}

.footer-About-section {
  display: flex;
  flex-direction: column;
}

.footer-heading {
  font-weight: 600;
  color: #4e4e4e;
  font-size: 1rem;
  padding-bottom: 0.625rem;
}

.footer-About-section > span {
  font-size: 1rem;
  font-weight: normal;
  color: #7e7e7e;
  padding-bottom: 0.5rem;
  cursor: pointer;
}

.footer-Support-section > span {
  font-size: 1rem;
  font-weight: normal;
  color: #7e7e7e;
  padding-bottom: 0.5rem;
  cursor: pointer;
}

.footer-Community-section {
  display: flex;
  flex-direction: column;
  padding-right: 4.625rem;
}

.footer-Community-section > span {
  font-size: 1rem;
  font-weight: normal;
  color: #7e7e7e;
  padding-bottom: 0.5rem;
  cursor: pointer;
}

.footer-logo-section {
  display: flex;
  justify-content: space-between;
}

.footer-container .ant-divider-horizontal {
  margin: 0.3125rem 0 1.313rem 0 !important;
  background-color: #e4e4e4 !important;
}

.footer-social-link {
  display: flex;
  justify-content: space-between;
  min-width: 21.25rem;
}

.footer-social-link .anticon {
  font-size: 1.875rem;
  color: #7e7e7e;
}

.footer-icon-modifiction {
  font-size: 1.875rem;
  color: #7e7e7e;
}

.footer-divider-top {
  margin: 0;
  background-color: #f8f8f8;
  border-top: 0.0625rem solid #e4e4e4 !important;
}

.Footer-logo-Skillgig > img {
  height: 2.063rem;
}

.Footer-logo-Skillgig > span {
  padding-left: 0.938rem;
  color: #7e7e7e;
  font-size: 0.875rem;
}

.footer-Support-section {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .footer-container {
    padding: 0.313rem 1.25rem 1.25rem;
    min-height: 19.813rem;
  }

  .footer-section {
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
  }

  .Footer-logo-Skillgig {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-logo-section {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .footer-social-link > a {
    padding-left: 1rem;
  }

  .Footer-logo-Skillgig > span {
    padding: 0.938rem 0 0 0;
    color: #9e9e9e;
    font-weight: normal;
  }

  .footer-container .ant-divider-horizontal {
    margin-bottom: 1.25rem !important;
  }

  .footer-heading {
    padding-top: 1.25rem;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    color: #4e4e4e;
  }

  .footer-container .ant-divider-horizontal {
    margin-top: 1.188rem !important;
  }

  .footer-social-link {
    padding-top: 0.938rem;
    display: flex;
    justify-content: center;
  }

  .footer-social-link .anticon {
    font-size: 1.25rem;
  }

  .footer-icon-modifiction {
    font-size: 1.25rem;
  }

  .Footer-logo-Skillgig > img {
    max-height: 2rem;
  }

  .footer-social-link .ant-row {
    justify-content: space-between;
  }

  .footer-first-span {
    padding-top: 1.25rem !important;
  }

  .footer-heading .anticon {
    color: #4e4e4e;
    font-size: 0.875rem;
  }

  .footer-bottom-span {
    padding-bottom: 0 !important;
  }
  .footer-Community-section{
    padding-right: unset;
  }
}
