.containerStep3 {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 2rem 1.2rem 0;
  --buttonColor: #e73959;
  --borderColor: #e4e4e4;
  --background1: #fafcff;
  --background: #ffffff;
  --activeborderColor: #3a85e2;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 0rem 0rem 0rem;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: "tnum", "tnum", "tnum";
}

.skillListingOnePage {
  display: flex;
  background-color: #f8f8f8;
  /* justify-content: flex-start; */
  /* justify-content: space-between; */
  padding: 2.5rem 2.5rem 3.063rem;
}

.skillListingOne {
  /* padding: 2rem 1.5rem; */
  /* height: 42.44rem; */
  /* width: 47.5rem; */
  /* Removed due to overflow out of modal issue */
  /* margin: 2.5rem 2.5rem 3.063rem; */
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
}

/* .skillisting-card-outer-box {
  flex: 1;
} */

.skillListingOnePadding {
  padding: 1.25rem 1.875rem;
}

.skillListingModalPadding {
  padding: 0.3rem 1.875rem 0;
}

.skillListingModalPadding .ant-select-arrow {
  display: none;
}

.Sub-headSkillisting {
  font-size: 0.875rem;
  font-weight: normal;
  color: #7e7e7e;
  margin-bottom: 1.875rem;
}

.headSkillisting {
  /* width: 13.81rem; */
  /* height: 1.81rem; */
  margin: 0 8.69rem 0.75rem 0;
  font-size: 1.125rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.pencilSkilllisting {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  margin: 0 0.5rem 0 0;
}

.headDiscription {
  font-size: medium;
  color: #666666;
}

.skillListingOne .inputTitleSkillisting {
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.skillListingOne .inputBilltoClientLabel {
  margin-top: 1.25rem !important;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.wrapSalaryDate .ant-input-affix-wrapper-disabled {
  color: #1e1e1e;
}

.wrapSalaryDate .ant-input[disabled] {
  color: #1e1e1e;
}

.skillListingOne .helpAddSkill {
  margin: 0.63rem 0 0.3rem;
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e;
}

.skillListingOne .reqSkillisting {
  color: #db2828;
  margin-left: 0.2rem;
}

.skillListingOne .ant-form-item-label > label::after {
  display: none;
}

.skillListingOne
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.inputLabel {
  display: block;
}

.skillListingOne .placeholderSkillListingFormOne {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  margin-top: 7px;
}

.inputSalaryCss .ant-input:placeholder-shown {
  font-size: .75rem !important;
  font-weight: normal;
  color: #7e7e7e;
}

/* .ant-select-focused {
  margin-right: 5px;
  width: 7px;
} */

.skillListingOne .placeholderSkillListingForm {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  margin-top: 5px;
}

.inputLabel .ant-radio-button-wrapper {
  margin-right: 0.625rem;
  border-left-width: 0.07rem;
  border-color: #d4d4d4;
  color: #1e1e1e;
  border-radius: 0.25rem;
  padding: 0.1rem 0.625rem;
  height: 2.19rem;
  font-size: 1rem;
}

.inputLabel
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):first-child {
  border-color: #d4d4d4;
}

.inputLabel .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: unset;
}

.inputLabel .ant-radio-button-wrapper:hover {
  border-color: #d4d4d4;
}

.inputLabel
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: unset;
}

.inputLabel
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):focus-within {
  box-shadow: unset;
}

.inputLabel .ant-radio-button-checked {
  border-color: unset;
  background-color: #e4ffec;
}

.inputLabel
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  color: unset;
  border-color: #d4d4d4;
}

.skillListingOne .ant-select-multiple .ant-select-selection-item {
  border-radius: 1.25rem;
  border: solid 0.06rem #d4d4d4;
  background-color: #f2f2f2;
}

.skillListingOne .ant-select-selection-overflow {
  padding: 0.125rem;
  flex-wrap: wrap;
}

.skillListingOne .ant-form-item .ant-form-item-label {
  padding: 0 0 0;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
  margin-bottom: 0.3rem;
}

/* error handling */
.skillListingOne .ant-form-item-has-error .ant-radio-button-wrapper {
  /* border-color: var(--primaryColor-error) !important; */
  border-color: #db2828 !important;
}

.skillListingOne .ant-form-item-explain-error {
  /* color: var(--primaryColor-error); */
  color: #db2828 !important;
  margin-top: 0.4rem;
}

.skillListingOne .ant-form-item-has-error .dropCss {
  border-color: #db2828;
}

.skillListingOne .ant-form-item-has-error .display-title-input {
  border-color: #db2828;
}

.skillListingOne .ant-form-item-has-error .inputSalaryCss {
  border-color: var(--primaryColor-error);
  border-left: unset;
}

.skillListingOne .ant-form-item-has-error .ant-input-group-addon {
  color: black;
  border-right-color: var(--primaryColor-error);
  border-left-color: var(--primaryColor-error);
  /* border-left: unset; */
}

.inputCss {
  padding: 0.5rem 0.6rem;
  margin: 0 !important;
  /* padding: 12px 154px 12px 10px; */
  border-radius: 6px;
  border: solid 1px #e4e4e4;
  background-color: #ffffff;
  color: #2e2e2e;
}

.inputSalaryCss {
  margin: 0.5rem 0 0 !important;
  /* padding: 12px 154px 12px 10px; */
  /* display: flex;
  align-items: center; */

  width: 21.25rem;
  height: 2.75rem;
  flex-grow: 0;
  /* padding: 0 12rem 0 0; */
  border-radius: 0.25rem;
  border: solid 0.06rem #d4d4d4;
  background-color: #fff;
}

.inputSalaryCss .ant-input-group .ant-input {
  /* padding-top: 0.6rem; */
  min-height: 2.75rem;
  font-size: 1rem;
}

.skillListingOne .ant-input-group-addon {
  background-color: #fff;
  height: 2.75rem;
  width: 5.38rem;
  border-right: solid 0.06rem #d4d4d4;
  /* padding-bottom: 0.2rem; */
  padding: 0.625rem;
  font-size: 1rem;
  position: relative;
  bottom: 1px;
}

.wrapSalaryDate .ant-input-prefix {
  padding: 7px;
  margin-right: 4px;
  /* height: fit-content; */
  font-size: 1rem;
}

.wrapSalaryDate .ant-form-item-has-error .ant-input-prefix {
  color: #7e7e7e;
}

.wrapSalaryDate .ant-input-affix-wrapper {
  padding: 0 11px;
}

.skillListingOne .display-title-input {
  max-width: 43.75rem !important;
  min-height: 2.75rem;
  flex-grow: 0;
  margin: 0.5rem 0 0 !important;
  padding: 0.25rem 0.68rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #d4d4d4;
  background-color: #fff;
  overflow: hidden;

  font-size: 1rem;
}
.skillListingOne .display-title-input::placeholder {
  color: #7e7e7e;
}

.skillListingOne .dropCss {
  max-width: 43.75rem !important;
  min-height: 2.75rem;
  flex-grow: 0;
  margin: 0.5rem 0 0 !important;
  padding: 0.25rem 0.125rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #d4d4d4;
  background-color: #fff;
  overflow: hidden;
}

.dropCss .ant-select-selection-item {
  font-size: 1rem;
  margin-top: 2px;
}

.skillListingOne .ant-form-item-label > label {
  display: unset !important;
}

.skillListingOne .ant-form-item {
  margin-bottom: 1.25rem;
}

.wrapSalaryDate .ant-form-item {
  margin-bottom: 1.25rem;
}

.wrapSalaryDate .ant-form-item-control-input-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* row-gap: 1.25rem; */
  gap: 1.25rem;
}

.skillListingOnePage .ant-picker-header .ant-picker-month-btn,
.skillListingOnePage .ant-picker-year-btn,
.skillListingOnePage .ant-picker-header-prev-btn,
.skillListingOnePage .ant-picker-header-super-prev-btn,
.skillListingOnePage .ant-picker-header-next-btn,
.skillListingOnePage .ant-picker-header-super-next-btn {
  max-width: 1.6rem;
}

.sl-datepicker {
  /* padding: 0.5rem 0.6rem; */
  padding-right: 0.938rem;
  margin: 0.5rem 0 0;
  width: 21.25rem;
  height: 2.75rem;
  flex-grow: 0;
  /* padding: 0 13rem 0 0; */
  border-radius: 0.25rem;
  border: solid 0.06rem #d4d4d4;
  background-color: #fff;
}
.sl-datepicker-it {
  /* padding: 0.5rem 0.6rem; */
  padding-right: 0.938rem;
  margin: 0.5rem 0 0;
  width: 43.75rem !important;
  height: 2.75rem;
  flex-grow: 0;
  /* padding: 0 13rem 0 0; */
  border-radius: 0.25rem;
  border: solid 0.06rem #d4d4d4;
  background-color: #fff;
}

.sl-datepicker .ant-picker-input {
  flex-direction: row-reverse;
}
.sl-datepicker-it .ant-picker-input {
  flex-direction: row-reverse;
}

.sl-datepicker .ant-picker-suffix {
  border-right: 0.7px solid;
  margin-right: 0.93rem;
  height: 2.625rem;
}
.sl-datepicker-it .ant-picker-suffix {
  border-right: 0.7px solid;
  margin-right: 0.93rem;
  height: 2.625rem;
}

.skillListingOnePage
  .ant-form-item-has-error
  .sl-datepicker
  .ant-picker-suffix {
  border-right: 0.7px solid var(--primaryColor-error);
}
.skillListingOnePage
  .ant-form-item-has-error
  .sl-datepicker-it
  .ant-picker-suffix {
  border-right: 0.7px solid var(--primaryColor-error);
}

.sl-datepicker .ant-picker-suffix img {
  margin-right: 0.93rem;
  /* margin-top: 10px; */
  max-width: 1.25rem;
}
.sl-datepicker-it .ant-picker-suffix img {
  margin-right: 0.93rem;
  /* margin-top: 10px; */
  max-width: 1.25rem;
}

.skillListingTwo {
  display: flex;
  flex-direction: column;
  align-content: space-around;
  padding: 2rem 1.5rem;
}

.skillListingTwo .ant-form-item .ant-form-item-label {
  padding: 0 0 0;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
}

.skillListingTwo .ant-input-group-addon {
  background-color: lightgray;
}

.skillListingOne .finishSkillisting {
  display: flex;
  justify-content: flex-end;
  border-top: 0.06rem solid #e4e4e4;
  padding: 1.25rem 0 0;
  margin-top: 0.7rem;
  cursor: pointer;
}

.skillListingOne .finishSkillisting div {
  width: 12.5rem;
  height: 2.63rem;
  /* margin: 1.25rem 1.88rem 0 10.00rem; */
  /* padding: 0.75rem 4.81rem 0.69rem 4.75rem; */
  border-radius: 0.31rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  background-color: var(--primaryColor);

  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.modalEditSkillListing .ant-modal-close-x {
  display: inline;
}

.modalEditSkillListing .ant-modal-close {
  right: 1.875rem;
  top: 0.375rem;
  width: -moz-fit-content;
  width: fit-content;
}

.modalEditSkillListing .ant-modal-header {
  padding: 1.25rem 1.875rem;
}

.modalEditSkillListing .createSkillistingTitle {
  font-size: 1.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.login-form .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
}

.skillUpdateActive {
  background-color: var(--primaryColor);
}

.skillUpdateDisabled {
  background-color: #88c49b;
  /* pointer-events: none; */
  cursor: not-allowed !important;
  text-decoration: none;
}

.containerStep3 .ant-picker-focused {
  border-color: #fff !important;
}

.inputSalaryCss .ant-input-group {
  display: flex;
}

.skillListingOne .locationDrawerTitle {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.skillListingOnePadding .ant-select-arrow {
  display: none;
}

.skillListing-form .ant-col .ant-form-item-control {
  position: relative;
}

.skillListing-form .ErrorIcon {
  position: absolute;
  top: 1.375rem;
  right: 1.25rem;
  color: #db2828;
}

@media screen and (min-width: 768px) {
  .skillListingTwo {
    margin: auto 30%;
  }
}

@media screen and (max-width: 768px) {
  .skillListingOnePage {
    padding: 10px 15px;
    background-color: #fff;
  }

  .skillListingMargin {
    margin-top: -2.625rem;
  }

  .skillListingOne {
    display: unset;
    width: 100%;
    min-width: 20.63rem;
    margin: unset;
    padding: 0;
    border-radius: 0.25rem;
    border: unset;
    background-color: #fff;
  }

  .skillListingOne .login-form {
    margin-top: 1.25rem !important;
    margin-bottom: 1rem !important;
  }

  .modalEditSkillListing .ant-modal-content {
    min-height: 100vh !important;
  }

  .modalEditSkillListing .ant-modal-close {
    right: 0.938rem;
    top: -3px;
    /* bottom: 1px; */
  }

  .modalEditSkillListing .createSkillistingTitle {
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
  }

  .modalEditSkillListing .cancelUpdate {
    padding: 0;
    flex-direction: column-reverse;
    gap: 0.625rem;
    margin-bottom: 1rem;
  }

  .cancelUpdate .Update {
    width: 100%;
  }

  .cancelUpdate .cancel {
    width: 100%;
  }

  /* .headSkillisting,
  .Sub-headSkillisting {
    display: none;
  } */

  .wrapSalaryDate .ant-form-item-control-input-content {
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    row-gap: 0;
  }

  .skillListingOne .inputSalaryCss {
    margin-bottom: 0.25rem !important;
  }

  .inputLabel .ant-radio-button-wrapper {
    display: inline-block;
    /* height: 1.56rem; */
    font-size: 0.875rem;
    padding-top: 0.125rem;
    /* padding: 0.25rem 0.625rem; */
  }

  .login-form .inputLabel .ant-radio-button-wrapper {
    height: unset;
    padding: 0.1975rem 0.625rem;
    margin-right: 0.9375rem;
  }

  .login-form .inputLabel .ant-radio-button-wrapper span:last-child > div {
    line-height: normal;
  }

  /* .inputLabel .ant-radio-button-wrapper span {
    /* height: 1.56rem; */
  /* padding: 0.25rem 0.625rem; */
  /* } */

  .skillListingOne .finishSkillisting {
    border-top: unset;
    padding: unset;
    margin-top: 1rem;
  }

  .skillListingOne .finishSkillisting div {
    width: 100%;
  }

  .skillListingOne .dropCss {
    /* padding: 0.2rem 0 0; */
    /* height: 2.5rem; */
    overflow: auto;
  }

  .dropCss .ant-select-selection-item {
    font-size: 0.875rem;
  }

  .skillListingOne .dropCss {
    min-height: 2.5rem;
  }

  .inputSalaryCss .ant-input-group .ant-input {
    min-height: 2.5rem;
    font-size: 0.875rem;
  }

  .skillListingOne .placeholderSkillListingForm {
    margin-top: unset;
  }

  .skillListingOne .ant-input-group-addon {
    font-size: 0.875rem;
  }

  .wrapSalaryDate .ant-input-prefix {
    padding: 0.4375rem 0rem;
    margin-right: 0.625rem;
    font-size: 0.875rem;
  }

  .skillListingOnePage
    .ant-form-item-has-error
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    border: none;
  }

  .skillListingOne .inputTitleSkillisting {
    font-size: 0.875rem;
  }

  .skillListingOne .inputBilltoClientLabel {
    margin-top: 1.25rem !important;
    font-size: 0.88rem;
  }

  .skillListingOne .helpAddSkill {
    font-size: 0.75rem;
    width: 19.44rem;
  }

  .skillListingOne .sl-datepicker {
    width: unset;
    margin-bottom: 0.313rem;
  }
  .skillListingOne .sl-datepicker-it {
    width: unset !important;
    margin-bottom: 0.313rem;
  }

  .skillListingOne .inputSalaryCss {
    width: unset;
  }

  .skillListingOnePage .ant-radio-button-checked {
    border-color: unset;
    background-color: #e4ffec !important;
  }

  .skillListingOnePage .ant-radio-button-wrapper {
    background-color: unset !important;
  }

  .skillListingOnePage
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: unset !important;
    /* /*  border-color: #1890ff; */
  }

  .modalEditSkillListing .ant-modal-header {
    padding: 1rem 0.9375rem;
    border-bottom: 1.34px solid #e4e4e4;
  }
  .Sub-headSkillisting {
    font-size: 0.875rem;
    font-weight: normal;
    color: #7e7e7e;
    margin-bottom: 0px;
  }
}

/* @media screen and (max-width: 100px) {
  .container {
    background-color: #ffffff;
  }
  .span {
    display: none;
  }
  .span-bottom {
    display: none;
  }
  .bottom-span {
    display: none;
  }
  .head-text1 {
    display: none;
  }
  .head-text2 {
    display: none;
  }
  .head-text3 {
    display: none;
  }
  .bottom-box-text {
    display: none;
  }
  .button {
    display: none;
  }
  .box-text-top {
    display: none;
  }
  .box-text-middle {
    display: none;
  }
  .box-text-bottom {
    display: none;
  }
  .step3-divider {
    display: none;
  }
}
.span {
  align-self: center;
  margin-top: 1rem;
  width: 19.5rem;
  height: 5.5rem;
  border-radius: 0.5rem;
  background-color: var(--background);
  border: solid 1.5px var(--activeborderColor);
}

.span-bottom {
  align-self: center;
  margin-top: 1rem;
  width: 19.5rem;
  height: 4rem;
  border-radius: 0.5rem;
  background-color: var(--background);
  border: solid 1.5px var(--activeborderColor);
}

.bottom-span {
  align-self: center;
  margin-top: 1rem;
  width: 20rem;
  height: 10rem;
  border-radius: 0.5rem;
  background-color: var(--background);
  border: solid 1.7px var(--borderColor);
  box-shadow: 1px 1px 1px var(--borderColor);
}
.bottom-box-text {
  font-size: 14px;
  margin: 0.8rem 0rem 0rem 4.5rem;
}

.head-text1 {
  font-size: 17px;
  font-weight: 500; */
/* align-self: center; */
/* margin-left: -8rem;
}
.head-text2 {
  margin-top: 1rem;
  font-size: 12px;
  font-weight: normal;
  align-self: center;
  color: #666666;
}

.box-text-top {
  font-size: 16px;
  line-height: 1.5rem;
  font-weight: 500;
  margin: 0.4rem 0rem 0rem 1rem;
}
.box-text-middle {
  font-size: 12px;
  line-height: 1.5rem;
  margin: 0rem 0rem 0rem 1rem;
}
.box-text-bottom {
  font-size: 12px;
  line-height: 1.3rem;
  margin: 0rem 0rem 0rem 1rem;
}

.button {
  background-color: var(--buttonColor);
  align-self: center;
  width: 20rem;
  margin: 1rem 39rem 1rem 39rem;
}
.button:hover {
  background-color: var(--buttonColor);
}

.button:active {
  background-color: var(--buttonColor);
}

.select-before {
  width: 90px;
}

.bottom-span-header {
  font-size: 17px;
  font-weight: 500;
  align-self: center;
  margin-left: -13rem;
  color: #555555;
}

.btn-container {
  display: flex;
  width: 100vw;
  justify-content: space-around;
  font-size: 1rem;
  color: black;
  width: 20rem;
  margin: 0rem 0rem 0rem 1.5rem;
}

.btn-container .bt-default {
  width: 167px;
  color: white;
}

.step3-divider {
  display: flex;
  align-self: center;
  justify-self: center;
  align-items: center;
  width: 20rem;
}

.bottom-span-text {
  display: flex; */
/* width: 100vw; */
/* justify-content: space-around;
  font-size: 0.8rem;
  color: black;
  width: 20rem;
  margin: 1rem 0rem 0rem -2rem;
}

.bst2 {
  width: 167px;
  color: white;
} */

/* step 1 imported CSS */

/* .containerStep3 .ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: 4px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 0.1px;
  height: 65%;
  padding: 1px 0;
  background-color: #d9d9d9;
  transition: background-color 0.3s;
  content: "";
}

.containerStep3
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  border-color: unset;
}

.containerStep3
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  border-color: none;
} */

.rc-virtual-list-scrollbar .rc-virtual-list-scrollbar-thumb {
  background: #d9d9d9 !important;
  width: 4px !important;
  border-radius: 12px !important;
}

.rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-show {
  display: block !important;
}