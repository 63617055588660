.Guest_No_Search_Result_Container {
  margin-top: 0.625rem;
  padding: 5.1875rem 0rem 5.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3.95rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #d4d4d4;
  background-color: #fff;
}

.Guest_No_Search_Result_Icon > img {
  max-width: 15.625rem;
  max-height: 10.2375rem;
  object-fit: contain;
}

.Guest_No_Search_Result_Content {
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
}

.Guest_No_Search_Result_Content > h3 {
  margin-bottom: 0rem;
  font-size: 1.25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

.Guest_No_Search_Result_Content > p {
  margin-bottom: 0rem;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 768px) {
  .Guest_No_Search_Result_Container {
    margin: 0.625rem -0.625rem 0rem;
    padding: 4.375rem 0rem 11.625rem;
    gap: 1.25rem;
    border-radius: none;
    border: none;
    border-bottom: 0.0625rem solid #e4e4e4;;
  }

  .Guest_No_Search_Result_Icon > img {
    max-width: 9.375rem;
    max-height: 6.25rem;
  }

  .Guest_No_Search_Result_Content {
    gap: 0.375rem;
  }

  .Guest_No_Search_Result_Content > h3 {
    font-size: 1rem;
    line-height: 1.5;
    color: #3e3e3e;
  }

  .Guest_No_Search_Result_Content > p {
    font-size: 0.75rem;
    line-height: 1.5;
    color: #3e3e3e;
  }
}
/********** For Mobile View  Media Query Start **********/
