.messages-container {
  /* margin-top: 39px; */
  /* margin-bottom: 49px; */
  margin: 6.25rem 6.25rem 3.063rem 6.25rem;
  padding: 0rem;
  border: 0.063rem solid #5a5a5a;
  /* padding-bottom: 32px; */
  background-color: #FFFFFF;
}

.messages-container .header-dropdown-ManageBids2 {
  padding: 0.938rem 1.25rem !important;
  width: 0rem !important;
  box-shadow: none !important;
  height: 15rem;
  top:9.5rem !important;
  z-index: auto !important;
  
}

.messages-tab-bar-container {
  /* gap: 27%; */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  min-height: 80vh;
}
.conversations {
  border-right: 0.063rem solid black;
  display: flex;
  flex-direction: column;
  min-width: min-content;
  width: 50%;
}
.messages-container .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #e73959;
  font-weight: 500;
}
.manage-bids-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.messages-container .select {
  font-size: 1rem !important;
  color: #1e1e1e !important ;
}

.messages-container .select12 {
  margin-right: 5.5rem;
  cursor: pointer;
  font-size: 1rem !important;
  color: #1e1e1e !important ;
}
.messages-container .message-dropdown-arrow {
  margin-top: 0.563rem;
  width: 1.125rem;
  cursor: pointer;
  height: 1.125rem;

  /* flex-grow: 0; */

  object-fit: contain;
}
.selectorBox {
  /* max-width: 70%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.radioSortReceivedBidsGroup2 .ant-radio-checked .ant-radio-inner {
  border-color: var(--primaryColor);
}
.messages-container .ant-tabs-tab-btn:hover {
  color: #e73959;
}

.messages-container .ant-tabs-ink-bar {
  position: absolute;
  background: #e73959;
  pointer-events: none;
}

.messages-pagination-bar {
  display: flex;
  justify-content: center;
  padding-top: 1.25rem;
  padding-bottom: 0.625rem;
}

.conversationFilter {
  padding: 1.625rem 1.125rem 1.563rem 0.938rem;
  border-bottom: solid 0.063rem #e4e4e4;
  background-color: #fff;
  height: 4.375rem;
}

.conversationHeader {
  padding: 0.813rem 1.125rem 1.563rem 0.938rem;
  border: solid 0.063rem #e4e4e4;
  background-color: #fff;
  height: 4.375rem;
  /* position: absolute; */
  width: -webkit-fill-available;
  /* z-index: 2; */
  /* position: initial; */
}

.filter {
  height: 1.188rem;
  flex-grow: 0;
  font-family: SFProText;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.messages-container .conversationsDetails {
  /* height: max-content; */
  /* display: flex; */
  flex-direction: column;
  height: 80vh;
  position: relative;
  width: -webkit-fill-available;
}
.messages-container
  .conversationsDetails
  .conversationHeader
  .ant-badge-dot.ant-scroll-number {
  transition: background 1.5s;
  margin: 1.25rem 0 0 0;
}
.messages-container .conversationsDetails .conversationHeader .Avat {
  margin: -0.625rem 0 0 0;
}

 

.conversations .inputSelect23 {
  border-radius: 0.25rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.conversations .input-select-dropdown {
  width: 86%;
  height: 2.13rem;
  /* margin: 1.25rem 0 0.63rem 0.31rem; */
  padding: 0 0.63rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;

  position: relative;
}

.inputSelectMenu {
  display: flex;
}

.conversations .inputSelective {
  display: flex;
}

.claritySign12 {
  cursor: pointer;
  margin-top: 0.688rem;
  margin-left: 0.563rem;
}
.searchbar12 {
  cursor: pointer;
  padding: 3px;
  border: solid 1px #e4e4e4;
}

.conversations .input-select-dropdown .input-select {
  display: flex;
  align-items: center;
}

.conversations .selectDropdown {
  display: flex;
  flex-direction: column;
}

.conversations .input-select .search-cred {
  font-size: 1.25rem;
  margin-right: 0.625rem;
  position: relative;
  top: 0.188rem;
}

.conversations .input-select input {
  width: 100%;
  height: 2.13rem;
  border: none;
  background-color: #fff;
  font-size: 1rem;
}

.conversations .input-select input::placeholder {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.conversations .input-select input:focus {
  border: none;
  outline: none;
}

.conversations .input-select .close-search {
  padding-top: 0.313rem;
  margin-left: 0.3rem;
}

.conversations .input-select-dropdown .selectDropdown {
  max-height: 12.67rem;
  width: 100%;
  background-color: #fff;
  border: solid 0.06rem #e4e4e4;
  box-shadow: 0 0 0.4rem 0 rgba(0, 0, 0, 0.25);
  overflow: auto;
  overflow-x: hidden;
  position: absolute;
  z-index: 10;
  right: 0;
  top: 2.755rem;
}

.conversations .selectDropdown span {
  /* width: 36.88rem; */
  height: 1.81rem;
  /* margin: 0 0 0.38rem; */
  padding: 0.38rem 0.63rem;
  cursor: pointer;
}

.conversations .selectDropdown .docSearchName:hover {
  background-color: #e4e4e4;
}

.conversations .selectDropdown .docSearchName-active {
  background-color: #e4e4e4;
}

.conversations .selectDropdown .noDatafoundDocs {
  color: #e4e4e4;
}

.conversations .selectDropdown::-webkit-scrollbar {
  width: 0.438rem;
}

.conversations .selectDropdown::-webkit-scrollbar-track {
  background: rgb(248, 248, 248);
}

.conversations .selectDropdown::-webkit-scrollbar-thumb {
  background: #e4e4e4;
  border-radius: 0.75rem !important;
  cursor: pointer !important;
}

.conversation-inbox-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.conversations .clickable {
  margin-bottom: 0.188rem !important;
  cursor: pointer;
}

.conversations .conversation-threads {
  height: 70vh;
  overflow-y: scroll;
}

.conversations .conversation-threads::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.conversations .conversation-threads::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.conversations .conversation-threads::-webkit-scrollbar-thumb {
  background: #e4e4e4;
}

/* Handle on hover */
.conversations .conversation-threads::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.messages-container .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  left: 1rem !important;
}

.messages-container .conversation-message {
  margin-top: 3.306rem;
  font-size: 1.125rem;
  font-weight: 500;
  color: #3e3e3e;
}

.messages-container .conversation-message-image {
  max-width: 8.275rem;
  max-height: 6.531rem;
  margin-right: 0.875rem;
}

.manageBidsMenuHeader12 {
  padding: 0.938rem 1.25rem;
  height: 17.875rem;
}

.manageBidsMenuHeader12 > div {
  /* height: 1.81rem; */
  display: flex;
  align-items: center;
  cursor: pointer;

  /* font */
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.FilterBidByStatus1{
  padding: 1.344rem 1.25rem 1.344rem 1.25rem;
  background:#FFFFFF;
}
.FilterBidByStatus2{
  padding:1.344rem 1.25rem 1.344rem 1.25rem ;
  background:#FFFFFF;
}

.FilterBidByStatus3{
  padding: 1.344rem 1.25rem 1.375rem 1.25rem;
  background:#FFFFFF;
}
.messages-container  .ant-dropdown {
  border: 1px solid red !important;
}

.manageBidsMenuHeader12 > div::selection {
  color: var(--primaryColor);
}

/* .messages-container  .ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
   
  left: 111.9931px !important;
   
} */

.conversation-txt {
  color: #118936;
}
.hy23 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.placedbidsHeader12 {
  margin-top: 0.625rem;
}

.Ellipse-message-dot123 {
  height: 0.625rem;
  width: 0.625rem;
  border-radius: 50%;
  background-color: #9e9e9e;
  margin-top: 31px;
  margin-left: -6px;
}

/* .messages-container .hello12 .ant-dropdown-menu {
  min-width: 119px !important;
    left: 110.9931px !important;
    top: 157.993px !important;
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
} */

/* .messages-container .ant-dropdown-show-arrow {
  min-width: 119px !important;
    left: 110.9931px !important;
    top: 157.993px !important;
}
   */

.message-filter-options{
  display:flex;
   justify-content: space-between;
   color: #3e3e3e;
}

.message-filter-counts {
  color: #7e7e7e;
}

.FilterBidByStatus{
  padding-left: 1.25rem;
  background-color: #FFFFFF;
  font:1rem;
  color:black;
  font-weight: 600;
}
.messages-container   .radioSortReceivedBidsGroup2{
 
justify-content: flex-start !important;
width: 100%;
}

.message-mobile-inbox .ant-col-12 {
  border: solid 0.063rem #e4e4e4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.125rem;
  color: #4e4e4e;
  font-size: 1rem;
}

.message-mobile-inbox .ant-col-12 span {
  padding-left: 0.625rem;
}

.message-mobile-Conversation .ant-col-24 {
  border: solid 0.063rem #e4e4e4;
  color: #1e1e1e;
  height: 4.063rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
}

.message-mobile-Conversation .ant-col-24 .message-mobile-Conversation-span-2 {
  color: #7e7e7e;
}

.message-mobile-Conversation .ant-col-24 .message-mobile-Conversation-span-1 {
  padding-left: 0.938rem;
}

.message-mobile-Conversation .ant-row {
  background-color: #fff;
}

.message-mobile-inbox{
  background-color: #fff;
}

.message-mobile-noImage-msg {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 31rem;
}

.message-mobile-noImage-msg span {
  font-size: 1.125rem;
  font-weight: 500;
  color: #3e3e3e;
  padding-top: 3.306rem;
}
.text-color-green {
  color: #118936;
}

.message-mobile-searchBar {
  margin-right: 1rem;
  width: 1.75rem;
  height: 1.75rem;
  padding-left: 0.188rem;

  border-radius: 0.25rem;
  border: solid 0.063rem #e4e4e4;
  background-color: #fff;
}

.cilFilter-messages{
  display: flex;
  border-radius: 0.25rem;
  border: solid 0.063rem #e4e4e4;
  padding: 0.25rem;
  background-color: #fff;
  cursor: pointer;
  gap:0.5rem;
}

.cilFilter-messages-count{
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color:darkseagreen;
}


.message-mobile-filter {
  width: 3.063rem;
  height: 1.75rem;
  border-radius: 0.25rem;
  border: solid 0.063rem #e4e4e4;
  background-color: #fff;
  margin-right: 0.625rem;
  padding-left: 0.375rem;
}

.message-mobile-icon-section {
  display: flex;
}

.message-mobile-filter span {
  padding-left: 0.5rem;
  color: #118936;
}

.message-mobile-msg-search {
  display: flex;
  flex-direction: column;
  margin: 1.125rem 3.188rem 1.125rem 1rem;
}

.message-mobile-Drawer .ant-drawer-close {
  top: 0.75rem;
}

.message-mobile-closeSearch {
  padding-top: 0rem;
  margin-left: 0.3rem;
}

.message-mobile-Drawer-msg .ant-divider-horizontal {
  margin: 0rem;
}

.message-mobile-radiofilterMessageGroup{
  /* width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 0.625rem; */
  margin-top: 1.2rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.message-mobile-radiofilterMessageGroup .message-mobile-radiofilterMessage{
  display: flex;
  width: 100%;
  height: 2.75rem;
  /* margin: 1.25rem 0.94rem 0.63rem; */
  padding: 0.6rem 0 0.75rem 0.75rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.15);
  border: solid 0.06rem #d4d4d4;
  background-color: #fff;
}

.message-mobile-radiofilterMessageGroup .ant-space-vertical {
  width: 93%;
}

/* .message-mobile-radiofilterMessageGroup .ant-radio-wrapper{
  min-width: 22.63rem;
} */

.message-mobile-radiofilterMessageGroup .ant-radio-checked .ant-radio-inner {
  border-color: var(--primaryColor);
}

.message-mobile-radiofilterMessageGroup .ant-radio-inner::after {
  background-color: var(--primaryColor);
}

.message-mobile-FilterBidByStatus{
  color: #1e1e1e;
  font-weight: 600;
  padding-left: 0.938rem;
  padding-top: 1.25rem;
  font-size: 0.875rem;
}

.message-mobile-span-text-value {
  display: flex;
  justify-content: space-between;
}

.message-mobile-span-text-value-color  {
  font-size: 0.875rem;
  color: #3e3e3e !important;
}

.mobile-message-Apply-filter {
  padding-top: 1.25rem;
}

.message-mobile-titleBar{
  display: flex;
  width: 58%;
  justify-content: space-between;
}

.message-mobile-titleBar-filter {
  font-size: 1rem;
  font-weight: 500;
  color: #3e3e3e;
}

.message-mobile-titleBar-Clear {
  font-size: 0.875rem;
  font-weight: normal;
  color: #9e9e9e;
}

.message-mobile-length-count{
  color: #5e5e5e !important;
  font-size: 0.875rem;
}

.mobile-message-Drawer-top .ant-drawer-close {
  top: 1.125 !important;
}

.mobile-message-radioShowItemsParent {
  display: flex;
  justify-content: center;
  padding: 0.938rem 1.25rem;
  border-top: 0.063rem solid #e4e4e4;
}

.mobile-message-radioShowItemsChild{
  min-width: 20rem;
  width: 100%;
  height: 2.63rem;
  text-align: center;
  padding: 0.7rem 0;
  border-radius: 0.31rem;
  background-color: #118936;
  cursor: pointer;

  /* font */
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.inner-filter-sort-btns{
  padding: 0rem 1.25rem 1.25rem 2.25rem;
  background: white;
    display: flex;
    justify-content: space-between;
}
 
.inner-filter-sort-btns1{
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  color: #7e7e7e;
  background-color:white;
  padding:2px 4px 2px 4px;
  cursor:pointer;
  display: flex;
  align-items: center;
}
.inner-filter-sort-btns2{
font-size: 12px;
  font-weight:600;
  text-align: center;
  color: white;
  background-color:#118936;
  border-radius: 2px;
  padding:5px 20px 5px 20px;
  cursor:pointer;
}

.conversationFilter-inner{
  display: flex;
  gap: 10px;
}

 

.manage-bids-button-inside{
  display:flex;
  justify-content: flex-end;
  gap: 0.625rem;
}


.radioSortReceivedBidsGroup2 > span:nth-child(2){
  width:100%;

}

.messages-container .header-dropdown-ManageBids2  {
  top:9.5rem !important;
  z-index: auto !important;
  
}
.header-dropdown-ManageBids2 .ant-radio-checked .ant-radio-inner {
  border-color: var(--primaryColor);
}

 .radioSortReceivedBidsGroup2 .ant-radio-inner::after {
  background-color: var(--primaryColor);
}

.radioSortReceivedBidsGroup2 .ant-space.ant-space-vertical {
  gap: 1.125rem !important;
  width: 100%;
}

.radioSortReceivedBidsGroup2 .ant-radio-wrapper {
  width: 100%;
}

.radioSortReceivedBidsGroup2 .ant-radio-wrapper > span:nth-child(2) {
  width: 100%;
}
 
.dropDownMenu{
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 25%);
    background-color: #fff;
    position: relative;
    top: 6px;
    max-width: 22.5rem;
}
.mobile-message-AllClearButton{
  color: #9e9e9e;
  position: absolute;
  top: 0.938rem;
  left: 0.938rem;
}
.message-mobile-radiofilterMessage > span:nth-child(2) {
  width: 100%;
}
.mobile-message-Drawer-top .ant-drawer-title {
  display: flex;
  justify-content: center;
  font-weight: 600;
}
.message-mobile-span-text-value > span:nth-child(1) {
  font-size: 0.875rem;
  color: #3e3e3e !important;
}

.manage-bids-button-inside3 .ant-dropdown .header-dropdown-ManageBids2 .ant-dropdown-placement-bottomRight{
  top:50% !important;
}

@media only screen and (max-width: 768px) {
  .messageMobileDrawer .message-mobile-msg-search {
    margin: 0.9375rem 3.1875rem 1.125rem 0.9375rem;
    position: relative;
  }
  
  .messageMobileDrawer .ant-drawer-header-close-only {
    display: none;
  }
  .messageMobileDrawer .closeDrowerBtn{
    position: absolute;
    top: 0.375rem;
    right: -2.1875rem;
  }

  .messageMobileDrawer .input-select input {
    font-size: 0.875rem;
    margin-left: 0.3125rem;
}

.mobile-message-Drawer-top .anticon {
  position: relative;
  left: 20.398rem;
}
}
 