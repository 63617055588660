.loader {
    color: #118936;
    margin: auto;
    font-size: 35px;
    /* text-align: center; */
    background: transparent;
}
.skeleton {
    padding-left:'20px';
    padding-bottom:'0px';
    margin-bottom:'0px';
    background:'#bb0000';
}
.loader-div {
    background-color: #fff;
    /* background-color: #f8f8f8; */
    /* padding: 5%; */
    margin: auto;
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
    /* margin: 5vh 0; */
    color: #118936;
}