.TalentEmployerPage {
  padding: 3.4375rem 3.75rem 4.4375rem;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TalentEmployer {
  padding: 3.4375rem 3.75rem 6rem 3.4375rem;
  border-radius: 0.25rem;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.1);
}

.subHeaderTL {
  margin: 1rem 1.75rem 1.25rem 0;
}

.subHeaderTL > * {
  font-size: 0.9375rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.subHeaderTL .subHeaderTLBold {
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0.5rem 0 0.3rem;
}
.subHeaderTLBold .logo-class {
  height: 30px;
}

.chooseTalentorNot {
  display: flex;
  grid-gap: 1.25rem;
  gap: 1.25rem;
}

.chooseTalentorNot img {
  width: 98.04%;
  height: 100%;
  object-fit: contain;
}

.img-talent {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.becomeACandidateBOX,
.findACandidateBOX {
  /* overflow: hidden;
  max-width: 16.25rem;
  max-height: 13.4375rem;
  position: relative;
  border-radius: 0.3125rem;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  cursor: pointer; */
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 15.1rem;
  flex-grow: 0;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

/* .becomeACandidateBOX > img,
.findACandidateBOX > img {
  object-fit: fill;
  width: 16.625rem;
  height: 13.4375rem;
  margin: -0.1875rem 0rem 0rem -0.1875rem;
} */
.becomeACandidateBOX .becomeACandidate {
  width: 98%;
  height: 3.5rem;
  padding: 0.85rem 0 0;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  position: absolute;
  top: 150px;
}

.findACandidateBOX .findACandidate {
  width: 98%;
  height: 3.5rem;
  padding: 0.85rem 0 0;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  position: absolute;
  top: 150px;
}

/* .becomeACandidate .findACandidate {
  background-color: rebeccapurple;
  height: 3.5rem;
  flex-grow: 0;
  padding: 0.85rem 0 0;
  box-shadow: 0 0 4px 0 rgba(0,0,0,.25);
  background-color: #fff;
  text-align: center;
  position: absolute;
  margin-top: 21px;
} */
.title_div {
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: -2%;
}

.title {
  font-size: 14px !important;
  font-weight: 600 !important;
  align-self: center;
  color: #1e1e1e;
}

.sub_title {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  color: #3e3e3e;
  text-align: center;
}

/* .becomeACandidateBOX .becomeACandidate,
.findACandidateBOX .findACandidate {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  padding: 0.875rem 0rem;
  position: absolute;
  background-color: white;
  top: 8.76rem;
  border-radius: 0 0 4px 4px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e1e1e;
} */

@media screen and (max-width: 768px) {
  .TalentEmployerPage {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0%;
  }
  .TalentEmployer {
    /* margin-top: -3.25rem;
    padding: 3.4375rem 3.75rem 4.4375rem; 
    box-shadow: none; */
    width: 100%;
    margin: unset;
    bottom: 0;
    padding: 0 0 4rem;
    flex-direction: row;
    align-self: flex-end;
  }

  .chooseTalentorNot {
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
  }

  .becomeACandidateBOX {
    width: 23rem;
    margin: 0;
  }
  .becomeACandidateBOX .becomeACandidate {
    /* display: flex;
    align-items: center;
    justify-content: center;
    width: 22.5rem;
    height: 3.6rem;
    padding: 0.4rem 0 0;
    top: 11.7rem; */
    grid-gap: 0.4375rem;
    gap: 0.4375rem;
    margin-top: 40px;
    width: 96%;
  }
  .title_div {
    height: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .findACandidateBOX {
    margin: 1rem 0 0;
  }
  .findACandidateBOX .findACandidate {
    /* display: flex;
  align-items: center;
  justify-content: center;
  width: 22.5rem;
  height: 3.6rem;
  padding: 0.4rem 0 0;
  top: 11.7rem; */
    grid-gap: 0.4375rem;
    gap: 0.4375rem;
    width: 100%;
    margin-top: 40px;
  }
  /* .sectionTL {
    width: 23rem !important;
  } */

  .headerTL {
    padding: 0 1rem;
  }

  .TalentEmployer .logoTL > img {
    max-height: 1.5625rem;
    min-width: 6.25rem;
    object-fit: cover;
  }

  .subHeaderTL {
    margin: 1.0625rem 0 2.0625rem;
  }

  .subHeaderTL > *,
  .subHeaderTL .subHeaderTLBold {
    /* font-size: 0.875rem; */
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin: 0.5rem 0 0.3rem;
  }
  .subHeaderTL .subHeaderTLBold-heading {
    font-size: 14px;
    font-weight: 400;
  }
  .becomeACandidateBOX .becomeACandidate {
    /* display: flex;
    align-items: center;
    justify-content: center;
    width: 22.5rem;
    height: 3.6rem;
    padding: 0.4rem 0 0;
    top: 11.7rem; */
    grid-gap: 0.4375rem;
    gap: 0.4375rem;
    margin-top: 40px;
    width: 96%;
  }
  .title_div {
    height: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .findACandidateBOX {
    min-width: 22rem;
    min-height: 15.75rem;
  }

  .becomeACandidateBOX > img {
    width: 22.5rem;
    height: 16rem;
  }
  .findACandidateBOX > img {
    width: 22.5rem;
    height: 16rem;
    /* margin: -0.3125rem 0rem 0rem -0.3125rem; */
  }

  /* .becomeACandidateBOX .becomeACandidate,
  .findACandidateBOX .findACandidate {
    gap: 0.4375rem;
    padding: 0.75rem 0rem;
  } */

  .title {
    font-size: 0.75rem;
  }

  .becomeACandidateBOX > img {
    width: 96%;
    height: 30%;
  }
  .findACandidateBOX > img {
    width: 100%;
    height: 30%;
    /* margin: -0.3125rem 0rem 0rem -0.3125rem; */
  }

  /* .becomeACandidateBOX .becomeACandidate,
  .findACandidateBOX .findACandidate {
    gap: 0.4375rem;
    padding: 0.75rem 0rem;
  } */

  .title {
    font-size: 0.75rem;
  }
}
