.profile-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1.875rem;
  justify-content: center;
  padding: 2.5rem 0;
  /* padding: 2.5rem 6.25rem; */
}

.profile-container .iconColorED {
  color: #7e7e7e;
}

.profile-container .title {
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.profile-container .add-title {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--primaryColor-blue);
  cursor: pointer;
}

.add-edit-box .add-disable {
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25) !important;
  background-color: #88c49b !important;
  cursor: not-allowed;
}

.profile-right {
  max-width: 45rem;
}

.left-col-card-2 {
  width: 21.25rem;
  /* height: 41.81rem; */
  flex-grow: 0;
  margin: 1.25rem 0 0;
  padding: 1.88rem 0.94rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
}

.dividers {
  height: 0.06rem;
  margin: 1.875rem 0;
  background-color: #e4e4e4;
}

.profile-container .linkedAccountsTitle {
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  margin-bottom: 15px;
}

.profile-container .linkedAccounts {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.profile-container .socialA > span {
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.profile-container .socialActive span {
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.profile-container .editDelete {
  width: unset;
  gap: 0.625rem;
}

.profile-container .add-edit-profile-website {
  display: none;
}

.profile-container .about-skillListing {
  display: none;
}

.skilllisting-panel-page .viewAll-p {
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #7e7e7e;
}

.profile-container .skilllisting-title-counts .title {
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .divider-line-skill-listing {
    height: 0.06rem;
    /* margin: 1.875rem 0; */
    background-color: #e4e4e4;
  }

  .profile-container {
    flex-direction: column;
    padding: 0;
    gap: 0;
  }

  .profile-container .divider-line {
    width: 100%;
  }

  .skilllisting-panel-page {
    width: 100% !important;
    background-color: white;
    padding: 0 15px;
  }

  .skilllisting-panel {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .left-col-card {
    width: unset !important;
    padding: 1.88rem 0.94rem 0 !important;
    border: none;
  }

  .left-col-card-2 {
    width: unset;
    margin: unset;
    padding: 0.94rem;
    border: none;
  }

  .profile-avatar {
    width: 6.25rem !important;
    height: 6.25rem !important;
    min-width: unset !important;
    min-height: unset !important;
  }

  .profile-container .add-edit-profile-website {
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #6e6e6e;
    display: block;
  }

  .profile-dtl-btns {
    width: 100% !important;
    gap: 10px;
  }

  .profile-dtl-btns span {
    justify-content: center !important;
  }

  .bt-3d-resume {
    width: 100% !important;
  }

  .basic-profile-dtls {
    width: 100%;
  }

  .basic-item {
    width: 100% !important;
  }

  .profile-container .about-skillListing {
    display: flex;
    width: 100%;
    margin-top: 1.25rem;
    gap: 2.5rem;
  }

  .about-skillListing .about-p {
    font-size: 0.75rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--primaryColor);
    border-bottom: 0.13rem solid var(--primaryColor);
    padding-bottom: 0.5rem;
  }

  .about-skillListing .skillListing-p {
    font-size: 0.75rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #7e7e7e;
  }

  /* .profile-container .add-title {
    display: none;
  } */

  /* .career-summary .user-description {
    border-bottom: 0.06rem solid #e4e4e4;
  } */

  .user-description p {
    margin-bottom: 1.813rem;
  }

  .profile-container .socialActive span {
    font-size: 1rem;
    font-weight: 600;
  }

  /* .profile-container .editDelete {
    display: none;
  } */

  .profile-container .basic-item .addLocation {
    color: #7e7e7e !important;
  }

  .profile-container .skilllisting-panel .create-skilllisting-tab {
    display: none !important;
  }

  .profile-container .skilllisting-panel-page .skilllisting-title-counts {
    padding: 1.25rem 0;
    margin-bottom: 0 !important;
    justify-content: space-between;
    gap: unset;
    align-items: baseline;
  }

  .profile-container .skillisting-card-outer-box .dropdown-title-skilllisting {
    align-items: baseline;
  }

  .profile-container .skillisting-card-outer-box {
    width: 100%;
  }

  .profile-container .card-inner-box {
    width: 100% !important;
    height: auto !important;
  }
}
