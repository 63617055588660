.timeContainer{
    
    /* border-bottom: 2px solid #e4e4e4; */
     /*background-color:turquoise;*/
    height: 100%;
    margin-top: 20px;
    align-items: center;
}
/* .dateTimeContainer{*/
/*    background-color: red;*/

/*}*/
.listing-line{
    width: 100%;
    height: 1px;
    background-color: #e4e4e4;
}

.dateTime{
    font-size: 14px;
    text-align: left;
    /* margin: 0 27px 15px 0; */
    color: #1e1e1e;
    
}

.date{
    margin: 0 27px 15px 0;
    margin-right: 0;
    /* background: blueviolet; */
}
.request-tag{
    padding-bottom: 17px;
}
.request-tag .ant-tag{
    font-size: 14px;
    color: #3e3e3e;
}
.time{
    line-height: 5px;
    /* background: brown; */
}

.timeButton{

    width: 40%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;

    /* width: 200px;
    height: 40px; */
    /* flex-grow: 0; */
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    padding: 9px 42px 9px 43px;
    border-radius: 4px;
    background-color: #4054b2;
    border-color: #4054b2;

}
.timeButton{
    color: #fff;
    background-color: #4054b2;
}
.timeButton:hover {
    color: #fff;
    text-decoration: none;
    background-color: #4054b2;
}
.timeButton:not([disabled]):hover {
    color: #fff;
    text-decoration: none;
    background-color: #4054b2;
}

.timeButton:focus{
    color: #fff;
    text-decoration: none;
    background-color: #4054b2;
}

.startButton{
    padding: 9px 42px 9px 43px;
}

.buttonContainer{
    display: flex;
    height: 100%;
    width: 90%;
    flex-direction: row;
    /* margin: 5% 16%; */
    /* background-color: turquoise; */
    align-items: center;
    justify-content: end;
}

.endBtn{
    margin-left: 2%;
}
.hoursContainer{
    /* display: flex; */
    height: 100%;
    justify-content: center;
    /* background-color:violet; */
    align-items: center;
    text-align: left;

}
.hoursText{
    /* margin: 5% 20% ; */
    /* background-color: turquoise; */
    /*  */
    /* display: flex; */
    /* flex-direction: row; */
    /* margin-top: 38px; */
    text-align: right;
    /* justify-content: end; */
    /* padding-left: 15%; */
    font-size: 18px;
    /* padding: 9px 42px 9px 43px; */
    /* width: fit-content; */
    color:#1e1e1e;
}
.hoursText2{
    font-size: 18px;
    text-align: left;
    padding-left: 5px;
    color:#7e7e7e;
    /* margin-top: 41px; */
}
.date{
    height: 21px;
    flex-grow: 0;
    font-family: SFProText;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1e1e1e;
}

@media screen and (max-width: 480px) {
    .timeButton {
        display: flex;
        font-size: 12px;
        font-weight: 600;
        width: 3.5rem;
        height: 1.6rem;
        border-radius: 2px;
    }
    .timeButton{
        color: #fff;
        background-color: #4054b2;
    }
    
    .buttonContainer{
        display: flex;
        justify-content: left;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .startButton{
        padding: 5px 50px 5px 50px;
    }
    .pauseResumeButton, .endBtn{
        padding: 5px 40px 5px 40px;
    }
    .hoursText{
        font-size: 14px;
        font-family: Roboto;
    }   
    .hoursText2{
        font-size: 12px;
        font-family: Roboto;
        margin-top: 4px;
    }

    .request-tag .ant-tag{
        font-size: 12px;
    }

    .hoursContainer{
        justify-content: left;
    }

    .date{
        margin: 0 0px 15px 0;
        /* background: blueviolet; */
    }

    .listing-line{
        position: absolute;
        left: 0;
    }
    
}