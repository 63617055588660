.interestedSection-about-job {
  padding: 0 1.25rem 1.25rem;
  display: flex;
  flex-direction: column;
}

.Rescheduled{
  flex-direction: column-reverse !important;
}
.Accepted-Button-Hidden {
  display: none;
}
.interestedSection-about-job .interested-about-job {
  /* width: 22.5rem; */
  height: 2.63rem;
  flex-grow: 0;
  margin: 0rem 0 0.63rem;
  padding: 0.81rem 0 0.75rem;
  border-radius: 0.25rem;
  background-color: var(--primaryColor);
  text-align: center;
  cursor: pointer;

  /* font */
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.interestedSection-about-job .notInterested-about-job {
  /* width: 22.50rem; */
  height: 2.63rem;
  flex-grow: 0;
  margin: 0rem 0 0;
  padding: 0.7rem 0 0.8rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
  text-align: center;
  cursor: pointer;

  /* font */
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7e7e7e;
}

.interestedSection-about-job-disable {
  padding: 0 1.25rem 1.25rem;
}

.disabledAccepted {
  color: #7e7e7e !important;
  cursor: pointer !important;
}

.interestedSection-about-job-disable .interested-about-job {
  /* width: 22.5rem; */
  height: 2.63rem;
  flex-grow: 0;
  margin: 1.25rem 0 0.63rem;
  padding: 0.81rem 0 0.75rem;
  border-radius: 0.25rem;
  background-color: #e4e4e4;
  text-align: center;
  cursor: not-allowed;

  /* font */
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #c4c4c4;
}

.interestedSection-about-job-disable .notInterested-about-job {
  /* width: 22.50rem; */
  height: 2.63rem;
  flex-grow: 0;
  margin: 0.63rem 0 0;
  padding: 0.7rem 0 0.8rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;
  text-align: center;
  cursor: not-allowed;

  /* font */
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #c4c4c4;
}

.screening-status {
  /* width: 25.00rem; */
  height: 3.75rem;
  flex-grow: 0;
  margin: 0rem 0 1.25rem;
  padding: 0.63rem 0;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #ffba08;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.screening-status .spanOne {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.screening-status .spanTwo {
  font-size: 0.88rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #1e1e1e;
  display: flex;
  align-items: center;
  gap: 8px;
}

.screening-status-disable {
  /* width: 25.00rem; */
  height: 3.75rem;
  flex-grow: 0;
  margin: 0rem 0 1.25rem;
  padding: 0.63rem 0;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #e4e4e4;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.Show-1 {
  background-color: #eeeeee !important;
}

.screening-status-disable .spanOne {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.screening-status-disable .spanTwo {
  font-size: 0.88rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #7e7e7e;
  display: flex;
  align-items: center;
  gap: 8px;
}

.Talent-Stage-InterviewSelecter {
  color: #3e3e3e;
  font-size: 1rem;
  font-weight: bold;
  padding: 0 0 0 0.938rem;
  position: relative;
  top: 0.75rem;
  display: flex;
  align-items: center;
}

.bid-decline-headline-talent-stage {
  font-size: 0.875rem;
  margin-bottom: 1.25rem;
}

.talent-stages-button {
  margin-top: 1.25rem !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  font-size: 0.875rem !important;
}

.Talent-Stage-DetailPage-Modal .ant-modal-title {
  font-weight: 600;
  color: #3e3e3e;
}

.Talent-Stage-DetailPage-Modal .ant-modal-header {
  border-bottom: 0.1rem solid #e4e4e4;
}

.bid-decline-headline-rechedule {
  padding: 0px 1rem 0px 0.2rem;
  text-align: center;
}

.talent-stage-upload {
  border-radius: 0.25rem !important;
}

.bid-decline-button-talent-stages{
  cursor: pointer !important;
  margin-top: 0.313rem !important;
}

.ErrorMessage{
  color: #db2828;
  padding: 0.75rem .125rem 1.875rem 1.25rem;
}

.notInterested-about-job-margin {
  margin-top: 0.625rem !important;
}

.bid-decline-headline-rechedule-talent-stages2{
  margin: 0 0.5rem 0 0.563rem;
  margin-bottom: unset !important;
  padding-bottom: 1.87rem;
  text-align: center;
}

.bid-decline-headline-text-center{
  text-align: center;
  padding-right: 1.813rem;
}

.bid-decline-headline-rechedule-talent-stages {
  margin-left: 0.25rem;
  padding-bottom: .625rem;
}

.Talent-Stage-DetailPage-Modal .ant-picker-input>input:placeholder-shown {
    text-overflow: ellipsis;
    margin-left: 2rem;
  }

.Talent-Stage-DetailPage-Modal .ant-picker {
  padding: 0px 0.688rem 0px;
  height: 2.125rem;
  border: solid 0.063rem #e4e4e4;
  border-radius: 0.25rem;
}

.Talent-Stage-DetailPage-Modal .ant-picker-suffix {
  border-right: 0.063rem solid #e4e4e4;
}

.Talent-Stage-DetailPage-Modal .ant-picker-suffix img {
  padding-right: 0.563rem;
}

.Talent-Stage-DetailPage-Modal .ant-picker-input>input {
  padding-left: 0.688rem;
}

.interested-job-extraSpace{
  margin-top: 0px !important;
}

.declinedBidsModal .ant-modal-close-x {
  font-size: 0.875rem;
  margin-top: -0.125rem !important;
}

.Talent-Stage-DetailPage-Modal .ant-modal-close-x {
  font-size: 0.875rem;
  margin-top: -0.125rem !important;
}

@media screen and (max-width: 768px) {
  .screening-status {
    margin: 0px;
  }

  .notInterested-about-job-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
    font-size: 0.875rem;
    color: #7e7e7e;
    background-color: #fff;
    min-height: 2.875rem;
  }

  .MobileDisable {
    color: #c4c4c4 !important;
  }

  .bid-decline-headline-rechedule-talent-stages {
    padding: 1px 2.625rem 0px 2.625rem;
    text-align: center;
    margin-bottom: 0.75rem !important;
  }

  .bid-decline-headline-rechedule-talent-stages2 {
    padding: 1px 0.875rem 0px 1rem;
    text-align: center;
    margin-bottom: 1.25rem !important;
  }

  .Talent-Stage-DetailPage-Modal .ant-picker {
    padding: 0px 0.688rem 0px;
  }

  .Talent-Stage-DetailPage-Modal .ant-picker-input>input:placeholder-shown {
    text-overflow: ellipsis;
    margin-left: 2rem;
  }

  .bid-decline-button-talent-stages {
    margin-top: 1rem !important;
  }
}