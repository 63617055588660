.SkillListingTabs-css {
  display: flex;
  width: 100%;
  background-color: #ffffff;
  flex-direction: column;
  padding: 15px 0;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
}

.SkillListingTabs-css .section-title-sl {
  display: flex;
  align-items: center;
  height: 2.25rem;
}

.SkillListingTabs-css .selectedd {
  background-color: #f8f8f8;

  /* text */
  font-weight: 600;
}

.SkillListingTabs-css .selectedd {
  background-color: #f8f8f8 !important;
}

.SkillListingTabs-css .section-title-sl {
  background-color: #ffffff;
  cursor: pointer;

  font-size: 1rem;
  color: #1e1e1e;
}

.SkillListingTabs-css .section-title-sl:hover {
  background-color: #f8f8f8;
}

.SkillListingTabs-css .bar-section-sl:hover {
  background-color: #f8f8f8;
}

.SkillListingTabs-css .selectedd .bar-section-sl {
  background-color: var(--primaryColor) !important;
}

.SkillListingTabs-css .bar-section-sl {
  margin-right: 12.2px;
  width: 0.13rem;
  height: 100%;
}
