.report-container{
    margin: auto;
    width: auto;
    margin-top: 1%;
    /* background-color: #4054b2; */
    /* padding: 5px; */
}
.present-div{
    background-color: #6C6CE5;
    border-radius: 4px;
    text-align: center;
    color: white;
    /* padding: 16px 75px 14px;
    margin: 20px 10px 20px 80px; */
    /* padding: 16px; */
    padding: 0.9rem 0;
    /* width: 55%; */
    width: 250px;
    height: 90px;
    /* margin:  20px 40px;
    flex-grow: 0; */
}
.absent-div{
    background-color: #CA69DA;
    border-radius: 4px;
    text-align: center;
    color: white;
    /* padding: 16px 75px 14px; */
    /* padding: 16px; */
    padding: 0.9rem 0;
    /* width:10%; */
    width: 250px;
    height: 90px;
}
.Overtime-div{
    background-color: #F36F72;
    border-radius: 4px;
    text-align: center;
    color: white;
    width: 250px;
    height: 90px;
    /* padding: 16px ; */
    padding: 0.9rem 0;
}

.earning-div{
    background-color: #4EC0B2;
    border-radius: 4px;
    text-align: center;
    color: white;
    /* padding: 16px ; */
    padding: 0.9rem 0;
    width: 250px;
    height: 90px;
}
.Report-container-divs h2{
    color: white;
}
.Report-container-divs{
    margin-top: 3%;
    /* margin-bottom: 3%; */
}
.chart-container{
    padding: 15px;
    /* background-color: blue; */
    border: solid 1px #e4e4e4;
    margin-top: 2%;
    border-radius: 4px;
}
.present-circle{
    /* border-radius: 1rem;
    width: 2%; */
    border-radius: 50%;
    width: 1.5%;
    background-color: #4ec0b2;
    height: 20px;
    margin-right: 1%;
    margin-top: 3px;
}
.Overtime-circle{
    /* border-radius: 1rem;
    width: 2%; */
    border-radius: 50%;
    width: 1.5%;
    background-color: #4054b2;
    height: 20px;
    margin-right: 1%;
    margin-top: 3px;
}
.absent-circle{
    margin-top: 3px;
    /* border-radius: 1rem;
    width: 2%; */
    border-radius: 50%;
    width: 1.5%;
    background-color: #d4d4d4;
    height: 20px;
    margin-right: 1%;
}
.Report-details{
    font-family: SFProText;
    font-size: 18px;
    color: #1e1e1e;
  /* width: 151px; */
  /* height: 21px;
  margin: 0 0 0 20px; */
}
.div-detail{
    font-size: 16px;
    color: #fff;
    font-family: SFProText;
  height: 19px;
  line-height: 3.5;
  /* margin: 10px 0 0; */
}
.reporttext{
    font-size: 1.6rem;
    color: #fff;
    font-family: SFProText;
  height: 19px;
  line-height: 1.3;
  /* margin: 10px 0 0; */
}
.select-date .ant-select-selector{
    border: solid 1px #e4e4e4 !important;
    border-radius: 4px !important;
}
@media only screen and (max-width:414px){
    .present-circle{
        height: 18px;
        width: 6%;
        margin-top: 1%;
        margin-right: 3%;
    }
    .Overtime-circle{
        height: 18px;
        width: 6%;
        margin-top: 1%;
        margin-right: 3%;
    }
    .absent-circle{
        height: 18px;
        width: 6%;
        margin-top: 1%;
        margin-right: 3%;
    }
}
/* @media only screen and (max-width:1000px){
    .present-circle{
        height: 18px;
        width: 3%;
        margin-top: 1%;
        margin-right: 3%;
    }
    .Overtime-circle{
        height: 18px;
        width: 3%;
        margin-top: 1%;
        margin-right: 3%;
    }
    .absent-circle{
        height: 18px;
        width: 3%;
        margin-top: 1%;
        margin-right: 3%;
    }
} */
.Graph-detail-container{
    padding: 7px;
}
@media only screen and (max-width:612px){
    .Overtime-div{
        margin-top: 5%;
    }
}
@media only screen and (max-width:612px){
    .earning-div{
        margin-top: 5%;
        margin-left: 2%;
    }
    .absent-div{
        margin-left: 2%;
    }
}
.bids-spin{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 200px;
}

 .custom-select-datepicker .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner{
    background-color : var(--primaryColor);
}   

.custom-select-datepicker .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
    border : 1px solid var(--primaryColor);
}

.custom-select-datepicker .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
    background-color : var(--primaryColor);
}
 
.custom-select-datepicker .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after{
    border-top : 1px dashed #18c04d !important;
    border-bottom: 1px dashed #18c04d !important;
}

.custom-select-datepicker .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after{
    background-color : rgba(17, 137, 54, 0.1);
}

.custom-select-datepicker .ant-picker-cell-in-view.ant-picker-cell-in-range::before{
    background-color : rgba(17, 137, 54, 0.1);
}

.custom-select-datepicker .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background-color : rgba(17, 137, 54, 0.1);
}

.custom-select-datepicker .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before{
    background-color: rgba(17, 137, 54, 0.1) !important;
}

.custom-select-datepicker .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before{
    background-color: rgba(17, 137, 54, 0.1) !important;
}

.custom-select-datepicker .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after{
    background: rgba(17, 137, 54, 0.1)
}

.custom-select-datepicker .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after{
    border-right: 1px dashed #18c04d !important;
}

.custom-select-datepicker .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after{
    border-top: 1px dashed #18c04d !important;
    border-bottom: 1px dashed #18c04d !important;
}

.custom-select-datepicker .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after{
    border-right: 1px dashed #18c04d;
}

.custom-datepicker .ant-picker-active-bar{
    background-color: #18c04d !important;
}

.report-datepicker .ant-picker-input{
    flex-direction: row-reverse;
}
.report-datepicker .ant-picker-suffix{
    margin-right: 5px;
    margin-left: 0px;
}

.custom-datepicker .ant-picker-focused, .ant-picker:hover{
    border-color : none !important;
}

.report-container .ant-picker .ant-picker-range .custom-datepicker:hover {
    border-color : white !important;
}

/* .custom-datepicker .ant-picker-range-separator{
    padding: 0;
} */

.custom-datepicker .ant-picker-input > input{
    color: #9e9e9e;
}

.report-datepicker, .custom-datepicker{
    width: 15rem;
}

.custom-datepicker{
    padding-left:7%;
}

@media screen and (max-width: 768px) {

    .report-datepicker, .custom-datepicker{
        width: 100%;
    }

    .custom-datepicker{
        padding-left:15% !important;
    }

    .custom-select-datepicker .ant-picker-panel-container .ant-picker-panels{
        flex-direction: column;
    }

    .Report-details{
        font-size: 14px;
    }

    .Report-container-divs{
        margin-top: 7%;
        margin-bottom: 7%;
    }
}
