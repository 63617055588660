.reviewPlz {
  font-size: 0.6rem;
  padding: 0.6rem 0 0;
  font-style: italic;
  color: var(--primaryColor-grey);
}

.interviewCard {
  display: flex;
  flex-direction: row;
}

.interviewCard > div {
  width: 21vw;
}

.interviewCard .interestedBtn {
  justify-content: center;
}

.interviewCard .infoRight {
  padding: 0 0 0 1rem;
}

.interviewCard .infoLeft {
  width: 5.5rem;
}

@media screen and (max-width: 700px) {
  .interviewCard > div {
    width: 88vw;
  }
}
