.expired-gigs-container{
    display: flex;
    flex-direction: column;
    border-radius: 0.25rem;
    border: solid 0.0625rem #ffcdcd;
    background-color: #feefef;
    margin-top: 20px;
    padding: 0.9375rem 0rem 0.9375rem 0.9375rem;
}
.expired-gigs-items{
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.expired-gigs-items-text{
    padding-bottom: 0.9375rem;
}

@media screen and (max-width: 768px) {
    .expired-gigs-container{
        width: 91%;
        margin: 0.3125rem 0.75rem;
    }
    .expired-gigs-items{
        font-size: 0.875rem;
    }
}