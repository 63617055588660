.chat-msg{
    background-color: #F3F3F3;
    padding-left: 10px;
    border-radius: 5px;
    /* padding-top: -15px; */
}
.chat-msg .para{
    color: rgba(0,0,0,.87);
    font-size: 12px;

}
.chat-reply{
    border: 1px solid #efefef;
    padding-left: 10px;
    border-radius: 5px;
}
.chat-reply .para{
    color: rgba(0,0,0,.87);
    font-size: 12px;
}
.span{
    font-size: 11px;
    color: #8b8a8a;
}

.sender-msg-container .sender-msg{
    margin-bottom: 20px;
}
@media only screen and (min-width:780px){
    .sender-msg-container{
        /* margin-left: 35%; */
        margin-left: 35%;
        /* width: 100%; */
}
}
@media only screen and (min-width:780px) {
    .receiver-msg-conatainer{
        margin-right: 35%;
    }  
}
.chat-msg b{
    color: black;
}
.chat-reply b:hover{
    color:#1E9CDA;
}
.chat-reply b{
    color:black;
}
.chat-msg b:hover{
    color: #1E9CDA;
}
.msg-attachment-container{
    background-color: #F8F8F8;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.30);
    border-radius: 5px;
    padding: 3px;
    padding-left: 12px;
    margin-bottom: 6px;
    margin-top: 6px;
}
.download-icon{
    /* float: right; */
    margin-left: 88%;
    font-size: large;
}
.msg-attachment-container .span{
    color:rgba(0,0,0,.4);
}