.logoProfile {
  width: 2rem;
  margin: 0.5rem 0.5rem 0.5rem 0;
}
.header-dropdown-profile-text {
  text-align: left;
  overflow: auto;
  /* overflow-y: scroll; */
  height: 100%;
  padding-right: 1rem;
}

.header-dropdown-profile-text::-webkit-scrollbar {
  width: 7px;
}

.header-dropdown-profile-text::-webkit-scrollbar-track {
  background: #fff;
}

.header-dropdown-profile-text::-webkit-scrollbar-thumb {
  background: #e4e4e4;
  border-radius: 12px !important;
  cursor: pointer !important;
}

.resume-credentialing {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: var(--primaryColor) !important;
}

.dropdown-profile-main > div {
  padding: 0.1rem 0;
  cursor: pointer;
  font-size: 16px;
  height: 2.44rem;
  display: flex;
  align-items: center;

  /* font */
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e;
  display: flex;
  justify-content: space-between;
}

.Dropdown-interview-text {
  display: flex;
  justify-content: space-between;
}

.Dropdown-interview-text .dropdown-interview-counts {
  width: 1.88rem;
  height: 1.25rem;
  flex-grow: 0;
  border-radius: 0.75rem;
  background-color: rgba(64, 84, 178, 0.2);

  display: grid;
  place-items: center;

  /* font */
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4054b2;
}

.dropdown-my-profile {
  margin-top: 1.25rem;
}

.dropdown-my-profile h4 {
  margin-bottom: 0.56rem;

  /* font */
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e3e3e;
}

.myEarningsDropdown {
  margin-top: 1.25rem;
}

.myEarningsDropdown h4 {
  margin-bottom: 0.56rem;

  /* font */
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e3e3e;
}

.dropdown-general-text {
  margin-top: 1.25rem;
}

.dropdown-general-text h4 {
  margin-bottom: 0.56rem;

  /* font */
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e3e3e;
}

.dropdown-my-dashboard-text {
  padding: 0.5rem 0;
  padding-bottom: 0rem;
}
.dropdown-my-dashboard-text > div {
  padding: 0.1rem 0;
  cursor: pointer;
  font-size: 16px;
  height: 2.44rem;
  display: flex;
  align-items: center;

  /* font */
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e;
}
.bidDetail {
  padding: 0.5rem 0.9rem;
}
.bidDetail > div {
  /* padding: 0.2rem 0; */
  cursor: pointer;
  font-size: 16px;
}
.bidDetail > div:hover {
  background-color: rgb(241, 241, 241);
  border-radius: 5px;
}
.profileDropThird {
  padding-top: 0.5rem;
}
/* .refer {
  font-size: 16px;
  font-weight: 500;
  color: #118936;
}
.refer > div:hover {
  background-color: rgb(241, 241, 241);
  border-radius: 5px;
} */
.profileDropThird > div {
  padding: 0.1rem 0;
  cursor: pointer;
  font-size: 16px;
  height: 2.44rem;
  display: flex;
  align-items: center;

  /* font */
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e;
}
.dropdown-logout-text {
  padding-top: 0.5rem;
}
.dropdown-logout-text > div {
  padding: 0.1rem 0;
  cursor: pointer;
  font-size: 16px;
  height: 2.44rem;
  display: flex;
  align-items: center;

  /* font */
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e;
}
.dropdown-profile-main > div:hover {
  color: var(--primaryColor);
  border-radius: 5px;
}
.dropdown-my-dashboard-text > div:hover {
  color: var(--primaryColor);
  border-radius: 5px;
}
.profileDropThird > div:hover {
  color: var(--primaryColor);
  border-radius: 5px;
}
.dropdown-logout-text > div:hover {
  color: var(--primaryColor);
  border-radius: 5px;
}
.dropdown-profile-divider {
  background-color: #e4e4e4;
  height: 0.06rem;
}

.dropdown-my-dashboard-text .ant-row {
  justify-content: space-between;
}

.dropdown-my-dashboard-text .arrow-manage-bids {
  margin-right: 0.4rem;
}

.profileheader-divider{
 margin: 0.3rem 0 0.3rem 0 !important;
}

@media screen and (max-width: 768px) {
  .header-dropdown-profile-text {
    width: 100% !important;
    /* overflow-x: hidden;
    overflow-y: hidden;
    max-height: 700px; */
  }
  .logoProfile {
    width: 2rem;
    margin: 0 0.6rem 0 0;
  }

  .burgerMenuDrawerMobileView .ant-menu-vertical {
    border-right: none;
  }
}
