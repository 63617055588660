.messages-pagination-bar .ant-pagination-item a,
.messages-pagination-bar .ant-pagination-item-active:focus-visible,
.messages-pagination-bar .ant-pagination-item-active:hover,
.messages-pagination-bar .ant-pagination-item-active,
.messages-pagination-bar .ant-pagination-item:hover,
.messages-pagination-bar .ant-pagination-item-active a,
.messages-pagination-bar .ant-pagination-item a:hover {
  font-size: 1rem;
  font-stretch: normal;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  border-color: #c4c4c4;
  color: #1e1e1e;
}

.messages-pagination-bar .ant-pagination-item-active a {
  font-weight: bold !important;
  background-color: #e0e0e0;
}

.messages-pagination-bar .ant-pagination-item a:hover {
  font-weight: normal !important;
  background-color: #e0e0e0;
}

.messages-pagination-bar .ant-pagination-prev:hover .ant-pagination-item-link,
.messages-pagination-bar .ant-pagination-next:hover .ant-pagination-item-link {
  color: #1e1e1e;
  border-color: #c4c4c4;
}

.messages-pagination-bar .ant-pagination-prev:hover button,
.messages-pagination-bar .ant-pagination-next:hover button {
  font-stretch: normal;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
  border-color: #c4c4c4;
  background-color: #e0e0e0;
}
