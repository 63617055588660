.place-bid-item-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 270px;
}

.place-bid-item-card{
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 85%;
    border: 1px solid #e2e2e2;
}

.place-bid-item-card-header{
    height: 66%;
    padding: 6%;
    border-bottom: 1px solid #e2e2e2;
}

.place-bid-item-card-body{
    display: flex;
    flex-direction: row;
    height: 17%;
    border-bottom: 1px solid #e2e2e2;
    font-size: 12px;
    line-height: 14px;
    color: #888888;
}

.place-bid-item-card-footer{
    display: flex;
    align-items: center;
    padding-left: 5px;
    font-style: italic;
    height: 17%;
    width: 100%;
    font-size: 10px;
    color: #888888;
}

.bid-placed-tag{
    border-width: 0;
    padding: 5px;
    border-radius: 4px;
    color: #B98925;
}

.place-bid-item-action-button{
    margin-left: 5px;
}

.place-bid-item-column{
    display: flex;
    flex-direction: column;
}

.place-bid-title-button{
    margin-top: 10px;
    color: black;
    font-weight: bold;
    overflow: hidden;
}

.place-bid-title-button:hover{
    margin-top: 10px;
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
    overflow: hidden;
}

.place-bid-content{
    font-size: 12px;
}

.place-bid-content-category{
    float: left;
    width: 58px;
    color: gray;
}

.place-bid-item-stat-right{
    display: flex;
    color: #a5a2a2;
    flex: 1;
    flex-direction: column;
    border-left: 1px solid #e2e2e2;
    align-items: center;
    justify-content: center;
}
.place-bid-item-stat-left{
    display: flex;
    color: #a5a2a2;
    flex-direction: column;
    flex: 1;
    border-right: 1px solid #e2e2e2;
    align-items: center;
    justify-content: center;
}
.place-bid-item-stat-center{
    display: flex;
    color: #a5a2a2;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.place-bid-item-stat-bold{
    font-weight: bold;
}

