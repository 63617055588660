.OpenPositions-Cantainer .Skill-PieChart-Component {
    display: flex;
    justify-content: space-between;
    /* min-width: 25rem; */
    font-size: .875rem;
    color: #7e7e7e;
}

.OpenPositions-Cantainer .Skill-PieChart-Component>div:nth-child(2) {
    color: #1e1e1e;
}

@media screen and (max-width: 768px) {
    /* .OpenPositions-Cantainer{
        min-width: 22rem;
    } */

    .OpenPositions-Cantainer .Skill-PieChart-Component {
        font-size: .75rem;
        font-weight: normal;
    }

    .OpenPositions-Cantainer .Skill-PieChart-Component>div:nth-child(2) {
        font-size: .875rem;
    }
}