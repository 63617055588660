.home-category-card-container{
    box-shadow: 0px -0px 30px -6px rgba(0,0,0,0.30);
    -webkit-box-shadow: 0px -0px 30px -6px rgba(0,0,0,0.30);
    -moz-box-shadow: 0px -0px 30px -6px rgba(0,0,0,0.30);
    margin-top: 14px;
    margin-bottom: 14px;
    /*padding: 0;*/
    border: 1px solid #e2e2e2;
}

.titleBar{
    border-bottom: 1px solid #e2e2e2;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 6px;
    display: flex;
    direction: row;
    justify-content: space-between;
}

.view-all{
    color: #FC3163;
    font-size: 12px;
    text-decoration: underline dotted red;
}
.counter{
    /* padding-left: 25px; */
    margin-left: 20px;
    background-color: #4b4a4b;
    color:white;
    padding: .2em 0.6em 0.3em;
    border-radius: 1em;
    text-align: center;
}
