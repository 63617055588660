.favorite-bid-background {
  width: 1.25rem;
  height: 1.25rem;
  flex-grow: 0;
  /* margin: 0 0 2.13rem 8.13rem; */
  border-radius: 1.38rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;

  display: flex;
  justify-content: center;
}

.favorite-bid-background .iconStar-fav {
  margin-top: 1.5px;
  font-size: 15px;
  margin-left: 0.5px;
}

@media screen and (max-width: 768px) {
  .favorite-bid-background .iconStar-fav {
    margin-left: 1px;
  }
}
