.NotificationScreen{
    /* position: absolutes; */
    /* z-index: 1000;    */
}
/* @media screen and (max-width: 991px) {
    .NotificationScreen{
        position: fixed;
        z-index: 1000;   
    }
} */
.NotificationScreen .cont{
    margin: auto;
}