.tabSkillListing {
  width: 100%;
  height: max-content;
  flex-grow: 0;
  padding: 1.25rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  gap: 0.938rem;
}

.tabSkillListing .title {
  margin-bottom: 0.75rem;

  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.tabSkillListing .value {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.tabSkillListing .box {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.tabSkillListing .databox {
  width: max-content;
  height: max-content;
  flex-grow: 0;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* gap: 0.63rem;
    margin: 0.75rem 0.50rem 0.75rem 0; */
  padding: 0.31rem 0.63rem;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #f9f9f9;

  /* text */
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}
