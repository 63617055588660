.profile-avatar-menu-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 0.75rem 0rem 0.5rem 0rem;
}

.circular-progress-bar-menu {
  position: relative;
}

.circular-progress-bar-circle {
  position: absolute;
  bottom: 0rem;
  left: -0.1875rem;
  z-index: 99;
}

.user-name-menu {
  flex-grow: 0;
  /* margin: 0 11px 5px 0; */
  font-family: SFProText;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.user-info-menu {
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
}

.professional-code {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  padding-right: 0.875rem;
}
.use-rating-menu {
  display: flex;
  flex-direction: row;
}

.use-rating-menu .DashBoard-Profile-user-rating-section-menu {
  border-radius: 0.1875rem;
  box-shadow: 0 0.0625rem 0.125rem 0 #bbb;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0rem 0.3125rem 0.1875rem;
}
.use-rating-menu .DashBoard-Profile-user-rating-star-menu .ant-rate {
  font-size: 0.5625rem;
  color: #e39435;
}
.use-rating-menu .DashBoard-Profile-user-rating-number-menu {
  font-size: 0.5625rem;
  font-weight: bold;
  font-style: normal;
  color: #777;
  padding-left: 0.1875rem;
}
