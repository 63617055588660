.gql-justBackground {
  background-color: #fafafa;
}

.gql-dashBoardFull {
  /* height: 35rem; */
  height: auto;
  width: 100%;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  flex-direction: column;
}

.search-results-search {
  display: flex;
  margin: 4.75rem 0.938rem 0 0.938rem;
}

.search-results-divider {
  height: 1px;
  width: 100%;
  margin: 9px 0 0 0;
  background-color: #e4e4e4;
}

/* // FILTER */
.filters-wrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  /* border: 0.063rem solid black; */
}

.filters-wrapper .space .dropdown {
  width: fit-content;
  height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0px 12px 0px 0px;
  padding: 5px 10px;
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  color: #1e1e1e;
  background-color: #fff;
  /* border: solid 1px #118936;
  color: #118936;
  background-color: #f6fff9; */
}

.filters-wrapper .space .dropdown .button {
  /* font-family: SFProText; */
  font-size: 14px;
  font-weight: 500;
}

.filters-wrapper .space .dropdown .button .action-down>path {
  color: red !important;
}

/* APPLIED FILTER */
.filters-wrapper .space .dropdown-job {
  width: fit-content;
  height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0px 12px 0px 0px;
  padding: 5px 10px;
  border-radius: 4px;
  /* border: solid 1px #d4d4d4;
  background-color: #fff; */
  border: solid 1px #118936;
  color: #118936;
  background-color: #f6fff9;
}

.filters-wrapper .space .dropdown-skill {
  width: fit-content;
  height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0px 12px 0px 0px;
  padding: 5px 10px;
  border-radius: 4px;
  /* border: solid 1px #d4d4d4;
  background-color: #fff; */
  border: solid 1px #118936;
  color: #118936;
  background-color: #f6fff9;
}

.filters-wrapper .space .dropdown-location {
  width: fit-content;
  height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0px 12px 0px 0px;
  padding: 5px 10px;
  border-radius: 4px;
  /* border: solid 1px #d4d4d4;
  background-color: #fff; */
  border: solid 1px #118936;
  color: #118936;
  background-color: #f6fff9;
}

.filters-wrapper .space .dropdown-budget {
  width: fit-content;
  height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0px 12px 0px 0px;
  padding: 5px 10px;
  border-radius: 4px;
  /* border: solid 1px #d4d4d4;
  background-color: #fff; */
  border: solid 1px #118936;
  color: #118936;
  background-color: #f6fff9;
}

.gql-dashBoardFull .applied-filter {
  display: flex;
  flex-direction: row;
}

.gql-dashBoardFull .applied-filter .tag1 {
  border-radius: 20px;
  height: 29px;
  gap: 10px;
  margin: 20px 10px 0px 0px;
  padding: 4px 12px;
  border: solid 1px #e4e4e4;
  background-color: #e4e4e4;
  /* font-family: SFProText; */
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  /* line-height: normal; */
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e;
}

.gql-dashBoardFull .Applied-filter-wrap {
  flex-wrap: wrap;
}

.gql-dashBoardFull .applied-filter .tag1 svg {
  display: inline-block;
  margin: 0px 0px -1px 0.5rem;
  height: 14px;
}

/* FILTER RADIO BUTTON COLOR */
.budget-menu .ant-menu-item {
  cursor: default;
}

.job-type-menu .ant-menu-item {
  cursor: default;
  background-color: #fff !important;
}

.job-type-menu-invalid .ant-menu-item {
  cursor: default;
  background-color: #fff !important;
}

.s-result .ant-menu-item {
  cursor: default;
}

.s-result-invalid .ant-menu-item {
  cursor: default;
}

.Search-jobs .ant-radio-checked .ant-radio-inner {
  border-color: #118936 !important;
}

.Search-jobs .ant-radio-checked .ant-radio-inner:after {
  background-color: #118936;
}

.Search-jobs .ant-radio:hover .ant-radio-inner {
  border-color: #118936;
}

/* SORT FILTER */
.sort-filter {
  display: flex;
  flex-direction: row-reverse;
  margin: 1.25rem 0 1.25rem 0;
  /* right: 0; */
}

.sort-filter .sort-filter-dropdown {
  cursor: pointer;
}

.sort-values-style {
  color: #3e3e3e;
  font-size: 16px;
  width: 208px;
}

.sort-values-style-select {
  color: #118936;
  font-size: 16px;
  width: 208px;
}

.sort-filter .sort-filter-dropdown>span svg {
  cursor: pointer;
  margin: 0 0 0 0.5rem;
}

.sort-filter .sortby {
  width: 3.438rem;
  height: 1.188rem;
  flex-grow: 0;
  margin: 0 0.938rem 0 0;
  /* font-family: SFProText; */
  font-size: 1rem;
  /* line-height: normal; */
  color: #5e5e5e;
  cursor: default;
}

.sort-filter .sortby-text {
  width: 7rem;
  height: 1.188rem;
  margin: 0 0.938 0 0;
  /* font-family: SFProText; */
  font-size: 1rem;
  font-weight: 600;
  color: #1e1e1e;
}

.blank-space-div {
  flex: 1;
}

/* MOBILE FILTER */
.search-mobile-filter {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.search-mobile-filter .left {
  display: flex;
  width: -webkit-fill-available;
  height: 42px;
  /* padding: 13px 46px; */
  border-radius: 5px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.search-mobile-filter .left .search-filter-container {
  display: flex;
  justify-content: space-between;
  gap: 0.625rem;
}

.search-mobile-filter .left .search-filter-container .icon {
  width: 16px;
  height: 16px;
  /* margin: 0 10px 0 9px; */
  /* margin: 0 43px 0 0px; */
  margin: -2px 0 0 0;
  /* object-fit: contain; */
  color: #118936;
  padding: 0;
  position: relative;
}

.search-mobile-filter .left .search-filter-container .text {
  /* width: 60px; */
  height: 14px;
  /* margin: 1px 0 1px 33px; */
  /* font-family: SFProText; */
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.search-mobile-filter .right {
  display: flex;
  width: -webkit-fill-available;
  height: 42px;
  /* padding: 13px 46px; */
  border-radius: 5px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.search-mobile-filter .right .icon {
  width: 16px;
  height: 16px;
  /* margin: 0 10px 0 7px; */
  /* margin: 0 28px 0 0px; */
  margin: 0;
  object-fit: contain;
  color: #118936;
  position: absolute;
}

.search-mobile-filter .right .text {
  width: 25px;
  height: 14px;
  flex-grow: 0;
  margin: 1px 0 1px 30px;
  /* font-family: SFProText; */
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.SearchfilterDrawer .ant-drawer-header {
  background-color: #fff;
  border-bottom: 0.125rem solid #f0f0f0;
  padding: 1rem 0.938rem;
}

.SearchfilterDrawer .ant-drawer-close {
  padding: 0;
  width: fit-content;
  top: 1rem !important;
  right: 1.938rem;
  position: absolute;
}

.SearchfilterDrawer .ant-drawer-title {
  display: flex;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  justify-content: center;
}

.sortReceivedBidsDrawer-mobile .ant-drawer-title {
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.sortReceivedBidsDrawer-mobile .ant-drawer-header {
  border-bottom: 2px solid #f0f0f0;
}

.sortReceivedBidsDrawer-mobile .radioSortReceivedBidsGroup {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.2rem;
}

.sortReceivedBidsDrawer-mobile .radioSortReceivedBidsGroup .radioSortReceivedBids {
  display: flex;
  width: 100%;
  min-width: 22.63rem;
  min-width: auto;
  height: 2.75rem;
  /* margin: 1.25rem 0.94rem 0.63rem; */
  padding: 0.6rem 0.75rem 0.75rem 0.75rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.15);
  border: solid 0.06rem #d4d4d4;
  background-color: #fff;
}

.sortReceivedBidsDrawer-mobile .ant-space-vertical {
  width: 94%;
}

.SearchfilterDrawer .ant-menu-vertical .ant-menu-item {
  /* line-height: 1.8rem !important; */
  /* height: auto; */
  height: 570px;
  margin: 0;
}

.SearchfilterDrawer .ant-menu {
  padding-top: 0.2rem !important;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.sortReceivedBidsDrawer-mobile .ant-drawer-close {
  padding: unset;
  width: -moz-fit-content;
  width: fit-content;
  right: 15px;
  top: 18px;
}

.sortReceivedBidsDrawer-mobile .close-btn-icon {
  position: absolute;
  font-size: 1rem;
  right: 1.938rem;
  top: 0.9375rem;
}

.apply-button-mobile-filter {
  /* width: 320px; */
  height: 42px;
  /* padding: 12px 114px 12px 115px; */
  padding: 12px 0px;
  border-radius: 5px;
  background-color: #118936;
}

.apply-button-mobile-filter .apply-button-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.13;
  text-align: center;
  color: #fff;
}

.mobile-location-button {
  display: flex;
  justify-content: space-between;
  margin: 0.938rem 0 0.938rem 0;
}

.mobile-location-button .mobile-switch .ant-switch {
  width: 3rem;
  height: 1.438rem;
  color: #118936;
}

.mobile-location-button .mobile-switch .ant-switch ant-switch-checked {
  width: 3rem;
  height: 1.438rem;
  color: #118936;
}

/* .mobile-location-button .location-text {
} */

.suggestion-wrdapper-mobile {
  /* margin: 0rem 0 0.938rem 0; */
  display: flex;
  /* overflow-x: scroll; */
  /* padding: 0 0 0.813rem 0; */
}

.suggestion-mainBox {
  margin-bottom: 0.25rem;
  height: 2.125rem;
  overflow-x: scroll;
  overflow-y: hidden;
}

.suggestion-mainBox-empty {
  margin-bottom: 0rem;
  height: 0rem;
  overflow-x: hidden;
  overflow-y: hidden;
}

.suggestion-mainBox::-webkit-scrollbar-track {
  height: 0.125rem !important;
}

.suggestion-mainBox::-webkit-scrollbar {
  height: 0.125rem !important;
}

.suggestion-mainBox::-webkit-scrollbar-thumb {
  border-radius: 0.188rem;
  background-color: #7e7e7e;
}

.scroll-divider {
  position: relative !important;
  top: -0.813rem !important;
  width: calc(100vw + 0rem) !important;
  /* width: auto; */
  margin: 0.563rem 0 0 -1.063rem !important;
  height: 0.063rem !important;
  background-color: #e4e4e4 !important;
}

.suggestion-wrdapper {
  margin: 0 0 1.25rem 0;
}

.suggestion-wrdapper .tags {
  width: fit-content;
  height: 2;
  line-height: 1.1;
  margin: 0 0.9375rem 0 0;
  padding: 4px 5px;
  border-radius: 2px;
  border: solid 0.5px #d4d4d4;
  background-color: #fff;
  /* font-family: SFProText; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9e9e9e;
  cursor: pointer;
}

.parent {
  display: flex;
  font-size: 0;
  flex-wrap: wrap;
  margin: -0.625rem 0 0 -0.625rem;
}

.child {
  display: inline-block;
  background: blue;
  margin: 0.625rem 0 0 rem;
  flex-grow: 1;
  height: 6.25rem;
  width: 7.5rem;
}

.filters-wrapper .location-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  width: 11rem;
}

.location-text {
  /* width: 98px; */
  height: 19px;
  /* margin: 2px 0 2px 12px; */
  /* font-family: SFProText; */
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  /* line-height: normal; */
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.filters-wrapper .location-button .ant-switch-checked {
  width: 48px;
  height: 23px;
  flex-grow: 0;
  /* margin: 0 12px 0 0; */
  padding: 2px 2px 2px 27px;
  border-radius: 16px;
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.07);
  background-color: #118936;
}

.filters-wrapper .location-button .ant-switch {
  width: 48px;
  height: 23px;
  flex-grow: 0;
  /* margin: 0 12px 0 0; */
  padding: 2px 2px 2px 27px;
  border-radius: 16px;
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.07);
}

.skill-search {
  margin: 1rem 1.25rem;
  width: 20rem;
  height: 2.125rem;
  padding: 0.438rem 9.813rem 0.438rem 0.625rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  /* font-family: SFProText; */
  font-size: 14px;
}

.skill-search-mobile {
  height: 2.125rem;
  padding: 0.438rem 0.625rem 0.438rem 0.625rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border: solid 1px #d4d4d4;
  /* font-family: SFProText; */
  font-size: 16px;
}

.skill-search-mobile input[type="text"]::placeholder {
  font-size: 16px;
  color: #7e7e7e;
}

.s-result .ant-input-prefix {
  margin-right: 12.2px;
  height: 15.6px;
  width: 15.6px;
  color: #7e7e7e;
}

.s-result .ant-input-prefix svg {
  /* margin-right: 12.2px; */
  height: 15.6px;
  width: 15.6px;
  color: #7e7e7e;
}

.s-result input[type="text"]::placeholder {
  /* text-align: left; */
  color: #7e7e7e;
  font-size: 14px;
  width: fit-content;
}

.s-result {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}

.s-result-invalid {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  border: 1px solid #db2828;
}

.s-result-invalid-location {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  border: 1px solid #db2828;
}

/* MENU */
.search-results-filter-validation-text {
  font-size: 12px;
  color: #db2828;
  margin-left: 14px;
}

.search-results-filter-validation-text-location {
  font-size: 12px;
  color: #db2828;
  margin-left: 15px;
}

.search-results-filter-validation-text-budget {
  font-size: 12px;
  color: #db2828;
  margin-top: -5px;
  margin-bottom: 12px;
}

/* workplace */
.workplace-type-menu {
  width: 15rem;
  height: 11.875rem;
  padding: 0 1.25rem 1.25rem 1.25rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.workplace-invalid {
  width: 15rem;
  height: 12.875rem;
  padding: 0 1.25rem 1.25rem 1.25rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0 rgb(0 0 0 / 25%);
  background-color: #fff;
  border: 0.063rem solid #db2828;
}

.workplace .ant-menu-vertical {
  border-right: none;
}

.workplace-invalid .ant-menu-vertical {
  border-right: none !important;
}

/* JOBTYPE */
.job-type-menu {
  width: 240px;
  /* height: 144px; */
  padding: 0 20px 20px 20px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.Profession-type-menu {
  width: 20rem;
  height: 12.5rem;
  padding: 0 1.25rem 1.25rem 1.25rem;
  border-radius: .25rem;
  box-shadow: 0 0 .25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.Profession-type-menu-invalid {
  width: 20rem;
  height: 12.5rem;
  padding: 0 1.25rem 1.25rem 1.25rem;
  border-radius: .25rem;
  box-shadow: 0 0 .25rem 0 rgb(0 0 0 / 25%);
  background-color: #fff;
  border: .0625rem solid #db2828;
}

.Profession-type-menu .ant-menu-vertical {
  border-right: none !important;
}

.Profession-type-menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff;
}

.Profession-type-menu .ant-menu-item-selected {
  color: #118936;
}

.job-type-menu-invalid {
  width: 240px;
  /* height: 175px; */
  padding: 0 20px 20px 20px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 25%);
  background-color: #fff;
  border: 1px solid #db2828;
}

.job-type-menu .ant-menu-vertical {
  border-right: none;
}

.job-type-menu-invalid .ant-menu-vertical {
  border-right: none !important;
}

/* BUDGET */
.budget-menu {
  width: 19.375rem;
  height: 9.938rem;
  flex-grow: 0;
  padding: 1.25rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.budget-menu-invalid {
  width: 19.375rem;
  height: 11.538rem;
  flex-grow: 0;
  padding: 1.25rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0 rgb(0 0 0 / 25%);
  background-color: #fff;
  border: 1px solid #db2828;
}

/* .budget-menu .options {
} */

.budget-menu .input-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 20px 0;
}

.budget-menu-invalid .input-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 20px 0;
}

.budget-menu .input-container input[type="text"]::placeholder {
  color: #7e7e7e;
  font-size: 1rem;
}

.budget-menu .input-container input[type="text"] {
  font-size: 1rem;
  color: #1e1e1e;
  margin-left: 0.313rem;
}

.budget-menu-invalid .input-container input[type="text"]::placeholder {
  color: #7e7e7e;
  font-size: 1rem;
}

.budget-menu-invalid .input-container input[type="text"] {
  font-size: 1rem;
  color: #1e1e1e;
  margin-left: 0.313rem;
}

.gql-dashBoardFull .ant-input-affix-wrapper>input.ant-input {
  padding: 0;
  border: none;
  outline: none;
}

.budget-menu .ant-radio-checked .ant-radio-inner {
  border-color: #118936 !important;
}

.budget-menu-invalid .ant-radio-checked .ant-radio-inner {
  border-color: #118936 !important;
}

.budget-menu .ant-radio-checked .ant-radio-inner:after {
  background-color: #118936;
}

.budget-menu-invalid .ant-radio-checked .ant-radio-inner:after {
  background-color: #118936;
}

.budget-menu .ant-radio:hover .ant-radio-inner {
  border-color: #118936;
}

.budget-menu-invalid .ant-radio:hover .ant-radio-inner {
  border-color: #118936;
}

.budget-menu .ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-right: 30px;
  cursor: pointer;
}

.budget-menu-invalid .ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-right: 30px;
  cursor: pointer;
}

.budget-menu .input-container .input-left {
  width: 110px;
  height: 35px;
  flex-grow: 0;
  /* margin: 0 0px 0 0; */
  /* padding: 8px 49px 9px 12px; */
  border-radius: 4px;
  font-size: 1rem;
  border: solid 1px #d4d4d4;
  background-color: #fff;
}

.budget-menu-invalid .input-container .input-left {
  width: 110px;
  height: 35px;
  flex-grow: 0;
  /* margin: 0 0px 0 0; */
  /* padding: 8px 49px 9px 12px; */
  border-radius: 4px;
  font-size: 1rem;
  border: solid 1px #d4d4d4;
  background-color: #fff;
}

/* .budget-menu .input-container .input-left prefix {
  font-size: 16px;
}

.budget-menu .input-container .input-right prefix {
  font-size: 16px;
} */

.budget-menu .input-container .dash {
  align-self: center;
}

.budget-menu-invalid .input-container .dash {
  align-self: center;
}

.budget-menu .input-container .input-right {
  width: 110px;
  height: 35px;
  flex-grow: 0;
  /* margin: 0 0px 0 0; */
  font-size: 1rem;
  /* padding: 8px 49px 9px 12px; */
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
}

.budget-menu-invalid .input-container .input-right {
  width: 110px;
  height: 35px;
  flex-grow: 0;
  /* margin: 0 0px 0 0; */
  font-size: 1rem;
  /* padding: 8px 49px 9px 12px; */
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
}

/* CLEAR ALL BUTTON */
.clearAll {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin: 0 5%; */
  margin: 0.5rem 5% 1.125rem 5%;
}

.clearAll .clear-text {
  width: 5.375rem;
  /* height: 1.5rem; */
  /* font-family: SFProText; */
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  align-self: center;
  cursor: default;
  letter-spacing: normal;
  text-align: center;
  color: #7e7e7e;
  cursor: pointer;
}

.clearAll .clear-all-button {
  width: 4.688rem;
  height: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: default;
  gap: 0.625rem;
  color: #fff;
  font-weight: 600;
  padding: 0.313rem 1.25rem;
  border-radius: 0.125rem;
  background-color: #118936;
  cursor: pointer;
}

.gql-receivedGigs {
  display: grid;
  /* justify-content: space-between; */
  margin: 0 auto;
  flex-wrap: wrap;
  list-style: none;
  width: 100%;
  flex: 0 0 33.333333%;
  font-size: 1.5em;
  margin: 0rem 0 0 0;
  gap: 0.938rem;
  /* color: var(--primaryColor); */
  grid-template-columns: 1fr 1fr 1fr;
}

.gql-dashboardHeaders {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-top: 1rem; */
  margin-bottom: 0.5rem;
  /* margin: 2rem 4rem; */
  font-size: 1em !important;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.gql-viewAllBtn {
  font-size: 1em;
  color: var(--primaryColor);
  padding-top: 0.313rem;
}

.gql-headerLine {
  background-color: #eeeeee;
  opacity: 30%;
}

.gql-countCheck {
  /* width: 16.625rem; */
  height: 1.188rem;
  margin: 0 0 1.25rem 0;
  /* font-family: SFProText; */
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  cursor: default;
}

.gql-countCheck p>strong {
  font-weight: 600;
}

.gql-loader {
  display: flex !important;
  width: 100%;
  height: 40vh;
  justify-content: center !important;
  font-size: 3rem !important;
  /* margin: 1vh 0vh 0vh -4vh !important; */
  /* margin-right: 0 4vh !important; */
  /* color: #e73959 !important; */
  align-items: center !important;
  /* background-color: #777; */
}

.gql-pagination {
  display: flex !important;
  width: 100%;
  justify-content: center !important;
  font-size: 3rem !important;
  margin: 10vh 0 0 0;
  /* margin: 1vh 0vh 0vh -4vh !important; */
  /* margin-right: 0 4vh !important; */
  align-items: center !important;
  /* background-color: #777; */
}

.gql-message {
  display: flex !important;
  width: 100%;
  /* height: 40vh; */
  justify-content: center !important;
  /* font-size: 3rem !important; */
  /* margin: 1vh 0vh 0vh -4vh !important; */
  /* margin-right: 0 4vh !important; */
  align-items: center !important;
  /* background-color: #777; */
}

/* DRAWER */
/* .Basic Drawer {
} */

.workplace-type-menu .ant-menu-inline,
.workplace-type-menu .ant-menu-vertical,
.workplace-type-menu .ant-menu-vertical-left {
  border-right: none;
}

.DropdownSearchResult {
  z-index: 999;
}

@media screen and (max-width: 700px) {
  .gql-dashBoardFull {
    height: 100%;
    width: 100%;
    justify-content: center;
    padding: 0.625rem 0.9375rem;
    background: #fff;
  }

  .gql-countCheck {
    width: 100%;
    height: auto;
    margin: 0.7rem 0 0 0rem;
    font-size: 1em;
    font-weight: normal;
    color: #3e3e3e;
  }

  .gql-receivedGigs {
    width: auto;
    padding: 0px 0rem;
    border-radius: 2px;
    display: flex;
  }

  .gql-listingTabsFull {
    margin-top: 0.8rem;
    width: 94vw;
    padding: 0 0.5rem;
    border-radius: 2px;
  }

  .gql-dashboardHeaders {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .sbar-loader {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 1vh auto;
  }

  .suggestion-wrdapper-mobile .tags {
    margin-right: 0.625rem;
  }

  .filter_search_button {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 0.125rem solid #e8e8e8;
    padding: 0.625rem 1rem;
    text-align: right;
    left: -0.438rem;
    background: #fff;
    border-radius: 0 0 0.25rem 0.25rem;
  }

  .Mobile-Detail-Sort .ant-drawer-body {
    padding: 0rem;
  }

  .Mobile-Detail-Sort .close-btn-icon {
    right: .9375rem;
  }
}

.location-menu-container.show-scrollbar {
  max-height: calc(35px * 7);
  overflow-y: overlay;
}

.location-menu-container.show-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.location-menu-container.show-scrollbar::-webkit-scrollbar-track {
  background: #fff;
}

.location-menu-container.show-scrollbar::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 12px !important;
  cursor: pointer !important;
}

.location-menu-container .ant-menu {
  border-right: none;
}