.certificationsAndAwards .certificationsAndAwardsHeader {
  display: flex;
  justify-content: space-between;
}

.certificationsAndAwards .addCertificates {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  margin-bottom: 1.875rem;
}

.certificationsAndAwards .textAreaCertificates {
  width: 100%;
  background-color: #fff;
  border: none;
  resize: none;
  box-shadow: 0 0 0.13rem 0 rgba(0, 0, 0, 0.25);
  padding: 7px 10px;
  font-size: 1rem;
  color: #3e3e3e;
}

.certificationsAndAwards .textAreaCertificates:hover {
  border: none;
}

.certificationsAndAwards .textAreaCertificates:focus {
  border: none;
  outline: none;
}

.certificationsAndAwards .textAreaCertificates::-webkit-scrollbar {
  width: 0.25rem;
}

.certificationsAndAwards .textAreaCertificates::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 12px !important;
  cursor: pointer !important;
}

.certificationsAndAwards .descriptionEdit {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.descriptionEdit .description-CA {
  font-size: 0.88rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.certificationsAndAwards .description-data-CA {
  font-size: 0.88rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
  margin-bottom: 0.938rem;
}

@media screen and (max-width: 768px) {
  .certificationsAndAwards .addCertificates {
    margin-bottom: 1rem;
  }
}
