.tick_green{
  border-radius: 50%;
    font-size: 3.125rem;
    margin: auto;
    margin-top: 3.125rem;
    margin-bottom: 1.25rem;
     display: flex;
    width: 3.75rem;
    height: 3.75rem;
    justify-content: center;
   background-color: #e4ffec;
}

.thank_tagline{
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        color: #3e3e3e; 
        margin-bottom: 0.625rem; 
}
.thank_tag_descrip{
    font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
  margin-bottom: 3.813rem;
}

.Review-other-assignmentss{
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
    margin-bottom: 1.25rem;
  }

  .card_main_rating{
    margin-bottom: 7.5rem;
  }
  .rating_status_card{
    display: flex;
    gap: 6.375rem;
    max-width: 43.125rem;
  /* margin: 1.25rem 290px 0.625rem 300px; */
  padding: 1.25rem 36px 1.25rem 1.25rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  }
.rating_informations{
    display: flex;
    gap: 0.625rem;
}

.job_ttitles{
    font-size: 1rem;
  color: #4054b2; 
   
}
.job_ttitle{
    font-size: 0.75rem;
  color: #7e7e7e;
  margin-bottom: 5px;
}

.employyer_name{
    font-size: 0.875rem;
    font-weight: 500;
    color: #2e2e2e;
}
.rate_div_9{
     display: flex;
     gap: 3.125rem;
     margin-top: 0.25rem;
  }
 
.rate_div_4{
    display: flex; 
    gap: 3.313rem;
}

.job_statuss{
    display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  padding: 0.063rem 0.313rem;
  border-radius: 0.125rem;
  background-color: #e4e4e4;
  font-size: 0.75rem;
  font-weight: 500;
  color: #7e7e7e;

}

@media screen and (max-width: 768px) {
  .tick_green{
    border-radius: 50%;
      font-size: 3.125rem;
      margin-top: 2.188rem;
      margin-bottom: 1.25rem;    
  } 
  .thank_tag_descrip {
    margin-bottom: 1.875rem
  }
  .Review-other-assignmentss { 
    margin-bottom: 0.938rem;
  }

  .rating_status_cards{
    
  /* margin: 1.25rem 290px 0.625rem 300px; */
  padding: 0.938rem 0.938rem 0.938rem 0.938rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  }
  .job_ttitles {
    font-size: 0.875rem;
    color: #4054b2;
}
.employyer_name {
  font-size: 0.75rem;
}
.job_ttitle{
  font-size: 0.75rem;
color: #7e7e7e;
margin-bottom: 5px;
}
.job_employer_main{
  display: flex;
  gap: 0.25rem;  
}
.rate_div_9{
  gap: 8.5rem;
  margin-top: 0rem;
}
.job_ttitle_div{
  display: flex;  
  gap: 0.25rem;  
}
.job_statuss{
font-size: 0.625rem;
font-weight: 500;
color: #7e7e7e;
padding: 0rem 0.313rem;
margin-top: 0.25rem;
}
.liner_rating{
  margin: 1.888rem 0rem 0.75rem 0rem;
}
}

   