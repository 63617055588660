.timerContainer{
    margin-right: 2%;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    text-align: center;
    /* justify-content: center; */
    border-radius: 4px;
    background-color: #bfdac8;
    height: 50px;
}
.clockTitle{
    font-size: 12px !important;
    margin-top: 12px;
    text-align: left;
    color: #1e1e1e;
    /* background-color: blueviolet; */
}
.stopWatch{
    font-size: 18px;
    text-align: left;
    /* background-color: brown; */
    margin-top: 11px;
    color: #1e1e1e;
}
.play_container{
    display: flex;
    width: 40px;
    height: 40px;
    flex-grow: 0;
    margin: 0 5px 0 0;
    padding: 5px;
    border-radius: 4px;
    background-color: #118936;
    align-items: center;
    justify-content: center;
}
.play_button{
    width: 30px;
    height: 30px ;
    color: #fff;
}
.stop_container{
    display: flex;
    width: 40px;
    height: 40px;
    flex-grow: 0;
    /* margin: 0 5px 0 5px; */
    padding: 5px;
    border-radius: 4px;
    background-color: #db2828;
    justify-content: center;
    align-items: center;
}
.stop_button{
    width: 30px;
    height: 30px;
}
.pause_container{
    display: flex;
    width: 40px;
    height: 40px;
    flex-grow: 0;
    margin: 0 5px 0 0;
    padding: 5px;
    border-radius: 4px;
    background-color: #118936;
    justify-content: center;
    align-items: center;
}
.pause_button{
    width: 30px;
  height: 30px;
}

@media screen and (max-width: 768px) {
    .timerContainer{
        margin-top: 7%;
        margin-bottom: 2%;
    }

    .clockTitle{
        margin-top: 2px;
    }

    .stopWatch{
        margin-top: 2px;
    }
}