.parentCard{
    max-width: 33.54%;
    
    border-radius: 0.313rem;
    background: #e7f3eb;
    padding: 0.938rem;
  }
  
  .Congratulations-Your-succesfully-completed-the-job {
    margin: 0 0 0.625rem;
    font-size: 0.875rem;  
    line-height: 1.79;
    letter-spacing: normal;
    text-align: left;
    color: #4e4e4e;
  }
  .Congratulations-Your-succesfully-completed-the-job .text-style-1 {
    font-weight: 600;
  }
  
  .card-attribute{
  display: flex; 
  justify-content: space-between; 
}

.card-attribute_item1{
    font-size: 0.875rem;
    font-weight: 500;
    color: #1e1e1e;
}

.card-attribute_item2{
    font-size: 0.875rem;
    font-weight: 500;
    color: #7e7e7e;
}
.card-attribute_item22{
    font-size: 0.875rem;
    font-weight: 500;
    color: #7e7e7e;
    margin-top: 0.938rem;
}
.card-insidebox{
    padding: 0.75rem;
    background: #fff;
}

.duration-card{
     
    font-size: 0.875rem;
     padding: 0.938rem 0.938rem 0rem 0rem;
    text-align: right;
    color: #b7b7b7;
}

 .g-star-ratings {
    font-weight: 500;
    font-size: 1.25rem;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 0.813rem;
  }

  /* card 3  */
  card-attribute_item3 {
    font-weight: 500;
    font-size: 0.938rem;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 0.813rem;
  }

.card-attribute_item33{
    margin-top: 0.625rem;
    font-size: 0.875rem;
    line-height: 1.79;
    text-align: center;
    color: #3e3e3e;
  
}

.card_attribute_item44{
    font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}   

.card-attribute_item23{
    font-size: 0.875rem;
    font-weight: 500;
    color: #7e7e7e;
}


 