.bhsCardBox {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  padding: 0.9375rem 0.625rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
}

.bhsUserInfo {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.bhsUser {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bhsUserName {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.bhsBidInfo {
  min-width: 11.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bhsUserBidRate {
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.bhsUserBidStatus {
  font-size: 0.625rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  padding: 0.0625rem 0.3125rem;
  border-radius: 0.125rem;
  background-color: #f2f2f2;
}

.bhsUserBTF {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bhsUserBidTitle {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4054b2;
  cursor: pointer;
}

.bhsUserfavorite {
  cursor: pointer;
}

.bhsUserfavorite .favorite-bid-background {
  box-shadow: none;
}

.bhsUserLocation {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.bhsBidStatusTimeInfo {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  padding: 0.75rem;
  margin-top: 0.125rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
}

.bhsBidStatusTimeItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bhsBidStatusTime {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.bhsOpenChat {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: right;
  color: #4054b2;
  cursor: pointer;
}

.bhsBidStatusInfo {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.bhsInterviewTimingSection {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.bhsInterviewTimingheading {
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.bhsInterviewTimingList {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
}

.bhsInterviewTimingList .ant-radio-wrapper:hover .ant-radio,
.bhsInterviewTimingList .ant-radio:hover .ant-radio-inner,
.bhsInterviewTimingList .ant-radio-input:focus + .ant-radio-inner,
.bhsInterviewTimingList .ant-radio-checked .ant-radio-inner,
.bhsInterviewTimingList .ant-radio-checked::after,
.bhsInterviewTimingList .ant-radio-inner::after {
  border-color: #118936;
}

.bhsInterviewTimingList .ant-radio-inner::after {
  background-color: #118936;
}

.bhsInterviewTimingItem {
  min-width: 49%;
  display: flex;
  gap: 0.125rem;
  margin: 0rem;
  padding: 0.4375rem 0.625rem;
  border-radius: 0.25rem;
  align-items: center;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
}

.bhsInterviewTimingItem .ant-radio {
  top: 0rem;
}

.bhsInterviewTimingItem .textBold {
  font-size: 0.8125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 0.1875rem;
}

.bhsInterviewTimingItem .textLight {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
}

.bhsInteviewAccepted {
  flex-direction: column;
  align-items: flex-start;
}

.bhsReasonSection {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.bhsFileSection {
  width: 56%;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 0.5rem 0.9375rem 0.5rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
  cursor: pointer;
}

.bhsFileSection > img {
  width: 1.25rem;
  height: 1.625rem;
  object-fit: cover;
}

.bhsFileInfo {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.bhsFileTitle {
  font-size: 0.8125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.bhsFileSize {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
}

.bhsButtonSection {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 0.625rem;
}

.bhsBtn {
  display: grid;
  place-items: center;
  min-width: 9.0625rem;
  min-height: 1.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border-radius: 0.25rem;
  background-color: #118936;
  cursor: pointer;
}

.bhsDisableBtn {
  cursor: not-allowed;
  background-color: #e4e4e4;
}

.bhsError {
  margin: -0.4375rem 0rem 0.9375rem;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #db2828;
}
