.container {
  align-self: center;
  margin: auto;
  padding: 1rem 1.2rem 0;
}

.socialLogin {
  text-align: center;
}

.social-buttons-top-section {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* padding: 1rem 1.9rem 0; */
  align-items: center;
  /* text-align: center; */
}

.buttonFont {
  margin: 0.2rem 0 0 1.313rem;
  font-size: 1rem;
  /* font-family: SF-Pro-Text-Medium; */
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.white-bg-button-font {
  margin: 0.1rem 0 0 1.93rem;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5e5e5e;
}

.authorization-sign-in-top-heading {
  /* margin: 0 96px 27.7px 95px; */
  /* font-family: -apple-system, BlinkMacSystemFont, sans-serif; */
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3e3e3e;
  margin-bottom: 1.7rem;
}

.continueSocial {
  width: max-content;
  flex-grow: 0;
  margin: 1.5rem 3rem;
  font-family: Roboto;
  font-size: 1.1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.78;
  letter-spacing: normal;
  text-align: left;
  color: #888888;
}

.social-button {
  display: flex;
  max-width: 20.6rem;
  width: 20.6rem;
  height: 2.5rem;
  flex-grow: 0;
  padding: 0.5rem 0;
  border-radius: 0.2rem;
  color: white;
  cursor: pointer;
  margin-bottom: 0.7rem;
}

.facebook-color {
  background-color: #4267b2;
}

.linkedin-color {
  background-color: #0a66c2;
}

.google-color {
  border: solid 1px #e4e4e4;
}

.apple-color {
  border: solid 1px #e4e4e4;
}

.divider-main {
  display: flex;
  justify-content: center;
  width: 100%;
  color: #d4d4d4;
}

.divider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  color: #5e5e5e;
  width: 20rem;
  font-size: 14px;
  margin: 0.5rem 0 1.2rem;
}

@media screen and (max-width: 900px) {
  .container {
    align-self: center;
    margin: auto;
    padding: 1.5rem 0 0;
    height: 13rem;
  }

  .continueSocial {
    height: 0;
    width: auto;
    margin: 1.2rem 2rem 1.4rem;
    font-family: Roboto;
    font-size: 0.9rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #888888;
  }

  .social-buttons-top-section {
    align-items: center;
    /* padding: 1rem 0 0; */
  }

  .authorization-sign-in-top-heading {
    margin-bottom: 1.875rem;
  }
}
