.tos-page-layout {
  margin: 1.25rem 6.188rem 1.75rem 2.5rem;
}
.tos {
  width: 10.188rem;
  height: 1.4rem;
  margin: 4.438rem;

  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}
.last-update {
  width: 10.688rem;
  height: 1.063rem;
  margin: 0.625rem 66.813rem;
  font-size: 0.825 rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}
.tos-page-layout p:not(.link-text) {
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}
.link-text{
  height: 1rem;
  color:#4054b2;
  margin-bottom:1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;

}
.handIcon{
  padding-right:0.5rem;
}
@media screen and (max-width: 768px) {
 p{
   margin:1rem inherit;
 }
.tos-card>.ant-card-body{
  padding:0.25rem;
 }
 .tos-page-layout {
  margin:1rem;
}
}
