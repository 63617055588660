.DeclinePageContainer{
    margin: auto;
    width: 100%;
    padding: 30px;
    padding-top: 50px;
    align-content: center;
    align-items: center;
    text-align: center;
    /* background-color: red; */
}
.title-div{
text-align: center;
margin: auto;
}
.Select-div{
    background-color: white;
    border: 1px solid #EEEEEE;
    padding: 20px;
    padding-bottom: 40px;
    align-items: center;
    align-content: center;
    margin: auto;
}
.icon-div{
    border: 1px solid lightgray;
    background-color: #EEEEEE;
    padding: 4px;
    width: 95%;
}
.page-body{
    margin: auto;
    margin-top: 60px;
    width: 100%;
}
.Btns-row{
    /* background-color: red; */
    margin-top: 10%;
    text-align: center;
    /* width: 95%; */
    /* margin: auto; */
}
.cancel-btn{
    width: 100%;
    background-color: #c0c0c0;
    padding: 10px;
    color:white;
    text-align: center;
    font-size: 18px;
    transition: all 200ms;
    border:1px solid #b3b3b3;
    vertical-align: middle;
    line-height: 1.30;
    height: auto;
    border-radius: 5px;
    /* margin-left: 5%; */
}
.cancel-btn-div{
    /* text-align: center;
    align-items: center; */
    margin: auto;
}
.cancel-btn:hover{
    background-color: darkgray;
    border: 1px solid lightgrey;
    color:white;
}
.decline-btn{
    width: 100%;
    background-color: #2FC7DC;
    opacity: 70%;
    padding: 10px;
    color:white;
    text-align: center;
    font-size: 18px;
    transition: all 200ms;
    border:1px solid #b3b3b3;
    vertical-align: middle;
    line-height: 1.30;
    border-radius: 5px;
    height: auto;
    /* margin-left: 5%; */
    /* margin-right: -25%; */
}
.decline-btn-div{
    margin: auto;
}
.decline-btn:hover{
    opacity: 100%;
    background-color: #2FC7DC;
    color: white;
}