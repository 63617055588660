.progress-bar {
    fill: none;
    stroke: green;
    stroke-width: 3;
    stroke-linecap: round;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    transition: stroke-dashoffset 0.35s;
}

.circular-progress-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.circular-progress-bar2 {
    width: 6.25rem;
    height: 6.25rem;
    position: relative;
}

.circular-progress-bar {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.progress-bar-background {
    fill: none;
    stroke: #f4f4f4;
    stroke-width: 3;
}

.small-circle {
    width: 1.375rem;
    height: 1.375rem;
    border-radius: 50%;
    background-color: #118936;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.percentage-text {
    font-size: .5rem;
    color: #fff;
}

.CircularProgressTooltip .ant-tooltip-inner {
    font-size: .625rem;
    font-weight: normal;
    color: #fff;
    align-items: center;
    display: flex;
}

@media screen and (max-width: 768px) {
    .small-circle {
        width: 1.25rem;
        height: 1.25rem;
    }

    .percentage-text {
        font-size: .4375rem;
        font-weight: bold;
        color: #fff;
    }
}