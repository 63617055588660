/* .container-error {
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  height: auto;
  border: 1px solid black;
  align-self: center;
  margin: auto;
  background-color: orangered;
  border-radius: 0.125rem;
} */
.container-error {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  /* width: 20rem; */
  width: -webkit-fill-available;
  height: auto;
  /* border: 1px solid black; */
  align-self: center;
  margin: auto;
  color: #ff4d4f;
}
.container-success {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20rem;
  height: auto;
  border: 1px solid black;
  align-self: center;
  margin: auto;
  background-color: green;
  border-radius: 0.125rem;
}
