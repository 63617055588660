.Profile_rating_main_viewbox{
    border-radius: 0.25rem;
    background-color: #fff;
    margin-top: 1.25rem;
    padding: 1.25rem 1.25rem 1.25rem 1.25rem ;
}

.profile_rating_frst_dv_flex{
    display:flex;
     justify-content: space-between;
     flex-direction: row;
}
.profile_rating_frst_dv{
 display:flex;
 gap: 0.25rem;
 margin-bottom: 0rem;
}
/* .profile_rating_frst_dvv{
    display:flex;
    gap: 0.25rem;
   } */
.profile_rating_frst_dv_ele1{
    font-size: 1.125rem;
    font-weight: 600;
    color: #3e3e3e;
    margin-right: 0.375rem;
}
.profile_rating_frst_dv_ele2{
    width: 0.831rem;
  height: 0.831rem;
   margin-top: 0.188rem;
color: #ffba08;
  
}
.profile_rating_frst_dv_ele22{
    width: 0.831rem;
  height: 0.831rem;
   margin-top: 0.313rem;
color: #ffba08;
}
.profile_rating_frst_dv_ele3{
    font-size: 1rem;
  font-weight: normal;
  color: #ffba08;
  margin-top: 0.125rem;
 
}
.profile_rating_frst_dv_ele4{
    font-size: 1rem;
    font-weight: normal;
    color: #7e7e7e; 
    margin-top: 0.125rem;
}

dropdownClasskilllistingss .filterMenuPUB{
   width: 13.063rem; 
   height: 6.125rem;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.dropdownClasskilllistingss .ant-dropdown-menu {
    width:  13.063rem;
    height: 6.125rem;
    flex-grow: 0;
    padding: 15.04 0;
    border-radius: 0.25rem;
    box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
  }

  .Profile_rating_main_viewbox .progressiveBar   
.ant-progress-bg {
  background-color: #ffba08; 
}
.progressiveBar {
    width: 12.5rem;
   
}
.progressiveBar .ant-progress-inner{
    background-color:  #c4c4c4;
}

.profile_rating_frst_dv_ele5{
    font-size: 1rem;
    color: #5e5e5e;
}
.profile_rating_frst_dv_ele6{
    font-size: 1rem;
  font-weight: 600;
  color: #1e1e1e;
  margin-right: 0.5rem;
  margin-left: 0.938rem;
}
.profile_rating_frst_dv_ele7{
    width: 0.625rem;
  height: 0.625rem;
  margin-top: 0rem;
}

.profile_rating_scnd_dvv-ele-main{
    display: flex;
    gap: 1.25rem;
    margin-bottom: 0.625rem !important;
}
.profile_rating_scnd_dvv-ele1{
    font-size: 0.875rem;
    font-weight: 500;
    color: #1e1e1e;
}
.profile_rating_scnd_dvv{
    display: flex;
    gap: 7.438rem;
    margin-top: 1.875rem;
    margin-bottom: 1.938rem;
}
.profile_rating_third_dvv-ele-one{
    font-size: 1rem;
    font-weight: 500;
    color: #1e1e1e;
    margin-bottom: 1.063rem;
    margin-top: 0rem;
}
.profile_rating_third_dvv-ele-main{
  display: flex;
  gap: 5.188rem !important;
  margin-bottom: 0.813rem !important;
  flex-direction: row !important;    
}
.profile_rating_third_dvv-ele1{
    width: 7.75rem;
    font-size: 0.875rem;
    color: #3e3e3e;
}
 
.profile_rating_third_dvv-ele3{
    font-size: 0.875rem;
    color: #ffba08;
}
.profile_rating_third_flex_group{
    display:flex;
    gap:0.313rem;
}
.above_rating_breakdown{
    margin-left: 0.375rem;
}
.profile_rating_fourth_dvv-ele-main{
    display: flex;
    gap: 0.625rem;
    margin-bottom: 1rem;
}
.profile_rating_frst_dv_ele23{
    width: 0.831rem;
    height: 0.831rem;
     margin-top: 0.188rem;
    color: #ffba08;
}
.profile_rating_star_rating{
    display: flex;
    gap: 0.25rem;
}
.countri{
    font-size: 0.875rem;
    font-weight: 500;
    color: #1e1e1e;
    margin-left: 0.313rem;
}
.reyting{
    font-size: 0.75rem;
    color: #ffba08;
    margin-top: 0.188rem;
}
.profile_company{
    font-size: 0.875rem;
  font-weight: 500;
  color: #1e1e1e;
  
}
.rating_descriptionn{
    font-size: 0.75rem;
    line-height: 1.83;
    color: #3e3e3e;
}
.rating_dyuration{
    font-size: 0.75rem;
    color: #7e7e7e;
    margin-top: 0.375rem;
}
.profile_rating_cardmain_seeMore{
  color: #118936;
}
.profile_outlineplus{
    font-size: 0.625rem;
    margin-right: 0.063rem;
}
.profile_continueMor{
    font-size: 1rem; 
}
 
@media screen and (max-width: 768px) {
   .profile_rating_frst_dv_flex{
    display: flex;
    flex-direction: column;
   }

   .profile_rating_frst_dv_ele1{
    font-size: 1rem;
    font-weight: 600;
    color: #3e3e3e;
   }

   .profile_rating_frst_dv_ele3{
    font-size: 0.875rem;
   }
   .profile_rating_frst_dv_ele4{
    font-size: 0.875rem;
   }
   .profile_rating_scnd_dvv{
    margin-top: 1.438rem;
    margin-bottom: 0rem;
    display: flex;
    flex-direction: column;
    gap: 0rem;
}
.profile_rating_frst_dv{
     margin-bottom: 1.25rem;
   }
.filterpubs{
    display: flex;
    justify-content: space-between;
    padding:1.063rem 0.625rem 1rem 0.625em;
    border: solid 0.063rem #e4e4e4;
    background-color: #fff;
}
.profile_rating_frst_dv_ele6 {
    font-size: 0.875rem;
    font-weight:normal;
    color: #1e1e1e;
}
.profile_rating_frst_dv_ele7{
    width: 0.813rem;
  height: 0.813rem;
  margin-top: 0.375rem;
}
.profile_rating_scnd_dvv-ele1 {
    font-size: 0.75rem;
}

.Profile_rating_main_viewbox{
    padding: 1.25rem 1.25rem 1.25rem 0rem ;
    margin-top: 0rem;
}
.above_rating_breakdown{
    margin-left: 0rem;
}
.profile_rating_third_dvv-ele-one {
    font-size: 0.875rem;
    font-weight: 500;
    color: #1e1e1e;
    margin-bottom: 0.938rem;
    margin-top: 0.25rem;
}
.profile_rating_third_dvv-ele1 {
    font-size: 0.75rem;
    color: #3e3e3e;
}
.profile_rating_third_dvv-ele3 {
    font-size: 0.75rem;
    color: #ffba08;
}
.profile_rating_frst_dv_ele2 {
    width: 0.75rem;
    height: 0.75rem;
    margin-top: 0rem;
}
.profile_rating_third_dvv-ele-main {
    display: flex;
    flex-direction: column; 
    gap: 0.625rem;
    margin-bottom:0.75rem;
}
.countri {
    font-size: 0.75rem;
}
.profile_rating_frst_dvv{
     max-width: 20.625rem;
  }

.profile_rating_scnd_dvv-ele-main{
    display: flex;
    gap: 1.875rem;
    margin-bottom: 0.625rem;
}
}
   
