.carousel-container {
  width: 100%;
  /* display: flex; */
  flex-direction: column;
}

.carousel-wrapper {
  /* display: flex; */
  width: 100%;
  position: relative;
}

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-content {
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none;
  /* hide scrollbar in IE and Edge */
  scrollbar-width: none;
  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}

.twoCard {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.carousel-content.show-2 > * {
  width: 50%;
}

.carousel-content.show-3 > * {
  width: calc(100% / 3);
}

.carousel-content.show-4 > * {
  width: calc(100% / 4);
}

.left-arrow,
.right-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.5rem;
  background-color: #fff;
  border: 0.063rem solid #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}

.left-arrow {
  left: -0.813rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: .5rem;
}

.right-arrow {
  right: -0.813rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: .1875rem;
}
.left-arrow .iconAnt {
  width: 0.5625rem;
  height: 0.875rem;
  color: "#4e4e4e";
}
.right-arrow .iconAnt {
  width: 0.5625rem;
  height: 0.875rem;
  color: "#4e4e4e";
}

.HomePage-Carousel-Card {
  padding-left: 0.469rem;
  padding-right: 0.469rem;
  width: 100%;
}

@media (hover: none) and (pointer: coarse) {
  .left-arrow,
  .right-arrow {
    display: none;
  }
}
