.skillListingCard {
  display: flex;
  flex-direction: row;
}

.statusSkill {
  display: flex;
}

.skillListingCard .information {
  display: flex;
  width: 15rem;
  line-height: 2;
}

.skillListingCard .infoRight {
  width: 11rem;
}

.theStatusPublished {
  color: var(--statusColorr-blue);
  background-color: var(--statusColor-lightBlue);
  margin-right: 5px;
  padding: 5px 10px;
  width: fit-content;
  border-radius: 3px;
  font-size: 0.8rem;
}

.skillListingCard .eyeOnYou {
  padding: 0.3rem 0.3rem 0;
  width: 4rem;
  display: flex;
  justify-content: space-around;
  background-color: #f0f0f0;
  margin-right: 5px;
  border-radius: 3px;
}

.skillListingCard .bidsOnYou {
  padding: 0.3rem 0.7rem 0;
  background-color: var(--primaryColor);
  color: white;
  border-radius: 3px;
}
