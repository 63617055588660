.bhsModel .ant-modal-header {
  padding: 0.9375rem;
  border-bottom: 0.0625rem solid #e4e4e4;
}

.bhsModel .ant-modal-close {
  top: -0.1875rem;
  right: -0.4375rem;
}

.bhsModel .ant-modal-title {
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.bhsModel .bhsModelBody {
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
  padding: 1.25rem;
}

.bhsFileUploader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  padding: 0.625rem 0rem;
  font-size: 1.25rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #118936;
  border-radius: 0.25rem;
  border: solid 0.0625rem #118936;
  background-color: #fff;
  cursor: pointer;
}

.bhsFileUploader span {
  font-size: 0.875rem;
}

.bhsFileView .bhsFileSection {
  width: 100%;
  gap: 0.625rem;
  padding: 0.625rem 1.25rem;
}

.bhsFileView .bhsFileSection > img {
  width: 1.25rem;
  height: 1.625rem;
  object-fit: cover;
}

.bhsFileView .bhsFileInfo {
  gap: 0.625rem;
}

.bhsFileView .bhsFileTitle {
  font-size: 1.0625rem;
}

.bhsFileView .bhsFileSize {
  font-size: 1rem;
}

.bhsModel .bhsModelHeading {
  margin-bottom: 0.3125rem;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.bhsModel .bhsModelReschedule {
  display: flex;
  gap: 0.625rem;
  text-align: center;
  margin-bottom: 0.9375rem;
  flex-direction: column;
}

.bhsModel .bhsModelOption {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.bhsModel .bhsModelOptionItem {
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  padding: 0.75rem 0.9375rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #d4d4d4;
  background-color: #fff;
  cursor: pointer;
}

.bhsModel .bhsModelOptionItem:hover,
.bhsModel .bhsModelOptionItemSelected {
  background-color: #e4ffec;
}

.bhsModel .bshRescheduleDatePicker {
  width: 100%;
  padding: 0rem;
  border-radius: 4px;
  border: 0.0625rem solid #e4e4e4;
  cursor: pointer;
}

.bhsModel .bshRescheduleDatePicker .ant-picker-input {
  flex-direction: row-reverse;
}

.bhsModel .bshRescheduleDatePicker .ant-picker-input > input {
  padding-left: 0.625rem;
  font-size: 0.75rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.bhsModel .bshRescheduleDatePicker .ant-picker-clear {
  right: 0.625rem;
}

.bhsModel .bshRescheduleDatePicker .ant-picker-suffix {
  margin: 0rem;
  border-right: 0.0625rem solid #e4e4e4;
  padding: 0.4375rem 1.125rem 0.4375rem 1.125rem;
}

.bhsModel .bhsModelButton {
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  padding: 0.75rem 0rem;
  text-align: center;
  border-radius: 0.3125rem;
  border: solid 0.0625rem #118936;
  background-color: #118936;
  cursor: pointer;
}

.bhsModel .bhsModelRescheduleButton {
  color: #118936;
  background-color: #fff;
}
