.Card-container {
  width: 22rem;
  background-color: #e7f3eb;
  padding: 4%;
}

.offer-time-stamp {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.offer-time-stamp .time-text {
  color: #b4b4b4;
}

.offer-time-stamp .tick-icon {
  display: flex;
  align-items: center;
  color: #007cf0;
}

.offer-card-offer-div {
  background-color: #fff;
  padding: 4%;
  border-radius: 4px;
}

.offer-date {
  padding: 0.5rem 0.6rem;
  /* margin: 0.5rem 0.5rem; */
  width: 100%;
  height: 3.75rem;
  flex-grow: 0;
  margin-bottom: 0.935rem !important;
  /* padding: 0 13rem 0 0; */
  border-radius: 0.313rem;
  /* border: solid 0.06rem #d4d4d4; */
  background-color: #fff;
}

.offer-card-details {
  justify-content: space-between;
  margin-bottom: 1%;
}

.offer-card-details div {
  color: #7e7e7e;
  font-size: 14px;
  font-weight: 500;
}

.user-threat {
  padding: 10px;
  /* background-color: #118936 !important; */
}

.datepicker {
  width: 90%
}

.bottom-container {
  padding: 0px 20px;
  /* text-align: center; */
}

.offer-card-attachmentdiv {
  background-color: #fff;
  padding: 2.5% 3.5%;
  border-radius: 4px;
  margin-top: 2%;
}

.offer-card-attachement-details {
  color: #4e4e4e;
  font-size: 14px;
  margin-left: 2%;
}

.open-document {
  /* margin-top: 0.238rem; */
  margin-bottom: 0.5rem;
  font-size: 13px;
  text-align: center;
  line-height: 1.57;
  color: #4e4e4e;
}

.document-details {
  height: 68px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.offer_accepted_datepicker {
  width: 98%;
  margin-right: 10px;
}

.offer_accepted_datepicker {
  width: 98%;
  margin-right: 10px;
}

.top-container {
  margin-left: 10px;
}

.file-name {
  font-size: 0.875rem;
  font-weight: bold;
  height: 25px;
  width: 207px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.remove-attachment-icon {
  position: absolute;
  right: 1rem;
  margin-top: -6px;
}

.signature-attachment-container{
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  margin: auto;
  border-radius: 4px;
  height: 50px;
}

.offercard-document {
  display: flex;
  width: 98%;
  /* margin-left: 0.4rem; */
  background-color: #fff;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}

.interested-decline {
  background-color: #f2f2f2;
  color: #9e9e9e;
  cursor: default;
}

.buttons {
  width: 100%;
  /* padding-top: 2px; */
  background-color: #ffffff;
  margin-top: 3%;
  display: flex;
  justify-content: space-between;
}

.btncard {
  /* padding: 20px 50px; */
  width: 48%;
  /* background-color: #e7f3eb; */
  background-color: #e7f3eb;
  height: 40px;
  flex-grow: 0;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #118936;
  border: solid 1px #e4e4e4;
  border-radius: 5px;
}

.offer-details {
  font-size: 0.875rem;
  margin-top: 1rem;
}


.date {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 2%;
}

.offer-modal-browse-files {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: solid 1px #118936;
  border-radius: 4px;
  height: 42px;
  align-items: center;
}

.bid-file-upload-icon {
  cursor: pointer;
}

.bid-file-upload-label {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
}

.bid-decline-button-offer {
  display: flex;
  height: 2.625rem;
  border-radius: 0.313rem;
  border: solid 1px var(--primaryColor);
  background-color: var(--primaryColor);
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin-top: 30px;
  width: 100%;
}

.bid-file-upload-text {
  color: #118936;
  font-weight: 600;
}

.time {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
  margin-top: 2%;
}

@media screen and (max-width: 375px) {
  .Card-container {
    width: 20rem;
  }
} 