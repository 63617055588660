.g-sgigs-receiveBidCard {
  display: flex;
  /* flex: 0 0 33.333333%; */
  flex-direction: row;
  justify-content: space-evenly;
}

.g-sgigs-thecard {
  /* width: 28.7vw;
  height: 14.5rem;
  border: 1px solid #eee;
  margin: 20px 20px 20px 0;
  padding: 20px 0 0;
  border-radius: 3px;
  box-shadow: 1px 1px 1px 1px #eee;
  background-color: #fff; */
  /* width: 390px; */
  width: 24.375rem;
  /* height: 284px; */
  /* margin: 20px 15px 20px 40px; */
  margin: 1.26rem 1.26rem 1.26rem 1.512rem;
  padding: 0 0 0.756rem;
  border-radius: 0.126rem;
  border: solid 0.063rem #e4e4e4;
  background-color: #fff;
  position: relative;
}

.g-sgigs-thecard .g-sgigs-upAbove {
  padding: 7.5rem 0.75rem 0rem;
}

.g-sgigs-theStatus {
  padding: 0.313rem 0.625rem;
  background-color: var(--statusColor-cream);
  width: fit-content;
  border-radius: 0.188rem;
  color: var(--statusColor-brown);
  font-size: 0.8rem;
  margin-right: 0.313rem;
}

.g-sgigs-image {
  /* width: 389px; */
  /* width: 442px; */
  width: 100%;
  height: 7.5rem;
  /* margin: 0 0 15px; */
  /* margin: 0rem 0rem 0rem -0.8rem; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
}

.g-sgigs-theTitle {
  width: 100%;
  height: 1.188rem;
  margin: 0.938rem 8.75rem 0rem 0rem;
  font-family: SFProText;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}
.g-sgigs-theFacility {
  /* font-size: 0.8rem;
  font-weight: 0;
  color: grey; */
  width: 18.75rem;
  height: 1.063rem;
  margin: 0.625rem 13.625rem 0rem 0rem;
  font-family: SFProText;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
  /* margin: 0.5rem 0; */
}

.g-sgigs-information {
  display: flex;
  justify-content: space-between;
  margin: 0.75rem 0rem 0rem 0rem;
  height: 1.1rem;
}
.g-sgigs-star {
  width: 0.875rem;
  height: 0.75rem;
  flex-grow: 0;
  /* margin: 0.75rem 0.5rem 0 0; */
  object-fit: contain;
  border-radius: 0.031rem;
  color: #ffba08;
}
.s-sgigs-rating {
  width: 1.313rem;
  height: 1.063rem;
  flex-grow: 0;
  /* margin: 0.75rem 0rem 0rem 0rem; */
  font-family: SFProText;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffba08;
}

.g-sgigs-thecard .ant-rate-star-second {
  color: #9e9e9e;
}

.g-sgigs-review {
  display: flex;
  width: 20rem;
  font-size: 0.875rem;
  font-family: SFProText;
  margin: -0.188rem 0rem 0rem 0rem;
  color: #7e7e7e;
  justify-content: space-between;
}

.g-sgigs-Budgets {
  display: flex;
  /* justify-content: baseline; */
  width: 6.5rem;
  height: 1.5rem;
  flex-grow: 0;
  margin: 0 0 0rem;
  /* font-family: SFProText; */
  font-size: 1.25rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #118936;
}

.g-sgigs-gigcard-interestedBtn {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin: -0.4rem 0 0rem;
  height: 1.5rem;
  /* width: 17.5rem; */
}
.g-sgigs-div1 {
  width: 4.25rem;
  height: 0.875rem;
  flex-grow: 0;
  margin: 0rem 2.25rem 0 0.25rem;
  font-family: SFProText;
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}
.g-sgigs-gigcard-interestedBtn .g-sgigs-div2 {
  width: 6rem;
  background-color: var(--primaryColor);
  font-size: 0.9rem;
  color: white;
  border-radius: 0.188rem;
  padding-top: 0.125rem;
}
.g-sgigs-div2 {
  cursor: pointer;
}

.g-sgigs-receiveTime {
  font-size: 0.65rem;
  font-style: italic;
  color: #777;
  padding: 0.5rem 1rem;
  width: max-content;
}

@media screen and (max-width: 700px) {
  .g-sgigs-thecard {
    width: -webkit-fill-available;
  }
}
.span-icon {
  font-size: 0.95rem;
  /* padding-top: .06rem; */
}
.span-item {
  font-size: 0.9rem;
  width: 24rem;
  margin-right: 0.7rem;
}
