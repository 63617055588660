.GigJobDetailsITUser-Cantainer .Skill-PieChart-Component {
    display: flex;
    justify-content: space-between;
    font-size: .875rem;
    max-width: unset;
    flex-direction: column;
    margin: unset;
    padding: 1.875rem;
}

.GigJobDetailsITUser-Cantainer .GigJobDetailsITUser-title {
    font-size: 1rem;
    color: #7e7e7e;
    padding-bottom: 1.25rem;
}

.GigJobDetailsITUser-Cantainer .GigJobDetailsITUser-Cantainer-Para {
    margin-left: .9375rem;
    margin-right: .9375rem;
}

@media screen and (max-width: 768px) {
    .GigJobDetailsITUser-Cantainer .GigJobDetailsITUser-title {
        font-size: .75rem;
        color: #7e7e7e;
        padding-bottom: .9375rem;
        padding-top: .9375rem;
    }

    .GigJobDetailsITUser-Cantainer .Skill-PieChart-Component {
        font-size: .75rem;
        padding: unset;
    }
}