.job_detials_gig_card {
  display: flex;
  flex-direction: column;
  flex: 1.558 1;
  border: solid 1px #e4e4e4;
  border-radius: 4px;
}
.ractangle-job-page-height {
  max-height: 20rem;
  padding: 0px 0px 0px !important;
}
.ractangle-right-padding {
  padding: 1.25rem 1.25rem 0rem;
  border-top: 0.063rem solid #e4e4e4 !important;
  border-left: 0.063rem solid #e4e4e4 !important;
  border-right: 0.063rem solid #e4e4e4 !important;
  border-bottom: none !important;
}
.Duration-job-detail-margin {
  margin-top: 0px !important;
  color: #e4e4e4;
}
.job_detials_gig_card .ractangle-right {
  border-top: 0.063rem solid #e4e4e4 !important;
  border-left: 0.063rem solid #e4e4e4 !important;
  border-right: 0.063rem solid #e4e4e4 !important;
  border: none !important;
}
.JobDetails-Heading-Section {
  font-size: 16px;
  font-weight: 500;
  color: #1e1e1e;
}
.JobDetails-point {
  display: flex;
  flex-direction: column;
  /* padding-left: 1.25rem; */
  /* padding-top: 1.2625rem; */
}
.JobDetails-main-div {
  padding-left: 1.25rem;
  padding-top: 1.25rem;
}
.JobDetails-line-height {
  line-height: 2.5;
  color: #3e3e3e;
  font-size: 0.875rem;
}
.JobDetails-paragraph {
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 1.64;
}
.JobDetails-Doller-text {
  color: #118936;
  padding-right: 4px;
}
.TickMark-point:before {
  content: "\2713\0020";
  padding-right: 0.625rem;
  /* font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial; */
}
