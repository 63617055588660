.noViewHistorySkill {
  width: 100%;
  height: 26.38rem;
  flex-grow: 0;
  /* margin: 1.25rem 1.25rem 9.19rem 1.19rem; */
  padding: 1.25rem 0;
  border-radius: 0.25rem;
  border: solid 0.06rem #e4e4e4;
  background-color: #fff;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.noViewHistorySkill .title-noViewHistorySkill {
  margin-left: 1.25rem;
  margin-bottom: 5rem;
  width: -webkit-fill-available;
  text-align: left;

  font-size: 1rem;
  font-weight: 600;
  color: #1e1e1e;
}

.noViewHistorySkill .emptyBox-img {
  height: 9.38rem;
  margin-bottom: 1.938rem;
}

.noViewHistorySkill .addhistory-skillListing {
  width: max-content;
  height: 2.38rem;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.63rem;
  padding: 0.5rem 1.25rem;
  border-radius: 0.25rem;
  background-color: var(--primaryColor);

  /* text */
  /* font-size: 1rem; */
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* text-align: left; */
  color: #fff;

  cursor: pointer;
}

.nohistoryEX {
  font-size: 1rem;
  color: #1e1e1e;
}
