.StatsJobDetailsIT-Cantainer .Skill-PieChart-Component {
    display: flex;
    /* min-width: 25rem; */
    flex-direction: column;
}

.StatsJobDetailsIT-Cantainer .StatsJobDetailsIT-stats {
    font-size: 1rem;
    font-weight: 500;
    color: #7e7e7e;
    padding-bottom: .3125rem;
}

.StatsJobDetailsIT-Cantainer .StatsJobDetailsIT-Bids-sent {
    display: flex;
    justify-content: space-between;
    padding-top: .9375rem;
    font-size: .875rem;
    font-weight: normal;
    color: #3e3e3e;
}

@media screen and (max-width: 768px) {
    .StatsJobDetailsIT-Cantainer .StatsJobDetailsIT-stats {
        font-size: .75rem;
        font-weight: normal;
    }
}