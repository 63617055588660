.Currency_Type_And_Symbol {
  margin-left: -0.25rem;
  gap: 0.125rem;
}

.Currency_Menu_Container {
  width: 100%;
  padding: 1.25rem;
}

.Currency_Menu_Header > h3,
.Currency_Menu_Content > h3,
.Currency_Menu_Content > div > span {
  font-size: 0.875rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.Currency_Menu_Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
}

.Currency_Menu_Header > h3 {
  font-weight: 600;
  margin-bottom: 0rem;
  color: #3e3e3e;
}
.Currency_Menu_Header > img {
  min-width: 1.25rem;
  min-height: 1.25rem;
  object-fit: contain;
  cursor: pointer;
}

.Currency_Menu_List_Item {
  display: flex;
  gap: 0.625rem;
  align-items: center;
  margin-bottom: 0.9375rem;
  cursor: pointer;
}

.Currency_Menu_List_Item > .Check_Button_Icon {
  min-width: 1rem;
  min-height: 1rem;
}

.Currency_Menu_List_Item:last-child {
  margin-bottom: 0rem;
}

.Currency_Menu_Content > h3 {
  margin-bottom: 0.375rem;
  font-weight: 500;
  color: #1e1e1e;
}

.Currency_Menu_Content > div {
  display: flex;
  text-align: center;
}

.Currency_Menu_Content > div > span {
  margin-bottom: 0rem;
  color: #5e5e5e;
}

.Currency_Menu_Content > div > img {
  min-width: 0.5687rem;
  min-height: 0.625rem;
  opacity: 0.75;
}

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 62.5rem) {
  .Currency_Menu_Header {
    display: none;
  }

  .Currency_Menu_Content > h3 {
    display: none;
  }

  .Currency_Dropdown_Mobile_View > .ant-row {
    flex: 1;
    justify-content: space-between;
  }

  .Currency_Dropdown_Mobile_View .Arrow_Currency_Change {
    margin-right: 0.4rem;
  }

  .profileDropFourth {
    padding-bottom: 0.625rem;
  }

  .Currency_Menu_Container {
    height: auto !important;
  }

  .Currency_Menu_Container {
    flex-direction: column;
    align-items: flex-start !important;
    padding-left: 1.25rem !important;
  }

  .Currency_Menu_Container > .Currency_Menu_List_Item {
    flex-basis: fill;
    gap: 0.375rem;
  }

  .Currency_Type_And_Symbol {
    margin-left: -0.125rem;
    gap: 0.25rem !important;
  }

  .Currency_Type_And_Symbol > img {
    margin-top: -0.1875rem;
  }

  .Currency_Type_And_Symbol > span {
    margin-left: 0.125rem;
  }
}
/********** For Mobile View  Media Query End **********/
