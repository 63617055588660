.upgradeYourself-container {
  display: flex;
  gap: .9375rem;
  background-color: #f8f8f8;
  justify-content: space-between;
  padding-top: 0.9rem;
}

.upgradeYourself-container-parent-Div  {
  display: flex;
  flex-flow: column;
  width: 13.75rem;
  padding: 0 0 0.875rem;
  border-radius: 0.25rem;
  border: solid 1px #e4e4e4;
  background-color: #fff;
  justify-content: space-between;
}

.upgradeYourself-container-images {
  height: 11.625rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.upgradeYourself-cardcontent-subHeading {
  font-size: 0.875rem;
  text-align: left;
  color: #1e1e1e;
  padding-top: 0.625rem;
  /* padding-bottom: 1.25rem; */
  text-indent: 0.1rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.upgradeYourself-cardcontent-heading {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.38;
  text-align: left;
  color: #1e1e1e;
  height: 1.25rem;
}
.upgradeYourself-primary-button {
  max-width: 9.6rem;
  /* display: flex; */
  flex-direction: row;
  justify-content: center;
  padding: 0.625rem 1rem;
  border-radius: 0.125rem;
  border: solid 0.063rem #4054b2;
  cursor: pointer;
}
.upgradeYourself-primary-button-learn-more {
  padding: 0.625rem 1.875rem;
}
.upgradeYourself-primaryButton-content {
  max-width: 4.813rem;
  max-height: 1.375rem;
  flex-grow: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.38;
  text-align: left;
  color: #4054b2;
}

.upgradeYourself-cardcontent {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  padding-left: 0.938rem;
  padding-top: 0.938rem;
  gap: 1.25rem;
}

@media screen and (max-width: 480px) {
  .upgradeYourself-container-parent-Div  {
    width: 100%;
    /* margin: 0.938rem 0.938rem 0 0; */
  }
  .upgradeYourself-container-images {
    width: 100%;
    height: 100%;
  }
  .upgradeYourself-cardcontent {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    /* padding-left: 1.2rem; */
    gap:unset;
  }
  .upgradeYourself-container{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    background-color: #fff;
    justify-content: space-between;
  }
  .upgradeYourself-primaryButton-content {
    font-weight: 600;
  }
  .upgradeYourself-cardcontent-subHeading{
    margin-bottom: 1.25rem;
  }
}
