.Message-box-container {
  background: white;
  cursor: pointer;
}

.Message-box-container-search {
  background-color: #e4ffec !important;
  cursor: pointer;
}

.Message-box-container-box {
  min-width: max-content;
  display: flex;
  justify-content: flex-start;
  /* border: solid 0.063rem #e4e4e4; */
  border-top: solid 0.063rem #e4e4e4;
  /* border-bottom: solid 0.031rem #e4e4e4; */
  padding: 0.938rem;
  /* border-radius: 0.25rem; */
  padding-bottom: 0.99px;
  line-height: none;
}

.Message-box-container-message {
  min-width: max-content;
  display: flex;
  justify-content: flex-start;
  /* border: solid 0.063rem #e4e4e4; */
  border-top: solid 0.063rem #e4e4e4;
  /* border-bottom: solid 0.031rem #e4e4e4; */
  padding: 0.625rem;
  /* border-radius: 0.25rem; */
  padding-bottom: 0.99px;
  line-height: none;
}

span.countPending {
  margin-left: 48px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  height: 16px;
  width: 20px;
  flex-grow: 0;
  padding: 5px 9px;
  background-color: #118936;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:5px;
}
.messageMeta {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center; */
  gap: 0.313rem;
  /* margin-top: 3px; */
  display: flex;
  flex-direction: column;
  align-content: space-between;
  align-items: center;
  height: 42px;
}
.Message-box-profile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.profile-img-candidate {
  margin-bottom: 0.938rem;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: #e4e4e4;
}

.Message-box-profff {
  display: flex;
  flex: 1;
}

.profile-img-inner-name {
  width: 1rem;
  height: 1.313rem;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  color: #7e7e7e;
  margin-left: 0.9rem;
  margin-top: 0.415rem;
}

.profile-img-inner-pic {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
}

.profile-img-inner-pic12 {
  height: 2.815rem;
  width: 2.815rem;
  border-radius: 50%;
}

.Ellipse-message-dot {
  height: 0.625rem;
  width: 0.625rem;
  border-radius: 50%;
  background-color: #9e9e9e;
  margin-top: 1.688rem;
  margin-left: -0.563rem;
}

.Ellipse-message-dot12 {
  height: 0.625rem;
  width: 0.625rem;
  border-radius: 50%;
  background-color: #9e9e9e;
  margin-top: 31px;
  margin-left: -9px;
}

.candidate-profile-data {
  font-size: 0.875rem;
  font-weight: 500;
  color: #1e1e1e;
  margin-left: 0.625rem;
}

.candidate-profile-name {
  /* padding-bottom: 0.188px; */
  line-height: normal;
  max-width: 10.625rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.candidate-message-txt {
  font-size: 0.75rem;
  color: #5e5e5e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 9rem;
}

.candidate-message-txt12 {
  font-size: 0.75rem;
  color: #5e5e5e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 9rem;
  margin-top: 0.313rem;
}

.set-data-timing-txt {
  /* padding-right: 0.9375rem;   */
  font-size: 0.75rem;
  font-weight: normal;
  color: #7e7e7e;
  padding-left: 3px;
  line-height: 1.5;
}

.set-content-txt {
  /* padding-right: 0.9375rem;   */
  font-size: 0.75rem;
  font-weight: normal;
  color: #7e7e7e;
}

.set-data-timing-txt12 {
  margin-top: 0.125rem;
  /* padding-right: 0.9375rem;   */
  font-size: 0.75rem;
  font-weight: normal;
  color: #7e7e7e;
}

.profile-img-candidate12 {
  margin-bottom: 0.938rem;
  margin-left:5px;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: #e4e4e4;
}

.profile-img-inner-name12 {
  width: 1rem;
  height: 1.313rem;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  color: #7e7e7e;
  margin-left: 18px;
  margin-top: 8.64px;
}

.candidate-profile-data12 {
  font-size: 0.875rem;
  font-weight: 500;
  color: #1e1e1e;
  margin-left: 1.5rem;
}

.set-data-timing12 {
  line-height: 1.5;
  margin-top: 8px;
}
@media only screen and (max-width: 768px) {
  .candidate-profile-name {
    font-weight: 600;
    margin-top: 0.125rem;
  }
  .Message-box-container-message {
    padding: 0.75rem 0.625rem 0.75rem;
  }

  .Message-box-profff {
    height: 2.5rem;
  }
  .messageMeta {
    gap: 0.5rem;
    height: 2.5rem;
    align-items: flex-end;
  }
  .candidate-profile-data12 {
    font-size: 0.75rem;
    margin-left: 0.9375rem;
  }
  .set-data-timing-txt {
    color: #9e9e9e;
    line-height: normal;
  }
  .profile-img-inner-pic12,
  .profile-img-candidate12 {
    height: 2.5rem;
    width: 2.5rem;
    margin-bottom: 0rem;
  }

  .profile-img-candidate12{
    margin-left: 0.7438rem;
  }
  .set-data-timing12 {
    margin-top: 0rem;
    line-height: normal;
    color: #727272;
  }
  .searchMobiledropdown {
    padding: 0.9375rem;
  }
  .searchMobiledropdown .candidate-profile-data12 {
    margin-left: 0.75rem;
  }
  .searchMobiledropdown .candidate-profile-name {
    font-size: 0.875rem;
    margin: 0rem;
    max-width: 18.125rem;
  }
  .searchMobiledropdown .profile-img-inner-pic12,
  .searchMobiledropdown .profile-img-candidate12 {
    height: 1.875rem;
    width: 1.875rem;
    margin: 0rem;
  }
  .searchMobiledropdown .candidate-message-txt12{
    line-height: normal;
    font-size: 0.625rem;
    color: #7e7e7e;
  }
  .searchMobiledropdown .messageMeta,
  .searchMobiledropdown .Message-box-profff {
    height: 2.125rem;
  }
  .searchMobiledropdown .Ellipse-message-dot12 {
    margin-top: 1.25rem;
    margin-left: -0.625rem;
  }
}
