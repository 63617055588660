.container-sidebar {
  height: 99.9%;
  width: 35%;
  transition: all 3s;
  /* transition-delay: display 1s; */
  position: fixed;
  z-index: 1;
  top: 4.05rem;
  right: 0;
  background-color: #ffffff;
  transition: 1s ease;
  overflow-x: hidden;
  border: solid black 0.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.sidenav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* border: solid black 0.1rem; */
  padding-top: 5rem;
  font-size: 16px;
}
.profilename-sidebar{
  margin: 0rem 0rem 0rem 0rem;
}
.logOut-sidebar{
  margin-top: 1rem;
  margin-right: 0rem;
  color: black;
}
.logOut-sidebar:hover{
  color:darkturquoise;
}

@media screen and (max-width: 450px) {
  .container-sidebar {
    width: 80%;
  }
  .sidenav{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* border: solid black 0.1rem; */
    padding-top: 5rem;
    font-size: 16px;
  }
  .profilename-sidebar{
    
  }
  .logOut-sidebar{
    /* display: none; */
  }
  .logOut-sidebar:hover{
    /* display: none; */
  }
  .icon {
    /* display: none; */
    margin-top: 0.25rem;
    /* margin-bottom: 10rem; */
    margin-right: 25rem;
    position: fixed;
  }
  .back {
    /* display: none; */
    margin-bottom: 10rem;
    margin-right: 20rem;
    font-size: 14px;
  }
}
@media screen and (max-width: 360px) {
  .container-sidebar {
    width: 55%;
  }
  .sidenav{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* border: solid black 0.1rem; */
    padding-top: 5rem;
    font-size: 16px;
  }
  .profilename-sidebar{
    
  }
  .logOut-sidebar{
    /* display: none; */
  }
  .logOut-sidebar:hover{
    /* display: none; */
  }
  .icon {
    /* display: none; */
    margin-top: 0.25rem;
    /* margin-bottom: 10rem; */
    margin-right: 25rem;
    position: fixed;
  }
  .back {
    /* display: none; */
    margin-bottom: 10rem;
    margin-right: 20rem;
    font-size: 14px;
  }
}

/* .sidenav:hover {
  width: 35%;
} */
.icon {
  margin-top: 0.25rem;
  /* margin-bottom: 10rem; */
  margin-right: -25rem;
  position: fixed;
}
.back {
  margin-bottom: 10rem;
  margin-right: 20rem;
  font-size: 14px;
  position: fixed;
}

