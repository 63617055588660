.chat-section-header {
    font-weight: bold;
    color: #1e1e1e;
    font-size: 14px;
    margin-bottom: 5%;
    margin-top: 5%;
}

.chat-filter-radios {
    margin-bottom: 6%;
}

.message-filter-options {
    display: flex;
    justify-content: space-between;
    width: 260px;
}

.filter-container {
    height: 62vh;
    overflow: auto;
}

.filter-container::-webkit-scrollbar {
    width: 5px;
    height: 5px !important;
}

.filter-container::-webkit-scrollbar-track {
    background: #fffefe;
}

.filter-container::-webkit-scrollbar-thumb {
    background: rgb(201, 201, 201);
}

.filter-container::-webkit-scrollbar-thumb:hover {
    background: rgb(201, 201, 201);
}

@media screen and (max-width: 768px) {
    .filter-container{
        height: 50vh;
    }
}