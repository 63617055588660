.SkillDensityPieChart-Cantainer .Skill-PieChart-Component {
    display: flex;
    justify-content: space-between;
    /* min-width: 25rem; */
    font-size: .875rem;
    color: #7e7e7e;
    flex-direction: column;
}

.SkillDensityPieChart-Cantainer .SkillDensityPieChart-Density {
    font-size: 1rem;
    font-weight: 500;
    color: #7e7e7e;
    padding-bottom: 1.25rem;
}

.SkillDensityPieChart-Cantainer .SkillDensityPieChart-Density-name {
    align-items: center;
    font-size: .875rem;
    font-weight: normal;
    color: #3e3e3e;
    padding-bottom: 0.3125rem;
    display: flex;
}

/* .SkillDensityPieChart-Cantainer .SkillDensityPieChart-Digram {
    display: flex;
    justify-content: space-between;
} */

.SkillDensityPieChart-Cantainer .SkillDensityPieChart-Digram-plot {
    max-width: 7.5rem
}

.SkillDensityPieChart-Density-color {
    margin-left: .625rem;
    width: 12rem;
}

.SkillDensityPieChart-Density-name .ant-row {
    align-items: center;
}

.SkillDensityPieChart-Digram .ant-row {
    flex-flow: unset;
}

@media screen and (max-width: 700px) {

    .SkillDensityPieChart-Cantainer .SkillDensityPieChart-Density {
        font-size: .75rem;
        font-weight: normal;
    }

    .SkillDensityPieChart-Digram .ant-col-18 {
        max-width: 59%;
    }

    .SkillDensityPieChart-Density-color {
        width: unset;
    }
}