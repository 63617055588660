/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

.container-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: auto;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  margin: auto;
  background-color: #777;
}
.container-search .searchbox-search {
  height: 2.2rem;
  width: 15rem;
  border-radius: 2rem;
  border: solid #777;
}
.icons {
  padding-left: 25px;
  background: url("https://img.icons8.com/ios-filled/50/ffffff/search.png")
    no-repeat left;
  background-size: 1.25rem;
  background-position: 0.438rem;
}
.search-gigs input[type="text"]::-webkit-input-placeholder {
  /* text-align: left; */
  color: #969292;
  margin-left: 1rem;
  font-size: 16px;
}
.search-gigs input[type="text"]::-moz-placeholder {
  /* text-align: left; */
  color: #969292;
  margin-left: 1rem;
  font-size: 16px;
}
.search-gigs input[type="text"]:-ms-input-placeholder {
  /* text-align: left; */
  color: #969292;
  margin-left: 1rem;
  font-size: 16px;
}
.search-gigs input[type="text"]::-ms-input-placeholder {
  /* text-align: left; */
  color: #969292;
  margin-left: 1rem;
  font-size: 16px;
}
.search-gigs input[type="text"]::placeholder {
  /* text-align: left; */
  color: #969292;
  margin-left: 1rem;
  font-size: 16px;
}
.search-gigs .ant-input:focus {
  outline: none;
  box-shadow: none;
}
/* .search-gigs .ant-input-focused {
  box-shadow: red !important
}

.ant-input:placeholder-shown {
  text-overflow: ellipsis;
  box-shadow: #1e1e1e !important;
} */

.certain-category-search-dropdown .rc-virtual-list-scrollbar {
  width: 2px !important;
  right: -9px !important;
  color: #7e7e7e !important;
}
.dropdown-menuu{
  position:absolute;
  background-color:#fff;
  border: solid 1px #e4e4e4;
  top: calc(100% + -1.05rem);
  width:28vw;
  padding-bottom: 20px;
}

.dropdown-menuu-insidebox
{
  display: flex;
  gap:8rem;
  justify-content: space-between;
  padding: 1.25rem 1rem 0.625rem 0.938rem;
}
.dropdown-menuu-recentSearch{  
   color: #1e1e1e;
   font-size: 14px;
   font-weight: 600;
}
.dropdown-menuu-clear-click{
   color:#118936;
   font-size:14px;
   cursor:pointer !important;
}
.dropdown-menuu-clear-recentjobss{
  display: flex;
  gap: 10px;
  padding: 5px 0px 5px 15px;
  color:black;
}
.dropdown-menuu-clear-trendingJobss{
  padding:1.25rem 0.938rem 0.625rem 0.938rem;
  color:#1e1e1e !important;
  font-weight: 600;
}
.dropdown-menuu-clear-recentjobss-onclick{
    color:#9e9e9e;
    font-size:0.75rem;
    font-weight: 500;
  
  }
  .dropdown-menuu-clear-recentjobss-inner{
    padding:0rem 0.938rem 0.063rem 0.938rem;
    display:flex;
    gap:0.625rem;
    flex-wrap: wrap;
  }
  .dropdown-menuu-clear-recentjobss-inside{
   display:flex;
   align-items: center;
   border: solid 0.063rem #d4d4d4;
   border-radius: 0.125rem;
   gap:0.313rem;
   padding: 0.313rem;
   justify-content: space-between;
   cursor:pointer !important ;
  }
  .dropdown-menuu-clear-recentjobss-inside > .dropdown-menuu-recentlyview-icon{
    width: 0.75rem;
    height: 0.75rem;
    display: block;
  }
/* .certain-category-search-dropdown::-webkit-scrollbar-track {
  width: 2px !important;
} */
.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #7e7e7e;
  font-weight: bold;
  /* font-family: SFProText;
  font-size: 16px; */
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 1rem;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 18.75rem;
}

.certain-category-search-dropdown {
  /* min-width: 27.8rem !important; */
  /* left: 385px !important; */
  /* left: 24.3% !important; */
  /* min-width: 31.5% !important;
  left: 28.3% !important; */
  /* display: flex;
  align-content: center;
  align-self: center;
  justify-content: center; */
  /* min-width: 30px; */
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  position: static;
  /* top: -9999px; */
  /* left: 100; */
  z-index: 1051;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.938rem 0.938rem 0.938rem 0.938rem;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  -webkit-box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.35);
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  /* box-shadow: ;
}
.searchInputDashboard
  .search-gigs
  .ant-select-dropdown
  .certain-category-search-dropdown
  .ant-select-dropdown-placement-bottomLeft {
  /* width: 100px; */
  min-width: 30rem;
  left: 10px;
  top: 55px;
}
.certain-category-search-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: none;
}
.search-gigs
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-search-input {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.sbar-render-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  /* font-size: 16px; */
  color: #1e1e1e;
}

.sbar-render-title1,
.sbar-render-title2 {
  display: flex;
  align-items: center;
  margin-bottom: 0.9375rem;
}

.sbar-render-title2 {
  margin-top: 0.5rem;
}

.sbar-render-title > .inner-div {
  padding-left: 1.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
}

.certain-category-search-dropdown .ant-select-item {
  padding: 0rem;
  min-height: auto;
}
.certain-category-search-dropdown
  .ant-select-item:last-child
  .sbar-render-title
  > .inner-div {
  padding-bottom: 0rem;
}
.Highlight_Seacrh_Value {
  color: #7e7e7e;
}
/* .sbar-render-title .inner-div::first-letter {
  color: #7e7e7e !important;
  font-size: 16px;
} */

.search-gigs .ant-select {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.search-gigs {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.sbar-suggestion-icon {
  margin: 0 10px 0 0;
  width: 18px;
  height: 18px;
}

.search-gigs .ant-select-selection-search-input {
  position: relative;
}

.search-gigs .ant-select-selection-search-input .closeIcon {
  display: none;
}

.searchInputBox {
  position: relative;
}

.search-gigs .ant-select-selection-search-input .visibleCloseIcon {
  display: block;
  position: absolute;
  min-width: 1.25rem;
  min-height: 1.25rem;
  top: 1.5625rem;
  right: 0.3125rem;
}

.search-gigs .ant-select-selection-search-input .ant-input {
  padding-right: 2rem;
}

@media screen and (max-width: 1000px) {
  .search-gigs .ant-select {
    width: 92vw;
  }
  .sbar-render-title1 {
    font-size: 14px;
    color: #7e7e7e;
    /* margin: 10px 0 0 0; */
  }
  .sbar-render-title2 {
    font-size: 14px;
    color: #7e7e7e;
  }
  .sbar-render-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 40px;
    font-size: 14px;
    color: #1e1e1e;
    font-weight: 500;
  }
  .sbar-suggestion-icon {
    margin: 0 10px 0 0;
    width: 15px;
    height: 15px;
  }
  .sbar-render-title > .inner-div {
    padding-left: 1.5625rem;
  }
  .search-gigs .ant-select-selection-search-input .closeIcon {
    top: 0.3125rem;
    right: 0.4375rem;
  }
  
}
@media screen and (max-width: 768px) {
  .dropdown-menuu {
    max-width: 22.598rem;
    width: 93%;
    height: 40rem;
  }
  .searchInputBox {
    width: 100%;
  }
}
