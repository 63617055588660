.conversationsDetails .send-message-container {
  padding: 0.3125rem 0.625rem;
  bottom: 0;
  position: absolute;
  width: 100%;
  background: #fff;
}
.conversationsDetails .send-message-container .ant-input {
  padding: 0rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  transition: all 0.3s, height 0s;
}

.conversationsDetails .send-message-container .teaxArea-content {
  padding: 0.75rem 0.9375rem 0.6875rem 0.9375rem;
  height: 2.5rem;
  font-size: 0.875rem;
  width: 100%;
  border: none;
  box-shadow: none;
  line-height: 1.1625rem;
  overflow: hidden;
  resize: none;
  transition: all 0.3s, height 0s;
}

.attachment-box {
  margin: 0rem;
}
.attachment-container {
  width: 50%;
  margin: 0.75rem 0.9375rem 0.6875rem;
  position: relative;
}

.attachment-content {
  width: 100%;
  padding: 0.625rem 0.75rem 0.9375rem;
  border-radius: 0.3125rem;
  border: solid 0.0625rem #c4c4c4;
  display: flex;
  gap: 0.9375rem;
}

.attachment-content .fileIcon {
  max-width: 1.25rem;
  max-height: 1.625rem;
  fill: #cb0606;
}

.attachment-content-info {
  width: 90%;
}

.attachment-content-info > h3 {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0rem;
  padding-bottom: 0.125rem;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.attachment-content-info > p {
  margin: 0rem;
  font-size: 0.625rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9e9e9e;
}

.attachment-container .attachment-container-close-btn {
  position: absolute;
  top: -0.4375rem;
  right: -0.5rem;
}

.conversationsDetails .send-message-container .message-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.125rem;
}

.conversation-icon {
  line-height: 0.0625rem;
}

.conversation-icon > label {
  min-width: 1.375rem;
  min-height: 1.5rem;
  margin: 0rem;
}

.conversation-icon > label svg.conversation-icon {
  min-width: 1.375rem;
  min-height: 1.5rem;
  margin: 0rem;
}

a.message-send-btn {
  color: #118936;
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 768px) {
  .Message-Detial {
    background-color: #ffffff;
    margin-bottom: 4.6875rem;
  }

  .send-message-container {
    /* position: fixed !important; */
    bottom: 0rem;
    left: 0rem;
  }

  .conversationsDetails .send-message-container {
    width: 100%;
    padding: 0rem 0.9375rem 1.25rem;
    border-top: 0.0625rem solid #e4e4e4;
  }

  .conversationsDetails .send-message-container .ant-input {
    /* height: 3.3125rem; */
    padding: 0rem;
    padding: 1rem 0rem;
    box-shadow: none;
    outline: none;
    border: none;
  }

  /* .conversationsDetails .send-message-container .teaxArea-content {
    height: 3.3125rem;
  } */

  .conversationsDetails .send-message-container .message-bottom {
    margin-top: 0rem;
  }

  .text-with-attachment.ant-input {
    padding-top: 0rem !important;
  }

  .attachment-container {
    width: 50%;
    margin-left: 0rem;
  }

  .attachment-content-info {
    width: 75%;
  }

  .attachment-content-info > h3 {
    width: 100%;
  }

  .conversation-icon,
  .conversation-icon svg {
    max-width: 1.25rem;
    max-height: 1.25rem;
    margin: 0;
  }

  a.message-send-btn {
    max-height: 1.25rem;
    line-height: 1.625rem;
  }
}
/********** For Mobile View  Media Query End **********/
