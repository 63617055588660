.profile-avatar:hover div {
  background-color: #283928;
}

.profile-avatar-skill:hover div {
  background-color: unset !important;
}

.profile-avatar:hover {
  background-color: #283928;
}

.profile-avatar:hover .overlay {
  opacity: 1;
}

.profile-avatar > .profilePicture {
  width: 9.5rem;
  height: 9.5rem;
}

.profile-avatar > .profilePicture .candidate-picture {
  width: 9.5rem;
  height: 9.5rem;
  border-radius: 50%;
  object-fit: cover;
}

.profile-avatar > .profilePicture .candidate-picture-skill {
  width: 9.5rem;
  height: 9.5rem;
  border-radius: 50%;
  object-fit: cover;
}

.profile-avatar > .candidate-picture-dummy {
  display: none;
}

.profile-avatar > .profilePicture .candidate-name {
  display: grid;
  place-items: center;
  height: 100%;
}

.profile-avatar > .profilePicture .candidate-name-skill {
  display: grid;
  place-items: center;
  height: 100%;
}

.profile-avatar .on-hover {
  display: none;
}

.profile-avatar:hover .profilePicture .candidate-picture {
  display: none;
}

/* .profile-avatar:hover .profilePicture .candidate-picture-skill {
  display: none;
} */

.profile-avatar:hover .profilePicture {
  display: grid;
  place-items: center;
}

.profile-avatar:hover {
  background-color: #283928;
  width: 9.375rem;
  height: 9.375rem;
}

.profile-avatar-skill:hover {
  background-color: var(--primaryColor) !important;
  /* width: unset !important;
  height: unset !important; */
}

.profile-avatar:hover .on-hover {
  display: inline-flex;
  text-align: center;
}

.profile-avatar:hover .profilePicture .candidate-name {
  display: none;
}

/* .profile-avatar:hover .profilePicture .candidate-name-skill {
  display: none;
} */

@media screen and (max-width: 768px) {
  .profile-avatar:hover div {
    background-color: #f5f0f0;
  }

  .profile-avatar > .profilePicture {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .profile-avatar > .profilePicture .candidate-picture {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 50%;
  }

  .profile-avatar > .profilePicture .candidate-picture-skill {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 50%;
  }

  .profile-avatar > .profilePicture .candidate-name {
    display: flex;
    justify-content: center;
    position: relative;
    /* margin-top: 1.6875rem; */
  }

  .profile-avatar > .profilePicture .candidate-name-skill {
    display: flex;
    justify-content: center;
    /* margin-top: 1.6875rem; */
  }

  .profile-avatar:hover .profilePicture {
    display: block;
    /* place-items: center; */
  }

  .profile-avatar:hover {
    width: 9.5rem;
    height: 9.5rem;
    background: var(--primaryColor);
  }

  .profile-avatar:hover .on-hover {
    display: none !important;
    text-align: center;
  }

  .profile-avatar:hover .profilePicture .candidate-picture {
    display: block;
  }

  .profile-avatar:hover .profilePicture .candidate-picture-skill {
    display: block;
  }

  .profile-avatar:hover .profilePicture .candidate-name {
    display: flex;
    background: none;
  }

  .profile-avatar:hover .profilePicture .candidate-name-skill {
    display: flex;
    background: none;
  }

  .profile-avatar > .candidate-picture-dummy {
    display: none;
  }

  .profile-avatar .edit-profile-camera-mobile {
    width: 2.19rem;
    height: 2.19rem;
    flex-grow: 0;
    background-color: #f5f0f0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
